import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Papa from 'papaparse';
import { useSelector, useDispatch } from 'react-redux';
import { getCategories } from 'redux/category/categoryActions';
import { createProductsBulk } from 'redux/products/productActions';
import { ADD_PRODUCT_BULK } from 'redux/products/productTypes';
import Loading from 'components/Loading';

const ImportConfirmFields = ({ file, handleNext, handleBack, data }) => {
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.category.categories);
  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const [form, setForm] = useState({
    categoryId: '',
    items: [],
  });

  const handleChange = (e) => {
    setForm({ ...data, [e.target.name]: e.target.value });
  };
  const mandatoryFields = ['name', 'description', 'price', 'quantity', 'image'];

  const handleFinish = () => {
    const categoriedSelectedData = data.map((data) => ({
      // ...data,
      name: data.name,
      description: data.description ?? '',
      image: data.image ? data.image.split(',')[0] : '',
      categoryId: form.categoryId,
      retailPrice: data.price.length > 0 ? parseFloat(data.price) : 0,
      buyingPrice: data.price.length > 0 ? parseFloat(data.price) : 0,
      quantity: data.quantity.length > 0 ? parseFloat(data.quantity) : 1,
    }));

    dispatch(createProductsBulk(categoriedSelectedData));
  };

  useEffect(() => {
    if (notification && notification.action == ADD_PRODUCT_BULK) {
      handleNext();
    }
  }, [notification]);
  return (
    <Stack direction="column" spacing={2} sx={{ py: 2 }}>
      <Typography variant="body2">Confirm your Data</Typography>
      <Stack spacing={2} direction="row">
        {/* Data */}
        {data && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {Object.keys(data[0])
                    .filter((field) => mandatoryFields.includes(field))
                    .map((rows, index) => {
                      return (
                        <TableCell align="right" key={index}>
                          {rows}
                        </TableCell>
                      );
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(0, 5).map((value, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {Object.values(value).map((val, i) => {
                        return (
                          <TableCell align="right" key={i}>
                            {val}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {/* Categories */}
        {categories && (
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor={`outlined-categories-simple`}>
              Select Category
            </InputLabel>
            <Select
              fullWidth
              labelId="outlined-categories-simple"
              value={data.categoryId}
              onChange={handleChange}
              name="categoryId"
              label="Select Category"
            >
              {categories.data.map((category) => (
                <MenuItem key={category.id} value={category.id}>
                  <em>{category.name}</em>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button
          color="inherit"
          disabled={loading}
          onClick={handleBack}
          sx={{ mr: 1 }}
        >
          Back
        </Button>
        <Box sx={{ flex: '1 1 auto' }} />

        <Button
          color="primary"
          variant="contained"
          disabled={loading || form.categoryId.length === 0}
          onClick={() => handleFinish()}
        >
          Finish
        </Button>
      </Box>
      {loading && categories && (
        <Loading title="Importing Products... Please wait" />
      )}
    </Stack>
  );
};

export default ImportConfirmFields;
