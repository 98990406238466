import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { Link } from 'react-router-dom';
import CustomSearchRender from 'utils/CustomSearchRender';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { getProducts } from '../../../redux/products/productActions';
import { numberFormatText } from '../../../utils/functions';
const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    margin: theme.spacing(1),
  },
}));

const CategoryProducts = ({ id }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);
  const store = useSelector((state) => state.auth.store);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    categoryId: id,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: getSortOrder('id'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sortOrder: getSortOrder('name'),
        print: false,
        filter: false,
      },
    },

    {
      name: 'categoryId',
      label: 'Category ID',
      options: {
        display: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'categoryName',
      label: 'Category',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('price'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Chip
              label={value}
              component={Link}
              style={{ cursor: 'pointer' }}
              to={`/admin/categories/detail/${tableMeta.rowData[2]}`}
            />
          );
        },
      },
    },
    {
      name: 'subCategoryId',
      label: 'Sub Category ID',
      options: {
        display: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'subCategoryName',
      label: 'Category',
      options: {
        display: false,
        sort: true,
        filter: false,
      },
    },
    {
      name: 'price',
      label: `Price${store ? `(${store.currency})` : ''}`,
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('price'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {numberFormatText(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: 'buyingPrice',
      label: `Buying Price${store ? `(${store.currency})` : ''}`,
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('buyingPrice'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {numberFormatText(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: 'margin',
      label: 'Margin',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('buyingPrice'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {value}%
            </Typography>
          );
        },
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <div>
              <Button
                component={Link}
                to={`/admin/products/edit/${tableMeta.rowData[0]}`}
                label={value}
                variant="outlined"
                type="submit"
                color="secondary"
              >
                View
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: false,
    downloadOptions: {
      filename: 'products.csv',
      useDisplayedRowsOnly: false,
    },

    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    count: products && products.count,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    elevation: 0,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true,
    },
    fixedHeader: true,
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getProducts(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (products) {
      var finalData =
        products &&
        products.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [products]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          sx={{ m: 2 }}
          variant="contained"
          size="large"
          color="primary"
          component={Link}
          to="/admin/products/add"
          className={classes.button}
          startIcon={<AddBoxIcon />}
        >
          Add Product
        </Button>
      </div>
      {data && <MUIDataTable data={data} columns={columns} options={options} />}
    </div>
  );
};

export default CategoryProducts;
