import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { grey } from '@mui/material/colors';
import { NumberFormatCustom, toTitleCase } from '../../../utils/functions';
import { SELLING_PRICE_OPTION } from '../../../redux/products/productTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(4),
  },
}));

const SectionPricing = ({ updateForm }) => {
  const classes = useStyles();

  const [form, setForm] = useState({
    buyingPrice: 0,
    wholesalePrice: 0,
    retailPrice: 0,
    minimumPrice: 0,
    sellingPriceOption: SELLING_PRICE_OPTION.RETAIL,
  });

  const current = useSelector((state) => state.product.current);
  const store = useSelector((state) => state.auth.store);
  useEffect(() => {
    if (current) {
      setForm(Object.assign({}, form, current));
    }
    // eslint-disable-next-line
  }, [current]);
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    updateForm({ ...form });
    // eslint-disable-next-line
  }, [form]);

  return (
    <Paper>
      <Stack direction="column">
        <Stack
          sx={{ p: 2, backgroundColor: grey[200], overflow: 'hidden' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="column">
            <Typography variant="h6">
              <strong>Pricing</strong>
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Set the Pricing Options for this product
            </Typography>
          </Stack>
        </Stack>

        <Grid sx={{ p: 2 }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="Retail Price"
              value={form.retailPrice}
              label="Retail Price"
              name="retailPrice"
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {store ? store.currency : ''}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="Buying Price"
              label="Buying Price"
              name="buyingPrice"
              value={form.buyingPrice}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {store ? store.currency : ''}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="Wholesale Price"
              label="Wholesale Price"
              name="wholesalePrice"
              value={form.wholesalePrice}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {store ? store.currency : ''}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="MinimumPrice"
              label="Minimum Price"
              name="minimumPrice"
              value={form.minimumPrice}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {store ? store.currency : ''}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-selling-price-simple">
                Select Selling Price Option
              </InputLabel>
              <Select
                fullWidth
                variant="outlined"
                placeholder="Select Selling Price Option"
                label="Select Selling Price Option"
                value={form.sellingPriceOption}
                onChange={onChange}
                name="sellingPriceOption"
                inputProps={{
                  id: 'outlined-selling-price-simple',
                }}
              >
                {Object.entries(SELLING_PRICE_OPTION).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    <em>{toTitleCase(value)}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default SectionPricing;
