import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import { getProductById } from 'redux/products/productActions';
import Loading from 'components/layout/Loading';
import { numberFormatText } from 'utils/functions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InventoryDetailDialog = ({
  id,
  inventoryDetailDialogOpen,
  handleInventoryDetailDialogClose,
}) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product.current);
  const store = useSelector((state) => state.auth.store);

  React.useEffect(() => {
    if (id) {
      dispatch(getProductById(id));
    }
  }, [id]);

  return (
    <div>
      <Dialog
        open={inventoryDetailDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleInventoryDetailDialogClose}
        aria-describedby="product-detail-dialog"
      >
        {product ? (
          <>
            <DialogTitle>{product.name}</DialogTitle>
            <DialogContent>
              <Stack direction="row" spacing={2}>
                <Stack direction="column">
                  {product.image ? (
                    <img
                      style={{ width: '100%', height: '100%' }}
                      src={product.image}
                      width={100}
                      height={100}
                      alt={product.name}
                    />
                  ) : (
                    <Avatar
                      name={product.name}
                      style={{ width: '100%' }}
                      color={product.color}
                    />
                  )}
                  {/* <Typography variant="body1" color="textSecondary">
                    {product.description}
                  </Typography> */}
                </Stack>
                <Stack direction="column" spacing={2}>
                  <Paper
                    sx={{
                      px: 2,
                      py: 3,
                      bgcolor: 'background.paper',
                    }}
                  >
                    {/* Total Product No */}
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Stack direction="column" justifyContent="center">
                        <Box
                          variant="h6"
                          sx={{
                            color: 'text.primary',
                            fontWeight: 'medium',
                            fontSize: 30,
                          }}
                        >
                          {product.quantity}
                        </Box>
                        <Typography
                          sx={{ color: 'text.secondary' }}
                          variant="body1"
                        >
                          Current Quantity
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                  <Paper sx={{ px: 2, py: 3 }}>
                    {/* Total Product No */}
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Stack direction="column" justifyContent="space-between">
                        <Box
                          variant="h6"
                          sx={{
                            color: 'text.primary',
                            fontWeight: 'medium',
                            fontSize: 30,
                          }}
                        >
                          {numberFormatText(
                            product.quantity * product.price,
                            store ? store.currency : ''
                          )}
                        </Box>

                        <Typography
                          sx={{ color: 'text.secondary' }}
                          variant="body1"
                        >
                          Total Inventory Value
                        </Typography>
                      </Stack>
                    </Stack>
                  </Paper>
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleInventoryDetailDialogClose}>CLOSE</Button>
            </DialogActions>
          </>
        ) : (
          <Loading title="Fetching Product" />
        )}
      </Dialog>
    </div>
  );
};

export default InventoryDetailDialog;
