import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { NumberFormatCustom, numberFormatText } from 'utils/functions';
import {
  getPurchaseOrderById,
  updatePurchaseOrderItemsReceive,
} from 'redux/purchase-orders/purchaseOrderActions';
import {
  getInventoryCountById,
  makeInventoryCount,
} from 'redux/inventory-count/inventoryCountActions';
import { getNumberColor } from 'utils/StatusColor';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const InventoryCountStep = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);
  const notification = useSelector((state) => state.notification.notification);
  const current = useSelector((state) => state.inventoryCount.current);

  const [items, setItems] = useState([]);

  const [completeCountOpen, setCompleteCountOpen] = useState(false);

  useEffect(() => {
    if (!current && id) {
      dispatch(getInventoryCountById(id));
    }
    if (current) {
      // Set Default Items
      setItems(
        current.products.map((product) => ({
          productId: product.productId,
          productName: product.productName,
          price: product.productPrice,
          counted: product.counted ?? 0,
          expected: product.expected,
          difference: product.expected - product.counted,
          costDifference:
            (product.expected - product.counted) * product.productPrice,
          updated: false,
        }))
      );
    }
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    if (notification && notification.type === 'success') {
      navigate(`/admin/inventory/inventory-count/detail/${id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleChange = (_item) => (e) => {
    const countedItem = {
      ..._item,
      productId: _item.productId,
      counted: parseInt(e.target.value),
      difference: parseInt(e.target.value - _item.expected),
      costDifference: parseInt((e.target.value - _item.expected) * _item.price),
      updated: true,
    };

    let _items = items.map((item) =>
      item.productId === countedItem.productId ? countedItem : item
    );
    setItems(_items);
  };

  const handleCount = (isComplete = false) => {
    dispatch(makeInventoryCount(id, { items: items, isComplete }));
  };

  if (!current) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Paper>
          <Stack
            style={{ padding: '1rem' }}
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="h5" color="textSecondary">
              Items
            </Typography>
          </Stack>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>Item</TableCell>
                  <TableCell align="right">Expected Stock</TableCell>
                  <TableCell align="right">Counted</TableCell>
                  <TableCell align="right">Difference</TableCell>
                  <TableCell align="right">
                    Cost Difference
                    {store && store.currency ? `(${store.currency})` : ''}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items &&
                  items.map((item, idx) => {
                    return (
                      <TableRow
                        key={idx}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={3} component="th" scope="row">
                          {item.productName}
                        </TableCell>
                        <TableCell align="right">
                          {numberFormatText(item.expected)}
                        </TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            type="number"
                            value={item.counted}
                            name="counted"
                            placeholder="0"
                            onChange={handleChange(item)}
                            inputProps={{ style: { textAlign: 'right' } }}
                          />
                        </TableCell>
                        <TableCell
                          sx={{
                            color: getNumberColor(item.difference),
                          }}
                          align="right"
                        >
                          <strong>{numberFormatText(item.difference)}</strong>
                        </TableCell>
                        <TableCell
                          sx={{
                            color: getNumberColor(item.costDifference),
                          }}
                          align="right"
                        >
                          <strong>
                            {numberFormatText(item.costDifference)}
                          </strong>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Grid
          style={{ marginTop: '1rem' }}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              onClick={() => navigate('/admin/inventory/inventory-count')}
              variant="outlined"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={loading}
              onClick={() => handleCount()}
              variant="outlined"
              color="primary"
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={loading}
              onClick={() => setCompleteCountOpen(true)}
              variant="contained"
              color="primary"
            >
              Complete
            </Button>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
        <Dialog
          open={completeCountOpen}
          onClose={() => setCompleteCountOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Confirm Complete Inventory Count'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please confirm changes. This action cannot be undone.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Stock level of {items.filter((item) => item.updated).length} will
              be updated
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCompleteCountOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                handleCount(true);
                setCompleteCountOpen(false);
              }}
              autoFocus
            >
              Confirm Count
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
};

export default InventoryCountStep;
