import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import {
  addCustomerGroup,
  getCustomerGroupById,
  updateCustomerGroup,
} from 'redux/customer-group/customerGroupActions';
import CustomerSelectList from './CustomerSelectList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddEditCustomerGroup = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const current = useSelector((state) => state.customerGroup.current);

  const [customerPickerOpen, setCustomerPickerOpen] = useState(false);

  const handleCustomerPickerClose = () => {
    setCustomerPickerOpen(false);
  };

  useEffect(() => {
    if (id) {
      dispatch(getCustomerGroupById(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const [form, setForm] = useState({
    name: null,
    description: '',
    customerIds: [],
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Customer Group') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (current) {
      dispatch(updateCustomerGroup(form, current.id));
    } else {
      dispatch(addCustomerGroup(form));
    }
  };

  useEffect(() => {
    if (current) {
      setForm({ ...current });
    }
    // eslint-disable-next-line
  }, [current]);

  const handleCustomerIds = (ids) => {
    setForm({ ...form, customerIds: ids });
  };

  useEffect(() => {
    if (current) {
      form.name = current.name;
      form.description = current.description;

      if (current.customers) {
        const customerIds = [];

        for (const customer of current.customers) {
          customerIds.push(customer.id);
        }
        form.customerIds = customerIds;
      }
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  if (id && !current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                label="Name"
                value={form.name}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                label="Description"
                value={form.description}
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                style={{ marginTop: '2rem' }}
                variant="outlined"
                color="primary"
                onClick={() => setCustomerPickerOpen(true)}
              >
                Select Customers{' '}
                {form.customerIds.length !== 0 &&
                  `(${form.customerIds.length})`}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!form.name || loading}
              >
                Save
              </Button>
            </Grid>

            <Grid item xs={12}>
              {loading && <LinearProgress variant="query" color="secondary" />}
            </Grid>
          </Grid>
        </form>
        {/* // Dialog */}
        <CustomerSelectList
          handleCustomerIds={handleCustomerIds}
          customerIds={form.customerIds}
          handleCustomerPickerClose={handleCustomerPickerClose}
          customerPickerOpen={customerPickerOpen}
        />
      </Paper>
    );
  }
};

export default AddEditCustomerGroup;
