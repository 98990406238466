import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import 'rc-color-picker/assets/index.css';
import {
  getCurrentBilling,
  submitFlutterwavePayment,
} from 'redux/billing/billingActions';
import Loading from 'components/Loading';
import BoltIcon from '@mui/icons-material/Bolt';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SettingsBilling = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);

  const billing = useSelector((state) => state.billing.current);
  const current = useSelector((state) => state.auth.current);
  const theme = useTheme();

  const [flutterwaveConfig, setFlutterwaveConfig] = useState({
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: 10,
    currency: 'KES',
    payment_options: 'card,mobilemoney',
    customer: {},
    customizations: {
      title: 'Sturr Subscription',
      description: 'Payment for Monthly Subscription',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
  });

  useEffect(() => {
    if (current) {
      setFlutterwaveConfig({
        ...flutterwaveConfig,
        customer: {
          ...flutterwaveConfig.customer,
          email: current.email,
          phonenumber: current.mobileNumber,
          name: `${current.firstName}${current.lastName}`,
        },
      });
    }
  }, [current]);

  useEffect(() => {
    if (!billing) {
      dispatch(getCurrentBilling());
    }
    //eslint-disable-next-line
  }, [billing]);

  const handleFlutterPayment = useFlutterwave(flutterwaveConfig);

  if (loading || !billing) {
    return <Loading />;
  } else {
    return (
      <Paper className={classes.root}>
        <Stack direction="column" spacing={2}>
          <Typography variant="h6" color="primary">
            Billing
          </Typography>

          <Stack
            spacing={2}
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <Typography variant="body2">
              <strong>Tier</strong>
            </Typography>
            <Typography
              variant="body2"
              sx={{
                p: 1,
                borderRadius: 2,
              }}
              style={{
                backgroundColor: `${
                  billing.tier.includes('FREE')
                    ? theme.palette.info.main
                    : theme.palette.success.main
                }`,
                color: `${
                  billing.tier.includes('FREE')
                    ? theme.palette.info.contrastText
                    : theme.palette.success.contrastText
                }`,
              }}
            >
              {billing.tier}
            </Typography>
            <Button
              color="primary"
              startIcon={<BoltIcon />}
              variant="contained"
              disabled={loading}
              onClick={() =>
                handleFlutterPayment({
                  callback: (data) => {
                    console.log(data);
                    // data.type = form.type;
                    dispatch(submitFlutterwavePayment(data));
                    closePaymentModal(); // this will close the modal programmatically
                  },
                  onClose: () => {},
                })
              }
            >
              Upgrade
            </Button>
            <Stack direction="column">
              <Button
                color="primary"
                component={Link}
                to="/admin/settings/billing/payments"
                variant="contained"
              >
                My Payments
              </Button>
            </Stack>
            {loading && <Loading />}
          </Stack>
        </Stack>
      </Paper>
    );
  }
};

export default SettingsBilling;
