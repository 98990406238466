import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
  getSupplierById,
  getSuppliers,
} from '../../../redux/suppliers/supplierActions';
import { grey } from '@mui/material/colors';
import Loading from 'components/Loading';
import AddUpdateSupplier from './AddUpdateSupplier';
import PurchaseOrderHome from '../purchase-orders/PurchaseOrderHome';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}
const SupplierDetail = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();

  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const loading = useSelector((state) => state.notification.loading);
  const supplier = useSelector((state) => state.supplier.current);

  useEffect(() => {
    dispatch(getSupplierById(id));
    // eslint-disable-next-line
  }, []);

  if (!supplier) {
    return <Loading />;
  } else {
    return (
      <Box sx={{ bgcolor: 'background.paper', width: 1 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="DETAIL" {...a11yProps(0)} />
            <Tab label="PURCHASE ORDERS" {...a11yProps(1)} />
            <Tab label="INVOICES" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <AddUpdateSupplier />
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <PurchaseOrderHome supplierId={supplier.id} />
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            Item Three
          </TabPanel>
        </SwipeableViews>
      </Box>
    );
  }
};

export default SupplierDetail;
