import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import EditIcon from '@mui/icons-material/Edit';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';
import { makeStyles, useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import MailIcon from '@mui/icons-material/Mail';
import PrintIcon from '@mui/icons-material/Print';
import BlockIcon from '@mui/icons-material/Block';
import CustomSearchRender from 'utils/CustomSearchRender';
import { parseISO, format } from 'date-fns/esm';
import { getOrders } from '../../redux/orders/orderActions';
import SalesEmailReceiptDialog from './SalesEmailReceiptDialog';
import { SET_CURRENT_ORDER } from '../../redux/orders/orderTypes';
import { numberFormatText } from 'utils/functions';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const SalesHistory = ({ history }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.order.orders);
  const store = useSelector((state) => state.auth.store);

  const theme = useTheme();

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: getSortOrder('id'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Receipt',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="body1">{tableMeta.rowData[2]}</Typography>
              <Typography variant="body1">
                {format(
                  parseISO(tableMeta.rowData[1], 'yyyy-MM-dd', new Date()),
                  'do MMM yyyy p'
                )}
              </Typography>
            </div>
          );
        },
      },
    },

    {
      name: 'customerId',
      label: 'Customer ID',
      options: {
        sort: false,
        display: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'customerName',
      label: 'Customer',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value ?? '-'}</Typography>;
        },
      },
    },

    {
      name: 'userID',
      label: 'User ID',
      options: {
        sort: false,
        display: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'userName',
      label: 'Sold By',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'notes',
      label: 'Notes',
      options: {
        display: false,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'amount',
      label: 'Total',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              <strong>{numberFormatText(value, store.currency)}</strong>
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'paymentStatus',
      label: 'Payment Status',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'payments',
      label: 'Payments',
      options: {
        display: false,
        filter: false,
        sort: false,
        download: false,
        print: false,
      },
    },
    {
      name: 'products',
      label: 'Products',
      options: {
        display: false,
        filter: false,
        sort: false,
        download: false,
        print: false,
      },
    },
    {
      name: 'subTotal',
      label: 'Sub Total',
      options: {
        display: false,
        filter: false,
        sort: false,
        download: false,
        print: false,
      },
    },
    {
      name: 'taxTotal',
      label: 'Tax Toal',
      options: {
        display: false,
        filter: false,
        sort: false,
        download: false,
        print: false,
      },
    },
    {
      name: 'discountTotal',
      label: 'Discount Total',
      options: {
        display: false,
        filter: false,
        sort: false,
        download: false,
        print: false,
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        display: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <div>
              <Button
                component={Link}
                to={`orders/view/${tableMeta.rowData[0]}`}
                label={value}
                variant="outlined"
                type="submit"
                color="secondary"
              >
                View
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: false,
    downloadOptions: {
      filename: 'sales.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    count: orders && orders.count,
    rowsPerPage: 5,
    elevation: 0,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
    expandableRows: true,
    // expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      const order = orders.data[rowMeta.dataIndex];
      const products = order.products;
      let totalPaymentsMade = order.payments
        ? order.payments.reduce(function (prev, cur) {
            return prev + cur.amount;
          }, 0)
        : 0;

      return (
        <TableRow sx={{ backgroundColor: '#f1f1f1' }}>
          <TableCell colSpan={colSpan - 2}>
            <Table className={classes.table} aria-label="simple table" sx={{backgroundColor:'#ffffff'}}>
              <TableHead>
                <TableRow>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products &&
                  products.map((product) => (
                    <TableRow key={product.id}>
                      <TableCell>{product.quantity}</TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          component={Link}
                          className={classes.labelRow}
                          style={{
                            textDecoration: 'none',
                            color: theme.palette.secondary.main,
                          }}
                          to={`/admin/products/detail/${product.productId}`}
                        >
                          {product.productName}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography variant="body1">
                          <strong>
                            @{numberFormatText(product.amount, store.currency)}
                          </strong>{' '}
                          +{' '}
                          {product.taxAmount &&
                            numberFormatText(
                              product.taxAmount,
                              store.currency
                            ) + ' Tax'}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1">
                          {numberFormatText(
                            product.amount + product.taxAmount ?? 0,
                            store.currency
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow
                  style={{ backgroundColor: theme.palette.action.hover }}
                >
                  <TableCell colSpan={2} />
                  <TableCell colSpan={1}>
                    <strong>Sub Total</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <strong>
                      {numberFormatText(order.subTotal, store.currency)}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow
                  style={{ backgroundColor: theme.palette.action.hover }}
                >
                  <TableCell colSpan={2} />
                  <TableCell colSpan={1}>
                    <strong>Tax Total</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <strong>
                      {numberFormatText(order.taxTotal, store.currency)}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow
                  style={{ backgroundColor: theme.palette.action.hover }}
                >
                  <TableCell colSpan={2} />
                  <TableCell colSpan={1}>
                    <strong>Discount</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <strong>
                      {numberFormatText(order.discountTotal, store.currency)}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow
                  style={{ backgroundColor: theme.palette.action.hover }}
                >
                  <TableCell colSpan={2} />
                  <TableCell colSpan={1}>
                    <strong>Total Amount</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <strong>
                      {numberFormatText(order.amount, store.currency)}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow
                  style={{ backgroundColor: theme.palette.action.hover }}
                >
                  <TableCell colSpan={2} />
                  <TableCell colSpan={1}>
                    <strong>Amount Paid</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <strong>
                      {numberFormatText(totalPaymentsMade, store.currency)}
                    </strong>
                  </TableCell>
                </TableRow>

                <TableRow
                  style={{ backgroundColor: theme.palette.action.selected }}
                >
                  <TableCell colSpan={2} />
                  <TableCell colSpan={1}>
                    <strong>Balance</strong>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <strong>
                      {numberFormatText(
                        order.amount - totalPaymentsMade,
                        store.currency
                      )}
                    </strong>
                  </TableCell>
                </TableRow>

                {/* Action Area*/}
                <TableRow>
                  <TableCell>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      endIcon={<EditIcon />}
                    >
                      Edit
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      endIcon={<MailIcon />}
                      onClick={() => {
                        dispatch({ type: SET_CURRENT_ORDER, payload: order });
                        setOpenSendEmailDialog(true);
                      }}
                    >
                      Email Receipt
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      endIcon={<PrintIcon />}
                    >
                      Print Receipt
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      className={classes.button}
                      endIcon={<BlockIcon />}
                    >
                      Void
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getOrders(filter));

    // eslint-disable-next-line
  }, [filter]);
  // When store/state data is updated
  useEffect(() => {
    if (orders) {
      var finalData =
        orders &&
        orders.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [orders]);

  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false);

  const handleCloseEmailDialog = () => setOpenSendEmailDialog(false);

  return (
    <div>
      <Paper className={classes.root} sx={{ m: 2 }}>
        <Stack direction="column">
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Sales History</strong>
              </Typography>
              <Typography variant="body1">
                Keep track of all the sales done
              </Typography>
            </Stack>
          </Stack>
          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </Stack>
      </Paper>

      <SalesEmailReceiptDialog
        openSendEmailDialog={openSendEmailDialog}
        handleCloseEmailDialog={handleCloseEmailDialog}
      />
    </div>
  );
};

export default SalesHistory;
