import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getCustomers,
  addCustomer,
} from '../../../redux/customers/customerActions';
import Loading from 'components/Loading';
import { ADD_CUSTOMER } from 'redux/customers/customerTypes';
const CustomerSearch = ({ updateCustomer }) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  const dispatch = useDispatch();

  const customers = useSelector((state) => state.customer.customers);

  useEffect(() => {
    dispatch(
      getCustomers({
        page: 1,
        limit: 200000,
        search: null,
        sort: null,
        sortOrder: null,
      })
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!customers) {
      return undefined;
    }

    setOptions(customers.data);
  }, [customers, loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  // New Customer Dialog Open
  const [newCustomerDialogOpen, setNewCustomerDialogOpen] = useState(false);
  const handleNewCustomerDialogOpen = (value) => {
    setNewCustomerDialogOpen(value);
  };

  return (
    <>
      <Autocomplete
        id="asynchronous-demo"
        fullWidth
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options.concat({ name: '➕ Add New Customer', id: 0 })}
        loading={loading}
        onChange={(event, newInputValue) => {
          // Check for Add New Customer to Open Dialog
          if (newInputValue.id == 0) {
            setNewCustomerDialogOpen(true);
            return;
          }
          updateCustomer(newInputValue.id);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select a Customer"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
      <NewCustomerDialog
        open={newCustomerDialogOpen}
        handleOpen={handleNewCustomerDialogOpen}
      />
    </>
  );
};

const NewCustomerDialog = ({ open, handleOpen }) => {
  const dispatch = useDispatch();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);

  const [form, setForm] = useState({
    name: null,
    email: '',
    mobileNumber: '',
    address: '',
    notes: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addCustomer(form));
  };
  useEffect(() => {
    if (
      notification &&
      notification.type === 'success' &&
      notification.action === ADD_CUSTOMER
    ) {
      handleOpen(false);
      // eslint-disable-next-line
    }
  }, [notification]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={() => handleOpen(false)}
    >
      <DialogTitle>Add New Customer</DialogTitle>
      <DialogContent>
        <Stack direction="column">
          <form onSubmit={handleSubmit} noValidate>
            <Stack direction="column" spacing={2}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                label="Name"
                value={form.name}
                autoFocus
              />
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                type="email"
                label="Email Address"
                name="email"
                onChange={onChange}
                autoComplete="email"
                value={form.email}
              />
              <TextField
                variant="outlined"
                required
                fullWidth
                type="number"
                id="mobileNumber"
                value={form.mobileNumber}
                label="Mobile Number"
                name="mobileNumber"
                onChange={onChange}
                autoComplete="phoneNumber"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!form.name || loading}
              >
                Save
              </Button>

              {loading && <Loading />}
            </Stack>
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOpen(false)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerSearch;
