import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_SUPPLIER,
  DELETE_SUPPLIER,
  GET_SUPPLIER,
  GET_SUPPLIERS,
  SUPPLIER_ERROR,
  UPDATE_SUPPLIER,
} from './supplierTypes';

const url = process.env.REACT_APP_API_URL + 'suppliers';

// Add Supplier
export const addSupplier = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_SUPPLIER, payload: res.data });
    // Set Register Success Notification

    const supplier = res.data;
    const notification = {
      message: `Added Supplier:  ${supplier.name} successfully`,
      type: 'success',
    };
    dispatch({ type: ADD_SUPPLIER, payload: supplier });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SUPPLIER_ERROR,
      payload: 'Failed to Add Supplier',
    });
  }
};

// Update Supplier
export const updateSupplier = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const supplier = res.data;
    const notification = {
      message: `Updated Supplier:  ${supplier.name} successfully`,
      type: 'success',
    };

    dispatch({ type: UPDATE_SUPPLIER, payload: supplier });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: SUPPLIER_ERROR, payload: 'Failed to Update Supplier' });
  }
};

// Get Suppliers
export const getSuppliers = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const taxes = res.data;
    dispatch({ type: GET_SUPPLIERS, payload: taxes });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    console.error(e);
    dispatch({ type: CLEAR_LOADING });
  }
};

// Get Suppliers By Id
export const getSupplierById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const supplier = res.data;
    dispatch({ type: GET_SUPPLIER, payload: supplier });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SUPPLIER_ERROR,
      payload: 'Failed to Get Supplier',
    });
  }
};

// Delete Supplier By Id
export const deleteSupplier = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_SUPPLIER, payload: id });

    const notification = {
      message: `Deleted Supplier successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SUPPLIER_ERROR,
      payload: 'Failed to Delete Supplier',
    });
  }
};
