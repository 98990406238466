import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import SwipeableViews from 'react-swipeable-views';
import SignUpBasicInfo from './steps/SignUpBasicInfo';
import SignUpContact from './steps/SignUpContact';
import SignUpStoreInfo from './steps/SignUpStoreInfo';
import SignUpStoreComplete from './steps/SignUpStoreComplete';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loading: {
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  swipeableViews: {
    padding: theme.spacing(2),
    width: '100%',
  },
}));

const steps = [
  SignUpBasicInfo,
  SignUpContact,
  SignUpStoreInfo,
  SignUpStoreComplete,
];

const SignUp = () => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div
      className={classes.loading}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className={classes.swipeableViews}>
        <SwipeableViews index={activeStep}>
          {steps.map((step, index) => {
            const Component = steps[index];
            return (
              <Component
                key={index}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            );
          })}
        </SwipeableViews>
      </div>
    </div>
  );
};

export default SignUp;
