import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(4),
  },
}));

const SectionInventory = ({  updateForm }) => {
  const classes = useStyles();

  const [form, setForm] = useState({
    quantity: 0,
    reorderLevel: 0,
    trackStock: false,
    backOrders: false,
    supplierId: null,
  });
  const current = useSelector((state) => state.product.current);
  const suppliers = useSelector((state) => state.supplier.suppliers);
  // const store = useSelector(state=>state.auth.store)

  useEffect(() => {
    if (current) {
      form.quantity = current.quantity;
      form.reorderLevel = current.reorderLevel;
      form.trackStock = current.trackStock;
      form.backOrders = current.backOrders;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    let value = '';
    if (e.target.type == 'number') {
      value = Number(e.target.value);
      console.log(value);
    } else {
      value = e.target.value;
    }

    setForm({ ...form, [e.target.name]: value });
  };

  useEffect(() => {
    updateForm({ ...form });
    // eslint-disable-next-line
  }, [form]);

  return (
    <Paper>
      <Stack direction="column">
        <Stack
          sx={{ p: 2, backgroundColor: grey[200], overflow: 'hidden' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="column">
            <Typography variant="h6">
              <strong>Inventory</strong>
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Set Inventory Levels and Suppliers
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={2} sx={{ p: 2 }} direction="column">
          <TextField
            name="quantity"
            variant="outlined"
            required
            fullWidth
            onChange={onChange}
            id="quantity"
            label="Quantity"
            autoFocus
            value={form.quantity}
            type="number"
          />
          <TextField
            name="reorderLevel"
            variant="outlined"
            required
            fullWidth
            onChange={onChange}
            id="reorderLevel"
            label="Reorder Level"
            value={form.reorderLevel}
            autoFocus
            type="number"
          />
          <Stack spacing={2} direction="row" alignItems="center">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  value={form.trackStock}
                  checked={form.trackStock}
                  onChange={(e) =>
                    setForm({ ...form, trackStock: e.target.checked })
                  }
                />
              }
              label="Track Stock"
            />
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  value={form.backOrders}
                  checked={form.backOrders}
                  onChange={(e) =>
                    setForm({ ...form, backOrders: e.target.checked })
                  }
                />
              }
              label="Back Orders"
            />
          </Stack>
          {suppliers && (
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor={`outlined-primary-supplier-simple`}>
                Select Primary Supplier
              </InputLabel>
              <Select
                fullWidth
                labelId="outlined-primary-supplier-simple"
                value={form.supplierId}
                onChange={onChange}
                name="supplierId"
                label="Select Primary Supplier"
              >
                {suppliers.data.map((supplier) => (
                  <MenuItem key={supplier.id} value={supplier.id}>
                    <em>{supplier.name}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SectionInventory;
