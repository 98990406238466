import {
  ADD_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
  PURCHASE_ORDER_ERROR,
  UPDATE_PURCHASE_ORDER,
  ADD_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM,
  SET_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER_ITEM,
} from './purchaseOrderTypes';

const initialState = {
  purchaseOrders: null,
  current: null,
  loading: false,
  error: null,
  new: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          data:
            state.purchaseOrders !== null
              ? state.purchaseOrders.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          data:
            state.purchaseOrders !== null
              ? state.purchaseOrders.data.map((purchaseOrder) =>
                  purchaseOrder.id === action.payload.id
                    ? action.payload
                    : purchaseOrder
                )
              : [action.payload],
        },
        current: action.payload,
      };
    case GET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: action.payload,
        current: null,
        new: null,
      };
    case GET_PURCHASE_ORDER:
      return {
        ...state,
        current: action.payload,
        new: null,
      };
    case DELETE_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: {
          ...state.purchaseOrders,
          data:
            state.purchaseOrders &&
            state.purchaseOrders.data &&
            state.purchaseOrders.data.filter(
              (purchaseOrder) => purchaseOrder.id !== action.payload
            ),
        },
      };
    case PURCHASE_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_PURCHASE_ORDER:
      return {
        ...state,
        current: Object.assign({}, state.current, action.payload),
      };
    case DELETE_PURCHASE_ORDER_ITEM:
      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.filter(
                  (item) => item.productId !== action.payload
                )
              : state.new.items,
        },
      };
    case ADD_PURCHASE_ORDER_ITEM:
      const itemExists =
        state.new &&
        state.new.items &&
        state.new.items.find((item) => item.productId === action.payload.id);
      if (itemExists) {
        return { ...state };
      }

      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.concat(action.payload)
              : [action.payload],
        },
      };
    case UPDATE_PURCHASE_ORDER_ITEM:
      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.map((item) =>
                  item.productId === action.payload.productId
                    ? action.payload
                    : item
                )
              : state.new.items,
        },
      };

    default:
      return state;
  }
};
