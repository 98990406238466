import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { useNavigate, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { makeStyles, useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import SubjectIcon from '@mui/icons-material/Subject';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import InputAdornment from '@mui/material/InputAdornment';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateMessageTemplate,
  getMessageTemplateById,
} from '../../redux/message-template/messageTemplateActions';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { MESSAGE_TYPES } from '../../redux/message-template/messageTemplateTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  unitTypeCard: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const EditMessageTemplate = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);

  const current = useSelector((state) => state.messageTemplate.current);

  const [form, setForm] = useState({
    mode: 'email',
    content: '',
    subject: '',
    type: MESSAGE_TYPES.NONE,
  });

  useEffect(() => {
    dispatch(getMessageTemplateById(id));

    // eslint-disable-next-line
  }, []);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Edit Message Template') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(`/superadmin/message-template/view/${id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.content = htmlContent;
    dispatch(updateMessageTemplate(form, id)(form));
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    generateSuggestions();
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (current) {
      setForm(current);
      setUserType(form.content.includes('value="user') ? 'user' : 'visitor');
      // if (form.mode === "email") {

      // TODO: Fix Render of Current HTML Form
      // const content = jsStringEscape(current.content);

      // console.log(jsStringEscape(current.content));

      const contentBlock = htmlToDraft(current.content);
      // console.log(content)

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorStateInitial = EditorState.createWithContent(contentState);

        // console.log(editorStateInitial)
        setEditorState(editorStateInitial);
      }
      // }
    }
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    if (editorState) {
      // Replace annoying href tags
      const html = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ).replace(/(href=".*?")/g, '');

      setHtmlContent(html);
    }
  }, [editorState]);

  const [suggestions, setSuggestions] = useState([]);

  // Change User Suggestions
  const [userType, setUserType] = useState('member');

  const generateSuggestions = () => {
    let suggestions = [
      { text: 'First Name', value: `${userType}.firstName` },
      { text: 'Last Name', value: `${userType}.lastName` },
      { text: 'Mobile Number', value: `${userType}.mobileNumber` },
      { text: 'Password Reset Link', value: `${userType}.passwordResetLink` },
      { text: 'User Activate Link', value: `${userType}.activateLink` },
    ];
    suggestions = suggestions.concat(getOptionalSuggestions());
    setSuggestions(suggestions);
  };

  const getOptionalSuggestions = () => {
    let optionalSuggestions = [];

    // if (form.type === MESSAGE_TYPES.INVITE) {
    //   optionalSuggestions = optionalSuggestions.concat(
    //     {
    //       text: 'Invite Contact',
    //       value: 'invite.contact',
    //     },
    //     {
    //       text: 'Invite Link',
    //       value: 'invite.link',
    //     }
    //   );
    // }
    return optionalSuggestions;
  };

  if (!current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-mode-simple">
                    Select Mode
                  </InputLabel>
                  <Select
                    fullWidth
                    value={form.mode}
                    onChange={onChange}
                    name="mode"
                    inputProps={{
                      name: 'mode',
                      id: 'outlined-mode-simple',
                    }}
                  >
                    <MenuItem value="sms">
                      <em>SMS</em>
                    </MenuItem>
                    <MenuItem value="email">
                      <em>Email</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor="outlined-message-type-simple">
                    Select Message Type
                  </InputLabel>

                  <Select
                    fullWidth
                    value={form.type}
                    onChange={onChange}
                    name="type"
                    inputProps={{
                      name: 'type',
                      id: 'outlined-message-type-simple',
                    }}
                  >
                    {Object.entries(MESSAGE_TYPES).map(([key, value]) => (
                      <MenuItem key={key} value={value}>
                        <em>{value.split('_').join(' ')}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  value={form.subject}
                  name="subject"
                  onChange={onChange}
                  id="subject"
                  label="Subject"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SubjectIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  // editorClassName="demo-editor"
                  onEditorStateChange={(editorState) =>
                    setEditorState(editorState)
                  }
                  placeholder="Enter Message Content"
                  editorStyle={{
                    border: `1px solid ${theme.palette.primary.light}`,
                    padding: '1rem',
                    borderRadius: '5px',
                  }}
                  mention={{
                    separator: ' ',
                    trigger: '@',
                    suggestions,
                  }}
                  hashtag={{}}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              style={{ marginTop: '2rem' }}
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                !htmlContent ||
                !form.name ||
                !form.type ||
                !form.mode ||
                !form.subject ||
                loading
              }
            >
              Update Message Template
            </Button>
          </Grid>
        </form>
      </Paper>
    );
  }
};



export default EditMessageTemplate;
