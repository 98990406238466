import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useParams, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  addCategory,
  getCategoryById,
  updateCategory,
} from 'redux/category/categoryActions';
import { setNotification } from 'redux/notification/notificationActions';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CropImage from 'components/shared/CropImage';
import Loading from 'components/layout/Loading';
import { styled } from '@mui/material/styles';
import imageCompression from 'browser-image-compression';

const Input = styled('input')({
  display: 'none',
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddEditCategory = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const current = useSelector((state) => state.category.current);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    name: '',
    description: '',
  });

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getCategoryById(id));
    }
  }, [id]);

  useEffect(() => {
    if (current) {
      form.name = current.name;
      form.description = current.description;
      if (current.image) setUserImage(current.image);
      setForm(form);
    }
  }, [current]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      (page.includes('Add Category') || page.includes('Update Category')) &&
      notification &&
      notification.type === 'success' &&
      notification.id != null
    ) {
      navigate(`/admin/categories/detail/${notification.id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  const [userImage, setUserImage] = useState('');
  const [file, setFile] = useState(null);
  const [cropImageOpen, setCropImageOpen] = useState(false);

  const handleSave = (files) => {
    // Set Image
    const file = files[0];
    setFile(file);
    // setUserImage(URL.createObjectURL(file));
  };

  const handleCropImageClose = () => {
    setCropImageOpen(false);
  };

  const handleSelectCropImage = (imageSrc) => {
    setUserImage(imageSrc);
    setCropImageOpen(false);
  };

  useEffect(() => {
    if (file) {
      setCropImageOpen(true);
    }
  }, [file]);

  const compressImage = async () => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      console.log(
        'compressedFile instanceof Blob',
        compressedFile instanceof Blob
      ); // true
      console.log(
        `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
      ); // smaller than maxSizeMB

      return compressedFile;
    } catch (error) {
      console.log(error);
      dispatch(setNotification({ type: 'warning', message: error }));
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (file) {
      const compressedFile = await compressImage();
      if (compressedFile) {
        formData.append('file', compressedFile);
      }
    }

    dispatch(
      current ? updateCategory(formData, current.id) : addCategory(formData)
    );
  };

  return (
    <Paper className={classes.root} sx={{ m: 2, width: 1 / 2 }}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Stack spacing={2}>
          <TextField
            name="name"
            variant="outlined"
            required
            fullWidth
            onChange={onChange}
            id="name"
            label="Name"
            value={form.name}
            autoFocus
          />
          <TextField
            variant="outlined"
            fullWidth
            multiline
            onChange={onChange}
            id="description"
            label="Description"
            value={form.description}
            name="description"
          />

          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={(e) => {
                console.log(e.target.files);
                handleSave(e.target.files);
              }}
            />
            <Button
              variant="contained"
              component="span"
              endIcon={<CloudUploadIcon />}
            >
              Upload
            </Button>
          </label>
          {cropImageOpen && (
            <CropImage
              imageFile={file}
              cropImageOpen={cropImageOpen}
              handleCropImageClose={handleCropImageClose}
              handleSelectCropImage={handleSelectCropImage}
              aspect={1 / 1}
            />
          )}
          {userImage && (
            <VisibilitySensor>
              <Img src={userImage} />
            </VisibilitySensor>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            size={'large'}
            color="primary"
            className={classes.submit}
            disabled={loading || form.name.length === 0}
          >
            {current ? 'Update' : 'Add'}
          </Button>
          {loading && <Loading />}
        </Stack>
      </form>
    </Paper>
  );
};

export default AddEditCategory;
