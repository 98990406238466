import {
  ADD_CUSTOMER,
  CUSTOMER_ERROR,
  DELETE_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER_BATCH,
} from './customerTypes';

const initialState = {
  customers: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CUSTOMER:
      return {
        ...state,
        customers: {
          ...state.customers,
          data:
            state.customers !== null
              ? state.customers.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_CUSTOMER:
      return {
        ...state,
        customers: {
          ...state.customers,
          data:
            state.customers !== null
              ? state.customers.data.map((customer) =>
                  customer.id === action.payload.id ? action.payload : customer
                )
              : [action.payload],
        },
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case GET_CUSTOMER:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_CUSTOMER:
      return {
        ...state,
        customers: {
          ...state.customers,
          data:
            state.customers &&
            state.customers.data &&
            state.customers.data.filter(
              (customer) => customer.id !== action.payload
            ),
        },
      };
    case DELETE_CUSTOMER_BATCH:
      return {
        ...state,
        customers: {
          ...state.customers,
          data:
            state.customers &&
            state.customers.data &&
            state.customers.data.filter(
              (customer) => !action.payload.includes(customer.id)
            ),
        },
      };
    case CUSTOMER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
