import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_PAYMENT,
  GET_PAYMENT,
  GET_PAYMENTS,
  PAYMENT_ERROR,
  GET_PAYMENTS_SUMMARY,
} from './paymentTypes';

const url = process.env.REACT_APP_API_URL + 'payments';

// Add Payment
export const addPayment = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_PAYMENT, payload: res.data });
    // Set Register Success Notification

    // const payment = res.data;
    const notification = {
      message: `Added Payment successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Add Payment',
    });
  }
};

// Get Payments
export const getPayments = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const payments = res.data;
    dispatch({ type: GET_PAYMENTS, payload: payments });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Get Payment',
    });
  }
};

// Get Payments Summary
export const getPaymentsSummary = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/summary`, {
      params: filter,
    });
    const paymentSummary = res.data;
    dispatch({ type: GET_PAYMENTS_SUMMARY, payload: paymentSummary });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Get Payment Summary',
    });
  }
};

// Get Payment By Id
export const getPaymentById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const paymentType = res.data;
    dispatch({ type: GET_PAYMENT, payload: paymentType });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_ERROR,
      payload: 'Failed to Get Payment',
    });
  }
};
