import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getMessageTemplates } from '../../redux/message-template/messageTemplateActions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Link } from 'react-router-dom';
import CustomSearchRender from 'utils/CustomSearchRender';
import { format, parseISO } from 'date-fns/esm';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MessageTemplateList = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();
  const messageTemplates = useSelector(
    (state) => state.messageTemplate.messageTemplates
  );
  // const notification = useSelector((state) => state.notification.notification);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 25,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        console.log(filter.sortOrder);
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
      },
    },
    {
      name: 'content',
      label: 'Content',
      options: {
        display: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        display: true,
        sortOrder: getSortOrder('type'),
        filter: true,
        customBodyRender: function renderType(value, tableMeta, updateValue) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'subject',
      label: 'Subject',
      options: {
        display: true,
      },
    },

    {
      name: 'mode',
      label: 'Mode',
      options: {
        display: true,
        sortOrder: getSortOrder('mode'),
        filter: true,
        customBodyRender: function renderMode(value, tableMeta, updateValue) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Created On',
      options: {
        display: true,
        sortOrder: getSortOrder('createdAt'),

        sort: true,
        customBodyRender: function renderCreatedAt(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(parseISO(value, 'yyyy-MM-dd', new Date()), 'do MMM yyyy')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated On',
      options: {
        display: true,
        sortOrder: getSortOrder('updatedAt'),

        sort: true,
        customBodyRender: function renderUpdatedAt(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(parseISO(value, 'yyyy-MM-dd', new Date()), 'do MMM yyyy')}
            </Typography>
          );
        },
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: function renderView(value, tableMeta, updateValue) {
          return (
            <Button
              component={Link}
              to={`/superadmin/message-templates/view/${tableMeta.rowData[0]}`}
              label={value}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              View
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    downloadOptions: {
      filename: 'message-templates.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    serverSide: true,
    rowHover: false,
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    count: messageTemplates && messageTemplates.count,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      console.log(column);
      console.log(direction);

      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getMessageTemplates(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (messageTemplates) {
      var finalData =
        messageTemplates &&
        messageTemplates.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [messageTemplates]);

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            component={Link}
            to="/superadmin/message-templates/add"
            className={classes.button}
            startIcon={<AddBoxIcon />}
          >
            Add Message Template
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Message Template List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MessageTemplateList;
