import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';

import {
  ADD_CUSTOMER_GROUP,
  CUSTOMER_GROUP_ERROR,
  GET_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUPS,
  REMOVE_CUSTOMER_GROUP,
  UPDATE_CUSTOMER_GROUP,
} from './customerGroupTypes';

const url = process.env.REACT_APP_API_URL + 'customer-group';

let notification;

// Create Customer Group

export const addCustomerGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const customerGroup = res.data;
    dispatch({ type: ADD_CUSTOMER_GROUP, payload: customerGroup });

    notification = {
      message: `Customer Group Created ${customerGroup.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error);
    dispatch({
      type: CUSTOMER_GROUP_ERROR,
      payload: 'Failed to Create Customer Group',
    });
  }
};

// Update Customer Group

export const updateCustomerGroup = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });

    const customerGroup = res.data;
    dispatch({ type: UPDATE_CUSTOMER_GROUP, payload: customerGroup });

    const notification = {
      message: `Updated Customer Group ${customerGroup.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CUSTOMER_GROUP_ERROR,
      payload: 'Failed to Update Customer Group',
    });
  }
};

// Get Customer Groups
export const getCustomerGroups = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const customerGroups = res.data;
  dispatch({ type: GET_CUSTOMER_GROUPS, payload: customerGroups });

  dispatch({ type: CLEAR_LOADING });
};

// Get Customer Group By ID
export const getCustomerGroupById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const customerGroup = res.data;
    dispatch({ type: GET_CUSTOMER_GROUP, payload: customerGroup });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CUSTOMER_GROUP_ERROR,
      payload: 'Failed to Get Customer Group ',
    });
  }
};

// Delete Customer Group
export const deletecustomerGroup = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    const notification = {
      message: `Deleted Customer Group`,
      type: 'info',
    };

    dispatch({ type: REMOVE_CUSTOMER_GROUP, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CUSTOMER_GROUP_ERROR,
      payload: 'Failed to Delete Customer Group',
    });
  }
};
