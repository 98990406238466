import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { makeStyles, useTheme } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { numberFormatText } from 'utils/functions';
import ellipsize from 'ellipsize';
import { getMonth, startOfWeek, endOfWeek, format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
  },
}));

const ProductStats = () => {
  const classes = useStyles();
  const current = useSelector((state) => state.product.current);
  const store = useSelector((state) => state.auth.store);
  const theme = useTheme();

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <Paper
            sx={{ px: 2, py: 3, width: 1 / 3, bgcolor: 'background.paper' }}
          >
            {/* Total Product No */}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="column" justifyContent="center">
                <Box
                  variant="h6"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 'medium',
                    fontSize: 30,
                  }}
                >
                  {current.quantity}
                </Box>
                <Typography sx={{ color: 'text.secondary' }} variant="body1">
                  Current Quantity
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          <Paper sx={{ px: 2, py: 3, width: 1 / 3 }}>
            {/* Total Product No */}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="column" justifyContent="space-between">
                <Box
                  variant="h6"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 'medium',
                    fontSize: 30,
                  }}
                >
                  {numberFormatText(
                    current.stats.totalInventoryValue,
                    store ? store.currency : ''
                  )}
                </Box>

                <Typography sx={{ color: 'text.secondary' }} variant="body1">
                  Total Inventory Value
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          {/* Total Net Profit */}
          <Paper sx={{ px: 2, py: 3, width: 1 / 3 }}>
            <Stack direction="column" justifyContent="center">
              <Box
                variant="h6"
                sx={{
                  color:
                    current.stats.grossProfit < 0
                      ? theme.palette.error.main
                      : theme.palette.success.main,
                  fontWeight: 'bold',
                  fontSize: 30,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
              >
                {numberFormatText(
                  current.stats.grossProfit,
                  store ? store.currency : ''
                )}
              </Box>
              <Typography sx={{ color: 'text.secondary' }} variant="body1">
                Total Profit
              </Typography>
            </Stack>
          </Paper>
        </Stack>

        {/* Most Sold */}
        <Stack direction="column" spacing={2}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Sales
            </Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <ProductStatCard
                title="Overall"
                name={numberFormatText(
                  current.stats.grossSales,
                  store ? store.currency : ''
                )}
              />
              <ProductStatCard
                title={`This Week (${format(
                  startOfWeek(new Date()),
                  'do MMM'
                )} - ${format(endOfWeek(new Date()), 'do MMM')})`}
                name={numberFormatText(
                  current.stats.grossSalesThisWeek,
                  store ? store.currency : ''
                )}
              />
              <ProductStatCard
                title={format(new Date(), 'MMMM')}
                name={numberFormatText(
                  current.stats.grossSalesThisMonth,
                  store ? store.currency : ''
                )}
              />
            </Stack>
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Total No {'#'} Sold
            </Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <ProductStatCard
                title="Overall"
                name={numberFormatText(current.stats.grossNoSold, '', '', true)}
              />
              <ProductStatCard
                title={`This Week (${format(
                  startOfWeek(new Date()),
                  'do MMM'
                )} - ${format(endOfWeek(new Date()), 'do MMM')})`}
                name={numberFormatText(
                  current.stats.grossNoSoldThisWeek,
                  '',
                  '',
                  true
                )}
              />
              <ProductStatCard
                title={format(new Date(), 'MMMM')}
                name={numberFormatText(
                  current.stats.grossNoSoldThisMonth,
                  '',
                  '',
                  true
                )}
              />
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};

const ProductStatCard = ({ title, name, nameId }) => {
  return (
    <Box sx={{ px: 2, py: 3, width: 1 / 3 }}>
      <Stack direction="column" justifyContent="center">
        <Box
          variant="h6"
          sx={{
            color: 'primary.main',
            fontWeight: 'bold',
            fontSize: 30,
            cursor: 'pointer',
            textDecoration: 'none',
          }}
        >
          {name}
        </Box>
        <Typography sx={{ color: 'text.secondary' }} variant="body1">
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};

export default ProductStats;
