import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { red } from '@mui/material/colors';
import { useSelector, useDispatch } from 'react-redux';
import { getProductById } from '../../../redux/products/productActions';
import toMaterialStyle from 'material-color-hash';
import ProductStats from './ProductStats';
import Loading from 'components/layout/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardContent: {
    // marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: red[500],
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const ProductDetail = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.product.current);
  const loading = useSelector((state) => state.notification.loading);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getProductById(id));
    }
    // eslint-disable-next-line
  }, []);

  const [productTab, setProductTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setProductTab(newValue);
  };
  return (
    <Box sx={{ p: 2 }} className={classes.root}>
      {loading && <Loading />}
      {current && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Paper>
              <Stack direction="column">
                <Stack direction="row" justifyContent="end" sx={{ p: 1 }}>
                  <Button
                    color="primary"
                    component={Link}
                    to={`/admin/products/edit/${current.id}`}
                  >
                    EDIT
                  </Button>
                </Stack>
                <Divider />
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ p: 2 }}
                >
                  {current.image ? (
                    <Avatar
                      sx={{ width: 100, height: 100 }}
                      aria-label="Category Name"
                      className={classes.avatar}
                      src={current.image}
                    />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: toMaterialStyle(
                          `${current.name}`,
                          '250'
                        ).backgroundColor,
                        fontSize: '2em',
                      }}
                      sx={{ width: 100, height: 100 }}
                      aria-label="Product Name"
                      className={classes.avatar}
                    >
                      {current.name.charAt(0)}
                    </Avatar>
                  )}
                  <Typography variant="h6" sx={{ color: 'text.primary' }}>
                    {current.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {current.description}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          {/* Product Stats */}
          <Grid item xs={12} md={8}>
            {/* Add Stats */}
            <ProductStats />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ProductDetail;
