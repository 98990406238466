import {
  CREATE_RECEIPT_SETTING,
  DELETE_RECEIPT_SETTING,
  GET_RECEIPT_SETTING,
  UPDATE_RECEIPT_SETTING,
  RECEIPT_SETTING_ERROR,
} from './receiptSettingsTypes';

const initialState = {
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_RECEIPT_SETTING:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_RECEIPT_SETTING:
      return {
        ...state,
        current: null,
      };
    case UPDATE_RECEIPT_SETTING:
      return {
        ...state,
        current: action.payload,
      };
    case GET_RECEIPT_SETTING:
      return {
        ...state,
        current: action.payload,
      };
    case RECEIPT_SETTING_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
