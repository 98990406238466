export const GET_CASH_MOVEMENT = 'GET_CASH_MOVEMENT';
export const GET_CASH_MOVEMENTS = 'GET_CASH_MOVEMENTS';
export const ADD_CASH_MOVEMENT = 'ADD_CASH_MOVEMENT';
export const UPDATE_CASH_MOVEMENT = 'UPDATE_CASH_MOVEMENT';
export const DELETE_CASH_MOVEMENT = 'DELETE_CASH_MOVEMENT';
export const CASH_MOVEMENT_ERROR = 'CASH_MOVEMENT_ERROR';

export const CASH_MOVEMENT_TYPE = {
  CASH_OUT: 'Cash Out',
  CASH_IN: 'Cash In',
  CLOSING_FLOAT: 'Closing Float',
  OPENING_FLOAT: 'Opening Float',
  FLOAT_ADJUSTMENT: 'Float Adjustment',
  PETTY_CASH_IN: 'Petty Cash In',
  PETTY_CASH_OUT: 'Petty Cash Out',
};
