import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import { ADD_ORDER_ITEM } from '../../../redux/orders/orderTypes';
import Avatar from 'react-avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '1rem',
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
}));

const ProductItem = ({ product }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const theme = useTheme();
  const orderItems = useSelector((state) => state.order.newOrder.orderItems);
  const dispatch = useDispatch();

  const productExistsInOrder = orderItems.find(
    (order) => order.productId == product.id
  );
  const handleClick = () => {
    dispatch({
      type: ADD_ORDER_ITEM,
      payload: {
        productExistsInOrder,
        id: orderItems ? orderItems.length + 1 : 1,
        quantity: 1,
        productId: product.id,
        productName: product.name,
        amount: 1 * product.price,
        productBrand: product.brand,
        productStyle: product.style,
        productSize: product.size,
        discount: product.discountPercent ?? 0,
        notes: '',
        color: product.color,
        price: product.price,
        taxId: product.taxId,
        taxRate: product.taxRate,
        taxName: product.taxName,
        taxType: product.taxType,
      },
    });
  };

  return (
    <Grid item xs={12} md={3} zeroMinWidth className={classes.root}>
      <Card
        sx={{
          border: productExistsInOrder
            ? `3px solid ${theme.palette.primary.light}`
            : `none`,
        }}
      >
        <CardActionArea onClick={handleClick}>
          <div style={{ height: '75px' }}>
            {product.image ? (
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'scale-down',
                  backgroundColor: '#e5e5e5',
                  padding: '5px',
                }}
                src={product.image}
                width={75}
                height={75}
                alt={product.name}
              />
            ) : (
              <Avatar
                name={product.name}
                style={{ width: '100%', maxHeight: '75px', overflow: 'hidden' }}
                color={product.color}
              />
            )}
          </div>

          <CardContent>
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item xs zeroMinWidth style={{ textAlign: 'center' }}>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="body2"
                  style={{ overflowWrap: 'break-word' }}
                  component="h2"
                >
                  {product.name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
        {/* <div
          style={{
            width: '100%',
            height: '1rem',
            overflow: 'hidden',
            background: product.color,
          }}
        ></div> */}
      </Card>
    </Grid>
  );
};

export default ProductItem;
