import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Link } from 'react-router-dom';
import { parseISO, format } from 'date-fns/esm';
import {
  deleteBusinessType,
  getBusinessTypes,
} from '../../redux/business-type/businessTypeActions';
import CustomSearchRender from 'utils/CustomSearchRender';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const BusinessTypesList = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();

  const businessTypes = useSelector(
    (state) => state.businessType.businessTypes
  );
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);

  const handleDelete = () => {
    dispatch(deleteBusinessType(selectedBusinessType));
    setShowDialogDelete(false);
    setSelectedBusinessType(null);
  };
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: getSortOrder('id'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sortOrder: getSortOrder('name'),
        print: false,
        filter: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        display: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'image',
      label: 'Image',
      options: {
        display: false,
        print: false,
        filter: false,
        download: false,
        viewColumns: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: true,
        sort: true,
        filter: false,

        sortOrder: getSortOrder('createdAt'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('updatedAt'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <div>
              <Button
                component={Link}
                to={`/superadmin/business-types/edit/${tableMeta.rowData[0]}`}
                label={value}
                variant="outlined"
                type="submit"
                color="secondary"
              >
                Edit
              </Button>
              <Button
                style={{ marginLeft: '1rem' }}
                variant="outlined"
                type="submit"
                color="primary"
                onClick={() => {
                  setSelectedBusinessType(tableMeta.rowData[0]);
                  setShowDialogDelete(true);
                }}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: false,
    downloadOptions: {
      filename: 'business-types.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    count: businessTypes && businessTypes.count,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getBusinessTypes(filter));

    // eslint-disable-next-line
  }, [filter]);
  // When store/state data is updated
  useEffect(() => {
    if (businessTypes) {
      var finalData =
        businessTypes &&
        businessTypes.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
    // eslint-disable-next-line
  }, [businessTypes]);

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            component={Link}
            to="/superadmin/business-types/add"
            className={classes.button}
            startIcon={<AddBoxIcon />}
          >
            Add Business Type
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Business Type List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>

      {showDialogDelete && (
        <Dialog
          open={showDialogDelete}
          onClose={() => setShowDialogDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Business Type Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Business Type ? This action
              will delete all products related to this Business Type
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete()} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={() => setShowDialogDelete(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default BusinessTypesList;
