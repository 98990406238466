import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { NumberFormatCustom, numberFormatText } from '../../../utils/functions';
import ProductItemSearch from '../../../components/product/ProductItemSearch';
import { getProducts } from '../../../redux/products/productActions';
import {
  ADD_PURCHASE_ORDER_ITEM,
  DELETE_PURCHASE_ORDER_ITEM,
  UPDATE_PURCHASE_ORDER_ITEM,
} from '../../../redux/purchase-orders/purchaseOrderTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const PURCHASE_ORDER_FILTER_ITEMS = {
  ALL_ITEMS: 'All Items from the Supplier',
  LOW_STOCK_FROM_SUPPLIER: 'Low Stock Items from the Supplier',
};

const PurchaseOrderItems = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const loading = useSelector((state) => state.notification.loading);

  const products = useSelector((state) => state.product.products);
  const store = useSelector((state) => state.auth.store);
  // const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.purchaseOrder.current);
  const newPurchaseOrder = useSelector((state) => state.purchaseOrder.new);

  useEffect(() => {
    dispatch(getProducts(filter));
    // eslint-disable-next-line
  }, []);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    showAll: true,
    showLowStockProducts: false,
    supplierId: false,
  });

  useEffect(() => {
    if (current) {
      // Set Default Items
      for (const product of current.products) {
        dispatch({
          type: ADD_PURCHASE_ORDER_ITEM,
          payload: {
            ...product,
            productId: product.productId,
            productName: product.productName,
            instockQuantity: product.quantity,
            quantity: product.quantity,
            totalPurchaseCost: product.productPrice * product.quantity,
            purchaseCost: product.purchaseCost,
          },
        });
      }
    }
  }, [current]);

  const handleSelectProduct = (product) => {
    dispatch({
      type: ADD_PURCHASE_ORDER_ITEM,
      payload: {
        ...product,
        productId: product.id,
        productName: product.name,
        instockQuantity: product.quantity,
        quantity: product.reorderLevel,
        totalPurchaseCost: product.quantity * product.price,
        purchaseCost: product.price,
      },
    });
  };

  // useEffect(() => {
  //   if (products && (filter.showLowStockProducts || filter.supplierId)) {

  //   }
  //   // eslint-disable-next-line
  // }, [products]);

  const handleDeleteItem = (id) => {
    dispatch({
      type: DELETE_PURCHASE_ORDER_ITEM,
      payload: id,
    });
  };

  const [autofillOpen, setAutoFillOpen] = useState(false);
  const [autofillAnchorEl, setAutofillAnchorEl] = React.useState(null);
  const handleAutofillItemClick = (e, key) => {
    switch (key) {
      case PURCHASE_ORDER_FILTER_ITEMS.ALL_ITEMS:
        break;
      case PURCHASE_ORDER_FILTER_ITEMS.LOW_STOCK_FROM_SUPPLIER:
        break;
      default:
        break;
    }
    setAutofillAnchorEl(null);
  };

  const getTotalAmount = () => {
    let totalAmount =
      newPurchaseOrder && newPurchaseOrder.items
        ? newPurchaseOrder.items.reduce(function (prev, cur) {
            return prev + cur.totalPurchaseCost;
          }, 0)
        : 0;
    return totalAmount;
  };
  if (!products) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={4}>
            <Typography variant="h5" color="textSecondary">
              Items
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Menu
              id="lock-menu"
              anchorEl={autofillAnchorEl}
              open={autofillOpen}
              onClose={() => {
                setAutoFillOpen(false);
                setAutofillAnchorEl(null);
              }}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
              }}
            >
              {Object.entries(PURCHASE_ORDER_FILTER_ITEMS).map(
                ([key, value]) => (
                  <MenuItem
                    key={key}
                    value={value}
                    onClick={(e) => handleAutofillItemClick(e, value)}
                  >
                    <em>{value.split('_').join(' ')}</em>
                  </MenuItem>
                )
              )}
            </Menu>
          </Grid>
        </Grid>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">In Stock</TableCell>
                <TableCell align="right">Incoming</TableCell>
                <TableCell align="right">Quantity</TableCell>
                <TableCell align="right">
                  Purchase Cost&nbsp;{store ? `(${store.currency})` : ''}
                </TableCell>
                <TableCell align="right">
                  Amount &nbsp;{store ? `(${store.currency})` : ''}
                </TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newPurchaseOrder &&
                newPurchaseOrder.items.map((item, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.productName}
                    </TableCell>
                    <TableCell align="right">{item.instockQuantity}</TableCell>
                    <TableCell align="right">
                      <Typography variant="body1">0</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        variant="outlined"
                        name=""
                        value={item.quantity}
                        type="number"
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        onChange={(e) => {
                          const quantity = e.target.value;
                          item.quantity = parseInt(quantity);
                          dispatch({
                            type: UPDATE_PURCHASE_ORDER_ITEM,
                            payload: item,
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        variant="outlined"
                        name=""
                        value={item.purchaseCost}
                        type="number"
                        InputProps={{
                          inputComponent: NumberFormatCustom,
                        }}
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        onChange={(e) => {
                          const purchaseCost = e.target.value;
                          item.purchaseCost = parseInt(purchaseCost);
                          dispatch({
                            type: UPDATE_PURCHASE_ORDER_ITEM,
                            payload: {
                              ...item,
                              totalPurchaseCost:
                                item.purchaseCost * item.quantity,
                            },
                          });
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography variant="body1">
                        {numberFormatText(item.totalPurchaseCost)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleDeleteItem(item.productId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={7} component="th" scope="row">
                  <ProductItemSearch
                    handleSelectProduct={handleSelectProduct}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} />

                <TableCell colSpan={1}>
                  <Typography>
                    <strong>Total</strong>
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  <Typography>
                    <strong>
                      {numberFormatText(
                        getTotalAmount(),
                        store && store.currency ? store.currency : ''
                      )}
                    </strong>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
};

export default PurchaseOrderItems;
