import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import { ADD_BUSINESS_TYPE, BUSINESS_TYPE_ERROR, DELETE_BUSINESS_TYPE, GET_BUSINESS_TYPE, GET_BUSINESS_TYPES } from './businessTypeTypes';


const url = process.env.REACT_APP_API_URL + 'business-types';

// Add Business Type
export const addBusinessType = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_BUSINESS_TYPE , payload: res.data });
    // Set Register Success Notification

    const business = res.data;
    const notification = {
      message: `Added Business Type :  ${business.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BUSINESS_TYPE_ERROR,
      payload: 'Failed to Add Business Type',
    });
  }
};

// Update Business Type

export const updateBusinessType = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const businessType = res.data;
    const notification = {
      message: `Updated Business Type ${businessType.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BUSINESS_TYPE_ERROR,
      payload: 'Failed to Update Business Type',
    });
  }
};

// Get Business Types
export const getBusinessTypes = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const businessTypes = res.data;
  dispatch({ type: GET_BUSINESS_TYPES, payload: businessTypes });
  dispatch({ type: CLEAR_LOADING });
};

// Get Business Type By Id
export const getBusinessTypeById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const businessType = res.data;
    dispatch({ type: GET_BUSINESS_TYPE, payload: businessType });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BUSINESS_TYPE_ERROR,
      payload: 'Failed to Get Business Type',
    });
  }
};

// Delete Business Type
export const deleteBusinessType = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_BUSINESS_TYPE, payload: id });

    const notification = {
      message: `Deleted Business Type successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BUSINESS_TYPE_ERROR,
      payload: 'Failed to Delete Business Type',
    });
  }
};
