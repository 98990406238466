import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Cropper from 'react-easy-crop';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getOrientation } from 'get-orientation/browser';
import { getCroppedImg, getRotatedImage } from '../../utils/canvasUtils';

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const useStyles = makeStyles((theme) => ({
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 200,
    background: '#333',
    // [theme.breakpoints.up('sm')]: {
    //   height: 400,
    // },
  },
  cropButton: {
    flexShrink: 0,
    marginLeft: 16,
  },
  controls: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    // [theme.breakpoints.up('sm')]: {
    //   flexDirection: 'row',
    //   alignItems: 'center',
    // },
  },
  sliderContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  sliderLabel: {
    // [theme.breakpoints.down('xs')]: {
    //   minWidth: 65,
    // },
  },
  slider: {
    padding: '22px 0px',
    marginLeft: 16,
    // [theme.breakpoints.up('sm')]: {
    //   flexDirection: 'row',
    //   alignItems: 'center',
    //   margin: '0 16px',
    // },
  },
}));

const CropImage = ({
  imageFile,
  cropImageOpen,
  handleCropImageClose,
  handleSelectCropImage,
  aspect,
}) => {
  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const classes = useStyles();

  useEffect(() => {
    if (imageFile) {
      setImage(imageFile);
    }
    // eslint-disable-next-line
  }, [imageFile]);

  const setImage = async (image) => {
    let imageDataUrl = await readFile(imageFile);

    // apply rotation if needed
    const orientation = await getOrientation(imageFile);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
    }

    setImageSrc(imageDataUrl);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      console.log('donee', { croppedImage });
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const handleSelect = () => {
    handleSelectCropImage(croppedImage);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={cropImageOpen}
      onClose={handleCropImageClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle>Crop Image</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={6}>
            <React.Fragment>
              <div className={classes.cropContainer}>
                <Cropper
                  image={imageSrc}
                  crop={crop}
                  rotation={rotation}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onRotationChange={setRotation}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <div className={classes.controls}>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Zoom
                  </Typography>
                  <Slider
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    classes={{ root: classes.slider }}
                    onChange={(e, zoom) => setZoom(zoom)}
                  />
                </div>
                <div className={classes.sliderContainer}>
                  <Typography
                    variant="overline"
                    classes={{ root: classes.sliderLabel }}
                  >
                    Rotation
                  </Typography>
                  <Slider
                    value={rotation}
                    min={0}
                    max={360}
                    step={1}
                    aria-labelledby="Rotation"
                    classes={{ root: classes.slider }}
                    onChange={(e, rotation) => setRotation(rotation)}
                  />
                </div>
                <Button
                  onClick={showCroppedImage}
                  variant="contained"
                  color="primary"
                  classes={{ root: classes.cropButton }}
                >
                  Show Result
                </Button>
              </div>
            </React.Fragment>
          </Grid>
          <Grid item xs={12} md={6}>
            {croppedImage && (
              <img
                src={croppedImage}
                alt="CroppedImage"
                className={classes.img}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCropImageClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSelect} color="primary">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export default CropImage;
