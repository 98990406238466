import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { useNavigate,useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
// import { DropzoneDialog } from 'material-ui-dropzone';
import { getDemoCategories } from 'redux/category/demoCategoryActions';
import { addDemoSubCategory } from 'redux/sub-category/demoSubCategoryActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddDemoSubCategory = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const categories = useSelector((state) => state.category.categories);

  const [form, setForm] = useState({
    name: '',
    description: '',
    image: '',
    categoryId: null,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Add Sub Category') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    if (!categories) {
      dispatch(getDemoCategories());
    }
    // eslint-disable-next-line
  }, [categories]);

  // Image
  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [userImage, setUserImage] = useState('');
  const [file, setFile] = useState(null);

  const handleSave = (files) => {
    // Set Image
    const file = files[0];
    setUserImage(URL.createObjectURL(file));
    setFile(file);
    handleClose();
  };
  const handleClose = () => {
    setImagePickerOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (file) {
      formData.append('file', file);
    }
    dispatch(addDemoSubCategory(formData));
  };

  if (!categories) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                id="name"
                label="Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                multiline
                onChange={onChange}
                id="description"
                label="Description"
                name="description"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-category-simple">
                  Select Category
                </InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  placeholder="Select Category"
                  label="Select Category"
                  value={form.categoryId}
                  disabled={id}
                  onChange={onChange}
                  name="categoryId"
                  inputProps={{
                    name: 'categoryId',
                    id: 'outlined-categoryId-simple',
                  }}
                >
                  {categories.data.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      <em>{category.name}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12}>
              {/* <DropzoneDialog
                open={imagePickerOpen}
                onSave={handleSave}
                acceptedFiles={['image/*']}
                showPreviews={true}
                dropzoneText="Drag and drop an Image file here"
                fileLimit={1} // Select only one image
                showPreviewsInDropzone={true}
                showAlerts={true}
                maxFileSize={5000000}
                onClose={handleClose}
              /> */}
            </Grid>
            <Grid item xs={12} sm={12}>
              <VisibilitySensor>
                <Img
                  style={{ height: '200px' }}
                  onClick={() => setImagePickerOpen(true)}
                  src={
                    userImage
                      ? userImage
                      : [
                          'https://place-hold.it/500x200/F8F8F8?text=Add Demo Sub Category Image',
                        ]
                  }
                />
              </VisibilitySensor>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size={'large'}
                color="primary"
                className={classes.submit}
                disabled={
                  loading ||
                  form.name.length === 0 ||
                  form.description.length === 0 ||
                  (form.categoryId && form.categoryId.length === 0)
                }
              >
                Add
              </Button>
            </Grid>

            <Grid item xs={12}>
              {loading && <LinearProgress variant="query" color="secondary" />}
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default AddDemoSubCategory;
