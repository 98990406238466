import { SET_CURRENT_PAGE, CLEAR_CURRENT_PAGE } from './pagesTypes';

const initialState = {
  page: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case CLEAR_CURRENT_PAGE:
      return {
        ...state,
        page: null,
      };
    default:
      return state;
  }
};
