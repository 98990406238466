import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, getUserById, updateUser } from '../../redux/user/userActions';
import FormControl from '@mui/material/FormControl';
import LockIcon from '@mui/icons-material/Lock';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import validator from 'validator';
import { getRoles } from '../../redux/role/roleActions';
import { ROLE_OWNER } from '../../redux/role/roleTypes';
import { GET_USER } from '../../redux/user/userTypes';
import toMaterialStyle from 'material-color-hash';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    borderRadius: theme.spacing(7),
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddEditEmployee = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const roles = useSelector((state) => state.role.roles);
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const current = useSelector((state) => state.user.current);
  const storeUser = useSelector((state) => state.auth.current);

  useEffect(() => {
    if (!roles) {
      dispatch(getRoles());
    }
    // eslint-disable-next-line
  }, [roles]);

  useEffect(() => {
    const { pathname } = location;
    // Reset if Add
    if (pathname.includes('/add')) {
      console.log('Here');
      dispatch({ type: GET_USER, payload: null });
    } else {
      if (id) {
        dispatch(getUserById(id));
      }
    }
    // eslint-disable-next-line
  }, [location]);

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobileNumber: '',
    roleId: null,
    isPinEnabled: false,
    pin: Math.floor(1000 + Math.random() * 9000),
  });
  const [errors, setErrors] = useState({
    firstName: null,
    lastName: null,
    email: null,
    mobileNumber: null,
    roleId: null,
  });

  const onChange = (e) => {
    if (e.target.name === 'pin' && e.target.value.length > 4) {
      return;
    }

    setForm({ ...form, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (current) {
      setForm(current);
    }
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    if (
      page &&
      (page.includes('Add Employee') || page.includes('Update Employee')) &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (current) {
      dispatch(updateUser(form, id));
    } else {
      dispatch(addUser(form));
    }
  };
  if (id && !current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Avatar
                className={classes.avatar}
                variant="rounded"
                style={{
                  backgroundColor: toMaterialStyle(
                    `${form.firstName} ${form.lastName}`
                  ).backgroundColor,
                }}
              >
                {form.firstName.toString().charAt(0)}
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                id="firstName"
                label="First Name"
                value={form.firstName}
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                id="lastName"
                label="Last Name"
                value={form.lastName}
                name="lastName"
                autoComplete="lname"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                disabled={
                  storeUser &&
                  storeUser.roleName === ROLE_OWNER &&
                  form.email === storeUser.email
                }
                onChange={onChange}
                value={form.email}
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                type="number"
                id="mobileNumber"
                label="Mobile Number"
                name="mobileNumber"
                onChange={onChange}
                value={form.mobileNumber}
              />
            </Grid>

            {roles && storeUser && (
              <Grid item xs={12} md={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-role-simple">Role</InputLabel>
                  <Select
                    value={form.roleId || ''}
                    onChange={onChange}
                    name="roleId"
                    label="Select Role"
                    disabled={
                      current &&
                      current.roleName === ROLE_OWNER &&
                      current.roleId &&
                      storeUser.roleId
                    }
                    inputProps={{
                      name: 'roleId',
                      id: 'outlined-role-simple',
                    }}
                  >
                    {roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        <em>{role.name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            {/* {storeUser &&
              storeUser.roleName !== ROLE_OWNER &&
              current.roleName === storeUser.roleName && (
                <Grid item xs={12} md={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Role"
                    disabled
                    value="Owner"
                  />
                </Grid>
              )} */}
            <Grid item xs={12} md={12}>
              <TextField
                name="pin"
                onChange={onChange}
                value={form.pin}
                label="PIN"
                placeholder="Pin"
                variant="outlined"
                type="number"
                inputProps={{ maxLength: 4 }}
                InputProps={{
                  maxLength: 4,
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={form.isPinEnabled}
                    onChange={(e) =>
                      setForm({ ...form, isPinEnabled: e.target.checked })
                    }
                  />
                }
                label={'Is Pin Enabled'}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={
                  !form.email ||
                  !form.firstName ||
                  !form.lastName ||
                  !form.mobileNumber ||
                  !form.roleId ||
                  loading
                }
              >
                Save
              </Button>
            </Grid>

            <Grid item xs={12}>
              {loading && <LinearProgress variant="query" color="secondary" />}
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default AddEditEmployee;
