import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { getSuppliers } from '../../../redux/suppliers/supplierActions';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const SuppliersHome = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const loading = useSelector((state) => state.notification.loading);
  const suppliers = useSelector((state) => state.supplier.suppliers);

  useEffect(() => {
    dispatch(getSuppliers());
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'phone',
      label: 'Phone',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {value}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    serverSide: true,
    rowHover: true,
    fixedHeader: false,
    selectableRows: 'none',
    count: suppliers && suppliers.count,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/inventory/suppliers/detail/${rowData[0]}`);
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (suppliers) {
      var finalData =
        suppliers &&
        suppliers.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [suppliers]);

  if (!suppliers) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Stack direction="column" spacing={2}>
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Suppliers</strong>
              </Typography>
              <Typography variant="body1">
                Create Suppliers to fulfill Purchase Orders and add Products
              </Typography>
            </Stack>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={'/admin/inventory/suppliers/add'}
              startIcon={<AddIcon />}
            >
              Add Supplier
            </Button>
          </Stack>

          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </Stack>
      </Paper>
    );
  }
};

export default SuppliersHome;
