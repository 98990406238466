import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_CUSTOMER,
  CUSTOMER_ERROR,
  DELETE_CUSTOMER,
  GET_CUSTOMER,
  GET_CUSTOMERS,
  DELETE_CUSTOMER_BATCH,
} from './customerTypes';

const url = process.env.REACT_APP_API_URL + 'customers';

// Add Customer
export const addCustomer = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_CUSTOMER, payload: res.data });
    // Set Register Success Notification

    const customer = res.data;
    const notification = {
      message: `Added Customer:  ${customer.name} successfully`,
      type: 'success',
      action: ADD_CUSTOMER,
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: CUSTOMER_ERROR, payload: 'Failed to Add Customer' });
  }
};

// Update Customer
export const updateCustomer = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const customer = res.data;
    const notification = {
      message: `Updated Customer:  ${customer.name} successfully`,
      type: 'success',
      entity: ADD_CUSTOMER,
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: CUSTOMER_ERROR, payload: 'Failed to Update Custoemr' });
  }
};

// Get Customer
export const getCustomers = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const customers = res.data;
  dispatch({ type: GET_CUSTOMERS, payload: customers });
  dispatch({ type: CLEAR_LOADING });
  setTimeout(() => {
    dispatch({ type: CLEAR_NOTIFICATION });
  }, 1000);
};

// Get Customer By Id
export const getCustomerById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const customer = res.data;
    dispatch({ type: GET_CUSTOMER, payload: customer });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: CUSTOMER_ERROR, payload: 'Failed to Get Customer' });
  }
};

// Delete Customer By Id
export const deleteCustomer = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_CUSTOMER, payload: id });

    const notification = {
      message: `Delete Customer successfully`,
      type: 'success',
      entity: ADD_CUSTOMER,
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: CUSTOMER_ERROR, payload: 'Failed to Delete Customer' });
  }
};

// Delete Customer Batch
export const deleteCustomerBatch = (ids) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.delete(`${url}`, { ids });
    dispatch({ type: DELETE_CUSTOMER_BATCH, payload: ids });

    const notification = {
      message: res.data.message,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: CUSTOMER_ERROR, payload: 'Failed to Delete Customers' });
  }
};
