import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import OrderItem from './OrderItem';
import PaymentIcon from '@mui/icons-material/Payment';
import CustomerSearch from './CustomerSearch';
import { TAX_OPTIONS } from '../../../redux/tax/taxTypes';
import CounterConfirm from '../CounterConfirm';
import { numberFormatText } from '../../../utils/functions';
import { useNavigate } from 'react-router-dom';
import { UPDATE_NEW_ORDER } from 'redux/orders/orderTypes';
import { red, green } from '@mui/material/colors';
import { discardOrder, parkSale } from 'redux/orders/orderActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  grid: {
    margin: '1rem',
  },
  gridPay: {
    // paddingLeft: '1rem !important',
    // paddingRight: '1rem !important',
    // paddingBottom: '1.5rem !important',
  },
  btnPay: {
    padding: '1rem',
    fontSize: 'larger',
  },
}));

const OrderTab = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(deviceId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.notification.loading);
  const newOrder = useSelector((state) => state.order.newOrder);
  const notification = useSelector((state) => state.notification.notification);
  const currentDevice = useSelector((state) => state.device.current);
  const currentOpenRegister = useSelector(
    (state) => state.cashRegister.current
  );
  const registerError = useSelector((state) => state.cashRegister.error);
  const store = useSelector((state) => state.auth.store);

  // Set Device ID
  useEffect(
    () => {
      if (currentDevice && currentOpenRegister) {
        dispatch({
          type: UPDATE_NEW_ORDER,
          payload: {
            deviceId: currentDevice.id,
            cashRegisterId: currentOpenRegister.id,
          },
        });
      }
    },
    // eslint-disable-next-line
    [currentDevice, currentOpenRegister]
  );

  useEffect(() => {
    if (registerError && notification && notification.type === 'warning') {
      navigate('/admin/sell/cash-register/open');
    }
    // eslint-disable-next-line
  }, [notification, registerError]);

  const onChange = (e) => {
    dispatch({
      type: UPDATE_NEW_ORDER,
      payload: {
        [e.target.name]: e.target.value,
      },
    });
  };

  useEffect(() => {
    if (newOrder) {
      // get sum of msgCount prop across all objects in array
      var subTotal = newOrder.orderItems.reduce(function (prev, cur) {
        return prev + cur.amount;
      }, 0);
      var discountTotal = newOrder.orderItems.reduce(function (prev, cur) {
        return prev + (cur.discount / 100) * cur.amount;
      }, 0);
      var taxTotal = newOrder.orderItems.reduce(function (prev, cur) {
        return prev + cur.taxId && cur.taxType !== TAX_OPTIONS.INCLUSIVE
          ? (cur.taxRate / 100) * cur.amount
          : 0;
      }, 0);
      dispatch({
        type: UPDATE_NEW_ORDER,
        payload: {
          subTotal: subTotal,
          discountTotal: discountTotal,
          taxTotal: taxTotal,
        },
      });
    }
    // eslint-disable-next-line
  }, [newOrder]);

  const updateCustomer = (customerId) => {
    dispatch({
      type: UPDATE_NEW_ORDER,
      payload: {
        customerId,
      },
    });
  };

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleCloseConfirm = () => setOpenConfirm(false);

  const handleDiscardSale = () => {
    dispatch(discardOrder());
  };

  const handleParkSale = () => {
    dispatch(parkSale());
  };

  return (
    <div>
      <Paper sx={{ p: 2 }}>
        {/* <Typography color="textSecondary" gutterBottom>
          Customer
        </Typography> */}
        <CustomerSearch updateCustomer={updateCustomer} />
      </Paper>

      {/* Products */}
      <Paper style={{ marginTop: '1rem', marginBottom: '1rem' }}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ padding: '1rem' }}
              color="textSecondary"
              gutterBottom
            >
              Products{' '}
              {newOrder.orderItems &&
                newOrder.orderItems.length > 0 &&
                `(${newOrder.orderItems.length})`}
            </Typography>
          </Grid>

          {newOrder.orderItems &&
            newOrder.orderItems.map((orderItem) => (
              <Grid item xs={12} key={orderItem.id}>
                <OrderItem orderItem={orderItem} />
              </Grid>
            ))}
        </Grid>
      </Paper>

      {/* Notes */}
      <Paper
        style={{ padding: '1rem', marginBottom: '1rem', marginTop: '1rem' }}
      >
        <Typography color="textSecondary" gutterBottom>
          Notes
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          id="Notes"
          onChange={onChange}
          placeholder="Type a note about this Order"
          name="notes"
          multiline
        />
      </Paper>

      {/* Calculation Details */}
      <Paper>
        <Stack direction="column" p={3} container spacing={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Sub Total</Typography>
            <Typography variant="h6" style={{ textAlign: 'end' }}>
              <strong>
                {numberFormatText(newOrder.subTotal, store.currency)}
              </strong>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Tax Total</Typography>
            <Typography variant="h6" style={{ textAlign: 'end' }}>
              <strong>
                {numberFormatText(newOrder.taxTotal, store.currency)}
              </strong>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6"> Discount</Typography>
            <Typography variant="h6" style={{ textAlign: 'end' }}>
              <strong>
                {numberFormatText(newOrder.discountTotal, store.currency)}
              </strong>
            </Typography>
          </Stack>
        </Stack>
      </Paper>

      <Stack direction="column" sx={{ mt: 1 }} spacing={2}>
        <Button
          fullWidth
          variant="contained"
          className={classes.btnPay}
          size="large"
          color="primary"
          disabled={newOrder.subTotal === 0 || loading}
          onClick={() => setOpenConfirm(true)}
          startIcon={<PaymentIcon />}
        >
          Pay
          {'    '}
          <span style={{ marginRight: '1rem' }}>{` `}</span>
          {numberFormatText(
            newOrder.subTotal + newOrder.taxTotal - newOrder.discountTotal,
            store.currency
          )}
        </Button>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="info"
          disabled={newOrder.subTotal === 0 || loading}
          onClick={() => handleParkSale()}
          startIcon={<CachedIcon />}
        >
          Park Sale
        </Button>
        <Button
          fullWidth
          variant="outlined"
          size="large"
          color="error"
          disabled={newOrder.subTotal === 0 || loading}
          onClick={() => handleDiscardSale()}
          startIcon={<DeleteIcon />}
        >
          Discard Sale
        </Button>
      </Stack>

      {store && (
        <CounterConfirm
          currentOrder={newOrder}
          openConfirm={openConfirm}
          handleCloseConfirm={handleCloseConfirm}
        />
      )}
    </div>
  );
};

export default OrderTab;
