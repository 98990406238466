import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import AssessmentIcon from '@mui/icons-material/Assessment';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { FaBoxes, FaBox, FaCashRegister } from 'react-icons/fa';
import { AiFillContainer } from 'react-icons/ai';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { ROLE_OWNER } from '../../redux/role/roleTypes';
const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: theme.mixins.toolbar,
}));

const Sidebar = ({ selected, mobileOpen, closeDrawer }) => {
  const classes = useStyles();

  const current = useSelector((state) => state.auth.current);

  const handleDrawerToggle = () => {
    closeDrawer();
  };
  const location = useLocation();

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbar} />
      <List>
        <ListItem
          component={Link}
          to="/admin"
          selected={selected === 'Dashboard'}
          button
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        {/* {current && current.roleName === ROLE_SUPER_ADMIN && (
          <ListItem
            component={Link}
            to="/admin/organization"
            selected={selected === 'Organizations'}
            button
          >
            <ListItemIcon>
              <MdBusiness size={24} />
            </ListItemIcon>
            <ListItemText primary="Organizations" />
          </ListItem>
        )} */}
        <ListItem
          component={Link}
          to="/admin/categories"
          selected={selected === 'Categories'}
          button
        >
          <ListItemIcon>
            <AiFillContainer size={24} />
          </ListItemIcon>
          <ListItemText primary="Categories" />
        </ListItem>

        <ListItem
          component={Link}
          to="/admin/sell/counter"
          selected={location && location.pathname.includes('/admin/sell')}
          button
        >
          <ListItemIcon>
            <FaCashRegister size={24} />
          </ListItemIcon>
          <ListItemText primary="Sell" />
        </ListItem>

        {/* <ListItem
          component={Link}
          to="/admin/reports"
          selected={location && location.pathname.includes('/admin/reports')}
          button
        >
          <ListItemIcon>
            <AssessmentIcon size={24} />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem> */}

        <ListItem
          component={Link}
          to="/admin/products"
          selected={selected === 'Products'}
          button
        >
          <ListItemIcon>
            <FaBox size={24} />
          </ListItemIcon>
          <ListItemText primary="Products" />
        </ListItem>

        <ListItem
          component={Link}
          to="/admin/inventory/history"
          selected={selected === 'Inventory'}
          button
        >
          <ListItemIcon>
            <FaBoxes size={24} />
          </ListItemIcon>
          <ListItemText primary="Inventory Management" />
        </ListItem>

        {current && current.roleName === ROLE_OWNER && (
          <ListItem
            component={Link}
            to="/admin/employees"
            selected={selected === 'Employees'}
            button
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Employees" />
          </ListItem>
        )}

        <ListItem
          component={Link}
          to="/admin/customers"
          selected={selected === 'Customers'}
          button
        >
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" />
        </ListItem>
        <ListItem
          component={Link}
          to="/admin/settings/general"
          selected={selected === 'Settings'}
          button
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </React.Fragment>
  );
  return (
    <div>
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
        }}
        smUp
        implementation="css"
      >
        <Drawer
          className={classes.drawer}
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ onClick: handleDrawerToggle }}
        >
          {drawer}
        </Drawer>
      </Box>
      {!location.pathname.includes('/admin/sell') && (
        <Box
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
          }}
        >
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      )}
    </div>
  );
};

export default Sidebar;
