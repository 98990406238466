import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { useSelector, useDispatch } from 'react-redux';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import {
  addPaymentType,
  getPaymentTypeById,
} from '../../redux/payment-types/paymentTypeActions';
import { updatePaymentType } from '../../redux/payment-types/paymentTypeActions';
import { PAYMENT_TYPE_OPTIONS } from '../../redux/payment-types/paymentTypeTypes';
import toMaterialStyle from 'material-color-hash';
import { FaMoneyCheck } from 'react-icons/fa';
import { IoMdCash } from 'react-icons/io';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(2),
    // flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  avatar: {
    color: '#FFF',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const AddUpdatePaymentType = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const paymentType = useSelector((state) => state.paymentType.current);

  useEffect(() => {
    if (id) {
      dispatch(getPaymentTypeById(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const [form, setForm] = useState({
    name: null,
    type: PAYMENT_TYPE_OPTIONS.CARD,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(
    () => {
      if (paymentType) {
        setForm({ ...paymentType });
      }
    },
    // eslint-disable-next-line
    [paymentType]
  );

  useEffect(() => {
    if (
      page &&
      page.includes('Payment Type') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (paymentType) {
      dispatch(updatePaymentType(form, paymentType.id));
    } else {
      dispatch(addPaymentType(form));
    }
  };

  if (id && !paymentType) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <Paper className={classes.root}>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Avatar
                    style={{
                      backgroundColor: toMaterialStyle(`${form.type}`)
                        .backgroundColor,
                    }}
                    sx={{ width: 100, height: 100 }}
                    className={classes.avatar}
                  >
                    {form.type === PAYMENT_TYPE_OPTIONS.CARD && (
                      <CreditCardIcon fontSize="large" />
                    )}
                    {form.type === PAYMENT_TYPE_OPTIONS.CHECK && (
                      <FaMoneyCheck size={40} />
                    )}
                    {form.type === PAYMENT_TYPE_OPTIONS.CASH && (
                      <IoMdCash size={40} />
                    )}
                    {form.type === PAYMENT_TYPE_OPTIONS.OTHER && (
                      <ChromeReaderModeIcon fontSize="large" />
                    )}
                  </Avatar>
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor={`outlined-payment-type-simple`}>
                      Select Type
                    </InputLabel>
                    <Select
                      fullWidth
                      value={form.type}
                      onChange={onChange}
                      name="type"
                      // labelWidth={labelWidth}
                    >
                      {Object.keys(PAYMENT_TYPE_OPTIONS).map((option) => (
                        <MenuItem key={option} value={option}>
                          <em>{option}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    id="name"
                    value={form.name}
                    label="Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size={'large'}
                    color="primary"
                    className={classes.submit}
                    disabled={!form.name || loading}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  {loading && (
                    <LinearProgress variant="query" color="secondary" />
                  )}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
};

export default AddUpdatePaymentType;
