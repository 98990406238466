import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AccordionSummary from '@mui/material/AccordionSummary';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import AccordionDetails from '@mui/material/AccordionDetails';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  REMOVE_ORDER_ITEM,
  UPDATE_ORDER_ITEM,
} from '../../../redux/orders/orderTypes';
import { TAX_OPTIONS } from '../../../redux/tax/taxTypes';
import { NumberFormatCustom, numberFormatText } from '../../../utils/functions';
import InventoryDetailDialog from './InventoryDetailDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const OrderItem = ({ orderItem }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();

  const store = useSelector((state) => state.auth.store);

  const [order, setOrder] = useState({
    quantity: '',
    productName: '',
    amount: '',
    productBrand: null,
    productStyle: null,
    productSize: null,
    productId: null,
    discount: null,
    notes: '',
    color: '#000',
  });

  useEffect(() => {
    if (orderItem) {
      setOrder(orderItem);
    }
    // eslint-disable-next-line
  }, [orderItem]);
  const onChange = (e) => {
    let value = '';
    if (e.target.type == 'number') {
      value = Number(e.target.value);
    } else {
      value = e.target.value;
    }
    if (e.target.name === 'quantity') {
      setOrder({
        ...order,
        [e.target.name]: value,
        amount: value * order.price,
      });
    } else if (e.target.name === 'price') {
      setOrder({
        ...order,
        [e.target.name]: value,
        amount: value * order.quantity,
      });
    } else {
      setOrder({ ...order, [e.target.name]: value });
    }
  };

  const handleClickAway = () => {
    dispatch({ type: UPDATE_ORDER_ITEM, payload: order });
  };

  const handleDeleteOrderItem = () => {
    dispatch({ type: REMOVE_ORDER_ITEM, payload: order.id });
  };

  // Inventory Dialog
  const [selectedProductId, setSelectedProductId] = useState(null);

  const [inventoryDetailDialogOpen, setInventoryDetailDialogOpen] =
    useState(false);
  const handleInventoryDetailDialogClose = () => {
    setSelectedProductId(null);
    setInventoryDetailDialogOpen(false);
  };

  return (
    <Grid item xs={12}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{ display: 'flex' }}>
          <div
            style={{
              width: '1rem',
              background: order.color,
            }}
          ></div>
          <Accordion
            square={true}
            style={{ margin: '0px' }}
            onChange={(evt, expanded) => {}}
          >
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography className={classes.heading} style={{ flex: '0.2' }}>
                  <strong>{order.quantity}</strong>
                </Typography>
                <div style={{ flex: '0.5' }}>
                  <Typography className={classes.heading}>
                    <strong>{order.productName}</strong>
                  </Typography>
                  <Typography variant="body1">
                    {order.productBrand && order.productBrand}/
                    {order.productStyle && order.productStyle}/
                    {order.productSize && order.productSize}
                  </Typography>
                </div>
                <Typography
                  variant="body1"
                  style={{ flex: '0.2', textAlign: 'center' }}
                >
                  {numberFormatText(order.amount, store.currency)}
                </Typography>
                <IconButton
                  style={{ flex: '0.1' }}
                  aria-label="close"
                  color="inherit"
                  size="small"
                >
                  <DeleteIcon
                    fontSize="inherit"
                    onClick={handleDeleteOrderItem}
                  />
                </IconButton>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    id="Quantity"
                    label="Quantity"
                    onChange={onChange}
                    name="quantity"
                    value={order.quantity}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="Unit Price"
                    label="Unit Price"
                    name="price"
                    onChange={onChange}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      startAdornment: (
                        <InputAdornment position="start">
                          {store.currency}
                        </InputAdornment>
                      ),
                    }}
                    value={order.price}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="Discount %"
                    label="Discount %"
                    onChange={onChange}
                    name="discount"
                    type="number"
                    value={order.discount}
                  />
                </Grid>
                {order.taxId && (
                  <Grid item xs={12} md={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="tax"
                      label={`Tax Rate ${
                        order.taxType === TAX_OPTIONS.INCLUSIVE
                          ? TAX_OPTIONS.INCLUSIVE
                          : TAX_OPTIONS.EXCLUSIVE
                      }`}
                      onChange={onChange}
                      name="taxRate"
                      disabled
                      type="number"
                      value={order.taxRate}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="Notes"
                    onChange={onChange}
                    label="Notes"
                    name="notes"
                    multiline
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button
                    onClick={() => {
                      setSelectedProductId(order.productId);
                      setInventoryDetailDialogOpen(true);
                    }}
                    startIcon={<InfoIcon />}
                    size="small"
                  >
                    Inventory {'&'} Details
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </ClickAwayListener>

      <InventoryDetailDialog
        id={selectedProductId}
        inventoryDetailDialogOpen={inventoryDetailDialogOpen}
        handleInventoryDetailDialogClose={handleInventoryDetailDialogClose}
      />
    </Grid>
  );
};

export default OrderItem;
