import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessIcon from '@mui/icons-material/Business';
import Hidden from '@mui/material/Hidden';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import StoreIcon from '@mui/icons-material/Store';
import SendIcon from '@mui/icons-material/Send';
const drawerWidth = 180;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  toolbar: theme.mixins.toolbar,
}));

const SuperAdminSidebar = ({ selected, mobileOpen, closeDrawer }) => {
  const classes = useStyles();

  const handleDrawerToggle = () => {
    closeDrawer();
  };

  const drawer = (
    <React.Fragment>
      <div className={classes.toolbar} />
      <List>
        <ListItem
          component={Link}
          to="/superadmin"
          selected={selected === 'Dashboard'}
          button
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        <ListItem
          component={Link}
          to="/superadmin/stores"
          selected={selected === 'Stores'}
          button
        >
          <ListItemIcon>
            <StoreIcon />
          </ListItemIcon>
          <ListItemText primary="Stores" />
        </ListItem>

        <ListItem
          component={Link}
          to="/superadmin/message-templates"
          selected={selected === 'Message Templates'}
          button
        >
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="Message Templates" />
        </ListItem>

        <ListItem
          component={Link}
          to="/superadmin/demodata"
          selected={selected === 'Demo Data'}
          button
        >
          <ListItemIcon>
            <DataUsageIcon />
          </ListItemIcon>
          <ListItemText primary="Demo Data" />
        </ListItem>

        <ListItem
          component={Link}
          to="/superadmin/business-types"
          selected={selected === 'Business Types'}
          button
        >
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Business Types" />
        </ListItem>
      </List>
    </React.Fragment>
  );
  return (
    <div>
      <Hidden smUp implementation="css">
        <Drawer
          className={classes.drawer}
          variant="temporary"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor={'left'}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
        >
          {drawer}
        </Drawer>
      </Hidden>

      <Hidden xsDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default SuperAdminSidebar;
