import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import notificationReducer from './notification/notificationReducer';
import userReducer from './user/userReducer';
import pagesReducer from './pages/pagesReducer';
import roleReducer from './role/roleReducer';
import categoryReducer from './category/categoryReducer';
import productReducer from './products/productReducer';
import stockAdjustmentReducer from './stock-adjustment/stockAdjustmentReducer';
import storeReducer from './store/storeReducer';
import settingsReducer from './settings/settingsReducer';
import taxReducer from './tax/taxReducer';
import paymentTypeReducer from './payment-types/paymentTypeReducer';
import deviceReducer from './devices/deviceReducer';
import receiptSettingReducer from './receipt-settings/receiptSettingReducer';
import customerReducer from './customers/customerReducer';
import customerGroupReducer from './customer-group/customerGroupReducer';
import subCategoryReducer from './sub-category/subCategoryReducer';
import orderReducer from './orders/orderReducer';
import cashRegisterReducer from './cash-register/cashRegisterReducer';
import cashMovementReducer from './cash-movements/cashMovementReducer';
import paymentReducer from './payments/paymentReducer';
import businessTypesReducer from './business-type/businessTypesReducer';
import messageTemplateReducer from './message-template/messageTemplateReducer';
import supplierReducer from './suppliers/supplierReducer';
import purchaseOrderReducer from './purchase-orders/purchaseOrderReducer';
import inventoryCountReducer from './inventory-count/inventoryCountReducer';
import billingReducer from './billing/billingReducer';

export default combineReducers({
  auth: authReducer,
  billing: billingReducer,
  notification: notificationReducer,
  user: userReducer,
  page: pagesReducer,
  role: roleReducer,
  category: categoryReducer,
  subcategory: subCategoryReducer,
  product: productReducer,
  order: orderReducer,
  payment: paymentReducer,
  store: storeReducer,
  cashRegister: cashRegisterReducer,
  cashMovement: cashMovementReducer,
  messageTemplate: messageTemplateReducer,
  tax: taxReducer,
  customer: customerReducer,
  customerGroup: customerGroupReducer,
  supplier: supplierReducer,
  purchaseOrder: purchaseOrderReducer,
  inventoryCount: inventoryCountReducer,
  stockAdjustment: stockAdjustmentReducer,
  paymentType: paymentTypeReducer,
  businessType: businessTypesReducer,
  setting: settingsReducer,
  receiptSetting: receiptSettingReducer,
  device: deviceReducer,
});
