export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_BULK = 'ADD_PRODUCT_BULK';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';

export const STOCK_STATUS = {
  OUT_OF_STOCK: 'Out Of Stock',
  IN_STOCK: 'In Stock',
  LOW_STOCK: 'Low',
  ON_BACKORDER: 'On Back Order',
};
export const PRODUCT_TYPE = {
  GOODS: 'GOODS',
  SERVICE: 'SERVICE',
};
export const SELLING_PRICE_OPTION = {
  RETAIL: 'RETAIL',
  WHOLESALE: 'WHOLESALE',
  MINIMUM_PRICE: 'MINIMUM_PRICE',
};

export const UNIT_MEASURE = {
  weight: ['Kg', 'gms', 'Tonne'],
  volume: ['Litre', 'Ml', 'Galon'],
  length: [
    'Kilometre',
    'Metre',
    'Inch',
    'Yard',
    'Foot',
    'Cm',
    'Foot',
    'Cm',
    'Millimetre',
  ],
  area: ['square foot', 'square metre', 'Acre', 'Hectare'],
  time: ['minute', 'hour', 'day', 'month', 'year'],
  count: [
    'scoop',
    'box',
    'dozen',
    'piece',
    'case',
    'crate',
    'packet',
    'carton',
    'roll',
    'set',
  ],
};
