import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_TAX,
  DELETE_TAX,
  GET_TAX,
  GET_TAXES,
  TAX_ERROR,
  UPDATE_TAX,
} from './taxTypes';

const url = process.env.REACT_APP_API_URL + 'taxes';

// Add Tax
export const addTax = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_TAX, payload: res.data });
    // Set Register Success Notification

    const tax = res.data;
    const notification = {
      message: `Added Tax:  ${tax.name} successfully`,
      type: 'success',
    };
    dispatch({ type: ADD_TAX, payload: tax });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: TAX_ERROR,
      payload: 'Failed to Add Tax',
    });
  }
};

// Update Tax
export const updateTax = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const tax = res.data;
    const notification = {
      message: `Updated Tax:  ${tax.name} successfully`,
      type: 'success',
    };

    dispatch({ type: UPDATE_TAX, payload: tax });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: TAX_ERROR, payload: 'Failed to Update Tax' });
  }
};

// Get Taxes
export const getTaxes = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const taxes = res.data;
  dispatch({ type: GET_TAXES, payload: taxes });
  dispatch({ type: CLEAR_LOADING });
};

// Get Taxes By Id
export const getTaxById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const tax = res.data;
    dispatch({ type: GET_TAX, payload: tax });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: TAX_ERROR,
      payload: 'Failed to Get Tax',
    });
  }
};

// Delete Tax By Id
export const deleteTax = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_TAX, payload: id });

    const notification = {
      message: `Deleted Tax successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: TAX_ERROR,
      payload: 'Failed to Delete Tax',
    });
  }
};
