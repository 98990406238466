import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import SellTab from './SellTab';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import CounterHome from '../counter/CounterHome';
import SellCashManagement from './cash-register/SellCashManagement';
import SellOpenCashRegister from './cash-register/SellOpenCashRegister';
import SalesHistory from './SalesHistory';
import SellOpenCloseRegister from './openclose/SellOpenCloseRegister';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3, 2),
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sidebar: {
    flex: 0.1,
    alignItems: 'stretch',
    // background:'#F0F0F0'
  },
  container: {
    flex: 0.9,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));
const SellHome = (props) => {
  const classes = useStyles();
  const store = useSelector((state) => state.auth.store);

  if (!store) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.sidebar}>
          <SellTab {...props} />
        </div>
        <div className={classes.container}>
          <Routes>
            <Route exact path="counter" element={<CounterHome />} />
            <Route
              exact
              path="cash-management"
              element={<SellCashManagement />}
            />
            <Route
              exact
              path="cash-register/open"
              element={<SellOpenCashRegister />}
            />
            <Route
              exact
              path="cash-register/close"
              element={<SellOpenCloseRegister />}
            />
            <Route exact path="all" element={<SalesHistory />} />
          </Routes>
        </div>
      </div>
    );
  }
};

export default SellHome;
