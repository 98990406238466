import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { parseISO, format } from 'date-fns/esm';
import Paper from '@mui/material/Paper';
import { NumberFormatCustom, numberFormatText } from '../../../utils/functions';
import { CASH_MOVEMENT_TYPE } from '../../../redux/cash-movements/cashMovementTypes';
import { getPaymentsSummary } from '../../../redux/payments/paymentActions';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const SellCashRegisterSummary = ({ updateForm }) => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const cashMovements = useSelector(
    (state) => state.cashMovement.cashMovements
  );
  const currentCashRegister = useSelector(
    (state) => state.cashRegister.current
  );
  const paymentSummary = useSelector((state) => state.payment.summary);
  const store = useSelector((state) => state.auth.store);
  const [totalCash, setTotalCash] = useState(0);
  const [values, setValues] = useState({
    differenceCashMovements: 0,
    countedTotal: 0,
    differenceTotal: 0,
  });

  useEffect(() => {
    if (currentCashRegister) {
      dispatch(getPaymentsSummary({ cashRegisterId: currentCashRegister.id }));
    }
    // eslint-disable-next-line
  }, [currentCashRegister]);

  // Calculate Cash Movements
  useEffect(() => {
    if (cashMovements && paymentSummary) {
      var total = cashMovements.data.reduce(function (prev, cur) {
        return prev + cur.amount;
      }, 0);
      setTotalCash(total + paymentSummary.total);
      setForm({ countedNo: total + paymentSummary.total });
    }
    // eslint-disable-next-line
  }, [cashMovements, paymentSummary]);

  const [expandCashMovements, setExpandCashMovements] = useState(false);

  const [form, setForm] = useState({
    countedNo: 0,
  });

  useEffect(() => {
    if (form) {
      let differenceCashMovements =
        parseFloat(form.countedNo).toFixed(2) -
        parseFloat(totalCash).toFixed(2);
      setValues({
        ...values,
        differenceCashMovements: differenceCashMovements,
        countedTotal: form.countedNo,
        differenceTotal: differenceCashMovements,
      });
      updateForm({ total: totalCash, ...form });
    }
    // eslint-disable-next-line
  }, [form]);

  if (!paymentSummary || !cashMovements) {
    return (
      <div className={classes.root} in>
        <CircularProgress color="primary" />
      </div>
    );
  } else {
    return (
      <TableContainer elevation={0} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Payment Types</TableCell>
              <TableCell align="right">
                Expected&nbsp;({store.currency})
              </TableCell>
              <TableCell align="right">
                Counted&nbsp;({store.currency})
              </TableCell>
              <TableCell align="right">
                Differences&nbsp;({store.currency})
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setExpandCashMovements(!expandCashMovements)}
                >
                  {expandCashMovements ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </TableCell>

              <TableCell>
                <span style={{ color: theme.palette.primary.main }}>
                  <strong>Cash</strong>
                </span>
                <br />
                <Typography variant="body2">
                  View Cash Payments, Floats and Movements
                </Typography>
              </TableCell>
              <TableCell>{numberFormatText(totalCash)}</TableCell>
              <TableCell>
                <TextField
                  name="countedNo"
                  style={{ textAlign: 'end' }}
                  value={form.countedNo}
                  onChange={(e) =>
                    setForm({ ...form, countedNo: e.target.value })
                  }
                  variant="outlined"
                  placeholder="Enter amount"
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  inputProps={{ style: { textAlign: 'end' } }}
                />
              </TableCell>
              <TableCell align="right">
                <Typography
                  variant="body2"
                  style={{
                    color:
                      values.differenceCashMovements >= 0 ? '#41af4b' : '#F00',
                  }}
                >
                  <strong>
                    {numberFormatText(values.differenceCashMovements)}
                  </strong>
                </Typography>
              </TableCell>
            </TableRow>
            {/* Cash Movements Detail Expand */}
            <TableRow>
              <TableCell
                style={{ paddingBottom: 0, paddingTop: 0 }}
                colSpan={6}
              >
                <Collapse in={expandCashMovements} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Table size="small" aria-label="purchases">
                      <TableHead>
                        <TableRow>
                          <TableCell>Cash Movements</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>User</TableCell>
                          <TableCell>Amount ({store.currency})</TableCell>
                          <TableCell align="right">Reason</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {cashMovements &&
                          cashMovements.data.map((cashMovement) => (
                            <TableRow key={cashMovement.id}>
                              <TableCell />
                              <TableCell>
                                {format(
                                  parseISO(
                                    cashMovement.createdAt,
                                    'yyyy-MM-dd',
                                    new Date()
                                  ),
                                  'do MMM yyyy p'
                                )}
                              </TableCell>
                              <TableCell>{cashMovement.userName}</TableCell>
                              <TableCell>
                                <Typography
                                  color={
                                    cashMovement.type.includes(
                                      CASH_MOVEMENT_TYPE.CASH_OUT
                                    )
                                      ? 'secondary'
                                      : 'primary'
                                  }
                                  variant="body1"
                                >
                                  {numberFormatText(cashMovement.amount)}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <strong>{cashMovement.type}</strong>
                                <br />
                                {cashMovement.notes ?? ''}
                              </TableCell>
                            </TableRow>
                          ))}
                        <TableRow>
                          <TableCell>
                            <strong>Cash Payments Received</strong>
                          </TableCell>
                          <TableCell />
                          <TableCell />
                          <TableCell>
                            <Typography variant="body1">
                              {numberFormatText(paymentSummary.total)}
                            </Typography>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>

            {/* Totals */}
            <TableRow>
              <TableCell />
              <TableCell>
                <strong>Totals</strong>
              </TableCell>
              <TableCell>
                <strong>{numberFormatText(totalCash)}</strong>
              </TableCell>
              <TableCell align="right">
                <strong>{numberFormatText(values.countedTotal)}</strong>
              </TableCell>
              <TableCell align="right">
                <strong
                  style={{
                    color:
                      values.differenceCashMovements >= 0 ? '#41af4b' : '#F00',
                  }}
                >
                  {numberFormatText(values.differenceTotal)}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default SellCashRegisterSummary;
