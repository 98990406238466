import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import toMaterialStyle from 'material-color-hash';
import {
  getCategoryById,
  deleteCategory,
} from '../../../redux/category/categoryActions';
import CategoryProducts from './CategoryProducts';
import SubCategoryList from './subcategories/SubCategoryList';
import { numberFormatText } from 'utils/functions';
import CategoryStats from './CategoryStats';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    backgroundColor: red[500],
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

const CategoryDetail = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const current = useSelector((state) => state.category.current);
  const store = useSelector((state) => state.auth.store);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getCategoryById(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const [categoryTab, setCategoryTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setCategoryTab(newValue);
  };
  const [showDialogDelete, setShowDialogDelete] = useState(false);

  return (
    <Box className={classes.root} sx={{ p: 2 }}>
      {current && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper>
              <Stack direction="column">
                <Stack direction="row" justifyContent="end" sx={{ p: 1 }}>
                  <Button
                    color="primary"
                    component={Link}
                    to={`/admin/categories/edit/${id}`}
                  >
                    EDIT
                  </Button>
                  {current.stats.totalProductNo == 0 && (
                    <Button
                      color="primary"
                      onClick={() => {
                        setShowDialogDelete(true);
                      }}
                    >
                      DELETE
                    </Button>
                  )}
                </Stack>
                <Divider />
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ p: 2 }}
                >
                  {current.image ? (
                    <Avatar
                      sx={{ width: 100, height: 100 }}
                      aria-label="Category Name"
                      className={classes.avatar}
                      src={current.image}
                    />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: toMaterialStyle(
                          `${current.name}`,
                          '250'
                        ).backgroundColor,
                        fontSize: '2em',
                      }}
                      sx={{ width: 100, height: 100 }}
                      aria-label="Category Name"
                      className={classes.avatar}
                    >
                      {current.name.charAt(0)}
                    </Avatar>
                  )}
                  <Typography variant="h6" sx={{ color: 'text.primary' }}>
                    {current.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {current.description}
                  </Typography>
                </Stack>
              </Stack>
            </Paper>
          </Grid>
          {/* Category Stats */}
          <Grid item xs={12} md={8}>
            <CategoryStats />
          </Grid>

          <Grid item xs={12} style={{ marginTop: '1rem' }}>
            <Paper>
              <Tabs
                value={categoryTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                className={classes.tabs}
                textColor="primary"
                centered
                variant="fullWidth"
              >
                <Tab label="Products" />
                <Tab label="Sub Categories" />
              </Tabs>

              <div className={classes.tabs}>
                {categoryTab === 0 && current && (
                  <CategoryProducts id={current.id} />
                )}
                {categoryTab === 1 && current && (
                  <SubCategoryList id={current.id} />
                )}
              </div>

              {/* <div>{value === 2 && <div>Hello Notifications</div>}</div> */}
            </Paper>
          </Grid>
        </Grid>
      )}
      {showDialogDelete && (
        <Dialog
          open={showDialogDelete}
          onClose={() => setShowDialogDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Category Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete <strong>{current.name}</strong> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dispatch(deleteCategory(id));
                setShowDialogDelete(false);
              }}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
            <Button onClick={() => setShowDialogDelete(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default CategoryDetail;
