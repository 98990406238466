import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from 'react-redux';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Stack from '@mui/material/Stack';
import { getOrders } from 'redux/orders/orderActions';
import { getUsers } from 'redux/user/userActions';
import { useTheme } from '@mui/styles';
import Loading from 'components/layout/Loading';
import SalesFilterBar from './SalesFilterBar';
import { DURATION } from 'utils/Constants';
import { capitalizeFirstLetter } from 'utils/functions';
import { numberFormatText } from 'utils/functions';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  ResponsiveContainer,
  TooltipProps,
} from 'recharts';

import {
  parseISO,
  subWeeks,
  addDays,
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
  addHours,
  eachHourOfInterval,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachYearOfInterval,
  format,
} from 'date-fns';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CustomTooltip = ({ active, payload, label, total }) => {
  if (active && payload && payload.length) {
    return (
      <Paper>
        <Stack>
          <Typography variant="body2" color="primary">
            <strong> {label}</strong>
          </Typography>
          <Typography variant="p" color="textSecondary">
            {total}
          </Typography>
        </Stack>
      </Paper>
    );
  }

  return null;
};

const SalesChart = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.order.orders);
  const users = useSelector((state) => state.user.users);
  const store = useSelector((state) => state.auth.store);

  const [filter, setFilter] = useState({
    showAll: true,
    employeeIds: [],
    createdDateFrom: subWeeks(new Date(), 1),
    createdDateTo: new Date(),
  });

  useEffect(() => {
    dispatch(getOrders(filter));
    dispatch(getUsers());
    // eslint-disable-next-line
  }, []);

  const [data, setData] = useState([]);

  const [chartDuration, setChartDuration] = useState(DURATION.DAYS);

  const handleChartViewChange = (
    chartView = DURATION.DAYS,
    startDate = subWeeks(new Date(), 1),
    endDate = new Date()
  ) => {
    setChartDuration(chartView);
    switch (chartView) {
      case DURATION.HOURS: {
        // Fetch Labels for Each Hour of the Day
        const data = eachHourOfInterval({
          start: startDate,
          end: endDate,
        }).map((date) => {
          // Get Data Between Date + Date+1
          let totalOrders = orders.data
            .filter(
              (order) =>
                parseISO(order.createdAt).getTime() > date.getTime() &&
                parseISO(order.createdAt).getTime() <
                  addHours(date, 1).getTime()
            )
            .reduce(function (prev, cur) {
              return prev + cur.amount;
            }, 0);
          return { label: format(date, 'hh:mm aa'), total: totalOrders };
        });
        setData(data);
        break;
      }
      case DURATION.DAYS: {
        // Fetch Labels for Each Day
        const data = eachDayOfInterval({
          start: startDate,
          end: endDate,
        }).map((date) => {
          // Get Data Between Date + Date+1
          let totalOrders = orders.data
            .filter(
              (order) =>
                parseISO(order.createdAt).getTime() > date.getTime() &&
                parseISO(order.createdAt).getTime() <
                  addHours(addDays(date, 1), 23).getTime()
            )
            .reduce(function (prev, cur) {
              return prev + cur.amount;
            }, 0);
          return { label: format(date, 'MMM dd'), total: totalOrders };
        });
        setData(data);
        break;
      }
      case DURATION.WEEKS: {
        // Fetch Labels for Each Day
        const data = eachWeekOfInterval({
          start: startDate,
          end: endDate,
        }).map((date, index) => {
          // Get Data Between Date + Date+1
          let totalOrders = orders.data
            .filter(
              (order) =>
                parseISO(order.createdAt).getTime() > date.getTime() &&
                parseISO(order.createdAt).getTime() <
                  addWeeks(date, 1).getTime()
            )
            .reduce(function (prev, cur) {
              return prev + cur.amount;
            }, 0);
          return {
            label: `${format(date, 'MMM dd')} - ${format(
              addWeeks(date, 1),
              'MMM dd'
            )}`,
            total: totalOrders,
          };
        });
        setData(data);
        break;
      }
      case DURATION.MONTHS: {
        // Fetch Labels for Each Month
        const data = eachMonthOfInterval({
          start: startDate,
          end: endDate,
        }).map((date) => {
          // Get Data Between Date + Date+1
          let totalOrders = orders.data
            .filter(
              (order) =>
                parseISO(order.createdAt).getTime() > date.getTime() &&
                parseISO(order.createdAt).getTime() <
                  addMonths(date, 1).getTime()
            )
            .reduce(function (prev, cur) {
              return prev + cur.amount;
            }, 0);
          return { label: format(date, 'MMM'), total: totalOrders };
        });
        setData(data);
        break;
      }
      case DURATION.MONTHS: {
        // Fetch Labels for Each Quarter
        const data = eachQuarterOfInterval({
          start: startDate,
          end: endDate,
        }).map((date) => {
          // Get Data Between Date + Date+1
          let totalOrders = orders.data
            .filter(
              (order) =>
                parseISO(order.createdAt).getTime() > date.getTime() &&
                parseISO(order.createdAt).getTime() <
                  addQuarters(date, 1).getTime()
            )
            .reduce(function (prev, cur) {
              return prev + cur.amount;
            }, 0);
          return { label: format(date, 'QQQ yyyy'), total: totalOrders };
        });
        setData(data);
        break;
      }
      case DURATION.YEARS: {
        // Fetch Labels for Each Quarter
        const data = eachYearOfInterval({
          start: startDate,
          end: endDate,
        }).map((date) => {
          // Get Data Between Date + Date+1
          let totalOrders = orders.data
            .filter(
              (order) =>
                parseISO(order.createdAt).getTime() > date.getTime() &&
                parseISO(order.createdAt).getTime() <
                  addYears(date, 1).getTime()
            )
            .reduce(function (prev, cur) {
              return prev + cur.amount;
            }, 0);
          return { label: format(date, 'QQQ yyyy'), total: totalOrders };
        });
        setData(data);
        break;
      }
    }
  };

  useEffect(() => {
    if (orders) {
      handleChartViewChange();
    }
  }, [orders]);

  console.log('Data', data);

  if (!users || !orders) {
    return <Loading title="Fetching Sales Data" />;
  } else {
    return (
      <Stack sx={{ width: 1 }} direction="column">
        <Paper sx={{ px: 2, py: 3, width: 1, bgcolor: 'background.paper' }}>
          <Stack direction="column" spacing={2}>
            <SalesFilterBar handleChartViewChange={handleChartViewChange} />
            <div style={{ height: '600px' }}>
              {data.length > 0 && (
                <ResponsiveContainer>
                  <BarChart data={data}>
                    {/* <XAxis type="number" stroke="#344053" hide /> */}
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip
                      content={<CustomTooltip />}
                      wrapperStyle={{ width: 150, backgroundColor: '#ccc' }}
                    />
                    <Legend
                      width={100}
                      wrapperStyle={{
                        top: 40,
                        right: 20,
                        width: 'fit-content',
                        backgroundColor: '#f5f5f5',
                        border: '1px solid #d5d5d5',
                        borderRadius: 3,
                        lineHeight: '40px',
                      }}
                    />
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                    <Bar dataKey="total" fill="#344053" barSize={30} />
                  </BarChart>
                </ResponsiveContainer>
              )}
            </div>
          </Stack>
        </Paper>
      </Stack>
    );
  }
};

export default SalesChart;
