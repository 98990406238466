export const GET_BILLING = 'GET_BILLING';
export const GET_BILLINGS = 'GET_BILLINGS';
export const FILTER_BILLING = 'FILTER_BILLING';
export const UPDATE_BILLING = 'UPDATE_BILLING';
export const SET_CURRENT_BILLING = 'SET_CURRENT_BILLING';
export const CREATE_BILLING = 'CREATE_BILLING';
export const REMOVE_BILLING = 'REMOVE_BILLING';
export const BILLING_ERROR = 'BILLING_ERROR';

export const GET_BILLING_PAYMENTS = 'GET_BILLING_PAYMENTS';
