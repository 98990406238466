export const GET_PAYMENT_TYPE = 'GET_PAYMENT_TYPE';
export const GET_PAYMENT_TYPES = 'GET_PAYMENT_TYPES';
export const ADD_PAYMENT_TYPE = 'ADD_PAYMENT_TYPE';
export const UPDATE_PAYMENT_TYPE = 'UPDATE_PAYMENT_TYPE';
export const DELETE_PAYMENT_TYPE = 'DELETE_PAYMENT_TYPE';
export const PAYMENT_TYPE_ERROR = 'PAYMENT_TYPE_ERROR';

export const PAYMENT_TYPE_OPTIONS = {
  CASH: 'CASH',
  CARD: 'CARD',
  CHECK: 'CHECK',
  OTHER: 'OTHER',
};
