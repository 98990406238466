import {
  ADD_ORDER,
  UPDATE_ORDER,
  GET_ORDERS,
  GET_ORDER,
  DELETE_ORDER,
  ORDER_ERROR,
  UPDATE_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  SET_CURRENT_ORDER,
  ADD_ORDER_ITEM,
  DISCARD_ORDER,
  GET_ORDERS_STATS,
  UPDATE_NEW_ORDER,
  GET_PRODUCT_ORDER_STATS,
  PARK_SALE,
} from './orderTypes';

const initialState = {
  orders: null,
  current: null,
  loading: false,
  error: null,
  stats: null,
  productStats: null,
  newOrder: {
    notes: '',
    subTotal: 0,
    discountTotal: 0,
    taxTotal: 0,
    paymentId: '',
    sendCustomerReceipt: false,
    orderItems: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          data:
            state.orders !== null && state.orders.count > 0
              ? state.orders.data.concat(action.payload)
              : [action.payload],
        },
        newOrder: initialState.newOrder,
      };
    case UPDATE_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          data:
            state.orders !== null
              ? state.orders.data.map((order) =>
                  order.id === action.payload.id ? action.payload : order
                )
              : [action.payload],
        },
      };
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_ORDER:
      return {
        ...state,
        current: action.payload,
      };
    case SET_CURRENT_ORDER:
      return {
        ...state,
        current: action.payload,
      };
    case GET_ORDERS_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case GET_PRODUCT_ORDER_STATS:
      return {
        ...state,
        productStats: action.payload,
      };
    case DELETE_ORDER:
      return {
        ...state,
        orders: {
          ...state.orders,
          data:
            state.orders &&
            state.orders.data &&
            state.orders.data.filter((order) => order.id !== action.payload),
        },
      };
    case ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ADD_ORDER_ITEM:
      return {
        ...state,
        loading: false,
        newOrder: {
          ...state.newOrder,
          orderItems: state.newOrder.orderItems.concat(action.payload),
        },
      };
    case UPDATE_NEW_ORDER:
      return {
        ...state,
        newOrder: Object.assign(state.newOrder, action.payload),
      };
    case UPDATE_ORDER_ITEM:
      return {
        ...state,
        loading: false,
        newOrder: {
          ...state.newOrder,
          orderItems: state.newOrder.orderItems.map((orderItem) =>
            orderItem.id === action.payload.id ? action.payload : orderItem
          ),
        },
      };
    case REMOVE_ORDER_ITEM:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          orderItems: state.newOrder.orderItems.filter(
            (orderItem) => orderItem.id !== action.payload
          ),
        },
      };
    case DISCARD_ORDER:
    case PARK_SALE:
      return {
        ...state,
        newOrder: initialState.newOrder,
        current: null,
      };
    default:
      return state;
  }
};
