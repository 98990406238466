import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_PRODUCT,
  PRODUCT_ERROR,
  GET_PRODUCTS,
  GET_PRODUCT,
  DELETE_PRODUCT,
  ADD_PRODUCT_BULK,
  FILTER_PRODUCTS,
} from './productTypes';

const url = process.env.REACT_APP_API_URL + 'products';

// Add Product
export const addProduct = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_PRODUCT, payload: res.data });
    // Set Register Success Notification

    const product = res.data;
    const notification = {
      message: `Added Product:  ${product.name} successfully`,
      type: 'success',
      id: product.id,
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: PRODUCT_ERROR, payload: 'Failed to Add Product' });
  }
};

// Create Product Bulk
export const createProductsBulk = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/bulk`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_PRODUCT_BULK });
    // Set Register Success Notification

    const notification = {
      message: `Added ${data.length} Product(s) successfully`,
      type: 'success',
      action: ADD_PRODUCT_BULK,
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: PRODUCT_ERROR, payload: 'Failed to Create Products' });
  }
};

// Update Product
export const updateProduct = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const product = res.data;
    const notification = {
      message: `Updated Product:  ${product.name} successfully`,
      type: 'success',
      id: product.id,
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: PRODUCT_ERROR, payload: 'Failed to Update Product' });
  }
};

// Get Products
export const getProducts = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const products = res.data;
    dispatch({ type: GET_PRODUCTS, payload: products });
    dispatch({ type: FILTER_PRODUCTS, payload: filter });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    console.error(e);
  }
};

// Get Product By Id
export const getProductById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const product = res.data;
    dispatch({ type: GET_PRODUCT, payload: product });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: PRODUCT_ERROR, payload: 'Failed to Get Product' });
  }
};

// Delete Category By Id
export const deleteProduct = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_PRODUCT, payload: id });

    const notification = {
      message: `Deleted Product successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: PRODUCT_ERROR, payload: 'Failed to Delete Product' });
  }
};
