import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { parseISO, format } from 'date-fns/esm';
import { numberFormatText } from '../../../utils/functions';
import { getInventoryCountById } from '../../../redux/inventory-count/inventoryCountActions';
import { INVENTORY_COUNT_STATUS } from '../../../redux/inventory-count/inventoryCountTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    borderRadius: theme.spacing(7),
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const InventoryCountDetail = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const current = useSelector((state) => state.inventoryCount.current);
  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);

  useEffect(() => {
    dispatch(getInventoryCountById(id));
    // eslint-disable-next-line
  }, []);

  if (id && !current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Paper>
          <Grid container direction="column">
            <Grid item xs={12}>
              {loading && <LinearProgress variant="query" color="secondary" />}
            </Grid>
            <Grid item xs={12}>
              <Stack
                style={{ padding: '1rem' }}
                spacing={2}
                justifyContent="flex-end"
                direction="row"
              >
                {(current.status === INVENTORY_COUNT_STATUS.IN_PROGRESS ||
                  current.status === INVENTORY_COUNT_STATUS.PENDING) && (
                  <Button
                    component={Link}
                    to={`/admin/inventory/inventory-count/count/${current.id}`}
                  >
                    COUNT STOCK
                  </Button>
                )}
                {current.status === INVENTORY_COUNT_STATUS.PENDING && (
                  <Button
                    component={Link}
                    to={`/admin/inventory/inventory-count/edit/${current.id}`}
                  >
                    Edit
                  </Button>
                )}

                <Button endIcon={<ArrowDropDownIcon />}>More</Button>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={2} direction="column">
                {/* Header with ID and Status */}
                <Stack
                  sx={{
                    px: (theme) => theme.spacing(4),
                    pt: (theme) => theme.spacing(4),
                  }}
                  direction="column"
                  spacing={2}
                >
                  <Typography variant="h4">{current.id}</Typography>

                  <Typography
                    sx={{
                      bgcolor: 'text.primary',
                      color: 'background.paper',
                      p: 1,
                      width: 150,
                      borderRadius: 1,
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    variant="body1"
                    color="secondary"
                  >
                    {current.status}
                  </Typography>
                </Stack>
                {/* Count Details */}
                <Stack
                  sx={{
                    px: (theme) => theme.spacing(4),
                    pt: (theme) => theme.spacing(4),
                  }}
                  direction="column"
                  spacing={2}
                >
                  <Typography variant="body1">
                    Date Created:{' '}
                    <strong>
                      {format(
                        parseISO(current.createdAt, 'yyyy-MM-dd', new Date()),
                        'do MMM yyyy p'
                      )}
                    </strong>
                  </Typography>
                  {current.dateCompleted && (
                    <Typography variant="body1">
                      Completed On:{' '}
                      <strong>
                        {format(
                          parseISO(
                            current.dateCompleted,
                            'yyyy-MM-dd',
                            new Date()
                          ),
                          'do MMM yyyy p'
                        )}
                      </strong>
                    </Typography>
                  )}
                  <Typography variant="body1">
                    Created By: <strong>{current.userName}</strong>
                  </Typography>
                </Stack>

                <Divider />
                <Typography
                  sx={{
                    px: (theme) => theme.spacing(4),
                    pt: (theme) => theme.spacing(4),
                  }}
                  variant="h5"
                >
                  Items
                </Typography>
                <TableContainer>
                  <Table
                    // sx={{
                    //   ml: (theme) => theme.spacing(4),
                    // }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3}>Item</TableCell>
                        <TableCell align="right">Expected Stock</TableCell>
                        <TableCell align="right">Counted</TableCell>
                        <TableCell align="right">Difference</TableCell>
                        <TableCell align="right">
                          Cost Difference
                          {store && store.currency ? `(${store.currency})` : ''}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {current.products.map((product) => (
                        <TableRow
                          key={product.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell colSpan={3} component="th" scope="row">
                            {product.productName}
                          </TableCell>
                          <TableCell align="right">
                            {product.expected}
                          </TableCell>
                          <TableCell align="right">{product.counted}</TableCell>
                          <TableCell align="right">
                            {product.difference}
                          </TableCell>
                          <TableCell align="right">
                            {numberFormatText(product.costDifference)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={4} />

                        <TableCell align="right" colSpan={1}>
                          <Typography>
                            <strong>Total</strong>
                          </Typography>
                        </TableCell>
                        <TableCell align="right" colSpan={1}>
                          <Typography>
                            <strong>
                              {current.products.reduce(function (prev, cur) {
                                return prev + cur.difference;
                              }, 0)}
                            </strong>
                          </Typography>
                        </TableCell>
                        <TableCell align="right" colSpan={1}>
                          <Typography>
                            <strong>
                              {' '}
                              {numberFormatText(
                                current.products.reduce(function (prev, cur) {
                                  return prev + cur.costDifference;
                                }, 0)
                              )}
                            </strong>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
};

export default InventoryCountDetail;
