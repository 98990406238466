import {
  ADD_SUPPLIER,
  DELETE_SUPPLIER,
  GET_SUPPLIER,
  GET_SUPPLIERS,
  SUPPLIER_ERROR,
  UPDATE_SUPPLIER,
} from './supplierTypes';

const initialState = {
  suppliers: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          data:
            state.suppliers !== null
              ? state.suppliers.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          data:
            state.suppliers !== null
              ? state.suppliers.data.map((supplier) =>
                  supplier.id === action.payload.id ? action.payload : supplier
                )
              : [action.payload],
        },
      };
    case GET_SUPPLIERS:
      return {
        ...state,
        suppliers: action.payload,
        current: null,
      };
    case GET_SUPPLIER:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_SUPPLIER:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          data:
            state.suppliers &&
            state.suppliers.data &&
            state.suppliers.data.filter(
              (supplier) => supplier.id !== action.payload
            ),
        },
      };
    case SUPPLIER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
