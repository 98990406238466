import {
  SET_LOADING,
  CLEAR_LOADING,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_CASH_MOVEMENT,
  CASH_MOVEMENT_ERROR,
  GET_CASH_MOVEMENTS,
} from './cashMovementTypes';

const url = process.env.REACT_APP_API_URL + 'cash-movements';

export const addCashMovement = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_CASH_MOVEMENT, payload: res.data });
    // Set Register Success Notification

    // const category = res.data;
    // const notification = {
    //   message: `Added Cash`,
    //   type: 'success',
    // };
    // dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CASH_MOVEMENT_ERROR,
      payload: 'Failed to Add Cash Movement',
    });
  }
};

// Get Cash Movements
export const getCashMovements = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const cashMovements = res.data;
  dispatch({ type: GET_CASH_MOVEMENTS, payload: cashMovements });
  dispatch({ type: CLEAR_LOADING });
};
