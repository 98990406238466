import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux';
import { getDevices } from 'redux/devices/deviceActions';
import ProductTab from './product/ProductTab';
import OrderTab from './order/OrderTab';
import { getPaymentTypes } from 'redux/payment-types/paymentTypeActions';
import ProductSearch from './product/ProductSearch';
import { useNavigate } from 'react-router-dom';
import { ADD_ORDER } from 'redux/orders/orderTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2),
    flexGrow: 1,
    backgroundColor: theme.palette.grey[200],
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const CounterHome = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();

  const [form, setForm] = useState({
    deviceId: '',
  });
  const devices = useSelector((state) => state.device.devices);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const notification = useSelector((state) => state.notification.notification);
  const currentOrder = useSelector((state) => state.order.newOrder);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getDevices());
    dispatch(getPaymentTypes());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (devices) {
      setForm({ ...form, deviceId: devices.data[0].id });
    }
    // eslint-disable-next-line
  }, [devices]);

  useEffect(() => {
    if (
      page &&
      page.includes('Counter') &&
      notification &&
      notification.type === 'success' &&
      notification.action === ADD_ORDER
    ) {
      // navigate('/admin/sell/all');
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <div className={classes.root}>
      <LinearProgress
        style={{ opacity: loading ? '1' : '0' }}
        variant="query"
        color="primary"
      />

      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} md={7}>
          <Stack direction="column">
            <ProductSearch />
            <ProductTab />
          </Stack>
        </Grid>
        <Grid item xs={12} md={5}>
          <OrderTab deviceId={form.deviceId} />
        </Grid>
      </Grid>
    </div>
  );
};

export default CounterHome;
