import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles, useTheme } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import {
  addDevice,
  getDeviceById,
  updateDevice,
} from '../../redux/devices/deviceActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(2),
    // flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  avatar: {
    color: '#FFF',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const AddUpdateDevice = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const device = useSelector((state) => state.device.current);

  useEffect(() => {
    dispatch(getDeviceById(id));
    // eslint-disable-next-line
  }, []);

  const [form, setForm] = useState({
    name: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(
    () => {
      if (device) {
        setForm({ ...device });
      }
    },
    // eslint-disable-next-line
    [device]
  );

  useEffect(() => {
    if (
      page &&
      page.includes('Device') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (device) {
      dispatch(updateDevice(form, device.id));
    } else {
      dispatch(addDevice(form));
    }
  };

  if (id && !device) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <Paper className={classes.root}>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.primary.main,
                    }}
                    className={classes.avatar}
                  >
                    <TabletAndroidIcon fontSize="large" />
                  </Avatar>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    id="name"
                    value={form.name}
                    label="Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size={'large'}
                    color="primary"
                    className={classes.submit}
                    disabled={!form.name || loading}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  {loading && (
                    <LinearProgress variant="query" color="secondary" />
                  )}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
};

export default AddUpdateDevice;
