import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ShopIcon from '../../../shared/icons/shop.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentStore } from '../../../redux/store/storeActions';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    display: 'flex',
    // padding: theme.spacing(4),
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    borderRadius: '0.3rem',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SetupStoreContactInfo = ({ handleNext }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const store = useSelector((state) => state.auth.store);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCurrentStore(form));
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [form, setForm] = useState({
    address: '',
    mobileNumber: '',
    email: '',
  });

  useEffect(() => {
    if (store) {
      setForm({
        address: store.address,
        mobileNumber: store.mobileNumber,
        email: store.email,
      });
    }
    // eslint-disable-next-line
  }, [store]);

  useEffect(() => {
    if (notification && notification.message === 'Updated Store') {
      handleNext();
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <img src={ShopIcon} alt="Store Contact" width={256} />
      </Grid>
      <Grid item xs={12} md={8}>
        <Typography variant="h3">
          <strong>{store ? store.name : `your store`} Contact Info</strong>
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Fill in your Store Contact Information and Address
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                name="email"
                value={form.email}
                label="Email"
                variant="outlined"
                onChange={onChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="mobileNumber"
                type="number"
                label="Mobile Number"
                name="mobileNumber"
                value={form.mobileNumber}
                onChange={onChange}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                value={form.address}
                onChange={onChange}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              disabled={
                (form.email && form.email.length === 0) ||
                (form.address && form.address.length === 0) ||
                (form.mobileNumber && form.mobileNumber.length === 0) ||
                loading
              }
            >
              <Button
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

SetupStoreContactInfo.label = 'Store Contact Info';

export default SetupStoreContactInfo;
