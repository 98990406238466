export const GET_ORDER = 'GET_ORDER';
export const GET_ORDERS = 'GET_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const PARK_SALE = 'PARK_SALE';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DISCARD_ORDER = 'DISCARD_ORDER';
export const ORDER_ERROR = 'ORDER_ERROR';
export const SET_CURRENT_ORDER = 'SET_CURRENT_ORDER';
export const GET_ORDERS_STATS = 'GET_ORDERS_STATS';
export const GET_PRODUCT_ORDER_STATS = 'GET_PRODUCT_ORDER_STATS';

export const UPDATE_NEW_ORDER = 'UPDATE_NEW_ORDER';
export const ADD_ORDER_ITEM = 'ADD_ORDER_ITEM';
export const UPDATE_ORDER_ITEM = 'UPDATE_ORDER_ITEM';
export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';

export const ORDER_STATUS = {
  PENDING_PAYMENT: 'Pending Payment', // Order Received, No Payment Initiated. Awaiting Payment(unpaid)
  ON_HOLD: 'On Hold', // Awaiting Payment - Stock is reduced, But you need to confirm payment
  PROCESSING: 'Processing', //Payment Received(paid) and stock has been reduced; order is awaiting fulfilment
  COMPLETED: 'Completed', //Order fulfilled and complete – requires no further action.
  FAILED: 'Failed', //Payment Failed or Declined(Unpaid)
  CANCELLED: 'Cancelled', //Cancelled by admin or the customer - stock is increased, No further action is required
  REFUNDED: 'Refunded',
};
