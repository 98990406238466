import React, { useEffect, Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../redux/pages/pagesActions';
import Grid from '@mui/material/Grid';
import EmployeeList from './users/EmployeeList';
import SignUp from '../components/auth/user/signup/SignUp';
import ForgotPassword from '../components/auth/user/ForgotPassword';
import ResetPassword from '../components/auth/user/ResetPassword';
import { Helmet } from 'react-helmet';
import CategoryList from './categories/CategoryList';
import AddEditCategory from './categories/AddEditCategory';
import CategoryDetail from './categories/detail/CategoryDetail';
import ProductList from './products/ProductList';
import AddEditProduct from './products/AddEditProduct';
import ProductDetail from './products/detail/ProductDetail';
import SignIn from '../components/auth/user/SignIn';
import { getCurrentUser } from '../redux/auth/authActions';
import SettingsHome from './settings/SettingsHome';
import AddUpdatePaymentType from './payment-types/AddUpdatePaymentType';
import AddUpdateTax from './taxes/AddUpdateTax';
import AddUpdateDevice from './devices/AddUpdateDevice';
import CustomerList from './customer/CustomerList';
import AddEditCustomer from './customer/AddEditCustomer';
import CustomerGroupList from './customer-group/CustomerGroupList';
import AddEditCustomerGroup from './customer-group/AddEditCustomerGroup';
import AddEditEmployee from './users/AddEditEmployee';
import ActivateUser from '../components/auth/user/ActivateUser';
import AddSubCategory from './categories/detail/subcategories/AddSubCategory';
import EditSubCategory from './categories/detail/subcategories/EditSubCategory';
import SalesReports from './reports/SalesReports';
import SellHome from './sell/SellHome';
import SuperAdminNavbar from '../components/layout/superadmin/SuperAdminNavbar';
import DemoDataHome from './demodata/DemoDataHome';
import BusinessTypesList from './business-types/BusinessTypesList';
import EditBusinessType from './business-types/EditBusinessType';
import AddBusinessType from './business-types/AddBusinessType';
import SetupStore from './setup/SetupStore';
import StoreList from './stores/StoreList';
import MessageTemplateList from './message-template/MessageTemplateList';
import EditMessageTemplate from './message-template/EditMessageTemplate';
import AddMessageTemplate from './message-template/AddMessageTemplate';
import MessageTemplateView from './message-template/view/MessageTemplateView';
import InventoryHome from './inventory/InventoryHome';
import Banner from '../components/layout/Banner';
import Home from './Home';
import Dashboard from './dashboard';
import { grey } from '@mui/material/colors';
import { useQuery } from 'utils/hooks';
import SuperAdminDashboard from './SuperAdminDashboard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    height: '100%',
    background: grey[100],
  },
  content: {
    marginLeft: 180,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    paddingTop: '4rem',
    height: '100%',
    background: theme.palette.grey[100],
  },
  notification: {
    position: 'fixed',
    top: 0,
    left: 0,
  },
}));

const Main = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const current = useSelector((state) => state.auth.current);
  const page = useSelector((state) => state.page.page);
  const store = useSelector((state) => state.auth.store);
  let query = useQuery();
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    // Listen for Route Changes
    if (location) {
      const { pathname } = location;
      dispatch(setCurrentPage(pathname));
      // Listen for Auth
      if (localStorage.getItem('token') == null) {
        // navigate('/signin');
      } else {
        if (!current) {
          dispatch(getCurrentUser());
        }
      }
    }

    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('reset-password')) {
      return;
    } else if (location.pathname.includes('signup')) {
      return;
    } else if (location.pathname.includes('activate')) {
      return;
    }
    // Setup Account
    else if (!current && query && query.get('setup')) {
      return;
    } else if (!current && localStorage.getItem('token') == null) {
      navigate('/signin');
    }
    // if (!current && localStorage.getItem('token') == null) {
    // }
    // eslint-disable-next-line
  }, [current]);
  // console.log(organization);

  return (
    <div>
      {page && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${store ? store.name : 'Sturr POS'} | ${page}`}</title>
          <link
            rel="icon"
            href={`${store ? store.image : `/favicon.ico`}`}
            sizes="any"
          />
          <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
        </Helmet>
      )}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/activate" element={<ActivateUser />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />

        <Route
          path="/admin/*"
          element={
            <Fragment>
              <Navbar />
              <Grid container>
                <Grid item xs={12}>
                  <div
                    className={classes.content}
                    style={{
                      marginLeft: location.pathname.includes('/admin/sell')
                        ? 0
                        : 180,
                    }}
                  >
                    <Routes>
                      <Route exact path="" element={<Dashboard />} />
                      <Route
                        exact
                        path="categories"
                        element={<CategoryList />}
                      />
                      <Route
                        exact
                        path="categories/add"
                        element={<AddEditCategory />}
                      />
                      <Route
                        exact
                        path="categories/edit/:id"
                        element={<AddEditCategory />}
                      />
                      <Route
                        exact
                        path="sub-categories/add"
                        element={<AddSubCategory />}
                      />
                      <Route
                        exact
                        path="sub-categories/add/:id"
                        element={<AddSubCategory />}
                      />
                      <Route
                        exact
                        path="sub-categories/edit/:id"
                        element={<EditSubCategory />}
                      />
                      <Route
                        exact
                        path="categories/detail/:id"
                        element={<CategoryDetail />}
                      />

                      <Route exact path="products" element={<ProductList />} />
                      <Route
                        exact
                        path="products/add"
                        element={<AddEditProduct />}
                      />
                      <Route
                        exact
                        path="products/edit/:id"
                        element={<AddEditProduct />}
                      />
                      <Route
                        exact
                        path="products/detail/:id"
                        element={<ProductDetail />}
                      />
                      <Route path="settings/*" element={<SettingsHome />} />
                      <Route
                        path="payment-types/add"
                        element={<AddUpdatePaymentType />}
                      />
                      <Route
                        path="payment-types/update/:id"
                        element={<AddUpdatePaymentType />}
                      />
                      <Route path="taxes/add" element={<AddUpdateTax />} />
                      <Route
                        path="taxes/update/:id"
                        element={<AddUpdateTax />}
                      />
                      <Route path="devices/add" element={<AddUpdateDevice />} />
                      <Route
                        path="devices/update/:id"
                        element={<AddUpdateDevice />}
                      />
                      <Route
                        path="employees/edit/:id"
                        element={<AddEditEmployee />}
                      />
                      <Route
                        path="employees/add"
                        element={<AddEditEmployee />}
                      />
                      <Route path="employees" element={<EmployeeList />} />

                      <Route
                        path="customers/add"
                        element={<AddEditCustomer />}
                      />
                      <Route
                        path="customers/edit/:id"
                        element={<AddEditCustomer />}
                      />
                      <Route path="customers" element={<CustomerList />} />

                      <Route
                        path="customer-groups/add"
                        element={<AddEditCustomerGroup />}
                      />
                      <Route
                        path="customer-groups/edit/:id"
                        element={<AddEditCustomerGroup />}
                      />
                      <Route
                        path="customer-groups"
                        element={<CustomerGroupList />}
                      />
                      <Route path="sell/*" element={<SellHome />} />
                      <Route path="inventory/*" element={<InventoryHome />} />

                      <Route
                        exact
                        path="reports/sales"
                        element={<SalesReports />}
                      />
                      <Route exact path="setup" element={<SetupStore />} />
                    </Routes>
                  </div>
                </Grid>
                {/* <Grid item xs={12}>
                  <Banner />
                </Grid> */}
              </Grid>
            </Fragment>
          }
        />

        <Route
          path="/superadmin/*"
          element={
            <Fragment>
              <SuperAdminNavbar />
              <Grid container>
                <Grid item xs={12}>
                  <div className={classes.content}>
                    <Routes>
                      <Route exact path="" element={<SuperAdminDashboard />} />

                      <Route
                        exact
                        path="demodata/*"
                        element={<DemoDataHome />}
                      />

                      <Route
                        exact
                        path="business-types"
                        element={<BusinessTypesList />}
                      />

                      <Route
                        exact
                        path="business-types/add"
                        element={<AddBusinessType />}
                      />

                      <Route
                        exact
                        path="business-types/edit/:id"
                        element={<EditBusinessType />}
                      />
                      <Route exact path="stores" element={<StoreList />} />
                      <Route
                        exact
                        path="message-templates"
                        element={<MessageTemplateList />}
                      />
                      <Route
                        exact
                        path="message-templates/edit/:id"
                        element={<EditMessageTemplate />}
                      />
                      <Route
                        exact
                        path="message-templates/add"
                        element={<AddMessageTemplate />}
                      />
                      <Route
                        exact
                        path="message-templates/view/:id"
                        element={<MessageTemplateView />}
                      />
                    </Routes>
                  </div>
                </Grid>
              </Grid>
            </Fragment>
          }
        />
      </Routes>
    </div>
  );
};

export default Main;
