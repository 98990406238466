import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import { makeStyles } from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { SET_CURRENT_DEVICE } from '../../redux/devices/deviceTypes';
import { getDevices } from '../../redux/devices/deviceActions';
import { getOpenCashRegister } from '../../redux/cash-register/cashRegisterActions';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3, 2),
    // display: 'flex',
    height: '100%',
    // maxHeight:'100vh'
  },
  formControl: {
    marginTop: '2rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingBottom: '0.5rem',
  },
}));

const options = [
  {
    name: 'Counter',
    link: '/admin/sell/counter',
  },
  {
    name: 'Open/Close',
    link: '/admin/sell/cash-register/close',
  },
  {
    name: 'Sales History',
    link: '/admin/sell/all',
  },
  {
    name: 'Cash Management',
    link: '/admin/sell/cash-management',
  },
];

const SellTab = ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onChange = (e) => {
    const device = devices.data.find(
      (current) => current.id === e.target.value
    );
    dispatch({ type: SET_CURRENT_DEVICE, payload: device });
  };

  const devices = useSelector((state) => state.device.devices);
  const currentDevice = useSelector((state) => state.device.current);

  useEffect(() => {
    dispatch(getDevices());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (devices) {
      dispatch(
        getOpenCashRegister(
          currentDevice ? currentDevice.id : devices.data[0].id
        )
      );
    }
    // eslint-disable-next-line
  }, [devices]);

  return (
    <Paper elevation={1} className={classes.root} sx={{ pt: 2 }}>
      {devices && (
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <InputLabel htmlFor="outlined-device-id-simple">Device</InputLabel>
          <Select
            autoWidth
            labelId="outlined-device-id-simple"
            value={currentDevice ? currentDevice.id : devices.data[0].id}
            onChange={onChange}
            name="deviceId"
            label="Device"
          >
            {devices.data.map((device) => (
              <MenuItem key={device.id} value={device.id}>
                <em>{device.name}</em>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <List component="nav">
        {options.map((option, index) => (
          <ListItem
            key={index}
            button
            component={Link}
            to={option.link}
            selected={location && location.pathname.includes(option.link)}
            // onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Paper>
  );
};

export default SellTab;
