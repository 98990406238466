import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import ListItem from '@mui/material/ListItem';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Search from '@mui/icons-material/Search';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import toMaterialStyle from 'material-color-hash';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import { getCustomers } from '../../redux/customers/customerActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flexGrow: 1,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: '100%',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: '1rem',
  },
}));

const CustomerSelectList = ({
  handleCustomerIds,
  customerIds,
  handleCustomerPickerClose,
  customerPickerOpen,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customer.customers);

  useEffect(() => {
    dispatch(getCustomers(filter));

    for (const customerId of Object.keys(customersSelected)) {
      customersSelected[customerId] = allSelected;
    }
    setCustomersSelected(customersSelected);

    // eslint-disable-next-line
  }, []);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 20000000000000,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const handleSaveCustomers = () => {
    const customerIds = [];
    const entriesCustomers = Object.entries(customersSelected);

    for (const [customerId, selected] of entriesCustomers) {
      if (selected) {
        customerIds.push(customerId);
      }
    }

    handleCustomerIds(customerIds);
    handleCustomerPickerClose();
  };

  const [customersSelected, setCustomersSelected] = useState({});

  useEffect(() => {
    if (customers) {
      const selected = [];
      for (const customer of customers.data) {
        // console.log(tenantsSelected)
        if (customersSelected[customer.id]) {
          selected[customer.id] = true;
        } else {
          selected[customer.id] = false;
        }

        if (customerIds && customerIds.includes(customer.id)) {
          selected[customer.id] = true;
        }
      }
      setCustomersSelected(selected);
    }

    // eslint-disable-next-line
  }, [customers]);

  const handleChange = (e) => {
    setCustomersSelected({
      ...customersSelected,
      [e.target.value]: e.target.checked,
    });
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
    setCount(
      Object.values(customersSelected).filter((item) => item === true).length
    );

    // eslint-disable-next-line
  }, [customersSelected]);

  const [allSelected, setAllSelected] = useState(null);

  useEffect(() => {
    if (allSelected != null) {
      const selected = {};
      for (const customerId of Object.keys(customersSelected)) {
        selected[customerId] = allSelected;
      }
      setCustomersSelected(selected);
    }

    // eslint-disable-next-line
  }, [allSelected]);

  // Filter
  useEffect(() => {
    dispatch(getCustomers(filter));
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Dialog
      onClose={() => {
        handleCustomerPickerClose();
      }}
      aria-labelledby="customized-dialog-title"
      open={customerPickerOpen}
      // open
      maxWidth="md"
      fullWidth
    >
      <Typography className={classes.dialogTitle} variant="h6">
        Select Customers {count > 0 ? `(` + count + `)` : ''}
      </Typography>
      <FormControl variant="outlined" style={{ margin: '1rem' }}>
        <InputLabel htmlFor="outlined-adornment-search">Search</InputLabel>
        <OutlinedInput
          id="outlined-adornment-search"
          type="text"
          name="search"
          value={filter.search}
          onChange={(e) => {
            setFilter({ ...filter, search: e.target.value });
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="search" edge="end">
                <Search />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </FormControl>
      <DialogContent dividers>
        <div className={classes.root}>
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            justify="space-between"
          >
            {/* <Grid item xs={12} sm={10}>
          <TextField
            id="standard search"
            label="Search"
            name="search"
            fullWidth
            disabled
            variant="outlined"
            onClick={handleSearch}
          />
        </Grid> */}

            <Grid item xs={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={allSelected != null ? allSelected : false}
                  onChange={(e) => {
                    setAllSelected(e.target.checked);
                  }}
                  value={allSelected != null ? allSelected : false}
                />

                <Typography variant="body1">All</Typography>
              </div>

              <List component="nav">
                {customers && customers.data.length !== 0 ? (
                  customers.data.map((customer, idx) => {
                    return (
                      <div key={customer.id} style={{ display: 'flex' }}>
                        <Checkbox
                          checked={customersSelected[customer.id] !== false}
                          onChange={handleChange}
                          value={customer.id}
                        />
                        <ListItem>
                          <ListItemIcon>
                            <Avatar
                              variant="rounded"
                              style={{
                                backgroundColor: toMaterialStyle(
                                  `${customer.name}`,
                                  '250'
                                ).backgroundColor,
                              }}
                            >
                              {customer.name.toString().charAt(0)}
                            </Avatar>
                          </ListItemIcon>
                          <ListItemText primary={`${customer.name}`} />
                        </ListItem>
                      </div>
                    );
                  })
                ) : (
                  <Alert severity="info">No Customers Available</Alert>
                )}
              </List>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            handleSaveCustomers();
          }}
          color="primary"
        >
          Save changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomerSelectList;
