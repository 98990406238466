import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import jwt from 'jsonwebtoken';
import { useSelector } from 'react-redux';

const SuperAdminDashboard = () => {
  const [url, setUrl] = useState(null);
  const darkMode = localStorage.getItem('darkMode');
  const current = useSelector((state) => state.auth.current);

  useEffect(() => {
    if (current) {
      const dkMode = darkMode === 'true' ? '#theme=night&' : '';

      // TODO: Make Logic for Dashboard Change
      let dashboard = 1;

      const payload = {
        resource: { dashboard },
        params: {},
        exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
      };
      const token = jwt.sign(
        payload,
        process.env.REACT_APP_METABASE_SECRET_KEY
      );
      // console.log(process.env.REACT_APP_METABASE_SECRET_KEY);
      // console.log(process.env.REACT_APP_METABASE_URL);
      //
      const iframeUrl =
        process.env.REACT_APP_METABASE_URL +
        '/embed/dashboard/' +
        token +
        `${dkMode}#bordered=true&titled=true`;

      setUrl(iframeUrl);
    }
    // eslint-disable-next-line
  }, [current]);

  // const url = process.e

  return (
    <Paper>
      <Grid container>
        <Grid item xs={12}>
          <iframe
            title="Dashboard"
            src={url}
            frameBorder="0"
            // width="900"
            // height="600"
            allowtransparency="true"
            style={{
              display: 'block' /* iframes are inline by default */,
              background: '#fff',
              border: 'none' /* Reset default border */,
              height: '100vh' /* Viewport-relative units */,
              width: '100%',
            }}
          ></iframe>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SuperAdminDashboard;
