import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { getProducts } from '../../../redux/products/productActions';
import {
  ADD_INVENTORY_COUNT_ITEM,
  DELETE_INVENTORY_COUNT_ITEM,
  UPDATE_INVENTORY_COUNT_ITEM,
} from '../../../redux/inventory-count/inventoryCountTypes';
import ProductItemSearch from '../../../components/product/ProductItemSearch';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const InventoryCountItems = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const loading = useSelector((state) => state.notification.loading);

  const products = useSelector((state) => state.product.products);
  const store = useSelector((state) => state.auth.store);
  // const loading = useSelector((state) => state.notification.loading);
  const newCount = useSelector((state) => state.inventoryCount.new);
  const current = useSelector((state) => state.inventoryCount.current);

  useEffect(() => {
    dispatch(getProducts(filter));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (current) {
      // Set Default Items
      for (const product of current.products) {
        dispatch({
          type: ADD_INVENTORY_COUNT_ITEM,
          payload: {
            ...product,
            productId: product.productId,
            productName: product.productName,
            expected: product.expected,
            counted: null,
            difference: null,
            costDifference: null,
          },
        });
      }
    }
  }, [current]);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    showAll: true,
  });

  const handleSelectProduct = (product) => {
    dispatch({
      type: ADD_INVENTORY_COUNT_ITEM,
      payload: {
        productId: product.id,
        productName: product.name,
        expected: product.quantity,
        counted: null,
        difference: null,
        costDifference: null,
      },
    });
  };
  const handleRemoveProduct = (productId) => {
    dispatch({
      type: DELETE_INVENTORY_COUNT_ITEM,
      payload: productId,
    });
  };

  // useEffect(() => {
  //   if (products && (filter.showLowStockProducts || filter.supplierId)) {

  //   }
  //   // eslint-disable-next-line
  // }, [products]);

  const handleDeleteItem = (id) => {
    dispatch({
      type: DELETE_INVENTORY_COUNT_ITEM,
      payload: id,
    });
  };

  if (!products) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Typography variant="h5" color="textSecondary">
            Items
          </Typography>
          <Stack direction="row" spacing={2}>
            <Button>ADD BY CATEGORY</Button>
            <Button>ADD BY SUPPLIER</Button>
          </Stack>
        </Stack>

        <TableContainer component={Box}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell align="right">Expected Stock</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newCount &&
                newCount.items.map((item, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.productName}
                    </TableCell>
                    <TableCell align="right">
                      <strong>{item.expected}</strong>

                      <IconButton>
                        <DeleteIcon
                          onClick={() => handleRemoveProduct(item.productId)}
                        />
                      </IconButton>
                    </TableCell>
                    {/* <TableCell align="right"></TableCell> */}
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={7} component="th" scope="row">
                  <ProductItemSearch
                    handleSelectProduct={handleSelectProduct}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  }
};

export default InventoryCountItems;
