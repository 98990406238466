import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import {
  getBusinessTypeById,
  updateBusinessType,
} from '../../redux/business-type/businessTypeActions';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const EditBusinessType = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const current = useSelector((state) => state.businessType.current);
  const page = useSelector((state) => state.page.page);
  const navigate = useNavigate();

  const [form, setForm] = useState({
    name: '',
    description: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Edit Business Type') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate('/superadmin/business-types');
    }
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    dispatch(getBusinessTypeById(id));
  }, []);

  useEffect(() => {
    if (current) {
      form.name = current.name;
      form.description = current.description;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateBusinessType(form, current.id));
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="name"
              label="Name"
              value={form.name}
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              multiline
              onChange={onChange}
              id="description"
              label="Description"
              name="description"
              value={form.description}
            />
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size={'large'}
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Update
          </Button>
          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default EditBusinessType;
