import { Link as RouterLink, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import { login, getCurrentUser } from 'redux/auth/authActions';
import setAuthToken from 'utils/setAuthToken';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import { ROLE_SUPER_ADMIN } from 'redux/role/roleTypes';
import { useQuery } from 'utils/hooks';
import { useMediaQuery } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="https://sturr.co/">
        Sturr
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const SignIn = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const current = useSelector((state) => state.auth.current);
  const store = useSelector((state) => state.auth.store);
  const loading = useSelector((state) => state.notification.loading);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const query = useQuery();
  const navigate = useNavigate();

  const theme = useTheme();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login({ email, password }));
  };

  useEffect(() => {
    if (current) {
      if (current.roleName === ROLE_SUPER_ADMIN) {
        navigate('/superadmin');
        return;
      } else {
        // Check if Setup is True
        if (query && query.get('setup')) {
          navigate(`/admin/setup`);
        } else {
          navigate(`/admin`);
        }
        return;
      }
    } else if (localStorage.getItem('token') != null) {
      dispatch(getCurrentUser());
    }
    // eslint-disable-next-line
  }, [current]);
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      {matches && (
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className={classes.image}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <img width={300} src="/logo_alt_primary_mark_transparent.svg" />

            {store && (
              <Paper sx={{ p: '3rem' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      <strong>{store.name}</strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
        <div className={classes.paper}>
          {!matches && (
            <img src="/logo_primary_mark_transparent.png" width={200} />
          )}
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              // autoComplete="email"
              autoFocus
            />
            <FormControl
              variant="outlined"
              fullWidth
              style={{ marginTop: '2rem' }}
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              className={classes.submit}
              disabled={loading}
              sx={{ mt: 3 }}
            >
              Sign In
            </Button>
            <Grid item xs={12} sx={{ mt: 3 }}>
              {loading && <LinearProgress variant="query" color="secondary" />}
            </Grid>

            <div
              sx={{ mt: 3 }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button
                component={RouterLink}
                variant="outlined"
                to="/forgot-password"
              >
                Forgot password?
              </Button>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '2rem',
              }}
              // spacing={10}
            >
              <Button
                component={RouterLink}
                to="/"
                style={{ textDecoration: 'none' }}
                startIcon={<ArrowBackIcon />}
                variant="outlined"
              >
                Back
              </Button>
              {!store && (
                <Button component={RouterLink} to="/signup" variant="outlined">
                  Sign Up
                </Button>
              )}
            </div>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
};

export default SignIn;
