import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './store';

const rootEl = document.getElementById('root');

const render = (Component) => {
  // eslint-disable-next-line react/no-render-return-value
  return ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <Component />
      </React.StrictMode>
    </Provider>,
    rootEl
  );
};
render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
