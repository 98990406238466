import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MailIcon from '@mui/icons-material/Mail';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import Button from '@mui/material/Button';
import 'rc-color-picker/assets/index.css';
import { getCurrentStore } from 'redux/store/storeActions';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import {
  getSettingStoreId,
  updateSetting,
} from 'redux/settings/settingsActions';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AllInboxIcon from '@mui/icons-material/AllInbox';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const fields = [
  {
    fieldName: 'skuGeneration',
    name: 'Auto Generate SKU Codes',
    icon: <CropFreeIcon />,
    description: 'Auto Generate SKU Codes',
  },
  {
    fieldName: 'lowStockNotifications',
    name: 'Low stock notifications',
    icon: <MailIcon />,
    description: 'Get daily email on items that are low or out of stock.',
  },
  {
    fieldName: 'shifts',
    name: 'Shifts',
    icon: <AccessTimeIcon />,
    description: 'Track cash that goes in and out of your drawer',
  },
  {
    fieldName: 'customerDisplays',
    name: 'Customer Displays',
    icon: <TabletAndroidIcon />,
    description:
      'Display order information to customers at the time of purchase',
  },
  {
    fieldName: 'deliveryOptions',
    name: 'Delivery Options',
    icon: <AllInboxIcon />,
    description: 'Mark orders with multiple delivery options',
  },
];

const SettingsOptions = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);
  const setting = useSelector((state) => state.setting.current);
  const store = useSelector((state) => state.auth.store);

  const [form, setForm] = useState({
    pricesTaxExclusive: false,
    skuGeneration: false,
    lowStockNotifications: false,
    shifts: false,
    employeeTimeClock: false,
    deliveryOptions: false,
    customerDisplays: false,
  });

  useEffect(() => {
    if (!store) {
      dispatch(getCurrentStore());
    }
    // eslint-disable-next-line
  }, [store]);

  useEffect(() => {
    if (setting) {
      setForm({ ...setting });
    }
    // eslint-disable-next-line
  }, [setting]);

  useEffect(() => {
    if (!setting && store) {
      dispatch(getSettingStoreId(store.id));
    }
    // eslint-disable-next-line
  }, [setting, store]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSetting(form, store.id));
  };

  if (!setting && store) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography color="textSecondary" gutterBottom>
                Options
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {fields.map((field, index) => (
                  <ListItem key={index}>
                    <ListItemAvatar>
                      <Avatar>{field.icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={field.name}
                      secondary={field.description}
                    />
                    <ListItemSecondaryAction>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={form[field.fieldName]}
                            onChange={onChange}
                            name={field.fieldName}
                          />
                        }
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
                disabled={loading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default SettingsOptions;
