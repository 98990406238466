import { SET_CURRENT_PAGE, CLEAR_CURRENT_PAGE } from './pagesTypes';

// Set Current Page

export const setCurrentPage = (path) => async (dispatch) => {
  const page = convertPathToPage(path.toString().toLowerCase());
  // console.log(page);

  dispatch({ type: SET_CURRENT_PAGE, payload: page });
};
// Clear Current Page

export const clearCurrentPage = async (dispatch) => {
  dispatch({ type: CLEAR_CURRENT_PAGE });
};

const convertPathToPage = (path) => {
  if (path.includes('admin/inventory/valuation')) {
    return 'Inventory Valuation';
  }
  if (path.includes('admin/inventory/adjustments')) {
    return 'Stock Adjustments';
  }
  if (path.includes('admin/inventory/adjustments/add')) {
    return 'Add Stock Adjustment';
  }
  if (path.includes('admin/inventory/adjustments/update')) {
    return 'Update Stock Adjustment';
  }
  if (path.includes('admin/inventory/adjustments/detail')) {
    return 'Stock Adjustment Detail';
  }
  if (path.includes('admin/inventory/inventory-count/count')) {
    return 'Count Inventory';
  }
  if (path.includes('admin/inventory/inventory-count/detail')) {
    return 'Inventory Count Detail';
  }
  if (path.includes('admin/inventory/inventory-count/edit')) {
    return 'Edit Inventory Count';
  }
  if (path.includes('admin/inventory/inventory-count/create')) {
    return 'Create Inventory Count';
  }
  if (path.includes('admin/inventory/inventory-count')) {
    return 'Inventory Counts';
  }
  if (path.includes('admin/inventory/purchase-orders/receive')) {
    return 'Receive Items - Purchase Order';
  }
  if (path.includes('admin/inventory/purchase-orders/edit')) {
    return 'Edit Purchase Order';
  }
  if (path.includes('admin/inventory/purchase-orders/detail')) {
    return 'Purchase Order Detail';
  }
  if (path.includes('admin/inventory/purchase-orders/add')) {
    return 'Add Purchase Order';
  }
  if (path.includes('admin/inventory/purchase-orders')) {
    return 'Purchase Orders';
  }
  if (path.includes('admin/inventory/suppliers/update')) {
    return 'Update Supplier';
  }
  if (path.includes('admin/inventory/suppliers/add')) {
    return 'Add Supplier';
  }
  if (path.includes('admin/inventory/suppliers')) {
    return 'Suppliers';
  }
  if (path.includes('admin/inventory')) {
    return 'Inventory';
  }

  if (path.includes('sell/all')) {
    return 'Sales';
  }
  if (path.includes('cash-register/open')) {
    return 'Open Register';
  }
  if (path.includes('cash-register/close')) {
    return 'Close Register';
  }

  if (path.includes('cash-management')) {
    return 'Cash Management';
  }

  if (path.includes('sub-categories/add')) {
    return 'Add Sub Category';
  }

  if (path.includes('sub-categories/edit')) {
    return 'Edit Sub Category';
  }
  if (path.includes('sub-categories')) {
    return 'Sub Categories';
  }

  if (path.includes('categories/add')) {
    return 'Add Category';
  }

  if (path.includes('categories/edit')) {
    return 'Update Category';
  }
  if (path.includes('categories')) {
    return 'Categories';
  }
  if (path.includes('products/add')) {
    return 'Add Product';
  }

  if (path.includes('products/edit')) {
    return 'Edit Product';
  }
  if (path.includes('products')) {
    return 'Products';
  }

  if (path.includes('users/add')) {
    return 'Add User';
  }

  if (path.includes('users/edit')) {
    return 'Update User';
  }
  if (path.includes('unit-types/edit')) {
    return 'Update Unit Type';
  }
  if (path.includes('unit-types/add')) {
    return 'Add Unit Type';
  }
  if (path.includes('units/add')) {
    return 'Add Unit';
  }
  if (path.includes('units/edit')) {
    return 'Update Unit';
  }

  if (path.includes('tenants/add')) {
    return 'Add Tenant';
  }
  if (path.includes('tenants/edit')) {
    return 'Update Tenant';
  }

  if (path.includes('maintenance-types/add')) {
    return 'Add Maintenance Type';
  }
  if (path.includes('maintenance-types/edit')) {
    return 'Update Maintenance Type';
  }
  if (path.includes('maintenance-types')) {
    return 'Maintenance Types';
  }
  if (path.includes('utilities')) {
    return 'Utilities';
  }
  if (path.endsWith('tenants/admin')) {
    return 'Home';
  }

  if (path.includes('maintenance/add')) {
    return 'Add Maintenance';
  }
  if (path.includes('maintenance/edit')) {
    return 'Update Maintenance';
  }
  if (path.includes('maintenance')) {
    return 'Maintenance';
  }

  if (path.includes('leases/create')) {
    return 'Create Lease';
  }
  if (path.includes('leases/edit')) {
    return 'Update Lease';
  }
  if (path.includes('leases')) {
    return 'Leases';
  }

  if (path.includes('bill-type/edit')) {
    return 'Update Bill Type';
  }
  if (path.includes('bill-type/add')) {
    return 'Add Bill Type';
  }
  if (path.includes('bill-type')) {
    return 'Bill Types';
  }

  if (path.includes('sell/counter')) {
    return 'Counter';
  }

  if (path.includes('sell')) {
    return 'Sell';
  }

  if (path.includes('sales/summary')) {
    return 'Sales Summary';
  }

  if (path.includes('store/edit')) {
    return 'Update Store';
  }
  if (path.includes('store/add')) {
    return 'Add Store';
  }
  if (path.includes('store')) {
    return 'Stores';
  }

  if (path.includes('admin/settings/billing')) {
    return 'Billing';
  }

  if (path.includes('admin/settings/billing/payments')) {
    return 'Billing Payments';
  }

  if (path.includes('admin/settings')) {
    return 'Settings';
  }

  if (path.includes('organization/add')) {
    return 'Add Organization';
  }
  if (path.includes('organization/edit')) {
    return 'Edit Organization';
  }
  if (path.includes('admin/organization')) {
    return 'Organizations';
  }

  if (path.includes('profile')) {
    return 'Profile';
  }

  if (path.includes('payments/add')) {
    return 'Add Payment';
  }
  if (path.includes('payments/edit')) {
    return 'Update Payments';
  }
  if (path.includes('payments')) {
    return 'Payments';
  }

  if (path.includes('payment-group/add')) {
    return 'Add Payment Group';
  }
  if (path.includes('payment-group/edit')) {
    return 'Edit Payment Group';
  }
  if (path.includes('payment-group')) {
    return 'Payment Groups';
  }
  if (path.includes('tenants')) {
    return 'Tenants';
  }

  if (path.includes('units')) {
    return 'Units';
  }
  if (path.includes('users')) {
    return 'Users';
  }
  if (path.includes('unit-types')) {
    return 'Unit Types';
  }

  if (path.includes('business-types/add')) {
    return 'Add Business Type';
  }
  if (path.includes('business-types/edit')) {
    return 'Edit Business Type';
  }

  if (path.includes('business-types')) {
    return 'Business Types';
  }

  if (path.includes('message-group/add')) {
    return 'Add Message Group';
  }
  if (path.includes('message-group/edit')) {
    return 'Edit Message Group';
  }
  if (path.includes('message-group')) {
    return 'Message Groups';
  }

  if (path.includes('message-templates/add')) {
    return 'Add Message Template';
  }

  if (path.includes('message-templates/edit')) {
    return 'Edit Message Template';
  }
  if (path.includes('message-templates')) {
    return 'Message Templates';
  }

  if (path.includes('messaging/add')) {
    return 'Send Message';
  }

  if (path.includes('messaging')) {
    return 'Messaging';
  }
  if (path.includes('payment-types/add')) {
    return 'Add Payment Type';
  }
  if (path.includes('payment-types/update')) {
    return 'Update Payment Type';
  }
  if (path.includes('taxes/add')) {
    return 'Add Tax';
  }
  if (path.includes('taxes/update')) {
    return 'Update Tax';
  }
  if (path.includes('devices/add')) {
    return 'Add Device';
  }
  if (path.includes('customers/add')) {
    return 'Add Customer';
  }
  if (path.includes('customers/edit')) {
    return 'Update Customer';
  }
  if (path.includes('customers')) {
    return 'Customers';
  }
  if (path.includes('customer-groups/add')) {
    return 'Add Customer Group';
  }
  if (path.includes('customer-groups/edit')) {
    return 'Update Customer Group';
  }
  if (path.includes('customer-groups')) {
    return 'Customer Groups';
  }

  if (path.includes('employees/add')) {
    return 'Add Employee';
  }
  if (path.includes('employees/edit')) {
    return 'Update Employee';
  }

  if (path.includes('employees')) {
    return 'Employees';
  }
  if (path.includes('devices/update')) {
    return 'Update Device';
  }
  if (path.includes('/admin')) {
    return 'Home';
  }
};
