import React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { deleteCustomerBatch } from '../../redux/customers/customerActions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem',
  },
  iconButton: {
    marginRight: '24px',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  deleteIcon: {
    color: '#000',
  },
  toolbar: {
    marginTop: theme.spacing(2),
  },
}));

const CustomerToolbarSelect = ({ selectedRows, displayData }) => {
  const classes = useStyles();

  const ids = selectedRows.data.map((object) => object.index);
  const data = displayData
    .map((data) => (ids.includes(data.dataIndex) ? data.data[0] : null))
    .filter(Boolean);

  const dispatch = useDispatch();

  const handleDeleteCusotmers = () => {
    dispatch(deleteCustomerBatch(data));
  };

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        endIcon={<DeleteIcon />}
        onClick={handleDeleteCusotmers}
      >
        Delete
      </Button>
    </div>
  );
};

export default CustomerToolbarSelect;
