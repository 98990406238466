import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

import {
  addCustomer,
  updateCustomer,
  getCustomerById,
} from 'redux/customers/customerActions';
import { ADD_CUSTOMER } from 'redux/customers/customerTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddEditCustomer = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);
  const current = useSelector((state) => state.customer.current);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerById(id));
    }
    // eslint-disable-next-line
  }, []);

  const [form, setForm] = useState({
    name: null,
    email: '',
    mobileNumber: '',
    address: '',
    notes: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      notification &&
      notification.type === 'success' &&
      notification.action === ADD_CUSTOMER
    ) {
      navigate('/admin/customers');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (current) {
      dispatch(updateCustomer(form, current.id));
    } else {
      dispatch(addCustomer(form));
    }
  };

  useEffect(() => {
    if (current) {
      setForm({ ...current });
    }
    // eslint-disable-next-line
  }, [current]);
  if (id && !current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Avatar className={classes.avatar}>
                <PersonIcon size={32} />
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                autoComplete="name"
                name="name"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                label="Name"
                value={form.name}
                autoFocus
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                type="email"
                label="Email Address"
                name="email"
                onChange={onChange}
                autoComplete="email"
                value={form.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                type="number"
                id="mobileNumber"
                value={form.mobileNumber}
                label="Mobile Number"
                name="mobileNumber"
                onChange={onChange}
                autoComplete="phoneNumber"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                value={form.address}
                onChange={onChange}
                autoComplete="address"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="notes"
                label="Notes"
                value={form.notes}
                multiline
                rows={2}
                name="notes"
                onChange={onChange}
                autoComplete="notes"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!form.name || loading}
              >
                Save
              </Button>
            </Grid>

            <Grid item xs={12}>
              {loading && <LinearProgress variant="query" color="secondary" />}
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default AddEditCustomer;
