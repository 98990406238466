import {
  ADD_CASH_MOVEMENT,
  CASH_MOVEMENT_ERROR,
  GET_CASH_MOVEMENT,
  GET_CASH_MOVEMENTS,
  UPDATE_CASH_MOVEMENT,
} from './cashMovementTypes';

const initialState = {
  cashMovements: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CASH_MOVEMENTS:
      return {
        ...state,
        cashMovements: action.payload,
      };
    case ADD_CASH_MOVEMENT:
      return {
        ...state,
        cashMovements: {
          ...state.cashMovements,
          data:
            state.cashMovements !== null
              ? [action.payload, ...state.cashMovements.data]
              : [action.payload.data],
        },
      };
    case UPDATE_CASH_MOVEMENT:
      return {
        ...state,
        current: action.payload,
      };
    case GET_CASH_MOVEMENT:
      return {
        ...state,
        current: action.payload,
      };
    case CASH_MOVEMENT_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
