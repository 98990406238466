import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { grey } from '@mui/material/colors';
import { getTaxes } from '../../../redux/tax/taxActions';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(4),
  },
}));

const SectionTax = ({ updateForm }) => {
  const classes = useStyles();

  const taxes = useSelector((state) => state.tax.taxes);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTaxes());
    // eslint-disable-next-line
  }, []);
  const [form, setForm] = useState({
    taxId: '',
  });

  const current = useSelector((state) => state.product.current);

  useEffect(() => {
    if (current) {
      form.taxId = current.taxId;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    updateForm({ ...form });
    // eslint-disable-next-line
  }, [form]);
  if (!taxes) {
    return null;
  } else {
    return (
      <Paper>
        <Stack direction="column">
          <Stack
            sx={{ p: 2, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6">
                <strong>Tax</strong>
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Set the Tax Option for this product
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ p: 2 }} direction="column">
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-taxId-simple">
                Select Tax
              </InputLabel>
              <Select
                fullWidth
                variant="outlined"
                placeholder="Select Tax"
                label="Select Tax"
                value={form.taxId}
                onChange={onChange}
                name="taxId"
                inputProps={{
                  name: 'taxId',
                  id: 'outlined-taxId-simple',
                }}
              >
                {taxes.data.map((tax) => (
                  <MenuItem key={tax.id} value={tax.id}>
                    <em>
                      {tax.name} - {tax.rate}%
                    </em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Paper>
    );
  }
};

export default SectionTax;
