export const GET_TAX = 'GET_TAX';
export const GET_TAXES = 'GET_TAXES';
export const ADD_TAX = 'ADD_TAX';
export const UPDATE_TAX = 'UPDATE_TAX';
export const DELETE_TAX = 'DELETE_TAX';
export const TAX_ERROR = 'TAX_ERROR';

export const TAX_OPTIONS = {
  INCLUSIVE: 'Included in the Price',
  EXCLUSIVE: 'Added to the Price',
};
