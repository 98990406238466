import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import StoreIcon from '@mui/icons-material/Store';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import TuneIcon from '@mui/icons-material/Tune';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { RiBillLine } from 'react-icons/ri';
import { ROLE_SUPER_ADMIN } from '../../redux/role/roleTypes';

const options = [
  {
    name: 'General',
    icon: <StoreIcon />,
    link: '/admin/settings/general',
  },
  {
    name: 'Billing',
    icon: <RiBillLine size={24} />,
    link: '/admin/settings/billing',
  },
  {
    name: 'Options',
    icon: <TuneIcon />,
    link: '/admin/settings/options',
  },
  {
    name: 'Payment Types',
    icon: <LocalAtmIcon />,
    link: '/admin/settings/payment-types',
  },
  {
    name: 'Taxes',
    icon: <MoneyOffIcon />,
    link: '/admin/settings/taxes',
  },
  {
    name: 'Devices',
    icon: <TabletAndroidIcon />,
    link: '/admin/settings/devices',
  },
  {
    name: 'Receipt',
    icon: <ReceiptIcon />,
    link: '/admin/settings/receipt',
  },
];
const accountOptions = [
  {
    name: 'Settings',
    icon: <AccountCircleIcon />,
    link: '/admin/settings/account/settings',
  },
];

const superAdminOptions = [
  {
    name: 'Demo Data',
    icon: <AttachFileIcon />,
    link: '/admin/settings/demodata/settings',
  },
];

const SettingsTab = ({ location }) => {
  // const classes = useStyles();
  const current = useSelector((state) => state.auth.current);

  return (
    <Grid container justifyContent="center" direction="column" spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Store
              </ListSubheader>
            }
          >
            {options.map((option, index) => (
              <ListItem
                button
                key={index}
                component={Link}
                to={option.link}
                selected={location && location.pathname.includes(option.link)}
                // onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            ))}
            {}
          </List>
          <Divider />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <List
            component="nav"
            subheader={<ListSubheader component="div">Account</ListSubheader>}
          >
            {accountOptions.map((option, index) => (
              <ListItem
                button
                key={index}
                component={Link}
                to={option.link}
                selected={location && location.pathname.includes(option.link)}
                // onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </Paper>
      </Grid>

      {current && current.roleName === ROLE_SUPER_ADMIN && (
        <Grid item xs={12}>
          <Paper>
            <List
              component="nav"
              subheader={
                <ListSubheader component="div">Superadmin</ListSubheader>
              }
            >
              {superAdminOptions.map((option, index) => (
                <ListItem
                  key={index}
                  button
                  component={Link}
                  to={option.link}
                  selected={location && location.pathname.includes(option.link)}
                  // onClick={(event) => handleListItemClick(event, index)}
                >
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <ListItemText primary={option.name} />
                </ListItem>
              ))}
            </List>
            <Divider />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default SettingsTab;
