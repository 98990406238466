import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { getProducts } from '../../../redux/products/productActions';

const ProductSearch = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);
  const error = useSelector((state) => state.product.error);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 200000,
    search: null,
    sort: null,
    sortOrder: null,
  });

  useEffect(() => {
    dispatch(getProducts(filter));
    // eslint-disable-next-line
  }, [filter]);

  return (
    <Paper style={{ padding: '1rem' }}>
      <TextField
        label="Search for a Product"
        variant="outlined"
        fullWidth
        value={filter.search || ''}
        onChange={(e) => {
          console.log('Filter');
          setFilter({ ...filter, search: e.target.value });
        }}
        InputProps={{
          endAdornment: (
            <Fragment>
              {loading && !error ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
            </Fragment>
          ),
        }}
      />
    </Paper>
  );
};

export default ProductSearch;
