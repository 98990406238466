import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import {
  DateRangePicker,
  DateRange,
} from '@matharumanpreet00/react-daterange-picker';
import {
  subWeeks,
  addWeeks,
  addDays,
  format,
  differenceInDays,
  subDays,
} from 'date-fns';

const DatePickerView = ({ handleDateChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  const [data, setData] = React.useState({
    startDate: subWeeks(new Date(), 1),
    endDate: new Date(),
    text: `${format(subWeeks(new Date(), 1), 'MMM d, yyyy')} - ${format(
      new Date(),
      'MMM d, yyyy'
    )}`,
  });

  const onDateChange = ({ startDate, endDate }) => {
    // console.log('Start Date ' + startDate);
    // console.log('End Date ' + endDate);
    handleClose();
    setData({
      startDate,
      endDate,
      text: `${format(startDate, 'MMM d, yyyy')} - ${format(
        endDate,
        'MMM d, yyyy'
      )}`,
    });
  };

  useEffect(() => {
    if (data) {
      handleDateChange(data);
    }
  }, [data]);
  return (
    <Paper sx={{ width: 1 }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        alignItems="center"
        sx={{ py: 1, px: 0.5 }}
        justifyContent="space-between"
      >
        <IconButton
          onClick={() => {
            onDateChange({
              startDate: subDays(
                data.startDate,
                differenceInDays(data.endDate, data.startDate)
              ),
              endDate: subDays(
                data.endDate,
                differenceInDays(data.endDate, data.startDate)
              ),
            });
          }}
        >
          <ArrowLeftIcon />
        </IconButton>
        <div>
          <Button sx={{ px: 3 }} fullWidth variant="text" onClick={handleClick}>
            {data.text}
          </Button>
          <Popover
            open={open}
            id={id}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            onClose={handleClose}
          >
            <DateRangePicker
              open
              initialDateRange={data}
              onChange={onDateChange}
            />
          </Popover>
        </div>

        <IconButton
          onClick={() => {
            onDateChange({
              startDate: addDays(
                data.startDate,
                differenceInDays(data.endDate, data.startDate)
              ),
              endDate: addDays(
                data.endDate,
                differenceInDays(data.endDate, data.startDate)
              ),
            });
          }}
        >
          <ArrowRightIcon />
        </IconButton>
      </Stack>
    </Paper>
  );
};

export default DatePickerView;
