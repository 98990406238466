import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { parseISO, format } from 'date-fns/esm';
import { numberFormatText } from '../../../utils/functions';
import {
  getPurchaseOrderById,
  updatePurchaseOrderStatus,
} from '../../../redux/purchase-orders/purchaseOrderActions';
import { PURCHASE_ORDER_STATUS } from '../../../redux/purchase-orders/purchaseOrderTypes';
import { getStatusColor, getStatusColorText } from '../../../utils/StatusColor';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    borderRadius: theme.spacing(7),
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const PurchaseOrderDetail = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const current = useSelector((state) => state.purchaseOrder.current);
  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);

  useEffect(() => {
    if (id) {
      dispatch(getPurchaseOrderById(id));
    }
    // eslint-disable-next-line
  }, []);

  if (id && !current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Paper>
          <Stack direction="column">
            {loading && <LinearProgress variant="query" color="secondary" />}
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={2}
              sx={{ p: 1 }}
            >
              {current.status === PURCHASE_ORDER_STATUS.DRAFT && (
                <Button
                  onClick={() =>
                    dispatch(
                      updatePurchaseOrderStatus(
                        current.id,
                        PURCHASE_ORDER_STATUS.PENDING
                      )
                    )
                  }
                >
                  Approve
                </Button>
              )}
              {current.status === PURCHASE_ORDER_STATUS.PENDING && (
                <Button
                  component={Link}
                  to={`/admin/inventory/purchase-orders/receive/${current.id}`}
                >
                  Receive
                </Button>
              )}
              {(current.status !== PURCHASE_ORDER_STATUS.CLOSED ||
                current.status !==
                  PURCHASE_ORDER_STATUS.PARTIALLY_RECEIVED) && (
                <Button
                  component={Link}
                  to={`/admin/inventory/purchase-orders/edit/${current.id}`}
                >
                  Edit
                </Button>
              )}
              <Button>Send</Button>
              <Button endIcon={<ArrowDropDownIcon />}>More</Button>
            </Stack>

            <Divider />
            <Stack direction="column">
              <Stack
                sx={{ p: 2 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {/* ID & Status */}
                <Stack direction="column">
                  <Typography variant="h4">{current.id}</Typography>
                  <Typography
                    sx={{
                      bgcolor: 'text.primary',
                      color: 'background.paper',
                      p: 1,
                      width: 150,
                      borderRadius: 1,
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    variant="body1"
                    color="secondary"
                  >
                    {current.status}
                  </Typography>
                </Stack>

                {/* Received */}
                <Stack direction="column">
                  <LinearProgress
                    variant="determinate"
                    value={(current.received / current.totalQuantity) * 100}
                  />
                  <Typography variant="body2" color="text.secondary">
                    Received {`${current.received}`} of{' '}
                    {`${current.totalQuantity}`}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          {/* Order Details */}
          <Stack direction="column" sx={{ p: 2 }} spacing={2}>
            <Typography variant="body1">
              Order Date:{' '}
              <strong>
                {format(
                  parseISO(current.orderDate, 'yyyy-MM-dd', new Date()),
                  'do MMM yyyy p'
                )}
              </strong>
            </Typography>
            <Typography variant="body1">
              Expected On:{' '}
              <strong>
                {format(
                  parseISO(current.expectedDate, 'yyyy-MM-dd', new Date()),
                  'do MMM yyyy p'
                )}
              </strong>
            </Typography>
            <Typography variant="body1">
              Ordered By: <strong>{current.userName}</strong>
            </Typography>
          </Stack>
          {/* Supplier Details */}
          <Stack direction="column" sx={{ p: 2 }} spacing={2}>
            <Typography variant="body1">
              <strong>Supplier</strong>
            </Typography>
            <Typography variant="body1">{current.supplierName}</Typography>
            {current.supplierEmail && (
              <Typography variant="body1">{current.supplierEmail}</Typography>
            )}
            {current.supplierPhone && (
              <Typography variant="body1">{current.supplierPhone}</Typography>
            )}
            {current.supplierAddress && (
              <Typography variant="body1">{current.supplierAddress}</Typography>
            )}
            {current.supplierAddress2 && (
              <Typography variant="body1">
                {current.supplierAddress2}
              </Typography>
            )}
          </Stack>
          <Divider />
          <Typography sx={{ pl: 2, pt: 1 }} variant="h6">
            <strong> Items</strong>
          </Typography>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={3}>Item</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">
                    Purchase Cost{' '}
                    {store && store.currency ? `(${store.currency})` : ''}
                  </TableCell>
                  <TableCell align="right">
                    Amount{' '}
                    {store && store.currency ? `(${store.currency})` : ''}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {current.products.map((product) => (
                  <TableRow
                    key={product.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell colSpan={3} component="th" scope="row">
                      {product.productName}
                    </TableCell>
                    <TableCell align="right">{product.quantity}</TableCell>
                    <TableCell align="right">{product.purchaseCost}</TableCell>
                    <TableCell align="right">
                      {numberFormatText(product.amount)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell colSpan={4} />

                  <TableCell align="right" colSpan={1}>
                    <Typography>
                      <strong>Total</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align="right" colSpan={1}>
                    <Typography>
                      <strong>
                        {numberFormatText(
                          current.totalAmount,
                          store && store.currency ? store.currency : ''
                        )}
                      </strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    );
  }
};

export default PurchaseOrderDetail;
