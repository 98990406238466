import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { getOrders } from 'redux/orders/orderActions';
import Button from '@mui/material/Button';
import { getUsers } from 'redux/user/userActions';
import { useTheme } from '@mui/styles';
import DatePickerView from './DatePickerView';
import {
  addWeeks,
  subWeeks,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
  differenceInQuarters,
  differenceInYears,
  isSameDay,
  isSameWeek,
  isSameMonth,
  isSameQuarter,
  isSameYear,
} from 'date-fns';
import { DURATION } from 'utils/Constants';
import { capitalizeFirstLetter } from 'utils/functions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SalesFilterBar = ({ handleChartViewChange }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const orders = useSelector((state) => state.order.orders);
  const users = useSelector((state) => state.user.users);

  const [filter, setFilter] = useState({
    showAll: true,
    employeeIds: [],
    createdDateTo: new Date(),
    createdDateFrom: subWeeks(new Date(), 1),
  });

  const [selectedChartView, setSelectedChartView] = useState(DURATION.DAYS);
  const [chartViews, setChartViews] = useState([DURATION.DAYS, DURATION.WEEKS]);

  useEffect(() => {
    if (!orders) {
      dispatch(getOrders(filter));
      dispatch(getUsers());
    }
  }, []);

  useEffect(() => {
    if (filter) {
      dispatch(getOrders(filter));
    }
  }, [filter]);

  const [usernames, setUsernames] = useState(['All Employees']);

  useEffect(() => {
    if (users) {
      setUsernames(
        usernames.concat(
          users.data.map((user) => `${user.firstName} ${user.lastName}`)
        )
      );
    }
  }, [users]);

  // Set Filters on UserID selected
  useEffect(() => {
    if (usernames.length > 0) {
      let employeeIds = users.data
        .filter((user) =>
          usernames.includes('All Employees')
            ? user.id != null
            : usernames.includes(`${user.firstName} ${user.lastName}`)
        )
        .map((user) => user.id);
      setFilter({ ...filter, employeeIds });
    }
  }, [usernames]);

  const handleSelectEmployee = (event) => {
    const {
      target: { value },
    } = event;

    let otherEmployees = '';
    // console.log(value);

    if (value.includes('All Employees')) {
      otherEmployees = users.data.map(
        (user) => `${user.firstName} ${user.lastName}`
      );
      setUsernames(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    } else if (!value.includes('All Employees')) {
      setUsernames(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    } else {
      setUsernames(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  const handleDateChange = ({ startDate, endDate }) => {
    setFilter({
      ...filter,
      createdDateFrom: startDate,
      createdDateTo: endDate,
    });
    // Handle Chart Views
    if (isSameDay(startDate, endDate)) {
      setChartViews([DURATION.HOURS]);
    } else if (isSameWeek(startDate, endDate)) {
      setChartViews([DURATION.DAYS]);
    } else if (isSameMonth(startDate, endDate)) {
      setChartViews([DURATION.WEEKS, DURATION.DAYS]);
    } else if (isSameQuarter(startDate, endDate)) {
      setChartViews([DURATION.MONTHS, DURATION.WEEKS]);
    } else if (isSameYear(startDate, endDate)) {
      setChartViews([DURATION.QUARTERS, DURATION.MONTHS]);
    }
    handleChartViewChange(selectedChartView, startDate, endDate);
  };

  // Chart View
  useEffect(() => {
    if (selectedChartView) {
      handleChartViewChange(
        selectedChartView,
        filter.createdDateFrom,
        filter.createdDateTo
      );
    }
  }, [selectedChartView]);

  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      direction={{ xs: 'column', sm: 'row' }}
    >
      {/* Date Picker */}
      <div>
        <DatePickerView handleDateChange={handleDateChange} />
      </div>
      <FormControl sx={{ maxWidth: 300 }}>
        <InputLabel id="demo-employee-checkbox-label">Employee</InputLabel>
        <Select
          labelId="demo-employee-checkbox-label"
          id="demo-employee-checkbox"
          multiple
          value={usernames}
          onChange={handleSelectEmployee}
          input={<OutlinedInput label="Employee" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          <MenuItem key={0} value={'All Employees'}>
            <Checkbox checked={usernames.indexOf('All Employees') > -1} />
            <ListItemText primary="All Employees" />
          </MenuItem>
          {users.data.map((user) => (
            <MenuItem
              key={user.id}
              value={`${user.firstName} ${user.lastName}`}
            >
              <Checkbox
                checked={
                  usernames.indexOf(`${user.firstName} ${user.lastName}`) > -1
                }
              />
              <ListItemText primary={`${user.firstName} ${user.lastName}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="demo-simple-select-label">Chart View</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedChartView}
          label="duration"
          onChange={(e) => {
            setSelectedChartView(e.target.value);
          }}
        >
          {Object.keys(DURATION).map((option) => (
            <MenuItem
              disabled={!chartViews.includes(option)}
              key={option}
              value={option}
            >
              <em>{capitalizeFirstLetter(option)}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default SalesFilterBar;
