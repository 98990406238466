import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InventoryTab from './InventoryTab';
import SuppliersHome from './suppliers/SuppliersHome';
import AddUpdateSupplier from './suppliers/AddUpdateSupplier';
import PurchaseOrderHome from './purchase-orders/PurchaseOrderHome';
import AddEditPurchaseOrder from './purchase-orders/AddEditPurchaseOrder';
import PurchaseOrderDetail from './purchase-orders/PurchaseOrderDetail';
import PurchaseOrderReceiveItems from './purchase-orders/PurchaseOrderReceiveItems';
import InventoryCountHome from './inventory-count/InventoryCountHome';
import CreateEditInventory from './inventory-count/CreateEditInventoryCount';
import InventoryCountDetail from './inventory-count/InventoryCountDetail';
import InventoryCountStep from './inventory-count/InventoryCountStep';
import Loading from 'components/Loading';
import StockAdjustmentHome from './stock-adjustment/StockAdjustmentHome';
import CreateStockAdjustment from './stock-adjustment/CreateStockAdjustment';
import StockAdjustmentDetail from './stock-adjustment/StockAdjustmentDetail';
import InventoryValuation from './inventory-valuation/InventoryValuation';
import InventoryHistory from './history/InventoryHistory';
import SupplierDetail from './suppliers/SupplierDetail';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  card: {
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
}));
const InventoryHome = () => {
  const classes = useStyles();

  const current = useSelector((state) => state.auth.current);

  return (
    <div className={classes.root}>
      {!current ? (
        <Collapse sx={{ px: 3 }} className={classes.root} in>
          <Loading />
        </Collapse>
      ) : (
        <Grid sx={{ px: 3 }} container spacing={4}>
          <Grid item xs={12} md={3}>
            <InventoryTab />
          </Grid>
          <Grid item xs={12} md={9}>
            <Routes>
              <Route exact path="suppliers" element={<SuppliersHome />} />
              <Route
                exact
                path="suppliers/add"
                element={<AddUpdateSupplier />}
              />
              <Route
                exact
                path="suppliers/update/:id"
                element={<AddUpdateSupplier />}
              />
              <Route
                exact
                path="suppliers/detail/:id"
                element={<SupplierDetail />}
              />

              <Route
                exact
                path="purchase-orders"
                element={<PurchaseOrderHome />}
              />
              <Route
                exact
                path="purchase-orders/add"
                element={<AddEditPurchaseOrder />}
              />
              <Route
                exact
                path="purchase-orders/edit/:id"
                element={<AddEditPurchaseOrder />}
              />
              <Route
                exact
                path="purchase-orders/detail/:id"
                element={<PurchaseOrderDetail />}
              />
              <Route
                exact
                path="purchase-orders/receive/:id"
                element={<PurchaseOrderReceiveItems />}
              />
              <Route
                exact
                path="inventory-count"
                element={<InventoryCountHome />}
              />
              <Route
                exact
                path="inventory-count/create"
                element={<CreateEditInventory />}
              />
              <Route
                exact
                path="inventory-count/edit/:id"
                element={<CreateEditInventory />}
              />
              <Route
                exact
                path="inventory-count/detail/:id"
                element={<InventoryCountDetail />}
              />
              <Route
                exact
                path="inventory-count/count/:id"
                element={<InventoryCountStep />}
              />
              <Route
                exact
                path="adjustments"
                element={<StockAdjustmentHome />}
              />
              <Route
                exact
                path="adjustments/add"
                element={<CreateStockAdjustment />}
              />
              <Route
                exact
                path="adjustments/detail/:id"
                element={<StockAdjustmentDetail />}
              />
              <Route exact path="valuation" element={<InventoryValuation />} />
              <Route exact path="history" element={<InventoryHistory />} />
            </Routes>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default InventoryHome;
