import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_SUB_CATEGORY,
  SUB_CATEGORY_ERROR,
  GET_SUB_CATEGORIES,
  DELETE_SUB_CATEGORY,
  GET_SUB_CATEGORY,
} from './subCategoryTypes';

const url = process.env.REACT_APP_API_URL + 'demo-sub-category';

// Add Demo Sub Category

export const addDemoSubCategory = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_SUB_CATEGORY, payload: res.data });
    // Set Register Success Notification

    const category = res.data;
    const notification = {
      message: `Added Demo Sub Category:  ${category.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SUB_CATEGORY_ERROR,
      payload: 'Failed to Add Demo Sub Category',
    });
  }
};

// Update Demo Sub Category

export const updateDemoSubCategory = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const subCategory = res.data;
    const notification = {
      message: `Updated Demo Sub Category:  ${subCategory.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SUB_CATEGORY_ERROR,
      payload: 'Failed to Update Demo Sub Category',
    });
  }
};

// Get Demo Sub Categories
export const getDemoSubCategories = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const subcategories = res.data;
  dispatch({ type: GET_SUB_CATEGORIES, payload: subcategories });
  dispatch({ type: CLEAR_LOADING });
};

// Get Demo Sub Category By Id
export const getDemoSubCategoryById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const subcategory = res.data;
    dispatch({ type: GET_SUB_CATEGORY, payload: subcategory });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SUB_CATEGORY_ERROR,
      payload: 'Failed to Get Demo Sub Category',
    });
  }
};

// Delete Demo Sub Category By Id
export const deleteDemoSubCategory = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_SUB_CATEGORY, payload: id });

    const notification = {
      message: `Delete Demo Sub Category successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SUB_CATEGORY_ERROR,
      payload: 'Failed to Delete Demo Sub Category',
    });
  }
};
