import React, { createContext, useState } from 'react';
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material/styles';
import { red } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';

const defaultTheme = {
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: `#163C4D`,
    },
    secondary: {
      main: `#64D9A0`,
    },
    type: 'light',
  },
};

export const CustomThemeContext = createContext({
  currentTheme: defaultTheme,
  setTheme: null,
  setThemeMode: null,
});

const CustomThemeProvider = (props) => {
  const { children } = props;

  // Get current theme from localStorage
  const currentTheme =
    localStorage.getItem('appTheme') != null
      ? JSON.parse(localStorage.getItem('appTheme'))
      : defaultTheme;

  // State to hold selected theme
  const [theme, _setTheme] = useState(currentTheme);

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeColors = (primaryColor, secondaryColor) => {
    const updatedTheme = {
      ...currentTheme,
      palette: {
        ...currentTheme.palette,
        primary: { main: primaryColor },
        secondary: { main: secondaryColor },
      },
    };
    localStorage.setItem('appTheme', JSON.stringify(updatedTheme));
    _setTheme(updatedTheme);
  };
  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeMode = (mode = 'light') => {
    const updatedTheme = {
      ...currentTheme,
      mode: mode,
    };
    localStorage.setItem('appTheme', JSON.stringify(updatedTheme));
    _setTheme(updatedTheme);
  };

  const contextValue = {
    currentTheme: currentTheme,
    setTheme: setThemeColors,
    setThemeMode: setThemeMode,
  };

  let appTheme = responsiveFontSizes(createTheme(theme));

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={appTheme}>
        <CssBaseline enableColorScheme />
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
