import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(4),
  },
}));

const SectionDiscount = ({  updateForm }) => {
  const classes = useStyles();

  const [form, setForm] = useState({
    activeDiscount: false,
    discountPercent: 0,
  });

  const current = useSelector((state) => state.product.current);

  useEffect(() => {
    if (current) {
      form.activeDiscount = current.activeDiscount;
      form.discountPercent = current.discountPercent;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    updateForm({ ...form });
    // eslint-disable-next-line
  }, [form]);

  return (
    <Paper>
      <Stack direction="column">
        <Stack
          sx={{ p: 2, backgroundColor: grey[200], overflow: 'hidden' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="column">
            <Typography variant="h6">
              <strong>Discount</strong>
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Do you want to apply a discount to this product ?
            </Typography>
          </Stack>
        </Stack>

        <Stack sx={{ p: 2 }} direction="column" spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>Active Discount</Typography>
            <FormControlLabel
              label={form.activeDiscount ? 'Yes' : 'No'}
              value={form.activeDiscount}
              control={
                <Switch
                  color="primary"
                  value={form.activeDiscount}
                  checked={form.activeDiscount}
                  onChange={(e) =>
                    setForm({ ...form, activeDiscount: e.target.checked })
                  }
                />
              }
            />
          </Stack>

          <TextField
            variant="outlined"
            required
            fullWidth
            onChange={onChange}
            id="discountPercent"
            disabled={!form.activeDiscount}
            label="Discount Percent (%)"
            name="discountPercent"
            type="number"
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SectionDiscount;
