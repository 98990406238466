import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { activateUser } from 'redux/auth/authActions';
import { useQuery } from 'utils/hooks';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.primary,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loading: {
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}));

const ActivateUser = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.auth.current);
  const [params, setParams] = useState(null);

  const query = useQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (query && query.get('tk')) {
      dispatch(activateUser(query.get('tk')));
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (current) {
      navigate('/admin');
    }
    // eslint-disable-next-line
  }, [current]);

  if (loading) {
    return (
      <div
        className={classes.loading}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            padding: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h3">
            Please wait
          </Typography>
          <CircularProgress size={142} />
        </Paper>
      </div>
    );
  } else {
    return (
      <div
        className={classes.loading}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            padding: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h3">
            Please wait
          </Typography>
          <CircularProgress size={142} />
        </Paper>
      </div>
    );
  }
};

export default ActivateUser;
