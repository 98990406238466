import {
  AUTH_ERROR,
  SET_USER,
  CLEAR_USER,
  FORGOT_PASS,
  GET_CURRENT_USER,
  SET_CURRENT_USER,
  GET_USER_STORE,
  CLEAR_ACCOUNT,
  UPDATE_CURRENT_STORE,
} from './authTypes';
import setAuthToken from '../../utils/setAuthToken';

const initialState = {
  loading: false,
  error: null,
  current: null,
  store: null,
};

export default (state = { initialState }, action) => {
  switch (action.type) {
    case AUTH_ERROR:
      localStorage.removeItem('token');
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_USER_STORE:
      return {
        ...state,
        store: action.payload,
        loading: false,
      };
    case UPDATE_CURRENT_STORE:
      return {
        ...state,
        store: action.payload,
        loading: false,
      };
    case SET_USER:
      const user = action.payload;
      localStorage.setItem('token', user.accessToken);
      setAuthToken(user.accessToken);

      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case GET_CURRENT_USER:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        current: Object.assign({}, state.current, action.payload),
        loading: false,
      };
    case CLEAR_USER:
      localStorage.removeItem('token');
      return {
        ...state,
        current: null,
      };
    case CLEAR_ACCOUNT:
      localStorage.clear();
      return {
        ...state,
        current: null,
        store: null,
      };
    case FORGOT_PASS:
      return {
        ...state,
        current: null,
      };
    default:
      return state;
  }
};
