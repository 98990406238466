import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { registerStoreOwner } from '../../../../../redux/auth/authActions';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '70%',
    padding: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUpStoreComplete = ({ handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(registerStoreOwner(form));
  };

  const currentUser = useSelector((state) => state.auth.current);
  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const [showPassword, setShowPassword] = useState(false);

  const [form, setForm] = useState({
    password: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (currentUser) {
      setForm(Object.assign({}, form, currentUser));
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (
      notification &&
      notification.type === 'success' &&
      notification.message === 'Account Created' &&
      currentUser &&
      currentUser.storeUrl
    ) {
      if (process.env.NODE_ENV === 'production') {
        window.location.href = `https://${currentUser.storeUrl}.sturr.app/signin?setup=true`;
      } else {
        navigate('/admin/setup');
      }
    }
    // eslint-disable-next-line
  }, [notification]);

  const [isCaptchaComplete, setIsCaptchaComplete] = useState(false);

  const handleCaptcha = (value) => {
    setIsCaptchaComplete(value);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid
            container
            // className={classes.root}
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'center' }} variant="h3">
                <strong>Almost there</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2">
                <strong>Store URL</strong>
              </Typography>
              <br />
              <Typography variant="body2">
                <span className={{ color: theme.palette.primary.main }}>
                  {form.storeName &&
                    form.storeName
                      .toString()
                      .toLowerCase()
                      .replace(/[^A-Z0-9]/gi, '')}
                </span>
                .sturr.app
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                variant="outlined"
                fullWidth
                style={{ marginTop: '2rem' }}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  value={form.password}
                  onChange={onChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={handleCaptcha}
              />
            </Grid>
            <Grid item xs={4}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>

            <Grid item xs={4}>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!form.password || loading || !isCaptchaComplete}
              >
                Start your Trial
              </Button>
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              {loading && <LinearProgress variant="query" color="secondary" />}
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default SignUpStoreComplete;
