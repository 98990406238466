import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_PAYMENT_TYPE,
  DELETE_PAYMENT_TYPE,
  GET_PAYMENT_TYPE,
  GET_PAYMENT_TYPES,
  PAYMENT_TYPE_ERROR,
} from './paymentTypeTypes';

const url = process.env.REACT_APP_API_URL + 'payment-types';

// Add Payment Types
export const addPaymentType = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_PAYMENT_TYPE, payload: res.data });
    // Set Register Success Notification

    const paymentType = res.data;
    const notification = {
      message: `Added Payment Type:  ${paymentType.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_TYPE_ERROR,
      payload: 'Failed to Add Payment Type',
    });
  }
};

// Update Payment Type
export const updatePaymentType = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const paymentType = res.data;
    const notification = {
      message: `Updated Payment Type:  ${paymentType.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: PAYMENT_TYPE_ERROR, payload: 'Failed to Update Payment' });
  }
};

// Get Payments
export const getPaymentTypes = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const paymentTypes = res.data;
  dispatch({ type: GET_PAYMENT_TYPES, payload: paymentTypes });
  dispatch({ type: CLEAR_LOADING });
};

// Get Paymetn Type By Id
export const getPaymentTypeById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const paymentType = res.data;
    dispatch({ type: GET_PAYMENT_TYPE, payload: paymentType });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_TYPE_ERROR,
      payload: 'Failed to Get Payment Type',
    });
  }
};

// Delete Payment Type By Id
export const deletePaymentType = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_PAYMENT_TYPE, payload: id });

    const notification = {
      message: `Deleted Payment Type successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PAYMENT_TYPE_ERROR,
      payload: 'Failed to Delete Payment Type',
    });
  }
};
