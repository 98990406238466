import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { grey } from '@mui/material/colors';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { numberFormatText } from 'utils/functions';
import SalesChart from './SalesChart';
import {
  getOrderStats,
  getProductOrdersStats,
} from 'redux/orders/orderActions';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { DURATION } from 'utils/Constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  cardItem: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.grey[100],
    },
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const store = useSelector((state) => state.auth.store);
  const ordersStats = useSelector((state) => state.order.stats);
  const productStats = useSelector((state) => state.order.productStats);

  useEffect(() => {
    if (!ordersStats) {
      dispatch(getOrderStats());
      dispatch(getProductOrdersStats());
    }
  }, [ordersStats]);

  return (
    <div className={classes.root}>
      {ordersStats && productStats && (
        <Stack spacing={2} direction="column" justifyContent="center">
          <Box>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={6} md={3}>
                <Grow
                  in={ordersStats != null && productStats != null}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(ordersStats && productStats ? { timeout: 500 } : {})}
                >
                  <Paper
                    sx={{ px: 2, py: 3, w: 1, bgcolor: 'background.paper' }}
                  >
                    <Stack direction="column" justifyContent="center">
                      <Box
                        variant="h6"
                        sx={{
                          color: 'text.primary',
                          fontWeight: 'medium',
                          fontSize: 34,
                        }}
                      >
                        {numberFormatText(
                          ordersStats.salesAllTime,
                          store ? store.currency : ''
                        )}
                      </Box>
                      <Typography
                        sx={{ color: 'text.secondary' }}
                        variant="body2"
                      >
                        Sales All Time
                      </Typography>
                    </Stack>
                  </Paper>
                </Grow>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grow
                  in={ordersStats != null && productStats != null}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(ordersStats && productStats ? { timeout: 600 } : {})}
                >
                  <Paper
                    sx={{ px: 2, py: 3, w: 1, bgcolor: 'background.paper' }}
                  >
                    <Stack direction="column" justifyContent="center">
                      <Box
                        variant="h6"
                        sx={{
                          color: 'text.primary',
                          fontWeight: 'medium',
                          fontSize: 34,
                        }}
                      >
                        {numberFormatText(
                          ordersStats.salesToday,
                          store ? store.currency : ''
                        )}
                      </Box>
                      <Typography
                        sx={{ color: 'text.secondary' }}
                        variant="body2"
                      >
                        Sales Today - {format(new Date(), 'MMM do')}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grow>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grow
                  in={ordersStats != null && productStats != null}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(ordersStats && productStats ? { timeout: 600 } : {})}
                >
                  <Paper
                    sx={{ px: 2, py: 3, w: 1, bgcolor: 'background.paper' }}
                  >
                    <Stack direction="column" justifyContent="center">
                      <Box
                        variant="h6"
                        sx={{
                          color: 'text.primary',
                          fontWeight: 'medium',
                          fontSize: 34,
                        }}
                      >
                        {numberFormatText(
                          ordersStats.salesMonth,
                          store ? store.currency : ''
                        )}
                      </Box>
                      <Typography
                        sx={{ color: 'text.secondary' }}
                        variant="body2"
                      >
                        Sales This Month - {format(new Date(), 'MMM')}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grow>
              </Grid>
              <Grid item xs={6} md={3}>
                <Grow
                  in={ordersStats != null && productStats != null}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(ordersStats && productStats ? { timeout: 700 } : {})}
                >
                  <Paper
                    sx={{ px: 2, py: 3, w: 1, bgcolor: 'background.paper' }}
                  >
                    <Stack direction="column" justifyContent="center">
                      <Box
                        variant="h6"
                        sx={{
                          color: 'text.primary',
                          fontWeight: 'medium',
                          fontSize: 34,
                        }}
                      >
                        {numberFormatText(
                          ordersStats.salesWeek,
                          store ? store.currency : ''
                        )}
                      </Box>
                      <Typography
                        sx={{ color: 'text.secondary' }}
                        variant="body2"
                      >
                        Sales This Week -{' '}
                        {`${format(
                          startOfWeek(new Date()),
                          'MMM do'
                        )} - ${format(endOfWeek(new Date()), 'MMM do')}`}
                      </Typography>
                    </Stack>
                  </Paper>
                </Grow>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={8}>
                <Grow
                  in={ordersStats != null && productStats != null}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(ordersStats && productStats ? { timeout: 800 } : {})}
                >
                  <Box>
                    <SalesChart />
                  </Box>
                </Grow>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grow
                  in={ordersStats != null && productStats != null}
                  style={{ transformOrigin: '0 0 0' }}
                  {...(ordersStats && productStats ? { timeout: 1000 } : {})}
                >
                  <Stack direction="column" spacing={2}>
                    <Paper>
                      <Stack direction="column">
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            sx={{ color: 'text.secondary', p: 2 }}
                            variant="body2"
                          >
                            Top 5 Grossing Products in{' '}
                            {format(new Date(), 'MMMM')}
                          </Typography>
                        </Stack>

                        <Stack direction="column">
                          {productStats.mostSoldProducts ? (
                            <>
                              {productStats.mostSoldProducts
                                .slice(0, 5)
                                .map((product, index) => {
                                  if (index === 0) {
                                    return (
                                      <Stack
                                        key={index}
                                        direction="column"
                                        className={classes.cardItem}
                                        component={Link}
                                        to={`/admin/products/detail/${product.id}`}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={2}
                                          sx={{ p: 2 }}
                                          alignItems="center"
                                        >
                                          <WorkspacePremiumIcon
                                            color="primary"
                                            fontSize="large"
                                          />
                                          <Stack
                                            direction="row"
                                            sx={{ width: 1 }}
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <Typography variant="body2">
                                              {product.name}
                                            </Typography>
                                            <Stack
                                              direction="column"
                                              alignItems="flex-end"
                                            >
                                              <Typography
                                                color="secondary"
                                                variant="h5"
                                              >
                                                <strong>
                                                  {numberFormatText(
                                                    product.amount,
                                                    store ? store.currency : '',
                                                    ''
                                                  )}
                                                </strong>
                                              </Typography>
                                              <Typography
                                                variant="overline"
                                                gutterBottom
                                                color="primary"
                                              >
                                                {product.quantity} sold
                                              </Typography>
                                            </Stack>
                                          </Stack>
                                        </Stack>
                                        <Divider />
                                      </Stack>
                                    );
                                  } else {
                                    return (
                                      <Stack
                                        key={index}
                                        direction="column"
                                        className={classes.cardItem}
                                        component={Link}
                                        to={`/admin/products/detail/${product.id}`}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={2}
                                          sx={{ p: 2 }}
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Typography variant="body2">
                                            {product.name}
                                          </Typography>
                                          <Stack
                                            direction="column"
                                            alignItems="flex-end"
                                          >
                                            <Typography
                                              color="secondary"
                                              variant="body2"
                                            >
                                              <strong>
                                                {numberFormatText(
                                                  product.amount,
                                                  store ? store.currency : '',
                                                  ''
                                                )}
                                              </strong>
                                            </Typography>
                                            <Typography
                                              variant="overline"
                                              gutterBottom
                                              color="primary"
                                            >
                                              {product.quantity} sold
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                        <Divider />
                                      </Stack>
                                    );
                                  }
                                })}
                              <Button component={Link} to={`/admin/products`}>
                                More Products
                              </Button>
                            </>
                          ) : (
                            <Stack sx={{ p: 2, backgroundColor: grey[100] }}>
                              <Box>
                                <Typography variant="body2">
                                  No Products Sold in{' '}
                                  {format(new Date(), 'MMMM')}
                                </Typography>
                              </Box>
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    </Paper>
                    <Paper>
                      <Stack direction="column">
                        <Stack direction="row" justifyContent="space-between">
                          <Typography
                            sx={{ color: 'text.secondary', p: 2 }}
                            variant="body2"
                          >
                            Top 5 Grossing Categories in{' '}
                            {format(new Date(), 'MMMM')}
                          </Typography>
                        </Stack>

                        <Stack direction="column">
                          {productStats.mostSoldCategory ? (
                            <>
                              {productStats.mostSoldCategory
                                .slice(0, 5)
                                .map((category, index) => {
                                  if (index === 0) {
                                    return (
                                      <Stack
                                        key={index}
                                        direction="column"
                                        className={classes.cardItem}
                                        component={Link}
                                        to={`/admin/categories/detail/${category.id}`}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={2}
                                          sx={{ p: 2 }}
                                          alignItems="center"
                                        >
                                          <WorkspacePremiumIcon
                                            color="primary"
                                            fontSize="large"
                                          />
                                          <Stack
                                            direction="row"
                                            sx={{ width: 1 }}
                                            justifyContent="space-between"
                                            alignItems="center"
                                          >
                                            <Typography variant="body2">
                                              {category.name}
                                            </Typography>
                                            <Stack
                                              direction="column"
                                              alignItems="flex-end"
                                            >
                                              <Typography
                                                color="secondary"
                                                variant="h5"
                                              >
                                                <strong>
                                                  {numberFormatText(
                                                    category.amount,
                                                    store ? store.currency : '',
                                                    ''
                                                  )}
                                                </strong>
                                              </Typography>
                                              <Typography
                                                variant="overline"
                                                gutterbottom
                                                color="primary"
                                              >
                                                {category.quantity} sold
                                              </Typography>
                                            </Stack>
                                          </Stack>
                                        </Stack>
                                        <Divider />
                                      </Stack>
                                    );
                                  } else {
                                    return (
                                      <Stack
                                        key={index}
                                        direction="column"
                                        className={classes.cardItem}
                                        component={Link}
                                        to={`/admin/categories/detail/${category.id}`}
                                      >
                                        <Stack
                                          direction="row"
                                          spacing={2}
                                          sx={{ p: 2 }}
                                          alignItems="center"
                                          justifyContent="space-between"
                                        >
                                          <Typography variant="body2">
                                            {category.name}
                                          </Typography>
                                          <Stack
                                            direction="column"
                                            alignItems="flex-end"
                                          >
                                            <Typography
                                              color="secondary"
                                              variant="body2"
                                            >
                                              <strong>
                                                {numberFormatText(
                                                  category.amount,
                                                  store ? store.currency : '',
                                                  ''
                                                )}
                                              </strong>
                                            </Typography>
                                            <Typography
                                              variant="overline"
                                              gutterbottom
                                              color="primary"
                                            >
                                              {category.quantity} sold
                                            </Typography>
                                          </Stack>
                                        </Stack>
                                        <Divider />
                                      </Stack>
                                    );
                                  }
                                })}
                              <Button component={Link} to={`/admin/categories`}>
                                More Categories
                              </Button>
                            </>
                          ) : (
                            <Stack sx={{ p: 2, backgroundColor: grey[100] }}>
                              <Typography variant="body2">
                                No Products Sold in {format(new Date(), 'MMMM')}
                              </Typography>
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                    </Paper>
                  </Stack>
                </Grow>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      )}
    </div>
  );
};

export default Dashboard;

{
  // `${format(startOfWeek(), 'MMM')} - ${format(endOfWeek(), 'MMM')}`;
}
