import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Stack from '@mui/material/Stack';
import SetupStoreWelcome from './steps/SetupStoreWelcome';
import SetupStoreContactInfo from './steps/SetupStoreContactInfo';
import SetupStoreBranding from './steps/SetupStoreBranding';
import SetupStoreData from './steps/SetupStoreData';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slide from '@mui/material/Slide';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.primary,
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  swipeableViews: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100%',
  },
}));

const steps = [
  SetupStoreWelcome,
  SetupStoreContactInfo,
  SetupStoreBranding,
  SetupStoreData,
];

const SetupStore = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClose = () => {
    navigate('/admin');
  };

  return (
    <Dialog
      fullScreen
      open={true}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={activeStep > 0 ? handleBack : handleClose}
            aria-label="close"
          >
            {activeStep > 0 ? <ArrowBackIcon /> : <CloseIcon />}
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            {steps[activeStep].label}
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack sx={{ mt: 4 }}>
        <div className={classes.swipeableViews}>
          {activeStep === 0 && (
            <SetupStoreWelcome
              handleNext={handleNext}
              handleBack={handleBack}
              isActiveStep={activeStep === 0}
            />
          )}
          {activeStep === 1 && (
            <SetupStoreContactInfo
              handleNext={handleNext}
              handleBack={handleBack}
              isActiveStep={activeStep === 1}
            />
          )}
          {activeStep === 2 && (
            <SetupStoreBranding
              handleNext={handleNext}
              handleBack={handleBack}
              isActiveStep={activeStep === 2}
            />
          )}
          {activeStep === 3 && (
            <SetupStoreData
              handleNext={handleNext}
              handleBack={handleBack}
              isActiveStep={activeStep === 3}
            />
          )}
        </div>
      </Stack>
    </Dialog>
  );
};

export default SetupStore;
