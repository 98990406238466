import {
  ADD_ROLE,
  GET_ROLE,
  GET_ROLES,
  DELETE_ROLE,
  UPDATE_ROLE,
} from './roleTypes';

const initialState = {
  roles: null,
  loading: false,
  error: null,
  current: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ROLE:
      return {
        ...state,
        roles: state.roles.concat(action.payload),
        loading: false,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
        loading: false,
      };
    case GET_ROLE:
      return {
        ...state,
        current: action.payload,
        loading: false,
      };
    case UPDATE_ROLE:
      return {
        ...state,
        roles: state.roles.map((role) =>
          role.id === action.payload.id ? action.payload : role
        ),
        loading: false,
      };
    case DELETE_ROLE:
      return {
        ...state,
        roles: state.roles.filter((role) => role.id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};
