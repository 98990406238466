import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { getBillingPayments } from '../../redux/billing/billingActions';
import { capitalizeFirstLetter, numberFormatText } from 'utils/functions';
import { parseISO, format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const SettingsBillingPayments = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const loading = useSelector((state) => state.notification.loading);
  const billingPayments = useSelector((state) => state.billing.payments);
  const store = useSelector((state) => state.auth.store);

  const [filter, setFilter] = useState({
    search: null,
    sort: null,
    sortOrder: null,
  });
  useEffect(() => {
    dispatch(getBillingPayments(filter));
    // eslint-disable-next-line
  }, [filter]);

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {capitalizeFirstLetter(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        display: true,
        sortOrder: true,
        print: true,
        filter: true,
        download: true,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {numberFormatText(value, store ? store.currency : '', '')}
            </Typography>
          );
        },
      },
    },

    {
      name: 'createdAt',
      label: 'Paid on',
      options: {
        display: true,
        sort: true,
        filter: false,

        sortOrder: getSortOrder('createdAt'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    serverSide: false,
    rowHover: true,
    fixedHeader: false,
    selectableRows: 'none',
    count: billingPayments && billingPayments.count,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    elevation: 0,
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (billingPayments) {
      var finalData =
        billingPayments &&
        billingPayments.data &&
        billingPayments.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [billingPayments]);

  if (!billingPayments) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Stack direction="column" spacing={2}>
          <Typography sx={{ p: 2 }} color="textSecondary">
            Billing Payments
          </Typography>
          <Stack direction="row">
            <Button
              component={Link}
              to={`/admin/settings/billing`}
              startIcon={<NavigateBeforeIcon />}
            >
              Back to Billing
            </Button>
          </Stack>
          {billingPayments &&
          billingPayments.data &&
          billingPayments.data.length > 0 ? (
            <>
              {data && (
                <MUIDataTable data={data} columns={columns} options={options} />
              )}
            </>
          ) : (
            <Stack sx={{ p: 2 }} alignItems="center">
              <Avatar className={classes.avatar}>
                <AccountBalanceWalletIcon fontSize="large" />
              </Avatar>
              <Typography variant="h5" color="primary">
                <strong>No Billing Payments</strong>
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                You have no made any billing payments for your account.
              </Typography>{' '}
            </Stack>
          )}
        </Stack>
      </Paper>
    );
  }
};

export default SettingsBillingPayments;
