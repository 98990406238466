import React, { useState, useEffect } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
// import { makeStyles, useTheme, lighten } from '@mui/styles';
import SaveIcon from '@mui/icons-material/Save';
import { addCashMovement } from '../../../redux/cash-movements/cashMovementActions';
import { CASH_MOVEMENT_TYPE } from '../../../redux/cash-movements/cashMovementTypes';
import { NumberFormatCustom } from '../../../utils/functions';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     padding: theme.spacing(3, 2),
//     flexGrow: 1,
//   },
//   button: {
//     margin: theme.spacing(1),
//   },
//   labelBadge: {
//     background: theme.palette.primary.light,
//     padding: theme.spacing(1),
//     borderRadius: '4px',
//     color: '#fff',
//     cursor: 'pointer',
//     textDecoration: 'none',
//     textAlign: 'center',
//   },
//   labelRow: {
//     backgroundColor: `${lighten(theme.palette.secondary.main, 0.6)}`,
//     color: theme.palette.secondary.contrastText,
//     padding: theme.spacing(1),
//     borderRadius: '0.5em',
//     textDecoration: 'none',
//   },
//   dialogTitle: {
//     margin: '1rem',
//   },
// }));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SellCashMovementUpdate = ({ isCashIn = true, open, handleClose }) => {
  // const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);
  const currentCashRegister = useSelector(
    (state) => state.cashRegister.current
  );
  const currentDevice = useSelector((state) => state.device.current);
  const store = useSelector((state) => state.auth.store);

  // const theme = useTheme();

  const [form, setForm] = useState({
    type: isCashIn ? CASH_MOVEMENT_TYPE.CASH_IN : CASH_MOVEMENT_TYPE.CASH_OUT,
    notes: '',
    amount: '',
    cashRegisterId: currentCashRegister && currentCashRegister.id,
    deviceId: currentDevice && currentDevice.id,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addCashMovement(form));
    handleClose();
  };
  //
  useEffect(() => {
    if (open) {
      setForm({
        type: isCashIn
          ? CASH_MOVEMENT_TYPE.CASH_IN
          : CASH_MOVEMENT_TYPE.CASH_OUT,
        notes: '',
        amount: '',
        cashRegisterId: currentCashRegister && currentCashRegister.id,
        deviceId: currentDevice && currentDevice.id,
      });
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <Dialog
      // open
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-title">
        {isCashIn ? 'Add' : 'Remove'} Cash
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="amount"
                placeholder="e.g 100.00"
                onChange={onChange}
                value={form.amount}
                label="Amount"
                InputProps={{
                  inputComponent: NumberFormatCustom,
                  startAdornment: (
                    <InputAdornment position="start">
                      {store.currency}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                name="notes"
                placeholder="Type to add a note"
                onChange={onChange}
                label="Notes"
                value={form.notes}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                required
                fullWidth
                size="large"
                type="submit"
                color="primary"
                name="duration"
                disabled={!form.amount || form.amount.length === 0 || loading}
                endIcon={<SaveIcon />}
              >
                {isCashIn ? 'Add' : 'Remove'} Cash
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default SellCashMovementUpdate;
