import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Avatar from 'react-avatar';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import { parseISO, format } from 'date-fns/esm';
import { getStores } from '../../redux/store/storeActions';
import toMaterialStyle from 'material-color-hash';
import { ROLE_OWNER } from '../../redux/role/roleTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const StoreList = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();

  const stores = useSelector((state) => state.store.stores);
  const loading = useSelector((state) => state.notification.loading);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 800000,
    search: null,
    sort: null,
    sortOrder: null,
  });

  useEffect(() => {
    dispatch(getStores(filter));
    // eslint-disable-next-line
  }, [filter]);
  if (loading && !stores) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading Stores
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div>
        <Paper className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="search"
                onChange={(e) =>
                  setFilter({ ...filter, search: e.target.value })
                }
                fullWidth
                variant="outlined"
                label="Search for Store Name"
                placeholder="Search for Store"
              />
            </Grid>
            {filter.search && filter.search.length > 0}
            <Grid item xs={12}>
              <Alert severity="info">
                {stores && stores.count} stores found
              </Alert>
            </Grid>
            {stores &&
              stores.data.map((store) => {
                const admin = store.users.find(
                  (user) => user.roleName === ROLE_OWNER
                );
                return (
                  <Grid item xs={12} md={4} key={store.id}>
                    <Card>
                      <div>
                        {store.image ? (
                          <img
                            style={{
                              width: '100%',
                              maxHeight: '30%',
                              objectFit: 'cover',
                            }}
                            src={store.image}
                            width={100}
                            height={100}
                            alt={store.name}
                          />
                        ) : (
                          <Avatar
                            name={store.name}
                            style={{ width: '100%', height: '100px' }}
                          />
                        )}
                      </div>
                      <CardContent>
                        <div>
                          <Typography
                            variant="h5"
                            // color="textSecondary"
                            // component="p"
                          >
                            <strong>{store.name}</strong>
                          </Typography>
                        </div>

                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <Avatar
                              round={true}
                              size={40}
                              name={admin.firstName}
                              color={
                                toMaterialStyle(`${admin.firstName}`, '250')
                                  .backgroundColor
                              }
                            />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              By {`${admin.firstName} ${admin.lastName}`}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={4}>
                          <Grid item xs={12} md={4}>
                            <Typography variant="body2" component="p">
                              <strong>No of Users</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Typography variant="body2" component="p">
                              <strong>{store.users.length}</strong>
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <Typography variant="body2" component="p">
                              <strong>Created At</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {format(
                                parseISO(
                                  store.createdAt,
                                  'yyyy-MM-dd',
                                  new Date()
                                ),
                                'p EEE dd MMM yyyy'
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* Store Enabled */}
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} md={4}>
                            <Typography variant="body2" component="p">
                              <strong>Enabled</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <Switch
                              checked={store.enabled}
                              // onChange={handleChange}
                              color="primary"
                              name="Enabled"
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                      <CardActions
                        disableSpacing
                        className={classes.cardActions}
                      >
                        <Button
                          component={RouterLink}
                          variant="outlined"
                          to={`/superadmin/stores/view/${store.id}`}
                          aria-label="more"
                          endIcon={<NavigateNextIcon />}
                          className={classes.cardActionItem}
                        >
                          View
                        </Button>
                      </CardActions>
                    </Card>
                    {/* </Grid> */}
                  </Grid>
                );
              })}
          </Grid>
        </Paper>
      </div>
    );
  }
};

export default StoreList;
