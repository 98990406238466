import { ADD_PAYMENT, GET_PAYMENT, GET_PAYMENTS, PAYMENT_ERROR,GET_PAYMENTS_SUMMARY } from "./paymentTypes";


const initialState = {
  payments: null,
  current: null,
  loading: false,
  error: null,
  summary:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAYMENT:
      return {
        ...state,
        payments: {
          ...state.payments,
          data:
            state.payments !== null
              ? state.payments.data.concat(action.payload)
              : [action.payload],
        },
      };

    case GET_PAYMENTS:
      return {
        ...state,
        payments: action.payload,
      };
    case GET_PAYMENT:
      return {
        ...state,
        current: action.payload,
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PAYMENTS_SUMMARY:
      return {
        ...state,
        summary: action.payload,
      };
    default:
      return state;
  }
};
