import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_ORDER,
  ORDER_ERROR,
  GET_ORDER,
  GET_ORDERS,
  DELETE_ORDER,
  DISCARD_ORDER,
  GET_ORDERS_STATS,
  GET_PRODUCT_ORDER_STATS,
} from './orderTypes';

const url = process.env.REACT_APP_API_URL + 'orders';

// Add Order
export const addOrder = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    const newOrder = res.data;

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_ORDER, payload: newOrder });
    // Set Register Success Notification

    const notification = {
      message: `Sale Completed`,
      type: 'success',
      id: newOrder.id,
      action: ADD_ORDER,
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Add Order' });
  }
};

// Email Receipt
export const sendEmailReceipt = (emails, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/${id}/receipt`, { emails });

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const notification = {
      message: res.data.message,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Send Receipt' });
  }
};

// Update Order
export const updateOrder = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const notification = {
      message: `Updated Order successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Update Order' });
  }
};
export const parkSale = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(`${url}/${id}/park`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const notification = {
      message: `Parked Sale`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Update Order' });
  }
};

// Get Orders
export const getOrders = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const orders = res.data;
    dispatch({ type: GET_ORDERS, payload: orders });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Get Orders' });
  }
};

// Get Product Orders Stats
export const getProductOrdersStats = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/stats/products`);
    const stats = res.data;
    dispatch({ type: GET_PRODUCT_ORDER_STATS, payload: stats });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: ORDER_ERROR,
      payload: 'Failed to Get Product Order Stats',
    });
  }
};

// Get Orders Stats
export const getOrderStats = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/stats`);
    const stats = res.data;
    dispatch({ type: GET_ORDERS_STATS, payload: stats });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Get Orders Stats' });
  }
};

// Get Order By Id
export const getOrderById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const order = res.data;
    dispatch({ type: GET_ORDER, payload: order });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Get Order' });
  }
};

// Get Order By Id
export const getOrderReceiptById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}/receipt`, {
      responseType: 'arraybuffer',
    });
    // console.log(typeof res.data)
    const docUrl = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = docUrl;
    link.setAttribute('download', 'Receipt.pdf'); //or any other extension
    document.body.appendChild(link);
    link.click();
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Get Order Receipt' });
  }
};

// Delete Order By Id
export const deleteOrder = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_ORDER, payload: id });

    const notification = {
      message: `Deleted Order successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Delete Order' });
  }
};

// Discard Order
export const discardOrder = () => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    dispatch({ type: DISCARD_ORDER });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: ORDER_ERROR, payload: 'Failed to Delete Order' });
  }
};
