import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Notification from './components/layout/Notification';
import { SnackbarProvider } from 'notistack';
import Main from './pages/Main';
import setAuthToken from './utils/setAuthToken';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getStoreByPrivateUrl } from './redux/store/storeActions';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './pages/Home';
import CustomThemeProvider from './CustomThemeProvider';

const App = () => {
  // Set Token
  setAuthToken(localStorage.getItem('token'));
  // const darkMode = localStorage.getItem('darkMode');

  const dispatch = useDispatch();
  const store = useSelector((state) => state.auth.store);


  useEffect(() => {
    if (store) {
      // if (store.primaryColor) {
      //   localStorage.setItem('primaryColor', store.primaryColor);
      //   setPrimaryColor(store.primaryColor);
      // }
      // if (store.secondaryColor) {
      //   localStorage.setItem('secondaryColor', store.secondaryColor);
      //   setSecondaryColor(store.secondaryColor);
      // }
    } else {
      // Fetch Current Store
      const currentUrl = window.location.href;
      let storeName = '';

      if (currentUrl.includes('superadmin')) {
        return;
      }

      if (currentUrl.includes('http://localhost')) {
        storeName = `teststore`;
      }

      if (currentUrl.includes('.sturr.app')) {
        storeName = currentUrl.split('https://').pop().split('.')[0];
      }
      dispatch(getStoreByPrivateUrl(storeName));
    }
    // eslint-disable-next-line
  }, [store]);

  return (
    <React.Fragment>
      <CustomThemeProvider>
        <SnackbarProvider maxSnack={2}>
          <Notification
            style={{
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </SnackbarProvider>
      </CustomThemeProvider>
    </React.Fragment>
  );
};

export default App;
