import {
  AUTH_ERROR,
  SET_USER,
  GET_CURRENT_USER,
  CLEAR_USER,
  GET_USER_STORE,
  CLEAR_ACCOUNT,
} from './authTypes';
import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import { getCurrentBilling } from 'redux/billing/billingActions';

const url = process.env.REACT_APP_API_URL;
// Register
export const registerUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    // Add Organization ID
    if (localStorage.getItem('prem_token') != null) {
      data.organizationId = localStorage.getItem('prem_token');
    }

    const res = await axios.post(`${url}auth/register`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: SET_USER, payload: res.data });
    // Set Register Success Notification

    const user = res.data;
    const notification = {
      message: `Thank you ${user.firstName}, Please confirm your account through mail`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Registration Failed' });
  }
};

// Register Store Owner
export const registerStoreOwner = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}auth/register/sturr`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: SET_USER, payload: res.data });
    // Set Register Success Notification

    // const user = res.data;
    const notification = {
      message: `Account Created`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Registration Failed' });
  }
};

// Login User
export const login = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    // Add Organization ID
    if (localStorage.getItem('store_token') != null) {
      data.storeId = localStorage.getItem('store_token');
    }
    const res = await axios.post(`${url}auth/login`, data);

    dispatch({ type: CLEAR_LOADING });
    const user = res.data;

    const notification = {
      message: `Welcome ${user.firstName}`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    dispatch({ type: SET_USER, payload: res.data });
    getCurrentUser();

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

export const loginMobile = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const res = await axios.post(`${url}auth/login/mobile/confirm`, data);

    dispatch({ type: CLEAR_LOADING });
    const user = res.data;

    const notification = {
      message: `Welcome ${user.firstName}`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    dispatch({ type: SET_USER, payload: res.data });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 3000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Send Code
export const sendCode = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    await axios.post(`${url}auth/login/mobile`, data);

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Great, Please enter the code sent`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Send Code', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Get Current User

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`${url}users/current`);

    const user = res.data;

    dispatch({ type: GET_CURRENT_USER, payload: user });

    if (user.storeId) {
      const resStore = await axios.get(`${url}store/current`);
      const store = resStore.data;
      dispatch({ type: GET_USER_STORE, payload: store });
    }
    dispatch(getCurrentBilling());

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};
// [ORG ADMIN] Get Current Org Admin

export const getCurrentOrgAdmin = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`${url}auth/organization/${id}`);

    dispatch({ type: SET_USER, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Failed to retrieve user', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Save Current User
export const saveCurrentUser = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.put(`${url}auth`, data);

    dispatch({ type: CLEAR_LOADING });

    // dispatch({ type: GET_CURRENT_USER, payload: res.data });

    const notification = {
      message: `Profile Updated`,
      type: 'info',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Login Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    // dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Log Out

export const logOut = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_USER });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Log Out Failed', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

//

// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}auth/forgot-password`, { email });

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: res.data.message,
      type: 'info',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
  }
};

// Reset Password
export const resetPassword =
  ({ password, emailToken }) =>
  async (dispatch) => {
    try {
      dispatch({ type: SET_LOADING });

      await axios.post(`${url}auth/password-reset`, { password, emailToken });

      dispatch({ type: CLEAR_LOADING });

      const notification = {
        message: `Password Reset. Please Login`,
        type: 'success',
      };

      dispatch({ type: SET_NOTIFICATION, payload: notification });

      setTimeout(() => {
        dispatch({ type: CLEAR_NOTIFICATION });
      }, 1000);
    } catch (error) {
      dispatch({
        type: SET_NOTIFICATION,
        payload: { message: 'Ooops :' + error, type: 'error' },
      });
      dispatch({ type: CLEAR_LOADING });

      setTimeout(() => {
        dispatch({ type: CLEAR_NOTIFICATION });
      }, 1000);

      dispatch({ type: AUTH_ERROR, payload: 'Login Failed' });
    }
  };

// Activate Password
export const activateUser = (emailToken) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}auth/activate`, { emailToken });

    dispatch({ type: SET_USER, payload: res.data });

    dispatch({ type: CLEAR_LOADING });

    const user = res.data;
    const notification = {
      message: `Account Activated. Welcome ${user.firstName}`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error.message, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Failed' });
  }
};
// Activate Store Owner
export const activateStoreOwner = (storeToken) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}auth/activate/owner`, { storeToken });

    const user = res.data;
    dispatch({ type: SET_USER, payload: user });

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: `Store Activated. Welcome ${user.firstName} !`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error.message, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Failed' });
  }
};

// Delete Account
export const deleteStoreAccount = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.delete(`${url}auth/account`);

    dispatch({ type: CLEAR_ACCOUNT });

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: res.data.message,
      type: 'info',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error.message, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Failed' });
  }
};

// Delete Account Data
export const deleteStoreAccountData = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.delete(`${url}auth/data`);

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: res.data.message,
      type: 'info',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error.message, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Failed' });
  }
};

export const deleteStoreDemoData = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.delete(`${url}auth/data/demo`);

    dispatch({ type: CLEAR_LOADING });

    const notification = {
      message: res.data.message,
      type: 'info',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Ooops :' + error.message, type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: AUTH_ERROR, payload: 'Failed' });
  }
};
