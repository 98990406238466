import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { parseISO, format } from 'date-fns/esm';
import { numberFormatText } from 'utils/functions';
import { getStockAdjustmentById } from 'redux/stock-adjustment/stockAdjustmentActions';
import Loading from 'components/layout/Loading';
import { STOCK_ADJUSTMENT_TYPE } from 'redux/stock-adjustment/stockAdjustmentTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    borderRadius: theme.spacing(7),
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const StockAdjustmentDetail = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const current = useSelector((state) => state.stockAdjustment.current);
  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getStockAdjustmentById(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const getHeaderByAdjustmentType = () => {
    switch (current.type) {
      case STOCK_ADJUSTMENT_TYPE.LOSS:
        return 'Remove';
      case STOCK_ADJUSTMENT_TYPE.RECEIVE:
        return 'Add';
      default:
        return 'Adjust';
    }
  };
  if (id && !current) {
    return <Loading />;
  } else {
    return (
      <div className={classes.root}>
        <Paper>
          <Stack container direction="column">
            {loading && <LinearProgress variant="query" color="secondary" />}
            <Stack
              style={{ padding: '1rem' }}
              spacing={2}
              justifyContent="flex-end"
              direction="row"
            >
              <Button endIcon={<ArrowDropDownIcon />}>More</Button>
            </Stack>

            <Divider />
            <Stack spacing={2} direction="column">
              {/* Header with ID and Status */}
              <Stack
                sx={{
                  px: (theme) => theme.spacing(4),
                  pt: (theme) => theme.spacing(4),
                }}
                direction="column"
                spacing={2}
              >
                <Typography variant="h4">{current.id}</Typography>
              </Stack>
              {/* Count Details */}
              <Stack
                sx={{
                  px: (theme) => theme.spacing(4),
                  pt: (theme) => theme.spacing(4),
                }}
                direction="column"
                spacing={2}
              >
                <Typography variant="body1">
                  Date Created:{' '}
                  <strong>
                    {format(
                      parseISO(current.createdAt, 'yyyy-MM-dd', new Date()),
                      'do MMM yyyy p'
                    )}
                  </strong>
                </Typography>

                <Typography variant="body1">
                  Type: <strong>{current.type}</strong>
                </Typography>

                <Typography variant="body1">
                  Made By: <strong>{current.userName}</strong>
                </Typography>
                <Stack direction="column" sx={{ mt: 2 }}>
                  <Typography variant="body1">
                    <strong>Notes</strong>
                  </Typography>
                  <Typography variant="body1">{current.notes}</Typography>
                </Stack>
              </Stack>

              <Divider />
              <Typography
                sx={{
                  px: (theme) => theme.spacing(4),
                  pt: (theme) => theme.spacing(4),
                }}
                variant="h5"
              >
                Items
              </Typography>
              <TableContainer>
                <Table
                  // sx={{
                  //   ml: (theme) => theme.spacing(4),
                  // }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={3}>Item</TableCell>
                      <TableCell align="right">
                        {getHeaderByAdjustmentType()}
                        Stock
                      </TableCell>
                      <TableCell align="right">
                        Cost{' '}
                        {store && store.currency ? `(${store.currency})` : ''}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {current.products.map((product) => (
                      <TableRow
                        key={product.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={3} component="th" scope="row">
                          {product.productName}
                        </TableCell>
                        <TableCell align="right">{product.quantity}</TableCell>
                        <TableCell align="right">
                          {product.cost * product.quantity}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Stack>
          </Stack>
        </Paper>
      </div>
    );
  }
};

export default StockAdjustmentDetail;
