import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { addBusinessType } from '../../redux/business-type/businessTypeActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const AddBusinessType = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);

  const [form, setForm] = useState({
    name: '',
    description: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Add Business Type') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate('/superadmin/business-types');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addBusinessType(form));
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Stack container spacing={2}>
          <TextField
            name="name"
            variant="outlined"
            required
            fullWidth
            onChange={onChange}
            id="name"
            label="Name"
            autoFocus
          />
          <TextField
            variant="outlined"
            required
            fullWidth
            multiline
            onChange={onChange}
            id="description"
            label="Description"
            name="description"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            size={'large'}
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Add
          </Button>
          {loading && <LinearProgress variant="query" color="secondary" />}
        </Stack>
      </form>
    </Paper>
  );
};

export default AddBusinessType;
