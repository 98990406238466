import {
  ADD_SUB_CATEGORY,
  DELETE_SUB_CATEGORY,
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORY,
  SUB_CATEGORY_ERROR,
  UPDATE_SUB_CATEGORY,
} from './subCategoryTypes';

const initialState = {
  subcategories: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_SUB_CATEGORY:
      return {
        ...state,
        subcategories: {
          ...state.subcategories,
          data:
            state.subcategories !== null
              ? state.subcategories.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_SUB_CATEGORY:
      return {
        ...state,
        subcategories: {
          ...state.subcategories,
          data:
            state.subcategories !== null
              ? state.subcategories.data.map((subcategory) =>
                  subcategory.id === action.payload.id
                    ? action.payload
                    : subcategory
                )
              : [action.payload],
        },
      };
    case GET_SUB_CATEGORIES:
      return {
        ...state,
        subcategories: action.payload,
      };
    case GET_SUB_CATEGORY:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_SUB_CATEGORY:
      return {
        ...state,
        subcategories: {
          ...state.subcategories,
          data:
            state.subcategories &&
            state.subcategories.data &&
            state.subcategories.data.filter(
              (category) => category.id !== action.payload
            ),
        },
      };
    case SUB_CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
