export const COLOR_SUCCESS = '#74B667';
export const COLOR_WARNING = '#ffeb3b';
export const COLOR_DANGER = '#d50000';
export const COLOR_INFO = '#0091ea';
export const COLOR_DEFAULT = '#e0e0e0';

export const DURATION = {
  HOURS: 'HOURS',
  DAYS: 'DAYS',
  WEEKS: 'WEEKS',
  MONTHS: 'MONTHS',
  QUARTERS: 'QUARTERS',
};
