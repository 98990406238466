import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getProducts } from '../../../redux/products/productActions';
import ProductItem from './ProductItem';
import { BsInboxesFill } from 'react-icons/bs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { getCategories } from 'redux/category/categoryActions';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
}));

const ProductTab = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();

  const theme = useTheme();
  const products = useSelector((state) => state.product.products);
  const categories = useSelector((state) => state.category.categories);
  const productsFilter = useSelector((state) => state.product.filter);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    search: null,
    sort: null,
    sortOrder: null,
    showAll: true,
  });

  useEffect(() => {
    if (filter) {
      dispatch(getProducts(filter));
    }
    // eslint-disable-next-line
  }, [filter]);
  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  return (
    <Stack direction="column" justifyContent="center">
      <Paper sx={{ p: 2, mt: 2 }}>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom>
              Products
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2}>
              <Stack direction="row">
                <Button
                  size="small"
                  onClick={() => setFilter({ ...filter, categoryId: null })}
                  color="primary"
                  variant="outlined"
                >
                  All
                </Button>
              </Stack>
              {categories &&
                categories.data.slice(0, 4).map((category) => (
                  <Stack key={category.id} direction="row">
                    <Button
                      size="small"
                      onClick={() =>
                        setFilter({ ...filter, categoryId: category.id })
                      }
                      variant="outlined"
                      color="primary"
                    >
                      {category.name}
                    </Button>
                  </Stack>
                ))}
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {products &&
            products.data.map((product) => (
              <ProductItem key={product.id} product={product} />
            ))}
          {/* {products &&
            products.count === 0 &&
            productsFilter == null &&
            productsFilter.search == null && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: 1 }}
                spacing={2}
              >
                <BsInboxesFill size={180} color={theme.palette.primary.main} />
                <Typography variant="h6" sx={{ textAlign: 'center' }}>
                  <strong> Wow, Such Empty!</strong>
                  <br /> Click the button below to Add your Products
                </Typography>
                <Button
                  variant="outlined"
                  component={Link}
                  to={`/admin/products/add`}
                >
                  ADD PRODUCT
                </Button>
              </Stack>
            )}
          {products &&
            products.count === 0 &&
            productsFilter != null &&
            productsFilter.search != null &&
            productsFilter.search.length > 3 && (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ width: 1 }}
              >
                <AiOutlineFileSearch
                  color={theme.palette.primary.main}
                  size={180}
                />
                <Typography color="primary" variant="h6">
                  <strong>Oops!</strong>
                </Typography>
                <Typography sx={{ textAlign: 'center' }} variant="body1">
                  There are no products with the name <br />{' '}
                  <i>{productsFilter.search}</i>
                </Typography>
              </Stack>
            )} */}
        </Grid>
      </Paper>

      {products && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <Button
            onClick={() => setFilter({ ...filter, page: filter.page - 1 })}
            disabled={filter.page === 1}
            variant="outlined"
            startIcon={<NavigateBeforeIcon />}
          >
            Previous
          </Button>
          <Button
            onClick={() => setFilter({ ...filter, page: filter.page + 1 })}
            disabled={!products.hasMore}
            variant="outlined"
            endIcon={<NavigateNextIcon />}
          >
            Next
          </Button>
        </div>
      )}
    </Stack>
  );
};

export default ProductTab;
