import {
  GET_CUSTOMER_GROUPS,
  ADD_CUSTOMER_GROUP,
  REMOVE_CUSTOMER_GROUP,
  UPDATE_CUSTOMER_GROUP,
  GET_CUSTOMER_GROUP,
} from './customerGroupTypes';

const initialState = {
  customerGroups: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOMER_GROUPS:
      return {
        ...state,
        customerGroups: action.payload,
      };

    case ADD_CUSTOMER_GROUP:
      return {
        ...state,
        customerGroups: {
          ...state.customerGroups,
          data:
            state.customerGroups !== null
              ? state.customerGroups.data.concat(action.payload)
              : [action.payload],
        },
      };
    case REMOVE_CUSTOMER_GROUP:
      return {
        ...state,
        customerGroups: {
          ...state.customerGroups,
          data:
            state.customerGroups !== null
              ? state.customerGroups.data.filter(
                  (customerGroup) => customerGroup.id !== action.payload
                )
              : [],
        },
      };
    case UPDATE_CUSTOMER_GROUP:
      return {
        ...state,
        customerGroups: {
          ...state.customerGroups,
          data:
            state.customerGroups !== null
              ? state.customerGroups.data.map((customerGroup) =>
                  customerGroup.id === action.payload.id
                    ? action.payload
                    : customerGroup
                )
              : [action.payload],
        },
      };
    case GET_CUSTOMER_GROUP:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};
