import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import { useSelector, useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import {
  getMessageTemplateById,
  deleteMessageTemplate,
} from 'redux/message-template/messageTemplateActions';
import Fade from '@mui/material/Fade';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  card: {
    width: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardContent: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  tabs: {
    flexGrow: 1,
  },
}));

const MessageTemplateView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getMessageTemplateById(id));
    // eslint-disable-next-line
  }, []);

  const current = useSelector((state) => state.messageTemplate.current);

  const notification = useSelector((state) => state.notification.notification);

  // const loading = useSelector((state) => state.notification.loading);
  const page = useSelector((state) => state.page.page);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action) => {
    setAnchorEl(null);
    if (action === 'edit') {
      navigate(`/superadmin/message-templates/edit/${id}`);
    } else if (action === 'delete') {
      setShowDialogDelete(true);
    }
  };

  const [showDialogDelete, setShowDialogDelete] = useState(false);

  const handleDeleteMessageTemplate = () => {
    dispatch(deleteMessageTemplate(current.id));
    setShowDialogDelete(false);
  };

  const handleShowDialogDeleteClose = () => {
    setShowDialogDelete(false);
  };
  useEffect(() => {
    if (
      page &&
      page.includes('Message Templates') &&
      notification &&
      notification.type === 'info'
    ) {
      window.location.reload();
    }

    if (
      page &&
      page.includes('Message Templates') &&
      notification &&
      notification.type === 'warning'
    ) {
      navigate(`/superadmin/message-templates`);
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <div className={classes.root}>
      {current && (
        <Grid container spacing={3}>
          <Card className={classes.card}>
            <CardHeader
              action={
                <IconButton onClick={handleClick} aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={current.subject}
            />

            <Menu
              id="fade-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={() => handleClose('edit')}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  Edit
                </Typography>
              </MenuItem>
              <MenuItem onClick={() => handleClose('delete')}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
            <CardContent>
              <Grid
                className={classes.cardContent}
                container
                direction="column"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Mode</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1" color="textSecondary">
                    {current.mode}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                className={classes.cardContent}
                container
                direction="column"
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant="body1">
                    <strong>Message Type</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {current.type.split('_').join(' ')}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}

      {/* Handle Delete */}
      <Dialog
        open={showDialogDelete}
        onClose={handleShowDialogDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Message Template Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this messsage template ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeleteMessageTemplate}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={handleShowDialogDeleteClose} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessageTemplateView;
