import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import { SwatchesPicker } from 'react-color';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
// import { DropzoneDialog } from 'material-ui-dropzone';
const Input = styled('input')({
  display: 'none',
});
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
    marginBottom: theme.spacing(4),
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(4),
  },
}));

const SectionRepresentation = ({ updateForm, updateImage }) => {
  const classes = useStyles();

  const [form, setForm] = useState({
    color: '#000',
    image: '',
  });

  useEffect(() => {
    updateForm({ ...form });
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const current = useSelector((state) => state.product.current);

  useEffect(() => {
    if (current) {
      if (current.image) setUserImage(current.image);
      form.color = current.color ?? '#000';
      form.image = current.image;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);
  // Image
  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [userImage, setUserImage] = useState('');
  const [file, setFile] = useState(null);

  const handleSave = (files) => {
    // Set Image
    const file = files[0];
    setUserImage(URL.createObjectURL(file));
    setFile(file);
  };
  const handleClose = () => {
    setImagePickerOpen(false);
  };

  useEffect(() => {
    if (file) {
      updateImage(file);
    }
    // eslint-disable-next-line
  }, file);

  return (
    <Paper>
      <Stack direction="column">
        <Stack
          sx={{ p: 2, backgroundColor: grey[200], overflow: 'hidden' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="column">
            <Typography variant="h6">
              <strong>Representation on POS</strong>
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Set the Icon/Color of the Product
            </Typography>
          </Stack>
        </Stack>

        <Stack
          sx={{ p: 2 }}
          direction="column"
          justifyContent="start"
          spacing={4}
        >
          <Typography variant="h5">Image</Typography>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={(e) => {
                console.log(e.target.files);
                handleSave(e.target.files);
              }}
            />
            <Button variant="contained" component="span">
              Upload
            </Button>
          </label>
          {/* <DropzoneDialog
              open={imagePickerOpen}
              onSave={handleSave}
              acceptedFiles={['image/*']}
              showPreviews={true}
              dropzoneText="Drag and drop an Image file here"
              fileLimit={1} // Select only one image
              showPreviewsInDropzone={true}
              showAlerts={true}
              maxFileSize={5000000}
              onClose={handleClose}
            /> */}
          <VisibilitySensor>
            <Img
              style={{ width: '200px', height: '200px' }}
              onClick={() => setImagePickerOpen(true)}
              src={
                userImage
                  ? userImage
                  : [
                      'https://place-hold.it/500x200/F8F8F8?text=Add Product Image',
                    ]
              }
            />
          </VisibilitySensor>

          <Typography variant="h5">Color</Typography>
          <SwatchesPicker
            width="100%"
            color={form.color}
            onChange={(color, event) => {
              setForm({ ...form, color: color.hex });
            }}
          />
        </Stack>
      </Stack>
    </Paper>
  );
};

export default SectionRepresentation;
