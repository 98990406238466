import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

const Notification = ({ notification: { loading, notification } }) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notification != null && notification.message && notification.type) {
      enqueueSnackbar(notification.message, {
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
        preventDuplicate: true,
        variant: notification.type,
        autoHideDuration: 1000,
      });
    }
    // eslint-disable-next-line
  }, [notification, loading]);

  // console.log(loading);
  if (loading) {
    return (
      <div className={classes.root}>
        <LinearProgress />
      </div>
    );
  } else {
    return <div></div>;
  }
};

Notification.propTypes = {
  notification: PropTypes.object,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, {})(Notification);
