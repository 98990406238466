import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MUIDataTable from 'mui-datatables';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import CustomSearchRender from 'utils/CustomSearchRender';
import { parseISO, format } from 'date-fns/esm';
import {
  getCategories,
  deleteCategory,
} from '../../redux/category/categoryActions';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const CategoryList = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const categories = useSelector((state) => state.category.categories);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleDelete = () => {
    dispatch(deleteCategory(selectedCategory));
    setShowDialogDelete(false);
    setSelectedCategory(null);
  };
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: getSortOrder('id'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sortOrder: getSortOrder('name'),
        print: false,
        filter: false,
      },
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        display: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: true,
        sort: true,
        filter: false,

        sortOrder: getSortOrder('createdAt'),
        customBodyRender: function renderCreatedAt(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('updatedAt'),
        customBodyRender: function renderUpdated(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: true,
    downloadOptions: {
      filename: 'categories.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    count: categories && categories.count,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    elevation: 0,
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/categories/detail/${rowData[0]}`);
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getCategories(filter));

    // eslint-disable-next-line
  }, [filter]);
  // When store/state data is updated
  useEffect(() => {
    if (categories) {
      var finalData =
        categories &&
        categories.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [categories]);

  return (
    <div>
      <Paper sx={{ m: 2 }}>
        <Stack direction="column" spacing={2}>
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Categories</strong>
              </Typography>
              <Typography variant="body1">
                Create Categories for your Products
              </Typography>
            </Stack>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={'/admin/categories/add'}
              startIcon={<AddIcon />}
            >
              Add Category
            </Button>
          </Stack>

          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </Stack>
      </Paper>

      {showDialogDelete && (
        <Dialog
          open={showDialogDelete}
          onClose={() => setShowDialogDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Category Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this category ? This action will
              delete all products related to this Category
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete()} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={() => setShowDialogDelete(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default CategoryList;
