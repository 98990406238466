import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_DEVICE,
  DELETE_DEVICE,
  DEVICE_ERROR,
  GET_DEVICE,
  GET_DEVICES,
} from './deviceTypes';

const url = process.env.REACT_APP_API_URL + 'devices';

// Add Device
export const addDevice = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_DEVICE, payload: res.data });
    // Set Register Success Notification

    const device = res.data;
    const notification = {
      message: `Added Device:  ${device.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: DEVICE_ERROR,
      payload: 'Failed to Add Device',
    });
  }
};

// Update Device
export const updateDevice = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const device = res.data;
    const notification = {
      message: `Updated Device:  ${device.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({ type: DEVICE_ERROR, payload: 'Failed to Update Device' });
  }
};

// Get Devices
export const getDevices = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const devices = res.data;
  dispatch({ type: GET_DEVICES, payload: devices });
  dispatch({ type: CLEAR_LOADING });
};

// Get Device By Id
export const getDeviceById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const device = res.data;
    dispatch({ type: GET_DEVICE, payload: device });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: DEVICE_ERROR,
      payload: 'Failed to Get Device',
    });
  }
};

// Delete Device By Id
export const deleteDevice = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_DEVICE, payload: id });

    const notification = {
      message: `Deleted Device successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: DEVICE_ERROR,
      payload: 'Failed to Delete Device',
    });
  }
};
