import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  CREATE_SETTING,
  GET_SETTING,
  SETTING_ERROR,
  UPDATE_SETTING,
} from './settingsTypes';

const url = process.env.REACT_APP_API_URL + 'settings';

let notification;

// Create Setting
export const createSetting = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const setting = res.data;

    dispatch({ type: CREATE_SETTING, payload: setting });

    notification = {
      message: `Settings created successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SETTING_ERROR,
      payload: 'Failed to Create Setting',
    });
  }
};

// Update Setting
export const updateSetting = (data, storeId) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    data.storeId = storeId;

    const res = await axios.put(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_SETTING, payload: res.data });
    // Set Register Success Notification

    const notification = {
      message: `Updated Settings successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SETTING_ERROR,
      payload: 'Failed to Update Settings',
    });
  }
};

// Get Setting By Store ID
export const getSettingStoreId = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const setting = res.data;
    dispatch({ type: GET_SETTING, payload: setting });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: SETTING_ERROR,
      payload: 'Failed to Get Setting ',
    });
  }
};
