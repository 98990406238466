import { ADD_BUSINESS_TYPE, BUSINESS_TYPE_ERROR, DELETE_BUSINESS_TYPE, GET_BUSINESS_TYPE, GET_BUSINESS_TYPES, UPDATE_BUSINESS_TYPE } from "./businessTypeTypes";

const initialState = {
  businessTypes: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_BUSINESS_TYPE :
      return {
        ...state,
        businessTypes: {
          ...state.businessTypes,
          data:
            state.businessTypes !== null
              ? state.businessTypes.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_BUSINESS_TYPE:
      return {
        ...state,
        businessTypes: {
          ...state.businessTypes,
          data:
            state.businessTypes !== null
              ? state.businessTypes.data.map((businessType) =>
                  businessType.id === action.payload.id ? action.payload : businessType
                )
              : [action.payload],
        },
      };
    case GET_BUSINESS_TYPES:
      return {
        ...state,
        businessTypes: action.payload,
      };
    case GET_BUSINESS_TYPE:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_BUSINESS_TYPE:
      return {
        ...state,
        businessTypes: {
          ...state.businessTypes,
          data:
            state.businessTypes &&
            state.businessTypes.data &&
            state.businessTypes.data.filter(
              (businessType) => businessType.id !== action.payload
            ),
        },
      };
    case BUSINESS_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
