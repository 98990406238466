import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MailIcon from '@mui/icons-material/Mail';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_USER } from '../../../../../redux/auth/authTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '70%',
    padding: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUpContact = ({ handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: SET_CURRENT_USER, payload: form });
    handleNext();
  };

  const currentUser = useSelector((state) => state.auth.current);

  const [form, setForm] = useState({
    mobileNumber: '',
    email: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (currentUser) {
      setForm({ ...form, ...currentUser });
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid
            container
            // className={classes.root}
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'center' }} variant="h3">
                <strong>How should we contact you</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                name="email"
                value={form.email}
                label="Email"
                variant="outlined"
                onChange={onChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="mobileNumber"
                type="number"
                label="Mobile Number"
                name="mobileNumber"
                value={form.mobileNumber}
                onChange={onChange}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!form.email || !form.mobileNumber}
              >
                Continue
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default SignUpContact;
