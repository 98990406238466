import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { getUsers } from '../../redux/user/userActions';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Link, useNavigate } from 'react-router-dom';
import CustomSearchRender from 'utils/CustomSearchRender';
import { format, parseISO } from 'date-fns/esm';
import UserToolbarSelect from './UserToolbarSelect';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3, 2),
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(2),
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(0.3),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
  },
}));

const EmployeeList = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector((state) => state.user.users);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    createdDateFrom: null,
    createdDateTo: null,
  });

  const handleFromDateChange = (date) =>
    setFilter({
      ...filter,
      createdDateFrom: date,
    });
  const handleToDateChange = (date) =>
    setFilter({ ...filter, createdDateTo: date });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    // console.log('Here');
    dispatch(getUsers(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (users) {
      var finalData =
        users.data &&
        users.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [users]);

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    filter: true,
    rowsPerPage: filter.limit,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    count: users && users.count,
    rowHover: true,
    responsive: 'stacked',
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/employees/edit/${rowData[0]}`);
    },
    elevation: 0,
    downloadOptions: { filename: 'employees.csv', useDisplayedRowsOnly: false },

    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },

    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onFilterChange: (changedColumn, filterList, type) => {
      columns.map((column, idx) => {
        if (column.name === changedColumn) {
          const value = filterList[idx][0];

          setFilter({ ...filter, [changedColumn]: value });
          return true;
        }
        return 0;
      });
    },
    onColumnSortChange: (column, direction) => {
      // console.log(column);
      // console.log(direction);
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
    customToolbarSelect: (selectedRows, displayData) => (
      <UserToolbarSelect
        selectedRows={selectedRows}
        displayData={displayData}
      />
    ),
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false,
        download: false,
      },
    },
    {
      name: 'firstName',
      label: 'First Name',
      options: {
        filter: false,
        display: true,
        sortOrder: getSortOrder('firstName'),
      },
    },
    {
      name: 'lastName',
      label: 'Last Name',
      options: {
        filter: false,
        filterType: 'textField',
        sortOrder: getSortOrder('lastName'),
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sortOrder: getSortOrder('email'),
      },
    },
    {
      name: 'mobileNumber',
      label: 'Mobile No',
      options: {
        filter: false,
        sortOrder: getSortOrder('mobileNumber'),
      },
    },
    {
      name: 'isEnabled',
      label: 'Enabled',
      options: {
        display: false,
        sortOrder: getSortOrder('isEnabled'),
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return value ? <CheckIcon /> : <ClearIcon />;
        },
        customFilterListOptions: {
          render: (v) => v.toLowerCase(),
        },
      },
    },
    {
      name: 'roleName',
      label: 'Role',
      options: {
        filter: true,
        sortOrder: getSortOrder('roleName'),
      },
    },
    {
      name: 'roleId',
      label: 'Role ID',
      options: {
        filter: false,
        display: false,
        download: false,
        print: false,
        viewColumns: false,
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: false,
        sort: true,
        filterType: 'custom',
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'p dd-MM-yyyy'
              )}
            </Typography>
          );
        },
        filterOptions: {
          names: [],
          logic(date, filters) {
            if (filters[0] && filters[1]) {
              return date < filters[0] || date > filters[1];
            } else if (filters[0]) {
              return date < filters[0];
            } else if (filters[1]) {
              return date > filters[1];
            }
            return false;
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Created Date</FormLabel>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <MobileDatePicker
                      label="From"
                      inputVariant="outlined"
                      value={filter.createdDateFrom || new Date()}
                      onChange={(date) => {
                        console.log(filterList);
                        handleFromDateChange(date);
                        filterList[index][0] = date.toString();
                        onChange(filterList[index], index, column);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MobileDatePicker
                      label="To"
                      inputVariant="outlined"
                      value={filter.createdDateTo || new Date()}
                      onChange={(date) => {
                        filterList[index][1] = date.toString();
                        handleToDateChange(date);
                        onChange(filterList[index], index, column);
                      }}
                      t={(params) => <TextField {...params} />}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </div>
          ),
        },
      },
    },
  ];

  return (
    <div>
      <Paper className={classes.root}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="primary"
            component={Link}
            to="/admin/employees/add"
            className={classes.button}
            startIcon={<AddBoxIcon />}
          >
            Add Employee
          </Button>
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable
                title={'Employee List'}
                data={data}
                columns={columns}
                options={options}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default EmployeeList;
