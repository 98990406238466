import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import MUIDataTable from 'mui-datatables';
import Grid from '@mui/material/Grid';
import { useSelector, useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CustomSearchRender from 'utils/CustomSearchRender';
import {
  deleteProduct,
  getProducts,
} from '../../redux/products/productActions';
import {
  deleteDemoProduct,
  getDemoProducts,
} from '../../redux/products/demoProductActions';
import { numberFormatText } from '../../utils/functions';
import { grey } from '@mui/material/colors';
import { TbDatabaseImport } from 'react-icons/tb';
import ImportDataDialog from './import/ImportDataDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
}));

const ProductList = () => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const products = useSelector((state) => state.product.products);
  const store = useSelector((state) => state.auth.store);
  const loading = useSelector((state) => state.notification.loading);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  const [isDemoProduct, setIsDemoProduct] = useState(
    location.pathname.includes('demodata')
  );
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleDelete = () => {
    dispatch(
      isDemoProduct
        ? deleteDemoProduct(selectedProduct)
        : deleteProduct(selectedProduct)
    );
    setShowDialogDelete(false);
    setSelectedProduct(null);
  };
  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: getSortOrder('id'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sortOrder: getSortOrder('name'),
        print: false,
        filter: false,
      },
    },

    {
      name: 'categoryId',
      label: 'Category ID',
      options: {
        display: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'categoryName',
      label: 'Category',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('price'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Chip
              label={value}
              component={Link}
              style={{ cursor: 'pointer' }}
              to={`/admin/categories/detail/${tableMeta.rowData[2]}`}
            />
          );
        },
      },
    },
    {
      name: 'subCategoryId',
      label: 'Sub Category ID',
      options: {
        display: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'subCategoryName',
      label: 'Category',
      options: {
        display: false,
        sort: true,
        filter: false,
      },
    },
    {
      name: 'price',
      label: `Price${store ? `(${store.currency})` : ''}`,
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('price'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {numberFormatText(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: 'buyingPrice',
      label: `Buying Price${store ? `(${store.currency})` : ''}`,
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('buyingPrice'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {numberFormatText(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: 'margin',
      label: 'Margin',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('buyingPrice'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {value}%
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: true,
    elevation: 0,
    downloadOptions: {
      filename: 'products.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    count: products && products.count,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/products/detail/${rowData[0]}`);
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(isDemoProduct ? getDemoProducts(filter) : getProducts(filter));
    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (products) {
      var finalData =
        products &&
        products.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [products]);

  const [openImport, setOpenImport] = useState(false);
  const handleCloseImport = () => {
    setOpenImport(false);
  };

  return (
    <div>
      <Paper sx={{ m: 2 }}>
        <Stack direction="column" spacing={2}>
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Products</strong>
              </Typography>
              <Typography variant="body1">
                Create your Product Directory
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                size="large"
                color="primary"
                component={Link}
                to={
                  isDemoProduct
                    ? `/superadmin/demodata/products/add`
                    : `/admin/products/add`
                }
                className={classes.button}
                startIcon={<AddIcon />}
              >
                Add Product
              </Button>
              <Button
                variant="outlined"
                size="large"
                onClick={() => setOpenImport(true)}
                startIcon={<TbDatabaseImport />}
              >
                Import Data
              </Button>
            </Stack>
          </Stack>

          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </Stack>
      </Paper>

      {showDialogDelete && (
        <Dialog
          open={showDialogDelete}
          onClose={() => setShowDialogDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Product Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this product ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleDelete()} color="primary" autoFocus>
              Yes
            </Button>
            <Button onClick={() => setShowDialogDelete(false)} color="primary">
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ImportDataDialog
        openImport={openImport}
        handleCloseImport={handleCloseImport}
      />
    </div>
  );
};

export default ProductList;
