export const GET_INVENTORY_COUNT = 'GET_INVENTORY_COUNT';
export const GET_INVENTORY_COUNTS = 'GET_INVENTORY_COUNTS';
export const ADD_INVENTORY_COUNT = 'ADD_INVENTORY_COUNT';
export const UPDATE_INVENTORY_COUNT = 'UPDATE_INVENTORY_COUNT';
export const DELETE_INVENTORY_COUNT = 'DELETE_INVENTORY_COUNT';
export const INVENTORY_COUNT_ERROR = 'INVENTORY_COUNT_ERROR';
export const SET_INVENTORY_COUNT = 'SET_INVENTORY_COUNT';
export const DELETE_INVENTORY_COUNT_ITEM = 'DELETE_INVENTORY_COUNT_ITEM';
export const ADD_INVENTORY_COUNT_ITEM = 'ADD_INVENTORY_COUNT_ITEM';
export const UPDATE_INVENTORY_COUNT_ITEM = 'UPDATE_INVENTORY_COUNT_ITEM';

export const INVENTORY_COUNT_TYPE = {
  PARTIAL: 'PARTIAL',
  FULL: 'FULL',
};

export const INVENTORY_COUNT_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN PROGRESS',
  COMPLETED: 'COMPLETED',
};
