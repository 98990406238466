import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  CASH_REGISTER_ERROR,
  CLOSE_CASH_REGISTER,
  GET_CASH_REGISTER,
  GET_CASH_REGISTERS,
  GET_OPEN_CASH_REGISTER,
} from './cashRegisterTypes';

const url = process.env.REACT_APP_API_URL + 'cash-register';

// Open Register

export const openCashRegister = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/open`, data);

    dispatch({ type: GET_OPEN_CASH_REGISTER, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const notification = {
      message: `Register Opened`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CASH_REGISTER_ERROR,
      payload: 'Failed to Open Cash Register',
    });
  }
};

export const closeCashRegister = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    await axios.post(`${url}/close`, data);

    dispatch({ type: CLOSE_CASH_REGISTER });

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const notification = {
      message: `Register Closed`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CASH_REGISTER_ERROR,
      payload: 'Failed to Close Register',
    });
  }
};

// Get Cash Registers
export const getCashRegisters = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });
  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const cashRegisters = res.data;
    dispatch({ type: GET_CASH_REGISTERS, payload: cashRegisters });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CASH_REGISTER_ERROR,
      payload: 'Failed to Get Cash Register',
    });
  }
};

// Get Cash Register By Id
export const getCashRegisterById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const cashRegister = res.data;
    dispatch({ type: GET_CASH_REGISTER, payload: cashRegister });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: CASH_REGISTER_ERROR,
      payload: 'Failed to Get Cash Register',
    });
  }
};

export const getOpenCashRegister = (deviceId) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${deviceId}/open`);
    const cashRegister = res.data;
    dispatch({ type: GET_CASH_REGISTER, payload: cashRegister });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    console.log(error);

    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: error.response.data.message, type: 'warning' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    dispatch({ type: CLEAR_LOADING });

    dispatch({
      type: CASH_REGISTER_ERROR,
      payload: error.message,
    });
  }
};
