import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { numberFormatText } from 'utils/functions';
import ellipsize from 'ellipsize';
import { getMonth, startOfWeek, endOfWeek, format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
  },
}));

const CategoryStats = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const current = useSelector((state) => state.category.current);
  const store = useSelector((state) => state.auth.store);

  return (
    <Box>
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2}>
          <Paper
            sx={{ px: 2, py: 3, width: 1 / 3, bgcolor: 'background.paper' }}
          >
            {/* Total Product No */}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="column" justifyContent="center">
                <Box
                  variant="h6"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 'medium',
                    fontSize: 34,
                  }}
                >
                  {current.stats.totalProductNo}
                </Box>
                <Typography sx={{ color: 'text.secondary' }} variant="body1">
                  Total No of Products
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          <Paper sx={{ px: 2, py: 3, width: 1 / 3 }}>
            {/* Total Product No */}
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="column" justifyContent="space-between">
                <Box
                  variant="h6"
                  sx={{
                    color: 'text.primary',
                    fontWeight: 'medium',
                    fontSize: 34,
                  }}
                >
                  {numberFormatText(
                    current.stats.totalInventoryValue,
                    store ? store.currency : ''
                  )}
                </Box>

                <Typography sx={{ color: 'text.secondary' }} variant="body1">
                  Total Inventory Value
                </Typography>
              </Stack>
            </Stack>
          </Paper>
          {/* Newest Product */}
          <Paper sx={{ px: 2, py: 3, width: 1 / 3 }}>
            <Stack direction="column" justifyContent="center">
              <Box
                variant="h6"
                sx={{
                  color: 'primary.main',
                  fontWeight: 'bold',
                  fontSize: 24,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                component={Link}
                to={
                  current.stats.newestProductId == 0
                    ? `#`
                    : `/admin/products/detail/${current.stats.newestProductId}`
                }
              >
                {ellipsize(current.stats.newestProduct, 25)}
              </Box>
              <Typography sx={{ color: 'text.secondary' }} variant="body1">
                Newest Product
              </Typography>
            </Stack>
          </Paper>
        </Stack>

        {/* Most Sold */}
        <Stack direction="column">
          <Paper sx={{ p: 2 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Most Sold Product
            </Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <CategoryStatsCard
                title="Overall"
                name={current.stats.mostSoldProduct}
                nameId={current.stats.mostSoldProductId}
              />
              <CategoryStatsCard
                title={`${format(startOfWeek(new Date()), 'do MMM')} - ${format(
                  endOfWeek(new Date()),
                  'do MMM'
                )}`}
                name={current.stats.mostSoldProductThisWeek}
                nameId={current.stats.mostSoldProductThisWeekId}
              />
              <CategoryStatsCard
                title={format(new Date(), 'MMMM')}
                name={current.stats.mostSoldProductThisMonth}
                nameId={current.stats.mostSoldProductThisMonthId}
              />
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};

const CategoryStatsCard = ({ title, name, nameId }) => {
  return (
    <Box sx={{ px: 2, py: 3, width: 1 / 3 }}>
      <Stack direction="column" justifyContent="center">
        <Box
          variant="h6"
          sx={{
            color: 'primary.main',
            fontWeight: 'bold',
            fontSize: 24,
            cursor: 'pointer',
            textDecoration: 'none',
          }}
          component={Link}
          to={nameId == 0 ? `#` : `/admin/products/detail/${nameId}`}
        >
          {ellipsize(name, 25)}
        </Box>
        <Typography sx={{ color: 'text.secondary' }} variant="body1">
          {title}
        </Typography>
      </Stack>
    </Box>
  );
};

export default CategoryStats;
