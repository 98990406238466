import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  BILLING_ERROR,
  GET_BILLING,
  GET_BILLINGS,
  GET_BILLING_PAYMENTS,
  REMOVE_BILLING,
  UPDATE_BILLING,
} from './billingTypes';

const url = process.env.REACT_APP_API_URL + 'billing';

let notification;

// Update Billing

export const updateBilling = (id, data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_BILLING, payload: res.data });
    // Set Register Success Notification

    const notification = {
      message: `Updated Billing successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BILLING_ERROR,
      payload: 'Failed to Update Billing',
    });
  }
};

// Get Billing
export const getBillings = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const billings = res.data;
    dispatch({ type: GET_BILLINGS, payload: billings });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });
    console.log(error);
    dispatch({
      type: BILLING_ERROR,
      payload: 'Failed to Update Billing',
    });
  }
};

// Get Billing By ID
export const getBillingByID = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const billing = res.data;
    dispatch({ type: GET_BILLING, payload: billing });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BILLING_ERROR,
      payload: 'Failed to Get Billing ',
    });
  }
};

// Get Current Billing By ID
export const getCurrentBilling = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/current`);
    const billing = res.data;
    dispatch({ type: GET_BILLING, payload: billing });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BILLING_ERROR,
      payload: 'Failed to Get Billing ',
    });
  }
};

// Delete Billing
export const deleteBilling = (id) => async (dispatch) => {
  const token = localStorage.getItem('token');
  // TODO: Work on Auth Guard

  if (!token) {
    notification.message = 'Unauthorized';
    notification.type = 'error';

    dispatch({ type: SET_NOTIFICATION, payload: notification });

    return;
  }
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`, {});
    const notification = {
      message: `Deleted Billing`,
      type: 'info',
    };

    dispatch({ type: REMOVE_BILLING, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BILLING_ERROR,
      payload: 'Failed to Delete Billing',
    });
  }
};

// Submit Flutterwave Payment

export const submitFlutterwavePayment = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/flutterwave`, data);

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_BILLING, payload: res.data });
    // Set Register Success Notification

    const notification = {
      message: `Store Account Upgraded. You are now on the paid plan`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BILLING_ERROR,
      payload: 'Failed to Update Billing',
    });
  }
};

// Get Billing Payments
export const getBillingPayments = (filter) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.get(`${url}/payments`, { params: filter });

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: GET_BILLING_PAYMENTS, payload: res.data });
    // Set Register Success Notification
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: BILLING_ERROR,
      payload: 'Failed to Get Billing Payments',
    });
  }
};
