import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_PURCHASE_ORDER,
  DELETE_PURCHASE_ORDER,
  GET_PURCHASE_ORDER,
  GET_PURCHASE_ORDERS,
  PURCHASE_ORDER_ERROR,
  UPDATE_PURCHASE_ORDER,
} from './purchaseOrderTypes';

const url = process.env.REACT_APP_API_URL + 'purchase-orders';

// Add Purchase Order
export const addPurchaseOrder = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_PURCHASE_ORDER, payload: res.data });
    // Set Register Success Notification

    const purchaseOrder = res.data;
    const notification = {
      message: `Added Purchase Order:  ${purchaseOrder.id} successfully`,
      type: 'success',
    };
    dispatch({ type: ADD_PURCHASE_ORDER, payload: purchaseOrder });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PURCHASE_ORDER_ERROR,
      payload: 'Failed to Add Purchase Order',
    });
  }
};

// Update Purchase Order
export const updatePurchaseOrder = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const purchaseOrder = res.data;
    const notification = {
      message: `Updated Purchase Order:  ${purchaseOrder.id} successfully`,
      type: 'success',
      id: purchaseOrder.id,
    };

    dispatch({ type: UPDATE_PURCHASE_ORDER, payload: purchaseOrder });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PURCHASE_ORDER_ERROR,
      payload: 'Failed to Update Supplier',
    });
  }
};

// Get Purchase Orders
export const getPurchaseOrders = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const taxes = res.data;
    dispatch({ type: GET_PURCHASE_ORDERS, payload: taxes });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    console.error(e);
    dispatch({ type: CLEAR_LOADING });
  }
};

// Get Purchase Order By Id
export const getPurchaseOrderById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const purchaseOrder = res.data;
    dispatch({ type: GET_PURCHASE_ORDER, payload: purchaseOrder });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PURCHASE_ORDER_ERROR,
      payload: 'Failed to Get purchaseOrder',
    });
  }
};

// Delete Purchase Order By Id
export const deletePurchaseOrder = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_PURCHASE_ORDER, payload: id });

    const notification = {
      message: `Deleted Purchase Order successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PURCHASE_ORDER_ERROR,
      payload: 'Failed to Delete Purchase Order',
    });
  }
};

// Update Purchase Order Status By Id
export const updatePurchaseOrderStatus = (id, status) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(`${url}/${id}/status`, { status });
    dispatch({ type: UPDATE_PURCHASE_ORDER, payload: res.data });

    const notification = {
      message: `Updated Purchase Order Status`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: PURCHASE_ORDER_ERROR,
      payload: 'Failed to Update Purchase Order',
    });
  }
};

// Update Purchase Order Receive Items
export const updatePurchaseOrderItemsReceive =
  (id, data) => async (dispatch) => {
    dispatch({ type: SET_LOADING });

    try {
      const res = await axios.put(`${url}/${id}/receive`, data);
      dispatch({ type: UPDATE_PURCHASE_ORDER, payload: res.data });

      const notification = {
        message: `Received Items for ${id}`,
        type: 'success',
      };

      dispatch({ type: SET_NOTIFICATION, payload: notification });
      dispatch({ type: CLEAR_LOADING });
      setTimeout(() => {
        dispatch({ type: CLEAR_NOTIFICATION });
      }, 1000);
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error);
      dispatch({
        type: PURCHASE_ORDER_ERROR,
        payload: 'Failed to Receive Purchase Order Items',
      });
    }
  };
