import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { capitalizeFirstLetter } from 'utils/functions';
import { STOCK_ADJUSTMENT_TYPE } from 'redux/stock-adjustment/stockAdjustmentTypes';
import { addStockAdjustment } from 'redux/stock-adjustment/stockAdjustmentActions';
import StockAdjustmentItems from './StockAdjustmentItems';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  item: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const CreateStockAdjustment = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newStockAdjustment = useSelector((state) => state.stockAdjustment.new);
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.stockAdjustment.current);

  const [form, setForm] = useState({
    notes: '',
    type: STOCK_ADJUSTMENT_TYPE.RECEIVE,
  });

  useEffect(() => {
    if (current) {
      form.notes = current.notes;
      form.type = current.type;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    dispatch(
      addStockAdjustment({ ...form, products: newStockAdjustment.items })
    );
  };

  useEffect(() => {
    if (notification && notification.type === 'success' && notification.id) {
      navigate(`/admin/inventory/adjustments/detail/${notification.id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <Stack direction="column" spacing={4}>
      <Stack>
        <Paper className={classes.root}>
          <Stack direction="column" spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Type</InputLabel>
              <Select
                labelId="type-label"
                id="type-label"
                name="type"
                value={form.type}
                label="Type"
                onChange={onChange}
              >
                {Object.entries(STOCK_ADJUSTMENT_TYPE).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    <em>{capitalizeFirstLetter(value)}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="notes"
              label="Notes"
              value={form.notes}
              multiline
              name="notes"
              onChange={onChange}
              autoComplete="notes"
            />
          </Stack>
        </Paper>
      </Stack>
      {form.type && <StockAdjustmentItems type={form.type} />}
      <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Button
            onClick={() => navigate('/admin/inventory/adjustments')}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={
              loading ||
              (newStockAdjustment && newStockAdjustment.items.length === 0)
            }
            variant="contained"
            onClick={() => handleSave()}
          >
            Adjust
          </Button>
        </Stack>
      </Paper>
      {loading && <LinearProgress color="secondary" />}
    </Stack>
  );
};

export default CreateStockAdjustment;
