import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import { getPurchaseOrders } from '../../../redux/purchase-orders/purchaseOrderActions';
import { numberFormatText } from '../../../utils/functions';
import { format, parseISO } from 'date-fns/esm';
import { grey } from '@mui/material/colors';
import Loading from 'components/Loading';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const PurchaseOrderHome = ({ supplierId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const loading = useSelector((state) => state.notification.loading);
  const purchaseOrders = useSelector(
    (state) => state.purchaseOrder.purchaseOrders
  );
  const store = useSelector((state) => state.auth.store);
  // const { supplierId } = useParams();

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  useEffect(() => {
    if (supplierId) {
      setFilter({ ...filter, supplierId });
    }
  }, [supplierId]);

  useEffect(() => {
    if (filter) {
      dispatch(getPurchaseOrders(filter));
    }
    // eslint-disable-next-line
  }, [filter]);

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: true,
      },
    },
    {
      name: 'orderDate',
      label: 'Order Date',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        sortOrder: getSortOrder('orderDate'),
        customBodyRender: function renderOrderDate(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'supplierId',
      label: 'Supplier ID',
      options: {
        display: false,
        print: false,
        download: false,
        viewColumns: false,
      },
    },
    {
      name: 'supplierName',
      label: 'Supplier Name',
      options: {
        display: true,
      },
    },
    {
      name: 'expectedDate',
      label: 'Expected Date',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        sortOrder: getSortOrder('expectedDate'),
        customBodyRender: function renderOrderDate(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        display: true,
      },
    },
    {
      name: 'received',
      label: 'Received',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'totalQuantity',
      label: 'Total Quantity',
      options: {
        display: false,
        sort: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'totalAmount',
      label: `Total Amount ${store ? `(${store.currency})` : ``}`,
      options: {
        display: false,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function renderTotalAmount(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">{numberFormatText(value)}</Typography>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    serverSide: true,
    rowHover: true,
    fixedHeader: false,
    selectableRows: 'none',
    count: purchaseOrders && purchaseOrders.count,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/inventory/purchase-orders/detail/${rowData[0]}`);
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (purchaseOrders) {
      var finalData =
        purchaseOrders &&
        purchaseOrders.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [purchaseOrders]);

  if (!purchaseOrders) {
    return <Loading title="Loading Purchase Orders..." />;
  } else {
    return (
      <Paper className={classes.root}>
        <Stack direction="column" spacing={2}>
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Purchase Orders</strong>
              </Typography>
              <Typography variant="body1">
                Keep track of inbound inventory from your Suppliers
              </Typography>
            </Stack>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={'/admin/inventory/purchase-orders/add'}
              startIcon={<AddIcon />}
            >
              Add Purchase Order
            </Button>
          </Stack>

          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </Stack>
      </Paper>
    );
  }
};

export default PurchaseOrderHome;
