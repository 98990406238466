import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { NumberFormatCustom, numberFormatText } from 'utils/functions';
import {
  getPurchaseOrderById,
  updatePurchaseOrderItemsReceive,
} from 'redux/purchase-orders/purchaseOrderActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const PurchaseOrderReceiveItems = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams;

  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);
  const notification = useSelector((state) => state.notification.notification);
  const current = useSelector((state) => state.purchaseOrder.current);

  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!current && id) {
      dispatch(getPurchaseOrderById(id));
    }
    if (current) {
      // Set Default Items
      setItems(
        current.products.map((product) => ({
          productId: product.productId,
          toReceive: 0,
        }))
      );
    }
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    if (notification && notification.type === 'success') {
      navigate(`/admin/inventory/purchase-orders/detail/${id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleChange = (_item) => (e) => {
    const receivedItem = {
      productId: _item.productId,
      toReceive: e.target.value,
    };
    let _items = items.map((item) =>
      item.productId === receivedItem.productId ? receivedItem : item
    );
    setItems(_items);
  };

  const handleReceive = () => {
    dispatch(updatePurchaseOrderItemsReceive(id, items));
  };
  const handleMarkAllReceived = () => {
    let _items = current.products.map((product) => ({
      productId: product.productId,
      toReceive: parseInt(product.quantity) - parseInt(product.received),
    }));
    setItems(_items);
  };

  if (!current) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Paper>
          <Grid
            style={{ padding: '1rem' }}
            container
            spacing={2}
            justifyContent="space-between"
          >
            <Grid item>
              <Typography variant="h5" color="textSecondary">
                Items
              </Typography>
            </Grid>
            <Grid item>
              <Button
                value="Mark All As Received"
                onClick={() => handleMarkAllReceived()}
              >
                Mark All As Received
              </Button>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Item</TableCell>
                  <TableCell align="right">Ordered</TableCell>
                  <TableCell align="right">Received</TableCell>
                  <TableCell align="right">To Receive</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {current &&
                  current.products.map((item, idx) => {
                    const receivedItem = items.find(
                      (received) => (received.productId = item.productId)
                    );
                    return (
                      <TableRow
                        key={idx}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.productName}
                        </TableCell>
                        <TableCell align="right">{item.quantity}</TableCell>
                        <TableCell align="right">{item.received}</TableCell>
                        <TableCell align="right">
                          <TextField
                            variant="outlined"
                            type="number"
                            value={receivedItem ? receivedItem.toReceive : 0}
                            placeholder="0"
                            onChange={handleChange(item)}
                            inputProps={{ style: { textAlign: 'right' } }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Grid
          style={{ marginTop: '1rem' }}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button variant="contained">Cancel</Button>
          </Grid>
          <Grid item>
            <Button
              disabled={loading}
              onClick={handleReceive}
              variant="contained"
              color="primary"
            >
              Receive
            </Button>
          </Grid>
          {loading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
};

export default PurchaseOrderReceiveItems;
