import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { UNIT_MEASURE } from '../../../redux/products/productTypes';
import { toTitleCase } from '../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(4),
  },
}));

const SectionAttributes = ({ updateForm }) => {
  const classes = useStyles();

  const [form, setForm] = useState({
    unitMeasure: '',
    unitValue: '',
    brand: '',
    size: '',
    style: '',
  });

  const current = useSelector((state) => state.product.current);

  useEffect(() => {
    if (current) {
      form.unitMeasure = current.unitMeasure;
      form.unitValue = current.unitValue;
      form.brand = current.brand;
      form.size = current.size;
      form.style = current.style;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);
  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    updateForm({ ...form });
    // eslint-disable-next-line
  }, [form]);

  return (
    <Paper>
      <Stack direction="column">
        <Stack
          sx={{ p: 2, backgroundColor: grey[200], overflow: 'hidden' }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Stack direction="column">
            <Typography variant="h6">
              <strong>Attributes</strong>
            </Typography>
            <Typography color="textSecondary" variant="body1">
              More about your product
            </Typography>
          </Stack>
        </Stack>

        <Grid sx={{ p: 2 }} container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel htmlFor="outlined-mode-simple">
                Select Unit Of Measure
              </InputLabel>
              <Select
                fullWidth
                variant="outlined"
                placeholder="Select Unit of Measure"
                label="Select Unit of Measure"
                value={form.unitMeasure}
                onChange={onChange}
                name="unitMeasure"
                inputProps={{
                  name: 'unitMeasure',
                  id: 'outlined-unitMeasure-simple',
                }}
              >
                {Object.keys(UNIT_MEASURE).map((key) => (
                  <MenuItem key={key} value={key}>
                    <em>{toTitleCase(key)}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {form.unitMeasure.toString().length > 1 && (
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-unitvalue-simple">
                  Select Unit Value
                </InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  placeholder="Select Unit Value"
                  label="Select Unit Value"
                  value={form.unitValue}
                  onChange={onChange}
                  name="unitValue"
                  inputProps={{
                    name: 'unitValue',
                    id: 'outlined-unitValue-simple',
                  }}
                >
                  {UNIT_MEASURE[form.unitMeasure].map((value) => (
                    <MenuItem key={value} value={value}>
                      <em>{toTitleCase(value)}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              name="brand"
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="brand"
              label="Brand"
              value={form.brand}
              autoFocus
              type="text"
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              name="size"
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="size"
              label="Size"
              value={form.size}
              autoFocus
              type="text"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name="style"
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="style"
              label="Style"
              value={form.style}
              autoFocus
              type="text"
            />
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
};

export default SectionAttributes;
