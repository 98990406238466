import {
  CREATE_SETTING,
  GET_SETTING,
  UPDATE_SETTING,
  DELETE_SETTING,
  SETTING_ERROR,
} from './settingsTypes';

const initialState = {
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SETTING:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_SETTING:
      return {
        ...state,
        current: null,
      };
    case UPDATE_SETTING:
      return {
        ...state,
        current: action.payload,
      };
    case GET_SETTING:
      return {
        ...state,
        current: action.payload,
      };
    case SETTING_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};
