import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import WelcomeIcon from '../../../shared/icons/welcome.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    display: 'flex',
    // padding: theme.spacing(4),
  },
  image: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SetupStoreWelcome = ({ handleNext }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} className={classes.image}>
        <img src={WelcomeIcon} alt="Welcome" width={256} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Grid
          direction="column"
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography variant="h3">
              <strong>Welcome to Sturr</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Before you get started we've got a few quick questions that will
              help us make sure you're getting the most out of Sturr.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              size="large"
              onClick={() => handleNext()}
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SetupStoreWelcome.label = 'Welcome';

export default SetupStoreWelcome;
