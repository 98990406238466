import {
  ADD_DEVICE,
  DELETE_DEVICE,
  DEVICE_ERROR,
  GET_DEVICE,
  GET_DEVICES,
  UPDATE_DEVICE,
  SET_CURRENT_DEVICE,
} from './deviceTypes';

const initialState = {
  devices: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          data:
            state.devices !== null
              ? state.devices.data.concat(action.payload)
              : [action.payload],
        },
      };
    case SET_CURRENT_DEVICE:
      return {
        current: action.payload,
      };
    case UPDATE_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          data:
            state.devices !== null
              ? state.devices.data.map((device) =>
                  device.id === action.payload.id ? action.payload : device
                )
              : [action.payload],
        },
      };
    case GET_DEVICES:
      return {
        ...state,
        devices: action.payload,
        current: action.payload.data.length > 0 && action.payload.data[0],
      };
    case GET_DEVICE:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_DEVICE:
      return {
        ...state,
        devices: {
          ...state.devices,
          data:
            state.devices &&
            state.devices.data &&
            state.devices.data.filter((device) => device.id !== action.payload),
        },
      };
    case DEVICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
