import {
  CREATE_STORE,
  GET_STORE,
  GET_STORES,
  REMOVE_STORE,
  UPDATE_STORE,
} from './storeTypes';

const initialState = {
  stores: null,
  current: null,
  error: null,
  loading: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STORES:
      return {
        ...state,
        stores: action.payload,
      };

    case CREATE_STORE:
      return {
        ...state,
        stores: state.stores.concat(action.payload),
      };
    case REMOVE_STORE:
      return {
        ...state,
        stores: state.stores.filter((store) => store.id !== action.payload),
      };
    case UPDATE_STORE:
      return {
        ...state,
        stores: state.stores.map(
          (store) => (store.id = action.payload.id ? action.payload : store)
        ),
      };
    case GET_STORE:
      return {
        ...state,
        current: action.payload,
      };

    default:
      return state;
  }
};
