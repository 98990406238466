import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { Link, useNavigate } from 'react-router-dom';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CustomSearchRender from 'utils/CustomSearchRender';
import { format, parseISO } from 'date-fns';
import { getSubCategories } from 'redux/sub-category/subCategoryActions';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const SubCategoryList = ({ id }) => {
  const classes = useStyles();
  // const <param name="" value=""/>s = useParams();
  // console.log(props);
  const dispatch = useDispatch();
  const subcategories = useSelector((state) => state.subcategory.subcategories);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    categoryId: id,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };
  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: getSortOrder('id'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sortOrder: getSortOrder('name'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'description',
      label: 'description',
      options: {
        display: false,
        sortOrder: getSortOrder('description'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'image',
      label: 'image',
      options: {
        display: false,
        sortOrder: getSortOrder('image'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'active',
      label: 'Active',
      options: {
        display: false,
        sortOrder: getSortOrder('active'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'categoryId',
      label: 'CategoryId',
      options: {
        display: false,
        sortOrder: getSortOrder('categoryId'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'categoryName',
      label: 'Category',
      options: {
        display: false,
        sortOrder: getSortOrder('categoryName'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        display: true,
        sort: true,
        filter: false,

        sortOrder: getSortOrder('createdAt'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'updatedAt',
      label: 'Updated At',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('updatedAt'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'view',
      label: 'Actions',
      options: {
        viewColumns: false,
        print: false,
        download: false,
        filter: false,
        sort: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Button
              component={Link}
              to={`/admin/sub-categories/edit/${tableMeta.rowData[0]}`}
              label={value}
              variant="outlined"
              type="submit"
              color="secondary"
            >
              EDIT
            </Button>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: false,
    downloadOptions: {
      filename: 'sub-categories.csv',
      useDisplayedRowsOnly: false,
    },

    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    count: subcategories && subcategories.count,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangeRowsPerPage: (noOfRows) => {
      setFilter({ ...filter, limit: noOfRows });
    },
    elevation: 0,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true,
    },
    fixedHeader: true,
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getSubCategories(filter));

    // eslint-disable-next-line
  }, [filter]);

  // When store/state data is updated
  useEffect(() => {
    if (subcategories) {
      var finalData =
        subcategories &&
        subcategories.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [subcategories]);

  return (
    <div className={classes.root}>
      <div>
        <Button
          variant="contained"
          size="large"
          color="primary"
          component={Link}
          to={`/admin/sub-categories/add/${id}`}
          className={classes.button}
          startIcon={<AddBoxIcon />}
        >
          Add Sub Category
        </Button>
      </div>

      {data && <MUIDataTable data={data} columns={columns} options={options} />}
    </div>
  );
};

export default SubCategoryList;
