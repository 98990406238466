import {
  ADD_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENTS,
  STOCK_ADJUSTMENT_ERROR,
  ADD_STOCK_ADJUSTMENT_ITEM,
  UPDATE_STOCK_ADJUSTMENT_ITEM,
  SET_STOCK_ADJUSTMENT,
  DELETE_STOCK_ADJUSTMENT_ITEM,
  CLEAR_STOCK_ADJUSTMENT_ITEMS,
  GET_STOCK_ADJUSTMENT_PRODUCTS,
} from './stockAdjustmentTypes';

const initialState = {
  stockAdjustments: null,
  current: null,
  loading: false,
  error: null,
  new: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_STOCK_ADJUSTMENT:
      return {
        ...state,
        stockAdjustments: {
          ...state.stockAdjustments,
          data:
            state.stockAdjustments !== null
              ? state.stockAdjustments.data.concat(action.payload)
              : [action.payload],
        },
      };
    case GET_STOCK_ADJUSTMENTS:
      return {
        ...state,
        stockAdjustments: action.payload,
        current: null,
        new: null,
      };
    case GET_STOCK_ADJUSTMENT_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        current: null,
        new: null,
      };
    case GET_STOCK_ADJUSTMENT:
      return {
        ...state,
        current: action.payload,
        new: null,
      };
    case STOCK_ADJUSTMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_STOCK_ADJUSTMENT:
      return {
        ...state,
        current: Object.assign({}, state.current, action.payload),
      };
    case DELETE_STOCK_ADJUSTMENT_ITEM:
      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.filter(
                  (item) => item.productId !== action.payload
                )
              : state.new.items,
        },
      };
    case ADD_STOCK_ADJUSTMENT_ITEM:
      const itemExists =
        state.new &&
        state.new.items &&
        state.new.items.find((item) => item.productId === action.payload.id);
      if (itemExists) {
        return { ...state };
      }

      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.concat(action.payload)
              : [action.payload],
        },
      };
    case UPDATE_STOCK_ADJUSTMENT_ITEM:
      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.map((item) =>
                  item.productId === action.payload.productId
                    ? action.payload
                    : item
                )
              : state.new.items,
        },
      };
    case CLEAR_STOCK_ADJUSTMENT_ITEMS:
      return {
        ...state,
        new: null,
      };
    default:
      return state;
  }
};
