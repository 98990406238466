import {
  CLOSE_CASH_REGISTER,
  GET_CASH_REGISTER,
  GET_CASH_REGISTERS,
  GET_OPEN_CASH_REGISTER,
  CASH_REGISTER_ERROR,
} from './cashRegisterTypes';

const initialState = {
  cashRegisters: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CASH_REGISTERS:
      return {
        ...state,
        cashRegisters: action.payload,
      };
    case GET_OPEN_CASH_REGISTER:
      return {
        ...state,
        current: action.payload,
      };
    case CLOSE_CASH_REGISTER:
      return {
        ...state,
        current: null,
      };
    case GET_CASH_REGISTER:
      return {
        ...state,
        current: action.payload,
      };
    case CASH_REGISTER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
