import {
  ADD_PAYMENT_TYPE,
  DELETE_PAYMENT_TYPE,
  GET_PAYMENT_TYPE,
  GET_PAYMENT_TYPES,
  PAYMENT_TYPE_ERROR,
  UPDATE_PAYMENT_TYPE,
} from './paymentTypeTypes';

const initialState = {
  paymentTypes: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PAYMENT_TYPE:
      return {
        ...state,
        paymentTypes: {
          ...state.paymentTypes,
          data:
            state.paymentTypes !== null
              ? state.paymentTypes.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_PAYMENT_TYPE:
      return {
        ...state,
        paymentTypes: {
          ...state.paymentTypes,
          data:
            state.paymentTypes !== null
              ? state.paymentTypes.data.map((paymentType) =>
                  paymentType.id === action.payload.id
                    ? action.payload
                    : paymentType
                )
              : [action.payload],
        },
      };
    case GET_PAYMENT_TYPES:
      return {
        ...state,
        paymentTypes: action.payload,
      };
    case GET_PAYMENT_TYPE:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_PAYMENT_TYPE:
      return {
        ...state,
        paymentTypes: {
          ...state.paymentTypes,
          data:
            state.paymentTypes &&
            state.paymentTypes.data &&
            state.paymentTypes.data.filter(
              (paymentType) => paymentType.id !== action.payload
            ),
        },
      };
    case PAYMENT_TYPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
