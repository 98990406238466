import {
  ADD_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCT,
  DELETE_PRODUCT,
  PRODUCT_ERROR,
  FILTER_PRODUCTS,
  PRODUCT_LOADING,
} from './productTypes';

const initialState = {
  filter: null,
  products: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          data:
            state.products !== null
              ? state.products.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          data:
            state.products !== null
              ? state.products.data.map((product) =>
                  product.id === action.payload.id ? action.payload : product
                )
              : [action.payload],
        },
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCT:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          data:
            state.products &&
            state.products.data &&
            state.products.data.filter(
              (product) => product.id !== action.payload
            ),
        },
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FILTER_PRODUCTS:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};
