import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import AddIcon from '@mui/icons-material/Add';
import { INVENTORY_COUNT_TYPE } from 'redux/inventory-count/inventoryCountTypes';
import { capitalizeFirstLetter } from 'utils/functions';
import InventoryCountItems from './InventoryCountItems';
import {
  getInventoryCountById,
  addInventoryCount,
  updateInventoryCount,
} from 'redux/inventory-count/inventoryCountActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  item: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const CreateEditInventoryCount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newCount = useSelector((state) => state.inventoryCount.new);
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.inventoryCount.current);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getInventoryCountById(id));
    }
  }, [id]);

  const [form, setForm] = useState({
    notes: '',
    type: INVENTORY_COUNT_TYPE.PARTIAL,
  });

  useEffect(() => {
    if (current) {
      form.notes = current.notes;
      form.type = current.type;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const [isCount, setIsCount] = useState(false);

  const handleSave = (count = false) => {
    setIsCount(count);
    dispatch(
      current
        ? updateInventoryCount(
            { ...form, products: newCount.items },
            current.id
          )
        : addInventoryCount({ ...form, products: newCount.items })
    );
  };

  useEffect(() => {
    if (notification && notification.type === 'success' && notification.id) {
      if (isCount) {
        navigate(`/admin/inventory/inventory-count/count/${notification.id}`);
      } else {
        navigate(`/admin/inventory/inventory-count/detail/${notification.id}`);
      }
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <Stack direction="column" spacing={2}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="notes"
              label="Notes"
              value={form.notes}
              multiline
              name="notes"
              onChange={onChange}
              autoComplete="notes"
            />
            <FormControl component="fieldset">
              <FormLabel component="legend">Type</FormLabel>
              <RadioGroup
                row
                aria-label="type"
                defaultValue={INVENTORY_COUNT_TYPE.PARTIAL}
                value={form.type}
                onChange={(e) => setForm({ ...form, type: e.target.value })}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value={INVENTORY_COUNT_TYPE.PARTIAL}
                  control={<Radio />}
                  label={capitalizeFirstLetter(INVENTORY_COUNT_TYPE.PARTIAL)}
                />
                <FormControlLabel
                  value={INVENTORY_COUNT_TYPE.FULL}
                  control={<Radio />}
                  label={capitalizeFirstLetter(INVENTORY_COUNT_TYPE.FULL)}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <InventoryCountItems />
      </Grid>
      <Grid item xs={12}>
        <Paper style={{ padding: '1rem', marginTop: '1rem' }}>
          <Stack
            direction="row"
            spacing={2}
            stifyContent="flex-end"
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Button
              onClick={() => navigate('/admin/inventory/inventory-count')}
              variant="outlined"
            >
              Cancel
            </Button>
            <Button variant="outlined" onClick={() => handleSave()}>
              Save
            </Button>
            {!id && (
              <Button
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
                onClick={() => handleSave('count')}
              >
                Save and Count
              </Button>
            )}
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {loading && <LinearProgress color="secondary" />}
      </Grid>
    </Grid>
  );
};

export default CreateEditInventoryCount;
