import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import Container from '@mui/material/Container';
import { useQuery } from 'utils/hooks';
import { activateUser } from 'redux/auth/authActions';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" to="https://sturr.co/">
        Sturr
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.background.default,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(4, 2),
  },
  loading: {
    height: '100vh',
    width: '100%',
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    width: '100vw',
  },
}));

const Home = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);
  const query = useQuery();

  const [params, setParams] = useState(null);

  useEffect(() => {
    if (
      query &&
      query.get('action') != null &&
      query.get('action') === 'activate'
    ) {
      dispatch(activateUser(query.get('tk')));
    }
    // eslint-disable-next-line
  }, [query]);

  if (loading && !store) {
    return (
      <div
        className={classes.loading}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            padding: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h3">
            Please wait
          </Typography>
          <CircularProgress size={142} />
        </Paper>
      </div>
    );
  } else if (!store && !loading) {
    return (
      <div
        className={classes.loading}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Paper
          style={{
            padding: '5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h3">
            Store not found
          </Typography>
          <Button
            component={Link}
            color="primary"
            to={`sturr.co`}
            variant="contained"
          >
            Go to Home Page
          </Button>
        </Paper>
      </div>
    );
  } else {
    return (
      <Box className={classes.image} sx={{ py: 8, mx: 'auto' }}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Paper style={{ padding: '2rem' }}>
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Welcome to Sturr Business
              </Typography>
              <Stack direction="column" sx={{ mt: 2, width: 1 / 2 }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  to="/signin"
                  component={Link}
                  className={classes.submit}
                >
                  Sign In
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  to="/signup"
                  component={Link}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  style={{ marginTop: '2rem' }}
                >
                  Sign Up
                </Button>
              </Stack>
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </Paper>
        </Container>
      </Box>
    );
  }
};

export default Home;
