import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { getTaxes } from '../../redux/tax/taxActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const SettingsTaxes = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const loading = useSelector((state) => state.notification.loading);
  const taxes = useSelector((state) => state.tax.taxes);

  useEffect(() => {
    dispatch(getTaxes());
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'rate',
      label: 'Rate',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography color="primary" variant="body1">
              {value} %
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    serverSide: true,
    rowHover: true,
    fixedHeader: false,
    selectableRows: 'none',
    count: taxes && taxes.count,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/taxes/update/${rowData[0]}`);
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (taxes) {
      var finalData =
        taxes &&
        taxes.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [taxes]);

  if (!taxes) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          {taxes.data.length > 0 ? (
            <>
              <Grid item xs={12}>
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  to={'/admin/taxes/add'}
                  startIcon={<AddIcon />}
                >
                  Add Tax
                </Button>
              </Grid>
              <Grid item xs={12}>
                {data && (
                  <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                  />
                )}
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Avatar className={classes.avatar}>
                    <AccountBalanceIcon fontSize="large" />
                  </Avatar>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4">Taxes</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Taxes may apply to specific items and be added when selling
                    those.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    component={Link}
                    variant="contained"
                    color="primary"
                    to={'/admin/taxes/add'}
                    startIcon={<AddIcon />}
                  >
                    Add Tax
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    );
  }
};

export default SettingsTaxes;
