import {
  ADD_TAX,
  DELETE_TAX,
  GET_TAX,
  GET_TAXES,
  TAX_ERROR,
  UPDATE_TAX,
} from './taxTypes';

const initialState = {
  taxes: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TAX:
      return {
        ...state,
        taxes: {
          ...state.taxes,
          data:
            state.taxes !== null
              ? state.taxes.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_TAX:
      return {
        ...state,
        taxes: {
          ...state.taxes,
          data:
            state.taxes !== null
              ? state.taxes.data.map((tax) =>
                  tax.id === action.payload.id ? action.payload : tax
                )
              : [action.payload],
        },
      };
    case GET_TAXES:
      return {
        ...state,
        taxes: action.payload,
      };
    case GET_TAX:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_TAX:
      return {
        ...state,
        taxes: {
          ...state.taxes,
          data:
            state.taxes &&
            state.taxes.data &&
            state.taxes.data.filter((tax) => tax.id !== action.payload),
        },
      };
    case TAX_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
