import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENT,
  GET_STOCK_ADJUSTMENTS,
  GET_STOCK_ADJUSTMENT_PRODUCTS,
  STOCK_ADJUSTMENT_ERROR,
} from './stockAdjustmentTypes';

const url = process.env.REACT_APP_API_URL + 'stock-adjustments';

// Add Stock Adjustment
export const addStockAdjustment = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_STOCK_ADJUSTMENT, payload: res.data });
    // Set Register Success Notification

    const stockAdjustment = res.data;
    const notification = {
      message: `Added Stock Adjustment:  ${stockAdjustment.id} successfully`,
      type: 'success',
      id: stockAdjustment.id,
    };
    dispatch({ type: ADD_STOCK_ADJUSTMENT, payload: stockAdjustment });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STOCK_ADJUSTMENT_ERROR,
      payload: 'Failed to Add Stock Adjustment',
    });
  }
};

// Get Stock Adjustments
export const getStockAdjustments = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const stockAdjustments = res.data;
    dispatch({ type: GET_STOCK_ADJUSTMENTS, payload: stockAdjustments });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    console.error(e);
    dispatch({ type: CLEAR_LOADING });
  }
};

// Get Stock Adjustments Products
export const getStockAdjustmentProducts = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/products`, {
      params: filter,
    });
    const stockAdjustmentsProducts = res.data;
    dispatch({
      type: GET_STOCK_ADJUSTMENT_PRODUCTS,
      payload: stockAdjustmentsProducts,
    });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    console.error(e);
    dispatch({ type: CLEAR_LOADING });
  }
};

// Get Stock Adjustment By Id
export const getStockAdjustmentById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const stockAdjustment = res.data;
    dispatch({ type: GET_STOCK_ADJUSTMENT, payload: stockAdjustment });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STOCK_ADJUSTMENT_ERROR,
      payload: 'Failed to Get Stock Adjustment',
    });
  }
};
