import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { makeStyles, useTheme } from '@mui/styles';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import SubjectIcon from '@mui/icons-material/Subject';
import { useSelector, useDispatch } from 'react-redux';
import { addMessageTemplate } from 'redux/message-template/messageTemplateActions';
import { MESSAGE_TYPES } from 'redux/message-template/messageTemplateTypes';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const AddMessageTemplate = () => {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);

  const page = useSelector((state) => state.page.page);

  const [form, setForm] = useState({
    name: '',
    mode: 'sms',
    content: '',
    subject: '',
    type: MESSAGE_TYPES.NONE,
    typeDefault: false,
  });

  const onChange = (e) => {
    if (e.target.value !== 'None') {
      setForm({ ...form, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Add Message Template') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate('/superadmin/message-templates');
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    form.content = htmlContent;

    dispatch(addMessageTemplate(form));
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlContent, setHtmlContent] = useState('');

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    if (form) {
      generateSuggestions();
    }
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    if (editorState) {
      const html = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ).replace(/(href=".*?")/g, '');

      setHtmlContent(html);
    }
  }, [editorState]);

  // Suggestions
  const [suggestions, setSuggestions] = useState([]);

  const generateSuggestions = () => {
    let suggestions = [
      { text: 'First Name', value: `customer.firstName` },
      { text: 'Last Name', value: `customer.lastName` },
      { text: 'Mobile Number', value: `customer.mobileNumber` },
      { text: 'Password Reset Link', value: `customer.passwordResetLink` },
      { text: 'User Activate Link', value: `customer.activateLink` },
    ];
    suggestions = suggestions.concat(getOptionalSuggestions());
    setSuggestions(suggestions);
  };

  const getOptionalSuggestions = () => {
    let optionalSuggestions = [];
    // if (form.type === MESSAGE_TYPES.INVITE) {
    //   optionalSuggestions = optionalSuggestions.concat(
    //     {
    //       text: 'Invite Contact',
    //       value: 'invite.contact',
    //     },
    //     {
    //       text: 'Invite Link',
    //       value: 'invite.link',
    //     }
    //   );
    // }
    return optionalSuggestions;
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-mode-simple">
                  Select Mode
                </InputLabel>
                <Select
                  fullWidth
                  value={form.mode}
                  onChange={onChange}
                  name="mode"
                  inputProps={{
                    name: 'mode',
                    id: 'outlined-mode-simple',
                  }}
                >
                  <MenuItem value="sms">
                    <em>SMS</em>
                  </MenuItem>
                  <MenuItem value="email">
                    <em>Email</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-message-type-simple">
                  Select Message Type
                </InputLabel>

                <Select
                  fullWidth
                  value={form.type || MESSAGE_TYPES.NONE}
                  onChange={onChange}
                  name="type"
                  inputProps={{
                    name: 'type',
                    id: 'outlined-message-type-simple',
                  }}
                >
                  {Object.entries(MESSAGE_TYPES).map(([key, value]) => (
                    <MenuItem key={key} value={value}>
                      <em>{value.split('_').join(' ')}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                value={form.subject}
                name="subject"
                onChange={onChange}
                id="subject"
                label="Subject"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SubjectIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                onEditorStateChange={onEditorStateChange}
                placeholder="Enter Message Content"
                editorStyle={{
                  border: `1px solid ${theme.palette.primary.light}`,
                  padding: '1rem',
                  borderRadius: '5px',
                }}
                mention={{
                  separator: ' ',
                  trigger: '@',
                  suggestions,
                }}
                hashtag={{}}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            style={{ marginTop: '2rem' }}
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={
              !htmlContent ||
              !form.type ||
              !form.mode ||
              !form.subject ||
              loading
            }
          >
            Add Message Template
          </Button>
        </Grid>
      </form>
    </Paper>
  );
};

export default AddMessageTemplate;
