import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';
import {
  addProduct,
  getProductById,
  updateProduct,
} from '../../redux/products/productActions';
import { PRODUCT_TYPE } from '../../redux/products/productTypes';
import SectionGeneral from './section/SectionGeneral';
import SectionInventory from './section/SectionInventory';
import SectionAttributes from './section/SectionAttributes';
import SectionPricing from './section/SectionPricing';
import SectionDiscount from './section/SectionDiscount';
import SectionRepresentation from './section/SectionRepresentation';
import SectionTax from './section/SectionTax';
import {
  addDemoProduct,
  getDemoProductById,
  updateDemoProduct,
} from '../../redux/products/demoProductActions';
import { getSuppliers } from '../../redux/suppliers/supplierActions';
import Loading from '../../components/layout/Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: theme.palette.grey[200],
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 14,
  },
}));

const AddEditProduct = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const current = useSelector((state) => state.product.current);
  const page = useSelector((state) => state.page.page);
  const [isDemoProduct, setIsDemoProduct] = useState(null);

  useEffect(() => {
    if (location) {
      setIsDemoProduct(location.pathname.includes('demodata'));
    }
  }, [location]);

  useEffect(() => {
    if (id && isDemoProduct !== null) {
      dispatch(isDemoProduct ? getDemoProductById(id) : getProductById(id));
    }
    // eslint-disable-next-line
  }, [id, isDemoProduct]);

  useEffect(() => {
    dispatch(getSuppliers());
  }, []);

  const [form, setForm] = useState({
    name: '',
    description: '',
    type: PRODUCT_TYPE.GOODS,
    image: '',
    quantity: 0,
    reorderLevel: 0,
    active: true,
    forSale: true,
    categoryId: '',
    subCategoryId: '',
    unitMeasure: '',
    unitValue: '',
    brand: '',
    size: '',
    style: '',
    color: '',
    stockStatus: '',
    sku: '',
    barcode: '',
    activeDiscount: '',
    discountAmount: '',
    discountPercent: null,
    buyingPrice: '',
    sellingPriceOption: '',
    retailPrice: '',
    minimumPrice: '',
    wholesalePrice: '',
    publishOnline: false,
    taxId: null,
    backOrders: true,
    trackStock: false,
    supplierId: null,
  });

  useEffect(() => {
    if (
      page &&
      (page.includes('Add Product') || page.includes('Edit Product')) &&
      notification &&
      notification.type === 'success' &&
      notification.id
    ) {
      navigate(`/admin/products/detail/${notification.id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  const [file, setFile] = useState(null);
  const updateImage = (file) => setFile(file);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    // TODO: Add File
    if (file) {
      formData.append('file', file);
    }

    if (current) {
      dispatch(
        isDemoProduct
          ? updateDemoProduct(formData, current.id)
          : updateProduct(formData, current.id)
      );
    } else {
      dispatch(isDemoProduct ? addDemoProduct(formData) : addProduct(formData));
    }
  };

  const updateForm = (newForm) => {
    // console.log(newForm);
    setForm({ ...form, ...newForm });
  };

  return (
    <div className={classes.root}>
      <Box sx={{ opacity: loading ? 100 : 0 }}>
        <LinearProgress variant="query" color={'primary'} />
      </Box>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <SectionGeneral
              updateForm={updateForm}
              isDemoProduct={isDemoProduct}
            />

            <SectionPricing updateForm={updateForm} />
            <SectionDiscount updateForm={updateForm} />
            {!isDemoProduct && <SectionTax updateForm={updateForm} />}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack direction="column" spacing={2}>
            <SectionAttributes updateForm={updateForm} />
            <SectionInventory updateForm={updateForm} />
            <SectionRepresentation
              updateForm={updateForm}
              updateImage={updateImage}
            />
          </Stack>
        </Grid>
      </Grid>

      <Button
        sx={{ mt: 2 }}
        type="submit"
        fullWidth
        variant="contained"
        size={'large'}
        color="primary"
        onClick={handleSubmit}
        className={classes.submit}
        disabled={
          form.name.toString().length === 0 ||
          form.retailPrice.toString().length === 0 ||
          form.loading
        }
      >
        Save
      </Button>
    </div>
  );
};

export default AddEditProduct;
