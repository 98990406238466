import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  MESSAGE_TEMPLATE_ERROR,
  GET_MESSAGE_TEMPLATES,
  GET_MESSAGE_TEMPLATE,
  ADD_MESSAGE_TEMPLATE,
  UPDATE_MESSAGE_TEMPLATE,
} from './messageTemplateTypes';

const url = process.env.REACT_APP_API_URL + 'message-template';

let notification;

// Create Message Template
export const addMessageTemplate = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: ADD_MESSAGE_TEMPLATE, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const messageTemplate = res.data;
    notification = {
      message: `Message Template Created ${messageTemplate.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_TEMPLATE_ERROR,
      payload: 'Failed to Create Message Template',
    });
  }
};

// Create Message Template Admin
export const addMessageTemplateAdmin = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/admin`, data);

    dispatch({ type: ADD_MESSAGE_TEMPLATE, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const messageTemplate = res.data;
    notification = {
      message: `Message Template Created ${messageTemplate.name} successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_TEMPLATE_ERROR,
      payload: 'Failed to Create Message Template',
    });
  }
};

// Send Message Template
export const sendMessageTemplate = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/${id}/send`);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const result = res.data;
    notification = {
      message: result.message,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_TEMPLATE_ERROR,
      payload: 'Failed to Send Message Template',
    });
  }
};

// Update Message Template Admin
export const updateMessageTemplateAdmin = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/admin/${id}`, data);

    dispatch({ type: UPDATE_MESSAGE_TEMPLATE, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_UNIT, payload: res.data });
    // Set Register Success Notification

    const messageGroup = res.data;
    const notification = {
      message: `Updated Message Template ${messageGroup.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_TEMPLATE_ERROR,
      payload: 'Failed to Update Message Error',
    });
  }
};

export const updateMessageTemplate = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: UPDATE_MESSAGE_TEMPLATE, payload: res.data });

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_UNIT, payload: res.data });
    // Set Register Success Notification

    const messageGroup = res.data;
    const notification = {
      message: `Updated Message Template ${messageGroup.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_TEMPLATE_ERROR,
      payload: 'Failed to Update Message Error',
    });
  }
};

// Get Message Templates
export const getMessageTemplates = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const messageTemplates = res.data;
  dispatch({ type: GET_MESSAGE_TEMPLATES, payload: messageTemplates });
  dispatch({ type: CLEAR_LOADING });
  setTimeout(() => {
    dispatch({ type: CLEAR_NOTIFICATION });
  }, 2000);
};

// Get Message Template By ID
export const getMessageTemplateById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`, {});
    const messageTemplate = res.data;

    dispatch({ type: GET_MESSAGE_TEMPLATE, payload: messageTemplate });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_TEMPLATE_ERROR,
      payload: 'Failed to Get Message Templates ',
    });
  }
};

// Delete Message Template
export const deleteMessageTemplate = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.delete(`${url}/${id}`);

    const notification = {
      message: res.data.message,
      type: 'warning',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: MESSAGE_TEMPLATE_ERROR,
      payload: 'Failed to Delete Message Template',
    });
  }
};

export const updateMessageTemplateDefault =
  (id, messageTypeDefault) => async (dispatch) => {
    dispatch({ type: SET_LOADING });

    try {
      const res = await axios.put(`${url}/${id}/default`, {
        default: messageTypeDefault,
      });

      const notification = {
        message: res.data.message,
        type: 'info',
      };

      // dispatch({ type: UPDATE_MESSAGE_TEMPLATE_JOB, payload: { id, active } });

      dispatch({ type: SET_NOTIFICATION, payload: notification });
      dispatch({ type: CLEAR_LOADING });
      setTimeout(() => {
        dispatch({ type: CLEAR_NOTIFICATION });
      }, 2000);
    } catch (error) {
      dispatch({ type: CLEAR_LOADING });

      console.log(error);
      dispatch({
        type: MESSAGE_TEMPLATE_ERROR,
        payload: 'Failed to Update Message Template',
      });
    }
  };
