import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import InputAdornment from '@mui/material/InputAdornment';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { addTax, getTaxById, updateTax } from '../../redux/tax/taxActions';
import { TAX_OPTIONS } from '../../redux/tax/taxTypes';
import { toTitleCase } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    margin: theme.spacing(2),
    // flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  avatar: {
    color: '#FFF',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const AddUpdateTax = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const tax = useSelector((state) => state.tax.current);

  useEffect(() => {
    if (id) {
      dispatch(getTaxById(id));
    }
    // eslint-disable-next-line
  }, []);

  const [form, setForm] = useState({
    name: null,
    type: Object.keys(TAX_OPTIONS)[0],
    rate: null,
  });

  const onChange = (e) => {
    if (e.target.name === 'rate' && e.target.value.length > 2) {
      return;
    }
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(
    () => {
      if (tax) {
        setForm({ ...tax });
      }
    },
    // eslint-disable-next-line
    [tax]
  );

  useEffect(() => {
    if (
      page &&
      page.includes('Tax') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tax) {
      dispatch(updateTax(form, tax.id));
    } else {
      dispatch(addTax(form));
    }
  };

  if (id && !tax) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Grid container>
        <Grid item xs={12} md={6}>
          <Paper className={classes.root}>
            <form onSubmit={handleSubmit} className={classes.form} noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={4}
              >
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    variant="outlined"
                    required
                    fullWidth
                    onChange={onChange}
                    id="name"
                    value={form.name}
                    label="Name"
                    autoFocus
                  />
                </Grid>
                {/*  */}
                <Grid item xs={12}>
                  <TextField
                    name="rate"
                    variant="outlined"
                    required
                    fullWidth
                    type="number"
                    onChange={onChange}
                    id="rate"
                    value={form.rate}
                    label="Rate"
                    InputProps={{
                      maxLength: 2,
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 2 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel htmlFor={`outlined-tax-simple`}>
                      Select Type
                    </InputLabel>
                    <Select
                      fullWidth
                      value={form.type}
                      onChange={onChange}
                      name="type"
                      // labelWidth={labelWidth}
                    >
                      {Object.entries(TAX_OPTIONS).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          <em>{toTitleCase(value)}</em>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size={'large'}
                    color="primary"
                    className={classes.submit}
                    disabled={!form.name || loading || !form.rate}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  {loading && (
                    <LinearProgress variant="query" color="secondary" />
                  )}
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    );
  }
};

export default AddUpdateTax;
