import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import {
  ADD_INVENTORY_COUNT,
  DELETE_INVENTORY_COUNT,
  GET_INVENTORY_COUNT,
  GET_INVENTORY_COUNTS,
  INVENTORY_COUNT_ERROR,
  UPDATE_INVENTORY_COUNT,
} from './inventoryCountTypes';

const url = process.env.REACT_APP_API_URL + 'inventory-count';

// Add Inventory Count
export const addInventoryCount = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: ADD_INVENTORY_COUNT, payload: res.data });
    // Set Register Success Notification

    const inventoryCount = res.data;
    const notification = {
      message: `Added Inventory Count:  ${inventoryCount.id} successfully`,
      type: 'success',
      id: inventoryCount.id,
    };
    dispatch({ type: ADD_INVENTORY_COUNT, payload: inventoryCount });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVENTORY_COUNT_ERROR,
      payload: 'Failed to Add Inventory Count',
    });
  }
};

// Update Inventory Count
export const updateInventoryCount = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const inventoryCount = res.data;
    const notification = {
      message: `Updated Purchase Order:  ${inventoryCount.id} successfully`,
      type: 'success',
      id: inventoryCount.id,
    };

    dispatch({ type: UPDATE_INVENTORY_COUNT, payload: inventoryCount });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVENTORY_COUNT_ERROR,
      payload: 'Failed to Update Inventory Count',
    });
  }
};

// Get Inventory Counts
export const getInventoryCounts = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}`, {
      params: filter,
    });
    const inventoryCounts = res.data;
    dispatch({ type: GET_INVENTORY_COUNTS, payload: inventoryCounts });
    dispatch({ type: CLEAR_LOADING });
  } catch (e) {
    console.error(e);
    dispatch({ type: CLEAR_LOADING });
  }
};

// Get Inventory Count By Id
export const getInventoryCountById = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const inventoryCount = res.data;
    dispatch({ type: GET_INVENTORY_COUNT, payload: inventoryCount });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVENTORY_COUNT_ERROR,
      payload: 'Failed to Get Inventory Count',
    });
  }
};

// Delete Inventory Count By Id
export const deleteInventoryCount = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    dispatch({ type: DELETE_INVENTORY_COUNT, payload: id });

    const notification = {
      message: `Deleted Inventory Count successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVENTORY_COUNT_ERROR,
      payload: 'Failed to Delete Inventory Count',
    });
  }
};

// Count Inventory
export const makeInventoryCount = (id, data) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.put(`${url}/${id}/count`, data);
    dispatch({ type: GET_INVENTORY_COUNT, payload: res.data });

    const notification = {
      message: `Count Submitted for ${id}`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: INVENTORY_COUNT_ERROR,
      payload: 'Failed to count Inventory Count',
    });
  }
};
