import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import { HiAdjustments } from 'react-icons/hi';
import { format, parseISO } from 'date-fns/esm';
import { getStockAdjustments } from 'redux/stock-adjustment/stockAdjustmentActions';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { STOCK_ADJUSTMENT_TYPE } from 'redux/stock-adjustment/stockAdjustmentTypes';
import { capitalizeFirstLetter } from 'utils/functions';
import CustomSearchRender from 'utils/CustomSearchRender';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const StockAdjustmentHome = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const loading = useSelector((state) => state.notification.loading);
  const stockAdjustments = useSelector(
    (state) => state.stockAdjustment.stockAdjustments
  );
  const store = useSelector((state) => state.auth.store);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    type: null,
  });

  useEffect(() => {
    dispatch(getStockAdjustments(filter));
    // eslint-disable-next-line
  }, [filter]);

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'Adjustment #',
      options: {
        display: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Date',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        sortOrder: getSortOrder('createdAt'),
        customBodyRender: function renderCreatedAtDate(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        sortOrder: getSortOrder('type'),
        customBodyRender: function renderCompletedDate(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'totalQuantity',
      label: 'Quantity',
      options: {
        display: true,
        print: true,
        download: true,
        sort: false,
        viewColumns: true,
        customBodyRender: function renderCompletedDate(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    serverSide: true,
    rowHover: true,
    fixedHeader: false,
    selectableRows: 'none',
    count: stockAdjustments && stockAdjustments.count,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/inventory/adjustments/detail/${rowData[0]}`);
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (stockAdjustments) {
      var finalData =
        stockAdjustments &&
        stockAdjustments.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [stockAdjustments]);

  if (!stockAdjustments) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Stack direction="column" spacing={2}>
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Stock Adjustments</strong>
              </Typography>
              <Typography variant="body1">
                Increase and decrease stock levels for received items, damages
                and loss.
              </Typography>
            </Stack>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={'/admin/inventory/adjustments/add'}
              startIcon={<AddIcon />}
            >
              Add Stock Adjustment
            </Button>
          </Stack>

          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </Stack>
      </Paper>
    );
  }
};

export default StockAdjustmentHome;
