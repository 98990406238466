export const GET_MESSAGE_TEMPLATES = 'GET_MESSAGE_TEMPLATES';
export const GET_MESSAGE_TEMPLATE = 'GET_MESSAGE_TEMPLATE';
export const REMOVE_MESSAGE_TEMPLATE = 'REMOVE_MESSAGE_TEMPLATE';
export const FILTER_MESSAGE_TEMPLATE = 'FILTER_MESSAGE_TEMPLATE';
export const ADD_MESSAGE_TEMPLATE = 'ADD_MESSAGE_TEMPLATE';
export const UPDATE_MESSAGE_TEMPLATE = 'UPDATE_MESSAGE_TEMPLATE';
export const UPDATE_MESSAGE_TEMPLATE_JOB = 'UPDATE_MESSAGE_TEMPLATE_JOB';
export const MESSAGE_TEMPLATE_ERROR = 'MESSAGE_TEMPLATE_ERROR';

// Message Types
export const MESSAGE_TYPES = {
  NONE: 'None',
  WELCOME: 'Welcome',
  PASSWORD_RESET: 'Password Reset',
};
export const MESSAGE_MODE = {
  SMS: 'sms',
  EMAIL: 'email',
};
