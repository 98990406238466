import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import { CssBaseline } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import Brightness2Icon from '@mui/icons-material/Brightness2';
import SuperAdminSidebar from './SuperAdminSidebar';
import { logOut } from '../../../redux/auth/authActions';
import tinycolor from 'tinycolor2';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      display: 'none',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  account: {
    display: 'flex',
  },
  nav: {
    backgroundColor: tinycolor(theme.palette.primary.main)
      .lighten(5)
      .toString(),
    borderRadius: '2%',
    '&:hover': {
      backgroundColor: tinycolor(theme.palette.primary.main)
        .lighten(10)
        .toString(),
    },
  },
  appBar: {
    // marginLeft: drawerWidth,
    // [theme.breakpoints.up('sm')]: {
    //   width: `calc(100% - ${drawerWidth}px)`
    // }
  },
}));

const SuperAdminNavbar = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const theme = useTheme();

  const page = useSelector((state) => state.page.page);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  useEffect(() => {}, [page]);

  const logout = (e) => {
    dispatch(logOut());
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={logout}>Log out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={logout}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-logout-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Log out</p>
      </MenuItem>
    </Menu>
  );

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const closeDrawer = () => {
    setMobileOpen(false);
  };

  const [darkMode, setDarkMode] = useState(false);

  //  Beginning of navbar check dark mode
  useEffect(() => {
    const dkMode = localStorage.getItem('darkMode');

    if (dkMode === 'true') {
      setDarkMode(true);
    } else {
      setDarkMode(false);
    }
  }, []);

  const handleDarkMode = () => {
    localStorage.setItem('darkMode', !darkMode);
    // Reload Page to reflect changes
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <div className={classes.grow}>
        <CssBaseline />

        <AppBar className={classes.appBar} position="fixed">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {page ? page : 'Sturr Admin'}
            </Typography>
            <div className={classes.search} hidden>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {
                <ListItem
                  alignItems="center"
                  button
                  dense
                  className={classes.nav}
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <ListItemAvatar>
                    <Avatar variant="rounded">S</Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={'Superadmin'}
                    style={{ marginRight: '0.5em' }}
                  />
                  <ListItemIcon style={{ marginRight: '-2em' }}>
                    <KeyboardArrowDownIcon
                      htmlColor={theme.palette.common.white}
                    />
                  </ListItemIcon>
                </ListItem>
              }

              <IconButton
                onClick={handleDarkMode}
                aria-label="Dark Mode"
                color="inherit"
              >
                {darkMode ? <Brightness5Icon /> : <Brightness2Icon />}
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <ListItem
                alignItems="center"
                button
                dense
                className={classes.nav}
                color="inherit"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <ListItemAvatar>
                  <Avatar variant="rounded">S</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={'Superadmin'}
                  style={{ marginRight: '0.5em' }}
                />
                <ListItemIcon style={{ marginRight: '-2em' }}>
                  <KeyboardArrowDownIcon
                    htmlColor={theme.palette.common.white}
                  />
                </ListItemIcon>
              </ListItem>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </div>

      <SuperAdminSidebar
        selected={page}
        mobileOpen={mobileOpen}
        closeDrawer={closeDrawer}
      />
    </div>
  );
};

export default SuperAdminNavbar;
