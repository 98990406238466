import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles, withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slide from '@mui/material/Slide';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { addOrder, getOrderReceiptById } from 'redux/orders/orderActions';
import { NumberFormatCustom, numberFormatText } from 'utils/functions';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const CounterConfirm = ({ currentOrder, handleCloseConfirm, openConfirm }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const orders = useSelector((state) => state.order.orders);
  const paymentTypes = useSelector((state) => state.paymentType.paymentTypes);
  const store = useSelector((state) => state.auth.store);

  const [form, setForm] = useState({
    paymentAmount: 0,
    paymentTypeId: '',
    paymentTypeNotes: '',
    downloadReceipt: false,
    sendCustomerReceipt: false,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const submitOrder = () => {
    dispatch(
      addOrder({
        ...currentOrder,
        ...form,
      })
    );
  };

  // Set Default Payment Type ID
  useEffect(
    () => {
      if (paymentTypes && paymentTypes.count > 0) {
        setForm({
          ...form,
          paymentTypeId: paymentTypes.data[0].id,
        });
      }
    },
    // eslint-disable-next-line
    [paymentTypes]
  );

  useEffect(() => {
    if (currentOrder) {
      const orderAmount =
        currentOrder.subTotal +
        currentOrder.taxTotal -
        currentOrder.discountTotal;
      setForm({
        ...form,
        amount: orderAmount,
        paymentAmount: orderAmount,
      });
    }
    // eslint-disable-next-line
  }, [currentOrder]);

  useEffect(() => {
    if (
      page &&
      page.includes('Counter') &&
      notification &&
      notification.type === 'success' &&
      notification.id != null
    ) {
      if (form.downloadReceipt) {
        dispatch(getOrderReceiptById(notification.id));
        // handleCloseConfirm();
      } else {
        handleCloseConfirm();
      }
    }
    // eslint-disable-next-line
  }, [notification]);

  if (loading) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        open={openConfirm}
        maxWidth="xl"
        fullWidth
        onClose={handleCloseConfirm}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div style={{ display: 'flex' }}>
                <IconButton onClick={handleCloseConfirm}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="h4">Complete Sale</Typography>
              </div>
              <TableContainer elevation={0} component={Paper}>
                <Table aria-label="simple table">
                  <TableBody>
                    {currentOrder.orderItems &&
                      currentOrder.orderItems.map((orderItem, index) => (
                        <TableRow key={index}>
                          <TableCell>{orderItem.quantity}</TableCell>
                          <TableCell>{orderItem.productName}</TableCell>
                          <TableCell>
                            {' '}
                            {numberFormatText(orderItem.amount, store.currency)}
                          </TableCell>
                        </TableRow>
                      ))}
                    <StyledTableRow>
                      <StyledTableCell />
                      <StyledTableCell>Sub Total</StyledTableCell>
                      <StyledTableCell>
                        {numberFormatText(
                          currentOrder.subTotal,
                          store.currency
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell>Discount Total</TableCell>
                      <TableCell>
                        {numberFormatText(
                          currentOrder.discountTotal,
                          store.currency
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell>Tax Total</TableCell>
                      <TableCell>
                        {numberFormatText(
                          currentOrder.taxTotal,
                          store.currency
                        )}
                      </TableCell>
                    </TableRow>
                    <StyledTableRow>
                      <StyledTableCell />
                      <StyledTableCell>Sale Total</StyledTableCell>
                      <StyledTableCell>
                        {numberFormatText(form.amount, store.currency)}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6}>
              <Paper style={{ padding: '2rem' }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="h3">Amount to Pay</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      variant="outlined"
                      value={form.paymentAmount}
                      onChange={onChange}
                      name="paymentAmount"
                      helperText="Edit to make a partial payment"
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        startAdornment: (
                          <InputAdornment position="start">
                            {store.currency}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      multiline
                      value={form.paymentNotes}
                      onChange={onChange}
                      id="notes"
                      name="paymentNotes"
                      label="Type notes about this payment"
                    />
                  </Grid>
                  <Grid item xs={12} style={{ marginTop: '2rem' }}>
                    {/* Payment Types */}
                    {paymentTypes && (
                      <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor={`outlined-payment-type-simple`}>
                          Select Payment Type
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="outlined-payment-type-simple"
                          value={form.paymentTypeId}
                          onChange={onChange}
                          name="paymentTypeId"
                          label="Select Payment Type"
                        >
                          {paymentTypes.data.map((paymentType) => (
                            <MenuItem
                              key={paymentType.id}
                              value={paymentType.id}
                            >
                              <em>{paymentType.name}</em>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {currentOrder && currentOrder.customerId && (
                    <Grid item xs={12}>
                      <FormControlLabel
                        label={'Send Customer Receipt'}
                        value={form.sendCustomerReceipt}
                        control={
                          <CheckBox
                            color="primary"
                            value={form.sendCustomerReceipt}
                            checked={form.sendCustomerReceipt}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                sendCustomerReceipt: e.target.checked,
                              })
                            }
                          />
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <FormControlLabel
                      label={'Download Receipt'}
                      value={form.downloadReceipt}
                      control={
                        <CheckBox
                          color="primary"
                          value={form.downloadReceipt}
                          checked={form.downloadReceipt}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              downloadReceipt: e.target.checked,
                            })
                          }
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={loading && form.paymentTypeId.length === 0}
                      size="large"
                      style={{ padding: '1rem' }}
                      onClick={() => submitOrder()}
                    >
                      Pay
                    </Button>
                    {loading && (
                      <LinearProgress
                        size={24}
                        variant="query"
                        color="secondary"
                      />
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <div className={classes.root}></div>
        </DialogContent>
      </Dialog>
    );
  }
};

export default CounterConfirm;
