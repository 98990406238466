import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import TagsInput from 'components/TagsInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { sendEmailReceipt } from 'redux/orders/orderActions';

const SalesEmailReceiptDialog = ({
  handleCloseEmailDialog,
  openSendEmailDialog,
}) => {
  const dispatch = useDispatch();

  const currentOrder = useSelector((state) => state.order.current);

  useEffect(() => {
    if (currentOrder && currentOrder.customerEmail.length > 0) {
      setEmail(currentOrder.customerEmail);
    }
    // eslint-disable-next-line
  }, [currentOrder]);

  const [email, setEmail] = useState('');
  const [ccEmails, setCcEmails] = useState([]);

  const handleSendEmailReceipt = () => {
    ccEmails.unshift(email);
    dispatch(sendEmailReceipt(ccEmails, currentOrder.id));
    handleCloseEmailDialog();
  };

  return (
    <div>
      <Dialog
        open={openSendEmailDialog}
        onClose={handleCloseEmailDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Email Receipt</DialogTitle>
        <DialogContent>
          <DialogContentText>Send Customer Email Reeceipt</DialogContentText>
          <Stack direction="column" spacing={2}>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email Address"
              type="email"
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TagsInput
              selectedTags={(ccEmails) => {
                setCcEmails(ccEmails);
              }}
              fullWidth
              variant="outlined"
              id="cc"
              name="cc"
              placeholder="Enter Emails to CC"
              label="CC"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={email.length === 0}
            onClick={handleSendEmailReceipt}
            color="primary"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SalesEmailReceiptDialog;
