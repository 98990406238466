import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { useParams, useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import { getSuppliers } from 'redux/suppliers/supplierActions';
import PurchaseOrderItems from './PurchaseOrderItems';
import {
  addPurchaseOrder,
  getPurchaseOrderById,
  updatePurchaseOrder,
} from 'redux/purchase-orders/purchaseOrderActions';
import { PURCHASE_ORDER_STATUS } from 'redux/purchase-orders/purchaseOrderTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  item: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const AddEditPurchaseOrder = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const newPurchaseOrder = useSelector((state) => state.purchaseOrder.new);
  const current = useSelector((state) => state.purchaseOrder.current);
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const suppliers = useSelector((state) => state.supplier.suppliers);

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getPurchaseOrderById(id));
    }
  }, [id]);

  const [form, setForm] = useState({
    orderDate: new Date(),
    expectedDate: null,
    notes: '',
    supplierId: '',
    storeId: '',
  });

  useEffect(() => {
    if (current) {
      form.notes = current.notes;
      form.orderDate = current.orderDate;
      form.expectedDate = current.expectedDate;
      form.supplierId = current.supplierId;
      setForm(form);
    }
    // eslint-disable-next-line
  }, [current]);

  useEffect(() => {
    dispatch(getSuppliers());
    // eslint-disable-next-line
  }, []);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const createPurchaseOrder = (status = PURCHASE_ORDER_STATUS.PENDING) => {
    dispatch(
      addPurchaseOrder({ ...form, products: newPurchaseOrder.items, status })
    );
  };

  useEffect(() => {
    if (notification && notification.type === 'success' && notification.id) {
      navigate(`/admin/inventory/purchase-orders/detail/${notification.id}`);
    }
    // eslint-disable-next-line
  }, [notification]);

  if (!suppliers) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction="column" spacing={4}>
          <Paper className={classes.root}>
            <Stack container direction="column" spacing={2}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel htmlFor="outlined-supplier-simple">
                  Select Supplier
                </InputLabel>
                <Select
                  fullWidth
                  variant="outlined"
                  placeholder="Select Supplier"
                  label="Select Supplier"
                  value={form.supplierId}
                  onChange={onChange}
                  name="supplierId"
                  inputProps={{
                    name: 'supplierId',
                    id: 'outlined-supplier-simple',
                  }}
                >
                  {suppliers.data.map((supplier) => (
                    <MenuItem key={supplier.id} value={supplier.id}>
                      <em>{supplier.name}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Stack direction="row" sx={{ width: 1 }} spacing={2}>
                <Box sx={{ width: 1 / 2 }}>
                  <MobileDatePicker
                    fullWidth
                    label="Purchase Order Date"
                    sx={{ width: 1 }}
                    inputVariant="outlined"
                    value={form.orderDate || new Date()}
                    onChange={(date) => {
                      setForm({ ...form, orderDate: date });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
                <Box sx={{ width: 1 / 2 }}>
                  <MobileDatePicker
                    fullWidth
                    sx={{ width: 1 }}
                    label="Expected Date"
                    inputVariant="outlined"
                    value={form.expectedDate}
                    onChange={(date) => {
                      setForm({ ...form, expectedDate: date });
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Box>
              </Stack>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="notes"
                label="Notes"
                value={form.notes}
                multiline
                rows={2}
                name="notes"
                onChange={onChange}
                autoComplete="notes"
              />
            </Stack>
          </Paper>
          <PurchaseOrderItems />
          <Divider />
          <Stack
            container
            justifyContent="flex-end"
            alignItems="center"
            direction="row"
            sx={{ p: 2, bgColor: 'white' }}
            spacing={2}
          >
            <Button variant="outlined">Cancel</Button>
            {!current && (
              <Button
                variant="outlined"
                onClick={() => {
                  createPurchaseOrder(PURCHASE_ORDER_STATUS.DRAFT);
                }}
              >
                Save as Draft
              </Button>
            )}
            {!current && (
              <Button
                onClick={() => createPurchaseOrder()}
                variant="contained"
                color="primary"
                endIcon={<AddIcon />}
              >
                Add
              </Button>
            )}
            {current && (
              <Button
                onClick={() =>
                  dispatch(
                    updatePurchaseOrder(
                      {
                        ...form,
                        products: newPurchaseOrder.items,
                      },
                      id
                    )
                  )
                }
                variant="contained"
                color="primary"
                endIcon={<SaveIcon />}
              >
                Save
              </Button>
            )}
          </Stack>
          {loading && <LinearProgress color="secondary" />}
        </Stack>
      </LocalizationProvider>
    );
  }
};

export default AddEditPurchaseOrder;
