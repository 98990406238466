import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import { getDevices } from '../../redux/devices/deviceActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SettingsDevices = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const loading = useSelector((state) => state.notification.loading);
  const devices = useSelector((state) => state.device.devices);

  useEffect(() => {
    dispatch(getDevices());
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
      },
    },
  ];

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    serverSide: true,
    rowHover: true,
    fixedHeader: false,
    selectableRows: 'none',
    count: devices && devices.count,
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    elevation: 0,
    onRowClick: (rowData, rowMeta) => {
      navigate(`/admin/devices/update/${rowData[0]}`);
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (devices) {
      var finalData =
        devices &&
        devices.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [devices]);

  if (!data) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={'/admin/devices/add'}
              startIcon={<AddIcon />}
            >
              Add Device
            </Button>
          </Grid>
          <Grid item xs={12}>
            {data && (
              <MUIDataTable data={data} columns={columns} options={options} />
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
};

export default SettingsDevices;
