import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import SettingsTab from './SettingsTab';
import SettingsOptions from './SettingsOptions';
import SettingsStoreDetails from './SettingsStoreDetails';
import SettingsPaymentTypes from './SettingsPaymentTypes';
import SettingsTaxes from './SettingsTaxes';
import SettingsDevices from './SettingsDevices';
import SettingsAccountSettings from './SettingsAccountSettings';
import SettingsBilling from './SettingsBilling';
import SettingsBillingPayments from './SettingsBillingPayments';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(2),
  },
  card: {
    padding: theme.spacing(1),
  },
  avatar: {
    width: theme.spacing(25),
    height: theme.spacing(25),
  },
  contentColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labelBadge: {
    background: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: '4px',
    color: '#fff',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'center',
  },
}));
const SettingsHome = (props) => {
  const classes = useStyles();

  const current = useSelector((state) => state.auth.current);

  if (!current) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <SettingsTab {...props} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Routes>
              <Route exact path="general" element={<SettingsStoreDetails />} />
              <Route exact path="options" element={<SettingsOptions />} />
              <Route
                exact
                path="payment-types"
                element={<SettingsPaymentTypes />}
              />
              <Route exact path="taxes" element={<SettingsTaxes />} />
              <Route exact path="devices" element={<SettingsDevices />} />
              <Route exact path="billing" element={<SettingsBilling />} />
              <Route
                exact
                path="billing/payments"
                element={<SettingsBillingPayments />}
              />
              <Route
                exact
                path="account/settings"
                element={<SettingsAccountSettings />}
              />
            </Routes>
          </Grid>
        </Grid>
      </div>
    );
  }
};

export default SettingsHome;
