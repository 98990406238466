export const GET_STOCK_ADJUSTMENT = 'GET_STOCK_ADJUSTMENT';
export const GET_STOCK_ADJUSTMENTS = 'GET_STOCK_ADJUSTMENTS';
export const ADD_STOCK_ADJUSTMENT = 'ADD_STOCK_ADJUSTMENT';
export const STOCK_ADJUSTMENT_ERROR = 'STOCK_ADJUSTMENT_ERROR';
export const SET_STOCK_ADJUSTMENT = 'SET_STOCK_ADJUSTMENT';
export const DELETE_STOCK_ADJUSTMENT_ITEM = 'DELETE_STOCK_ADJUSTMENT_ITEM';
export const ADD_STOCK_ADJUSTMENT_ITEM = 'ADD_STOCK_ADJUSTMENT_ITEM';
export const UPDATE_STOCK_ADJUSTMENT_ITEM = 'UPDATE_STOCK_ADJUSTMENT_ITEM';
export const CLEAR_STOCK_ADJUSTMENT_ITEMS = 'CLEAR_STOCK_ADJUSTMENT_ITEMS';
export const GET_STOCK_ADJUSTMENT_PRODUCTS = 'GET_STOCK_ADJUSTMENT_PRODUCTS';

export const STOCK_ADJUSTMENT_TYPE = {
  RECEIVE: 'RECEIVE',
  LOSS: 'LOSS',
};
