import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { getProducts } from 'redux/products/productActions';
import {
  ADD_STOCK_ADJUSTMENT_ITEM,
  DELETE_STOCK_ADJUSTMENT_ITEM,
  UPDATE_STOCK_ADJUSTMENT_ITEM,
} from 'redux/stock-adjustment/stockAdjustmentTypes';
import ProductItemSearch from 'components/product/ProductItemSearch';
import { STOCK_ADJUSTMENT_TYPE } from 'redux/stock-adjustment/stockAdjustmentTypes';
import { NumberFormatCustom } from 'utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const StockAdjustmentItems = ({ type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const loading = useSelector((state) => state.notification.loading);
  const products = useSelector((state) => state.product.products);
  const store = useSelector((state) => state.auth.store);
  // const loading = useSelector((state) => state.notification.loading);
  const current = useSelector((state) => state.stockAdjustment.current);

  useEffect(() => {
    dispatch(getProducts(filter));
    // eslint-disable-next-line
  }, []);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    showAll: true,
  });

  // useEffect(() => {
  //   if (products && (filter.showLowStockProducts || filter.supplierId)) {

  //   }
  //   // eslint-disable-next-line
  // }, [products]);

  const handleDeleteItem = (id) => {
    dispatch({
      type: DELETE_STOCK_ADJUSTMENT_ITEM,
      payload: id,
    });
  };

  if (!products) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Stack spacing={2} direction="row" justifyContent="space-between">
          <Typography variant="h5" color="textSecondary">
            Items
          </Typography>
        </Stack>

        {type === STOCK_ADJUSTMENT_TYPE.RECEIVE && <TableReceiveItems />}
        {type === STOCK_ADJUSTMENT_TYPE.LOSS && <TableLossItem />}
      </Paper>
    );
  }
};

const TableReceiveItems = () => {
  const dispatch = useDispatch();

  const newStockAdjustment = useSelector((state) => state.stockAdjustment.new);
  const handleRemoveProduct = (productId) => {
    dispatch({
      type: DELETE_STOCK_ADJUSTMENT_ITEM,
      payload: productId,
    });
  };
  const handleSelectProduct = (product) => {
    dispatch({
      type: ADD_STOCK_ADJUSTMENT_ITEM,
      payload: {
        productId: product.id,
        productName: product.name,
        cost: parseInt(product.price),
        inStock: parseInt(product.quantity),
        quantity: 0,
        stockAfter: parseInt(product.quantity),
      },
    });
  };
  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>Item</TableCell>
            <TableCell>In Stock</TableCell>
            <TableCell>Add Stock</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell>Stock After</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newStockAdjustment &&
            newStockAdjustment.items.map((item, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={4} component="th" scope="row">
                  {item.productName}
                </TableCell>
                <TableCell component="td" scope="row">
                  {item.inStock}
                </TableCell>
                <TableCell component="th" scope="row">
                  <TextField
                    variant="outlined"
                    name=""
                    value={item.quantity}
                    type="number"
                    inputProps={{ min: 0, style: { textAlign: 'right' } }}
                    onChange={(e) => {
                      const quantity =
                        e.target.value.length > 0
                          ? parseInt(e.target.value)
                          : 0;
                      dispatch({
                        type: UPDATE_STOCK_ADJUSTMENT_ITEM,
                        payload: {
                          ...item,
                          quantity,
                          stockAfter: item.inStock + quantity,
                        },
                      });
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <TextField
                    variant="outlined"
                    name=""
                    value={item.cost}
                    type="number"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={{ min: 0, style: { textAlign: 'right' } }}
                    onChange={(e) => {
                      const cost = e.target.value
                        ? parseInt(e.target.value)
                        : parseInt(item.quantity);
                      dispatch({
                        type: UPDATE_STOCK_ADJUSTMENT_ITEM,
                        payload: {
                          ...item,
                          cost,
                        },
                      });
                    }}
                  />
                </TableCell>

                <TableCell>
                  <strong>
                    {parseInt(item.inStock) + parseInt(item.quantity ?? 0)}
                  </strong>
                </TableCell>
                <TableCell>
                  <IconButton>
                    <DeleteIcon
                      onClick={() => handleRemoveProduct(item.productId)}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={9} component="th" scope="row">
              <ProductItemSearch handleSelectProduct={handleSelectProduct} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TableLossItem = () => {
  const dispatch = useDispatch();

  const newStockAdjustment = useSelector((state) => state.stockAdjustment.new);
  const handleRemoveProduct = (productId) => {
    dispatch({
      type: DELETE_STOCK_ADJUSTMENT_ITEM,
      payload: productId,
    });
  };
  const handleSelectProduct = (product) => {
    dispatch({
      type: ADD_STOCK_ADJUSTMENT_ITEM,
      payload: {
        productId: product.id,
        productName: product.name,
        cost: product.price,
        inStock: product.quantity,
        quantity: 0,
        stockAfter: product.quantity,
      },
    });
  };
  return (
    <TableContainer component={Box}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>Item</TableCell>
            <TableCell align="right">In Stock</TableCell>
            <TableCell align="right">Remove Stock</TableCell>
            <TableCell align="right">Stock After</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newStockAdjustment &&
            newStockAdjustment.items.map((item, idx) => (
              <TableRow
                key={idx}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell colSpan={4} component="th" scope="row">
                  {item.productName}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {item.inStock}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  <TextField
                    variant="outlined"
                    name=""
                    value={item.quantity}
                    type="number"
                    inputProps={{ min: 0, style: { textAlign: 'right' } }}
                    onChange={(e) => {
                      const quantity = e.target.value;
                      dispatch({
                        type: UPDATE_STOCK_ADJUSTMENT_ITEM,
                        payload: {
                          ...item,
                          quantity,
                        },
                      });
                    }}
                  />
                </TableCell>

                <TableCell align="right">
                  <strong>
                    {parseInt(item.inStock ?? 0) - parseInt(item.quantity ?? 0)}
                  </strong>
                </TableCell>
                <TableCell align="right">
                  <IconButton>
                    <DeleteIcon
                      onClick={() => handleRemoveProduct(item.productId)}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell colSpan={8} component="th" scope="row">
              <ProductItemSearch handleSelectProduct={handleSelectProduct} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StockAdjustmentItems;
