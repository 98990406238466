export const getStatusColor = (status) => {
  switch (status) {
    case 'PENDING':
      return '#FFCA28';
    case 'RUNNING':
      return '#78F26E';
    case 'ACTIVE':
      return '#35619B';
    case 'COMPLETED':
      return '#67AD5B';
    case 'IN PROGRESS':
      return '#35619B';
    case 'CANCELLED':
      return '#CE3231';
    case 'RECEIVE':
      return '#ABAD14';
    case 'CLEARED':
      return '#78F26E';
    case 'RECONCILED':
      return '#67AD5B';
    default:
      return '#000';
    case 'PAID':
      return '#78F26E';
    case 'PROCESSING':
      return '#FFCA28';
    case 'REFUNDED':
      return '#C686C0';
  }
};

export const getStatusColorText = (status) => {
  switch (status) {
    case 'PENDING':
      return '#fff';
    case 'RUNNING':
      return '#000';
    case 'RECONCILED':
      return '#FFF';
    case 'IN PROGRESS':
      return '#FFF';
    case 'RECEIVE':
      return '#000';
    case 'CLEARED':
      return '#000';
    case 'ACTIVE':
      return '#fff';
    case 'COMPLETED':
      return '#000';
    case 'CANCELLED':
      return '#000';
    case 'PAID':
      return '#000';
    case 'PROCESSING':
      return '#000';
    case 'REFUNDED':
      return '#000';
    default:
      return '#000';
  }
};

export const getNumberColor = (amount) => {
  if (amount === 0) {
    return '#000000';
  } else if (amount > 0) {
    return '#39773E';
  } else if (amount < 0) {
    return '#CE3231';
  }
};
