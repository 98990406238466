import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { numberFormatText } from '../../../utils/functions';
import { getProducts } from 'redux/products/productActions';
import Loading from 'components/layout/Loading';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import StoreIcon from '@mui/icons-material/Store';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShowChartIcon from '@mui/icons-material/ShowChartOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    borderRadius: theme.spacing(7),
    margin: theme.spacing(1),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const InventoryValuation = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const current = useSelector((state) => state.inventoryCount.current);
  const products = useSelector((state) => state.product.products);
  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);

  useEffect(() => {
    dispatch(getProducts({ showAll: true }));
    // eslint-disable-next-line
  }, []);
  const [inventoryStats, setInventoryStats] = useState({
    totalInventoryValue: 0,
    totalRetailValue: 0,
    totalProfit: 0,
    margin: 0,
  });

  useEffect(() => {
    if (products) {
      const totalInventoryValue = products.data.reduce(function (prev, cur) {
        return prev + cur.quantity * cur.buyingPrice;
      }, 0);
      const totalRetailValue = products.data.reduce(function (prev, cur) {
        return prev + cur.quantity * cur.retailPrice;
      }, 0);
      const totalProfit = totalRetailValue - totalInventoryValue;
      const margin = (totalProfit / totalInventoryValue) * 100;
      setInventoryStats({
        totalInventoryValue,
        totalRetailValue,
        totalProfit,
        margin,
      });
    }
  }, [products]);

  if (!products) {
    return (
      <Collapse className={classes.root} in>
        <Loading />
      </Collapse>
    );
  } else {
    return (
      <div className={classes.root}>
        <Grid container direction="row" spacing={2}>
          <InventoryCard
            title="Total Inventory Value"
            amount={inventoryStats.totalInventoryValue}
          />
          <InventoryCard
            title="Total Retail Value"
            amount={inventoryStats.totalInventoryValue}
            icon={<StoreIcon />}
          />
          <InventoryCard
            title="Potential Profit"
            amount={inventoryStats.totalProfit}
            icon={<MonetizationOnIcon />}
          />
          <InventoryCard
            title="Margin"
            amount={inventoryStats.margin}
            icon={<ShowChartIcon />}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper>
            <TableContainer>
              <Table
                // sx={{
                //   ml: (theme) => theme.spacing(4),
                // }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={3}>Item</TableCell>
                    <TableCell align="right">In Stock</TableCell>
                    <TableCell align="right">Cost</TableCell>
                    <TableCell align="right">Inventory Value</TableCell>
                    <TableCell align="right">Retail Value</TableCell>
                    <TableCell align="right">Potential Profit</TableCell>
                    <TableCell align="right">Margin</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.data.map((product) => {
                    const inventoryValue =
                      product.quantity * product.buyingPrice;
                    const retailValue = product.quantity * product.retailPrice;
                    const profit = retailValue - inventoryValue;
                    const margin = (profit / retailValue) * 100;
                    return (
                      <TableRow
                        key={product.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={3} component="th" scope="row">
                          {product.name}
                        </TableCell>
                        <TableCell align="right">{product.quantity}</TableCell>
                        <TableCell align="right">
                          {numberFormatText(product.price)}
                        </TableCell>
                        <TableCell align="right">
                          {numberFormatText(inventoryValue)}
                        </TableCell>
                        <TableCell align="right">
                          {numberFormatText(retailValue)}
                        </TableCell>
                        <TableCell align="right">
                          {numberFormatText(profit)}
                        </TableCell>
                        <TableCell align="right">
                          {numberFormatText(margin, '', '%')}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </div>
    );
  }
};

export const InventoryCard = ({ title, amount, icon = <Inventory2Icon /> }) => {
  return (
    <Grid item xs={12} md={3} sx={{ p: 2 }}>
      <Paper sx={{ px: 2, py: 3 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {icon}
          <Stack direction="column" justifyContent="center">
            <Typography variant="body1">{title}</Typography>
            <Typography variant="h6">
              <strong>{numberFormatText(amount)}</strong>
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    </Grid>
  );
};

export default InventoryValuation;
