import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Papa from 'papaparse';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { renameKeys } from 'utils/functions';

const ImportSelectFields = ({ file, handleNext, handleBack, updateData }) => {
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  const [tableRows, setTableRows] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  useEffect(() => {
    if (file) {
      changeHandler();
    }
  }, [file]);

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        setParsedData(results.data);

        // Filtered Column Names
        setTableRows(rowsArray[0]);

        // Filtered Values
        setValues(valuesArray);
      },
    });
  };

  useEffect(() => {
    if (values && tableRows) {
      console.log('Values ' + values);
      console.log('Table Rows ' + tableRows);
    }
  }, [values, tableRows]);

  const [fields, setFields] = useState({});

  const handleUpdate = () => {
    const selectedData = parsedData.map((data) => {
      return renameKeys(data, fields);
    });
    updateData(selectedData);
    handleNext();
  };

  return (
    <Grid container spacing={2} sx={{ py: 2 }}>
      <Grid item md={6} sx={12}>
        <Typography variant="body2">Data</Typography>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableRows.map((rows, index) => {
                  return (
                    <TableCell align="right" key={index}>
                      {rows}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {values.slice(0, 5).map((value, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    {value.map((val, i) => {
                      return (
                        <TableCell align="right" key={i}>
                          {val}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item md={6} sx={12}>
        <Stack direction="column" spacing={2}>
          {tableRows.map((rows, index) => {
            return (
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
                key={index}
              >
                <Typography sx={{ width: 1 / 2 }} variant="body1">
                  {rows}
                </Typography>
                <FormControl sx={{ width: 1 / 2 }}>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Maps to
                  </InputLabel>
                  <NativeSelect
                    defaultValue={30}
                    inputProps={{
                      name: 'age',
                      id: 'uncontrolled-native',
                    }}
                    onChange={(e) => {
                      if (e.target.value !== '*') {
                        setFields({ ...fields, [rows]: e.target.value });
                      }
                    }}
                  >
                    <option value="*">*</option>
                    <option value="name">Name</option>
                    <option value="description">Description</option>
                    <option value="price">Price</option>
                    <option value="quantity">Quantity</option>
                    <option value="image">Image</option>
                  </NativeSelect>
                </FormControl>
              </Stack>
            );
          })}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button color="inherit" onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />

          <Button disabled={values.length === 0} onClick={handleUpdate}>
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ImportSelectFields;
