import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { BiTransfer } from 'react-icons/bi';
import { HiAdjustments } from 'react-icons/hi';
import { ImListNumbered } from 'react-icons/im';
import { FaTruckMoving, FaHourglass } from 'react-icons/fa';

const options = [
  {
    name: 'Purchase Orders',
    icon: <AssignmentIcon />,
    link: '/admin/inventory/purchase-orders',
  },
  {
    name: 'Stock Adjustments',
    icon: <HiAdjustments size={20} />,
    link: '/admin/inventory/adjustments',
  },
  {
    name: 'Inventory Counts',
    icon: <ImListNumbered size={20} />,
    link: '/admin/inventory/inventory-count',
  },
  {
    name: 'Suppliers',
    icon: <FaTruckMoving size={20} />,
    link: '/admin/inventory/suppliers',
  },
  {
    name: 'Inventory History',
    icon: <FaHourglass size={20} />,
    link: '/admin/inventory/history',
  },
  {
    name: 'Inventory Valuation',
    icon: <BiTransfer size={20} />,
    link: '/admin/inventory/valuation',
  },
];

const InventoryTab = () => {
  // const classes = useStyles();
  // const current = useSelector((state) => state.auth.current);
  const location = useLocation();
  return (
    <Grid container justifyContent="center" direction="column" spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <List
            component="nav"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Inventory
              </ListSubheader>
            }
          >
            {options.map((option, index) => (
              <ListItem
                key={index}
                button
                component={Link}
                to={option.link}
                selected={location && location.pathname.includes(option.link)}
                // onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <ListItemText primary={option.name} />
              </ListItem>
            ))}
            {}
          </List>
          <Divider />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default InventoryTab;
