import {
  ADD_CATEGORY,
  UPDATE_CATEGORY,
  GET_CATEGORY,
  GET_CATEGORIES,
  CATEGORY_ERROR,
  DELETE_CATEGORY,
} from './categoryTypes';

const initialState = {
  categories: null,
  current: null,
  loading: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          data:
            state.categories !== null
              ? state.categories.data.concat(action.payload)
              : [action.payload],
        },
      };

    case UPDATE_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          data:
            state.categories !== null
              ? state.categories.data.map((category) =>
                  category.id === action.payload.id ? action.payload : category
                )
              : [action.payload],
        },
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_CATEGORY:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: {
          ...state.categories,
          data:
            state.categories &&
            state.categories.data &&
            state.categories.data.filter(
              (category) => category.id !== action.payload
            ),
        },
      };
    case CATEGORY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
