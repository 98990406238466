import React from 'react';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { updateUserStatusBulk } from '../../redux/user/userActions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1rem',
  },
  iconButton: {
    marginRight: '24px',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
    transform: 'translateY(-50%)',
  },
  deleteIcon: {
    color: '#000',
  },
  toolbar: {
    marginTop: theme.spacing(2),
  },
}));

const UserToolbarSelect = ({
  selectedRows,
  updateUserStatusBulk,
  displayData,
}) => {
  const classes = useStyles();

  const ids = selectedRows.data.map((object) => object.index);
  const data = displayData
    .map((data) => (ids.includes(data.dataIndex) ? data.data[0] : null))
    .filter(Boolean);

  const handleDisableUsers = () => {
    updateUserStatusBulk(data, false);
  };
  const handleEnableUsers = () => {
    updateUserStatusBulk(data, true);
  };

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        endIcon={<CheckIcon />}
        onClick={handleEnableUsers}
      >
        Enable
      </Button>
      <Button
        variant="outlined"
        endIcon={<ClearIcon />}
        onClick={handleDisableUsers}
      >
        Disable
      </Button>
    </div>
  );
};

export default connect(null, { updateUserStatusBulk })(UserToolbarSelect);
