import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MUIDataTable from 'mui-datatables';
import { grey } from '@mui/material/colors';
import { getStockAdjustmentProducts } from 'redux/stock-adjustment/stockAdjustmentActions';
import { parseISO, format } from 'date-fns/esm';
import CustomSearchRender from 'utils/CustomSearchRender';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const InventoryHistory = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const loading = useSelector((state) => state.notification.loading);
  const stockAdjustmentProducts = useSelector(
    (state) => state.stockAdjustment.products
  );

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
  });

  useEffect(() => {
    dispatch(getStockAdjustmentProducts(filter));
    // eslint-disable-next-line
  }, [filter]);
  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'id',
      label: 'ID',
      options: {
        display: false,
        sortOrder: false,
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'createdAt',
      label: 'Made On',
      options: {
        display: true,
        sort: true,
        filter: false,

        sortOrder: getSortOrder('createdAt'),
        customBodyRender: function renderCreatedAt(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'productName',
      label: 'Product',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
      },
    },
    {
      name: 'userName',
      label: 'Done By',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return <Typography variant="body1">{value}</Typography>;
        },
      },
    },
    {
      name: 'stockAdjustmentType',
      label: 'Type',
      options: {
        display: true,
        sort: false,
        print: false,
        filter: false,
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          let link = '#';
          let id = '';

          const isPurchaseOrder = tableMeta.rowData[9] != null;
          if (isPurchaseOrder) {
            link = `/admin/inventory/purchase-orders/detail/${tableMeta.rowData[9]}`;
            id = `#${tableMeta.rowData[9]}`;
          }
          const isOrder = tableMeta.rowData[8] != null;
          if (isOrder) {
            link = `/admin/sell/sales-history/${tableMeta.rowData[8]}`;
            id = `#${tableMeta.rowData[8]}`;
          }

          return (
            <Stack spacing={2} direction="row">
              <Typography variant="body1">{value}</Typography>
              {id.length > 0 && (
                <Typography
                  component={Link}
                  to={`${link}`}
                  color="primary"
                  variant="body1"
                >
                  <strong>{id}</strong>
                </Typography>
              )}
            </Stack>
          );
        },
      },
    },
  ];

  const options = {
    filter: false,
    download: true,
    print: false,
    viewColumns: false,
    serverSide: true,
    rowHover: true,
    fixedHeader: false,
    selectableRows: 'none',
    count: stockAdjustmentProducts && stockAdjustmentProducts.count,
    elevation: 0,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    if (stockAdjustmentProducts) {
      var finalData =
        stockAdjustmentProducts &&
        stockAdjustmentProducts.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
  }, [stockAdjustmentProducts]);

  if (!stockAdjustmentProducts) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <Stack direction="column" spacing={2}>
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Inventory History</strong>
              </Typography>
              <Typography variant="body1">
                View the Stock Movements of your Entire Inventory
              </Typography>
            </Stack>
          </Stack>

          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
        </Stack>
      </Paper>
    );
  }
};

export default InventoryHistory;
