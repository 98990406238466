export const GET_PURCHASE_ORDER = 'GET_PURCHASE_ORDER';
export const GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER';
export const PURCHASE_ORDER_ERROR = 'PURCHASE_ORDER_ERROR';
export const SET_PURCHASE_ORDER = 'SET_PURCHASE_ORDER';
export const DELETE_PURCHASE_ORDER_ITEM = 'DELETE_PURCHASE_ORDER_ITEM';
export const ADD_PURCHASE_ORDER_ITEM = 'ADD_PURCHASE_ORDER_ITEM';
export const UPDATE_PURCHASE_ORDER_ITEM = 'UPDATE_PURCHASE_ORDER_ITEM';

export const PURCHASE_ORDER_STATUS = {
  PARTIALLY_RECEIVED: 'PARTIALLY_RECEIVED',
  PENDING: 'PENDING',
  CLOSED: 'CLOSED',
  DRAFT: 'DRAFT',
};
