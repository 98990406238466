import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import Img from 'react-image';
import VisibilitySensor from 'react-visibility-sensor';
import { useTheme } from '@mui/material/styles';
import { updateCurrentStore } from '../../../redux/store/storeActions';
import BrandIcon from '../../../shared/icons/brand.svg';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CropImage from '../../../components/shared/CropImage';
import { SketchPicker } from 'react-color';

// import { DropzoneDialog } from 'material-ui-dropzone';
const Input = styled('input')({
  display: 'none',
});
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    display: 'flex',
    // padding: theme.spacing(4),
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    borderRadius: '0.3rem',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SetupStoreBranding = ({ handleNext, isActiveStep }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);
  const [form, setForm] = useState({
    primaryColor: theme.palette.primary.main,
    secondaryColor: theme.palette.secondary.main,
  });
  const handleSubmit = () => {
    const formData = new FormData();

    formData.append('file', file);
    formData.append('primaryColor', form.primaryColor);
    formData.append('secondaryColor', form.secondaryColor);

    dispatch(updateCurrentStore(formData));
  };
  const [userImage, setUserImage] = useState('');
  const [file, setFile] = useState(null);
  const [cropImageOpen, setCropImageOpen] = useState(false);

  const handleSave = (files) => {
    // Set Image
    const file = files[0];
    setFile(file);
    // setUserImage(URL.createObjectURL(file));
  };

  const handleCropImageClose = () => {
    setCropImageOpen(false);
  };

  const handleSelectCropImage = (imageSrc) => {
    setUserImage(imageSrc);
    setCropImageOpen(false);
  };

  useEffect(() => {
    if (file) {
      setCropImageOpen(true);
    }
  }, [file]);

  useEffect(() => {
    if (
      notification &&
      notification.message === 'Updated Store' &&
      isActiveStep
    ) {
      handleNext();
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <img src={BrandIcon} alt="Store Branding" width={256} />
      </Grid>
      <Grid item xs={12} md={8}>
        <form className={classes.form} noValidate>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4">
                <strong>
                  How do you want {store ? store.name : `your store`} branded ?
                </strong>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ background: theme.palette.primary.main }}
            >
              <Typography
                style={{ color: theme.palette.primary.contrastText }}
                variant="body1"
              >
                <strong>Store Logo</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => {
                    console.log(e.target.files);
                    handleSave(e.target.files);
                  }}
                />
                <Button
                  variant="contained"
                  component="span"
                  endIcon={<CloudUploadIcon />}
                >
                  Upload
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} md={12}>
              {cropImageOpen && (
                <CropImage
                  imageFile={file}
                  cropImageOpen={cropImageOpen}
                  handleCropImageClose={handleCropImageClose}
                  handleSelectCropImage={handleSelectCropImage}
                  aspect={1 / 2}
                />
              )}
            </Grid>
            {userImage && (
              <Grid item xs={12} sm={12}>
                <VisibilitySensor>
                  <Img style={{ cursor: 'pointer' }} src={userImage} />
                </VisibilitySensor>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={12}
              style={{ background: theme.palette.primary.main }}
            >
              <Typography
                style={{ color: theme.palette.primary.contrastText }}
                variant="body1"
              >
                <strong>Store Colors</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={4}>
                <Grid item xs={6} md={3}>
                  <Typography variant="body1">
                    <strong>Primary Color</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <SketchPicker
                    animation="slide-up"
                    name="primaryColor"
                    color={form.primaryColor}
                    onChange={(color) =>
                      setForm({ ...form, primaryColor: color.hex })
                    }
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography variant="body1">
                    <strong>Secondary Color</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <SketchPicker
                    animation="slide-up"
                    name="primaryColor"
                    style={{ zIndex: 1999 }}
                    color={form.secondaryColor}
                    onChange={(color) =>
                      setForm({ ...form, secondaryColor: color.hex })
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleNext()}
                disabled={loading}
                size="large"
              >
                Skip
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => handleSubmit()}
                disabled={loading}
                style={{ marginLeft: '2rem' }}
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

SetupStoreBranding.label = 'Store Branding';

export default SetupStoreBranding;
