import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
const ImportCSV = ({ handleSelectFile, handleNext }) => {
  const [file, setFile] = React.useState(null);
  const handleUpload = () => {};

  useEffect(() => {
    if (file) {
      handleSelectFile(file);
    }
  }, [file]);
  return (
    <Stack direction="column">
      <Typography variant="body2" sx={{ p: 2 }}>
        Select a CSV File with your Products
      </Typography>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="left"
        alignItems="center"
      >
        <Button variant="contained" component="label">
          Upload CSV
          <input
            hidden
            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            type="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </Button>
        {file && <Typography>{file.name}</Typography>}
      </Stack>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Button disabled={file === null} onClick={handleNext}>
          Next
        </Button>
      </Box>
    </Stack>
  );
};

export default ImportCSV;
