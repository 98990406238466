import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_USER } from '../../../../../redux/auth/authTypes';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '70%',
    padding: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUpBasicInfo = ({ handleNext }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: SET_CURRENT_USER, payload: form });
    handleNext();
  };

  const currentUser = useSelector((state) => state.auth.current);

  const [form, setForm] = useState({
    firstName: null,
    lastName: null,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (currentUser) {
      setForm({ ...form, ...currentUser });
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid
            container
            // className={classes.root}
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'center' }} variant="h3">
                <strong>Hi ! Nice to meet you</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="firstName"
                onChange={onChange}
                value={form.firstName}
                fullWidth
                label="First Name"
                placeholder="Enter First Name"
                variant="outlined"
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="lastName"
                onChange={onChange}
                value={form.lastName}
                fullWidth
                label="Last Name"
                placeholder="Enter Last Name"
                variant="outlined"
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={4} />
            <Grid item xs={4}>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={!form.firstName || !form.lastName}
              >
                Continue
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default SignUpBasicInfo;
