import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { FaTruckMoving } from 'react-icons/fa';
import toMaterialStyle from 'material-color-hash';
import {
  addSupplier,
  getSupplierById,
  updateSupplier,
} from '../../../redux/suppliers/supplierActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    // flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  avatar: {
    color: '#FFF',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

const AddUpdateSupplier = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const supplier = useSelector((state) => state.supplier.current);

  useEffect(() => {
    if (!supplier) {
      dispatch(getSupplierById(id));
    }
    // eslint-disable-next-line
  }, [supplier]);

  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    website: '',
    address: '',
    address2: '',
    city: '',
    postalCode: '',
    country: '',
    notes: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(
    () => {
      if (supplier) {
        setForm({ ...supplier });
      }
    },
    // eslint-disable-next-line
    [supplier]
  );

  useEffect(() => {
    if (
      page &&
      page.includes('Supplier') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (supplier) {
      dispatch(updateSupplier(form, supplier.id));
    } else {
      dispatch(addSupplier(form));
    }
  };

  if (id && !supplier) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <div>
        <Paper className={classes.root}>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            <Stack direction="column" spacing={4}>
              <Stack direction="row" justifyContent="center">
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  style={{
                    backgroundColor:
                      toMaterialStyle(`Supplier`).backgroundColor,
                  }}
                  className={classes.avatar}
                >
                  <FaTruckMoving size={40} />
                </Avatar>
              </Stack>
              <TextField
                name="name"
                variant="outlined"
                required
                fullWidth
                onChange={onChange}
                id="name"
                value={form.name}
                label="Name"
                autofocus
              />
              {/*  */}
              <TextField
                name="email"
                variant="outlined"
                fullWidth
                onChange={onChange}
                id="email"
                value={form.email}
                label="Email"
                type="email"
              />
              <TextField
                name="phone"
                variant="outlined"
                fullWidth
                onChange={onChange}
                id="phone"
                value={form.phone}
                label="Phone No"
                type="number"
              />
              <TextField
                name="address"
                variant="outlined"
                fullWidth
                onChange={onChange}
                id="address"
                value={form.address}
                label="Address"
              />
              <TextField
                name="address2"
                variant="outlined"
                fullWidth
                onChange={onChange}
                id="address2"
                value={form.address2}
                label="Address 2"
              />
              <TextField
                name="website"
                variant="outlined"
                fullWidth
                onChange={onChange}
                id="website"
                value={form.website}
                label="Website"
              />
              <Stack direction="row" spacing={4}>
                <TextField
                  name="city"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  id="city"
                  value={form.city}
                  label="City"
                />
                <TextField
                  name="postalCode"
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  id="postalCode"
                  value={form.postalCode}
                  label="Postal Code"
                />
              </Stack>
              <TextField
                name="notes"
                variant="outlined"
                fullWidth
                multiline
                onChange={onChange}
                id="notes"
                value={form.notes}
                label="Notes"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size={'large'}
                color="primary"
                className={classes.submit}
                disabled={!form.name || loading}
              >
                Save
              </Button>

              {loading && <LinearProgress variant="query" color="secondary" />}
            </Stack>
          </form>
        </Paper>
      </div>
    );
  }
};

export default AddUpdateSupplier;
