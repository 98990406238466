import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import MailIcon from '@mui/icons-material/Mail';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ColorPicker from 'rc-color-picker';
import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoneyIcon from '@mui/icons-material/Money';
import 'rc-color-picker/assets/index.css';
import { updateCurrentStore } from '../../redux/store/storeActions';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import FlagIcon from '@mui/icons-material/Flag';
import { CustomThemeContext } from 'CustomThemeProvider';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SettingsStoreDetails = () => {
  const [form, setForm] = useState({
    name: '',
    address: '',
    email: '',
    country: '',
    currency: '',
    mobileNumber: '',
    image: null,
    primaryColor: '',
    secondaryColor: '',
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { setTheme } = useContext(CustomThemeContext);

  const loading = useSelector((state) => state.notification.loading);
  const store = useSelector((state) => state.auth.store);

  useEffect(() => {
    if (store) {
      setForm({ ...store });
    }
    // eslint-disable-next-line
  }, [store]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateCurrentStore(form));

    setTheme(form.primaryColor, form.secondaryColor);
    
  };

  if (form.country && form.country.length === 0) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="name"
                name="name"
                value={form.name}
                label="Name"
                variant="outlined"
                onChange={onChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography color="textSecondary" variant="caption">
                        {form.name &&
                          form.name
                            .toString()
                            .toLowerCase()
                            .replace(/[^A-Z0-9]/gi, '')}
                        .sturr.app
                      </Typography>
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                name="email"
                value={form.email}
                label="Email"
                variant="outlined"
                onChange={onChange}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MailIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="mobileNumber"
                type="number"
                label="Mobile Number"
                name="mobileNumber"
                value={form.mobileNumber}
                onChange={onChange}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneAndroidIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                value={form.address}
                onChange={onChange}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LocationOnIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="currency"
                label="Currency"
                name="currency"
                value={form.currency}
                onChange={onChange}
                autoFocus
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <MoneyIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Country"
                variant="outlined"
                name="country"
                fullWidth
                value={form.country}
                onChange={onChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FlagIcon />
                    </InputAdornment>
                  ),
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <div className={classes.contentBox}>
                <Typography variant="body1">Primary Color</Typography>

                <div style={{ margin: '20px 20px 20px', textAlign: 'center' }}>
                  <ColorPicker
                    animation="slide-up"
                    name="primaryColor"
                    color={form.primaryColor}
                    onChange={(colors) =>
                      setForm({ ...form, primaryColor: colors.color })
                    }
                  />
                </div>

                <Typography variant="body1">Secondary Color</Typography>
                <div style={{ margin: '20px 20px 20px', textAlign: 'center' }}>
                  <ColorPicker
                    animation="slide-up"
                    name="primaryColor"
                    color={form.secondaryColor}
                    onChange={(colors) =>
                      setForm({ ...form, secondaryColor: colors.color })
                    }
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Button
                size="large"
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                disabled={loading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    );
  }
};

export default SettingsStoreDetails;
