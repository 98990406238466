import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { parseISO, format } from 'date-fns/esm';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { getCashMovements } from '../../../redux/cash-movements/cashMovementActions';
import SellCashRegisterSummary from './SellCashRegisterSummary';
import { closeCashRegister } from '../../../redux/cash-register/cashRegisterActions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const SellOpenCloseRegister = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const currentCashRegister = useSelector(
    (state) => state.cashRegister.current
  );

  const cashMovements = useSelector(
    (state) => state.cashMovement.cashMovements
  );

  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);

  const [filter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    isOpen: true,
    showAll: true,
  });

  // useEffect(() => {
  //   dispatch(getCashMovements(filter));
  //   // eslint-disable-next-line
  // }, [filter]);

  useEffect(() => {
    if (currentCashRegister) {
      dispatch(
        getCashMovements({ ...filter, cashRegisterId: currentCashRegister.id })
      );
    } else {
      navigate('/admin/sell/cash-register/open');
    }
    // eslint-disable-next-line
  }, [currentCashRegister]);

  const [form, setForm] = useState({});

  const updateForm = (updatedForm) => {
    setForm({
      ...form,
      countedNo: updatedForm.countedNo,
      total: updatedForm.total,
    });
  };

  const handleCloseRegister = () => {
    dispatch(closeCashRegister(form));
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Close Register') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate('/admin/sell/cash-register/open');
    }
    // eslint-disable-next-line
  }, [notification]);

  if (loading && !currentCashRegister) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else if (currentCashRegister && cashMovements) {
    return (
      <div className={classes.root}>
        <Paper style={{ padding: '2rem' }}>
          <Typography variant="h4">Close Register</Typography>
          <Alert severity="info">
            Close your register to finalize payments and sales for the day.
          </Alert>

          <Grid container spacing={2} style={{ marginTop: '2rem' }}>
            <Grid item xs={3}>
              <Typography variant="h6">Register Details</Typography>
            </Grid>
            <Grid item xs={9}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Register No #</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {currentCashRegister.registerNo}
                  </Typography>
                </Grid>
                {/* Opening Time */}
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Opening Time</strong>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {format(
                      parseISO(
                        currentCashRegister.openingTime,
                        'yyyy-MM-dd',
                        new Date()
                      ),
                      'do MMM yyyy p'
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        {/* Payments Summary */}
        <Paper style={{ marginTop: '2rem', padding: '2rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="h6">Payments Summary</Typography>
              <Typography variant="body2" color="textSecondary">
                Balance your register by entering the amount counted from the
                till and other payment terminals into the empty fields here.
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <SellCashRegisterSummary updateForm={updateForm} />
            </Grid>
          </Grid>
        </Paper>

        {/* Closing Summary */}
        <Paper style={{ marginTop: '2rem', padding: '2rem' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="h6">Closing Summary</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                variant="outlined"
                fullWidth
                id="Notes"
                onChange={(e) =>
                  setForm({ ...form, closingSummary: e.target.value })
                }
                placeholder="Type to add a register closure note"
                multiline
              />
            </Grid>
          </Grid>
        </Paper>

        {/* Close Register */}
        <Grid container style={{ marginTop: '2rem' }}>
          <Grid item xs={3}>
            <Button
              disabled={loading}
              fullWidth
              variant="contained"
              color="primary"
              style={{ padding: '1rem' }}
              onClick={handleCloseRegister}
            >
              Close Register
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  }
};

export default SellOpenCloseRegister;
