import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import ImportCSV from './ImportCSV';
import ImportSelectFields from './ImportSelectFields';
import { ImportDataContext } from 'context/ImportDataContext';
import ImportConfirmFields from './ImportConfirmFields';

const steps = ['Upload your CSV File', 'Choose your Data', 'Confirm'];

const ImportDataDialog = ({ openImport, handleCloseImport }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);

  const handleNext = () => {
    if (activeStep + 1 >= steps.length) {
      handleCloseImport();
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  const updateData = (data) => {
    setData(data);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openImport}
        onClose={handleCloseImport}
      >
        <DialogTitle>Import Data</DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing={2}>
            <Box sx={{ width: '100%' }}>
              {/* Stepper */}
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <React.Fragment>
                <ImportDataContext.Provider>
                  {activeStep == 0 && (
                    <ImportCSV
                      handleNext={handleNext}
                      handleSelectFile={(file) => setFile(file)}
                    />
                  )}
                  {activeStep == 1 && file && (
                    <ImportSelectFields
                      handleNext={handleNext}
                      handleBack={handleBack}
                      file={file}
                      updateData={updateData}
                    />
                  )}
                  {activeStep == 2 && (
                    <ImportConfirmFields
                      handleNext={handleNext}
                      handleBack={handleBack}
                      data={data}
                    />
                  )}
                </ImportDataContext.Provider>
              </React.Fragment>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImport}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ImportDataDialog;
