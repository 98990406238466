import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { getProducts } from '../../redux/products/productActions';

const useForceUpdate = () => useState()[1];

const ProductItemSearch = ({ handleSelectProduct }) => {
  const dispatch = useDispatch();

  const products = useSelector((state) => state.product.products);
  const loading = useSelector((state) => state.notification.loading);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 200000,
    search: null,
    sort: null,
    sortOrder: null,
    showAll: true,
  });

  useEffect(() => {
    dispatch(getProducts(filter));
    // eslint-disable-next-line
  }, [filter]);

  const [value, setValue] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let active = true;

    if (!products || !loading) {
      return undefined;
    }

    (async () => {
      // await sleep(1e3); // For demo purposes.

      if (active) {
        setOptions([...products.data]);
      }
    })();

    return () => {
      active = false;
    };
  }, [products, loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  if (!products) {
    return <div></div>;
  } else {
    return (
      <div>
        <Autocomplete
          open={open}
          filterOptions={(x) => x}
          fullWidth
          value={value}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={(e, product, reason) => {
            if (reason === 'selectOption') {
              handleSelectProduct(product);
              setValue(null);
              return;
            }
          }}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          options={products.data}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search for Product"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </div>
    );
  }
};

export default ProductItemSearch;
