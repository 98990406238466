import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CURRENT_USER } from '../../../../../redux/auth/authTypes';
import {
  clearLoading,
  setLoading,
} from '../../../../../redux/notification/notificationActions';
// import { countries, countryToFlag } from '../../../../../utils/functions';
import { getBusinessTypes } from '../../../../../redux/business-type/businessTypeActions';
import data from 'currency-codes/data';
import * as cc from 'currency-codes';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '70%',
    padding: theme.spacing(4),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignUpStoreInfo = ({ handleNext, handleBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: SET_CURRENT_USER, payload: form });
    handleNext();
  };

  const currentUser = useSelector((state) => state.auth.current);
  const loading = useSelector((state) => state.notification.loading);
  const businessTypes = useSelector(
    (state) => state.businessType.businessTypes
  );

  const [form, setForm] = useState({
    storeName: '',
    storeCurrency: '',
    businessTypeId: '',
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getBusinessTypes());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser) {
      setForm({ ...form, ...currentUser });
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const [storeExists, setStoreExists] = useState(false);

  const onChangeStore = async (e) => {
    setForm({
      ...form,
      storeName: e.target.value,
    });

    const storeName = e.target.value
      .toString()
      .toLowerCase()
      .replace(/[^A-Z0-9]/gi, '');

    dispatch(setLoading());
    const result = await axios.get(
      `${process.env.REACT_APP_API_URL}store/exists`,
      { params: { name: storeName } }
    );

    const storeExists = result.data;

    console.log(storeExists);
    dispatch(clearLoading());
    setStoreExists(storeExists);
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid
            container
            // className={classes.root}
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'center' }} variant="h3">
                <strong>Tell Us About Your Business</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="store"
                name="storeName"
                value={form.storeName}
                label="Store Name"
                placeholder="Business or trading Name"
                variant="outlined"
                onChange={onChangeStore}
                fullWidth
                error={storeExists}
                helperText={storeExists && 'Store Name not available'}
                InputProps={{
                  endAdornment: (
                    <Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      <InputAdornment position="end">
                        <Typography color="textSecondary" variant="caption">
                          {form.storeName &&
                            form.storeName
                              .toString()
                              .toLowerCase()
                              .replace(/[^A-Z0-9]/gi, '')}
                          .sturr.app
                        </Typography>
                      </InputAdornment>
                    </Fragment>
                  ),
                }}
              />
            </Grid>
            {/* Business Types */}
            <Grid item xs={12} md={6}>
              {businessTypes && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel htmlFor={`outlined-business-type-simple`}>
                    Select Business Type
                  </InputLabel>
                  <Select
                    fullWidth
                    labelId="outlined-business-type-simple"
                    value={form.businessTypeId}
                    onChange={onChange}
                    name="businessTypeId"
                    label="Select Business Type"
                  >
                    {businessTypes.data.map((businessType) => (
                      <MenuItem key={businessType.id} value={businessType.id}>
                        <em>{businessType.name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>

            {/* Country */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="country-select-demo"
                options={data}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => `${option.countries[0]}`}
                onInputChange={(e, newValue) => {
                  setForm({ ...form, storeCountry: newValue });
                }}
                inputValue={form.storeCountry}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    variant="outlined"
                    fullWidth
                    autoComplete="off"
                    inputProps={{
                      ...params.inputProps,
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
            </Grid>

            {/* Currency */}
            <Grid item xs={12} md={6}>
              <Autocomplete
                id="currency-select-demo"
                options={data}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                autoComplete="on"
                renderInput={(params) => (
                  <TextField {...params} label="Currency" />
                )}
                getOptionLabel={(option) =>
                  `${option.currency} (${option.code})`
                }
                onInputChange={(e, newValue) => {
                  setForm({
                    ...form,
                    storeCurrency: newValue.replace(/.*\(|\).*/g, ''),
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a Currency"
                    variant="outlined"
                    name="storeCurrency"
                    autoComplete="off"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              <Button
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={
                  (form.storeName.length === 0 ||
                    form.storeCurrency.length === 0 ||
                    form.businessTypeId.length === 0) &&
                  storeExists
                }
              >
                Continue
              </Button>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default SignUpStoreInfo;
