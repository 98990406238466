import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import MUIDataTable from 'mui-datatables';
import Typography from '@mui/material/Typography';
import TableCell from '@mui/material/TableCell';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { parseISO, format } from 'date-fns/esm';
import CustomSearchRender from 'utils/CustomSearchRender';
import { getCashMovements } from '../../../redux/cash-movements/cashMovementActions';
import SellCashMovementUpdate from './SellCashMovementUpdate';
import { CASH_MOVEMENT_TYPE } from '../../../redux/cash-movements/cashMovementTypes';
import { numberFormatText } from 'utils/functions';
import { grey } from '@mui/material/colors';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const SellCashManagement = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const currentCashRegister = useSelector(
    (state) => state.cashRegister.current
  );
  const store = useSelector((state) => state.auth.store);

  const currentDevice = useSelector((state) => state.device.current);
  const cashMovements = useSelector(
    (state) => state.cashMovement.cashMovements
  );

  // const notification = useSelector((state) => state.notification.notification);

  useEffect(() => {
    if (currentDevice) {
      setFilter({
        ...filter,
        deviceId: currentDevice.id,
      });
    }
    // eslint-disable-next-line
  }, [currentDevice]);

  useEffect(() => {
    if (currentCashRegister) {
      setFilter({
        ...filter,
        cashRegisterId: currentCashRegister.id,
      });
    } else {
      navigate('/admin/sell/cash-register/open');
    }
    // eslint-disable-next-line
  }, [currentCashRegister]);

  const [filter, setFilter] = useState({
    page: 1,
    limit: 5,
    search: null,
    sort: null,
    sortOrder: null,
    isOpen: true,
  });

  const getSortOrder = (name) => {
    let direction = 'none';

    // console.log(filter);

    if (filter) {
      if (filter.sort === name) {
        direction = filter.sortOrder;
      }
    }
    // console.log(direction);
    return direction;
  };

  const columns = [
    {
      name: 'createdAt',
      label: 'Date',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('createdAt'),
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography variant="body1">
              {format(
                parseISO(value, 'yyyy-MM-dd', new Date()),
                'do MMM yyyy p'
              )}
            </Typography>
          );
        },
      },
    },
    {
      name: 'userId',
      label: 'User ID',
      options: {
        display: false,
        sortOrder: getSortOrder('userId'),
        print: false,
        filter: false,
        download: false,
      },
    },
    {
      name: 'userName',
      label: 'Staff',
      options: {
        display: true,
        sortOrder: getSortOrder('userFirstName'),
        print: false,
        filter: false,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        display: true,
        print: false,
        filter: false,
      },
    },
    {
      name: 'notes',
      label: 'Reason',
      options: {
        display: true,
        print: false,
        filter: false,
      },
    },
    {
      name: 'amount',
      label: 'Amount',
      options: {
        display: true,
        sort: true,
        filter: false,
        sortOrder: getSortOrder('amount'),
        customHeadRender: (columnMeta, updateDirection) => (
          <TableCell key={6}>
            <th
              onClick={() => updateDirection(2)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
            >
              {columnMeta.label}
            </th>
          </TableCell>
        ),
        setCellProps: (value) => {
          return {
            style: {
              textAlign: 'right',
            },
          };
        },
        customBodyRender: function customBodyRender(
          value,
          tableMeta,
          updateValue
        ) {
          return (
            <Typography
              color={
                tableMeta.rowData[3].includes(CASH_MOVEMENT_TYPE.CASH_OUT)
                  ? 'secondary'
                  : 'primary'
              }
              variant="body1"
            >
              {numberFormatText(value, store.currency)}
            </Typography>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    serverSide: true,
    rowHover: false,
    downloadOptions: {
      filename: 'cashmovements.csv',
      useDisplayedRowsOnly: false,
    },
    selectableRows: 'none',
    count: cashMovements && cashMovements.count,
    rowsPerPage: 5,
    elevation: 0,
    rowsPerPageOptions: [5, 10, 20, 30, 50, 100],
    onChangePage: (currentPage) => {
      setFilter({ ...filter, page: currentPage + 1 });
    },
    customSearchRender: function customSearchRender(
      searchText,
      handleSearch,
      hideSearch,
      options
    ) {
      const doSearch = (searchText) => {
        setFilter({
          ...filter,
          search: searchText.length > 0 ? searchText : null,
        });
      };
      return (
        <CustomSearchRender
          searchText={searchText}
          onSearch={(text) => doSearch(text)}
          onHide={hideSearch}
          options={options}
        />
      );
    },
    onSearchClose: () => {
      setFilter({ ...filter, search: null });
    },
    onColumnSortChange: (column, direction) => {
      //
      setFilter({
        ...filter,
        sort: column,
        sortOrder: direction === 'ascending' ? 'asc' : 'desc',
      });
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getCashMovements(filter));

    // eslint-disable-next-line
  }, [filter]);
  // When store/state data is updated
  useEffect(() => {
    if (cashMovements) {
      var finalData =
        cashMovements &&
        cashMovements.data.map(function (obj) {
          return Object.values(obj);
        });
      setData(finalData);
      // console.log(finalData);
    }
    // eslint-disable-next-line
  }, [cashMovements]);

  const [isCashIn, setIsCashIn] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCashUpdate = (isCashIn) => {
    setIsCashIn(isCashIn);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (loading && !cashMovements && !currentDevice && !currentCashRegister) {
    return (
      <Collapse className={classes.root} in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper className={classes.root} sx={{ m: 2 }}>
        <Stack direction="column">
          <Stack
            sx={{ p: 4, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h4">
                <strong>Cash Management</strong>
              </Typography>
              <Typography variant="body1">
                Keep track of money coming in/out of your Cash Drawer
              </Typography>
            </Stack>
            <Stack direction="row" spacing={4}>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                className={classes.button}
                onClick={() => handleCashUpdate(false)}
              >
                Remove Cash
              </Button>
              <Button
                variant="contained"
                size="large"
                color="primary"
                className={classes.button}
                onClick={() => handleCashUpdate(true)}
              >
                Add Cash
              </Button>
            </Stack>
          </Stack>
          {data && (
            <MUIDataTable data={data} columns={columns} options={options} />
          )}
          <SellCashMovementUpdate
            isCashIn={isCashIn}
            open={open}
            handleClose={handleClose}
          />
        </Stack>
      </Paper>
    );
  }
};

export default SellCashManagement;
