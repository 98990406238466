import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';

const Loading = ({ title = 'Please wait', type = 'info' }) => {
  return (
    <Stack direction="column" justifyContent="center">
      <Alert severity={type} color={type}>
        {title}
      </Alert>
      <LinearProgress variant="query" color={'primary'} />
    </Stack>
  );
};

export default Loading;

Loading.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'success', 'error', 'warning']),
};
