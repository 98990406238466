import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import { getCategories } from '../../../redux/category/categoryActions';
import { PRODUCT_TYPE } from '../../../redux/products/productTypes';
import { getSubCategories } from '../../../redux/sub-category/subCategoryActions';
import { toTitleCase } from '../../../utils/functions';
import { getDemoCategories } from '../../../redux/category/demoCategoryActions';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  title: {
    fontSize: 30,
    marginBottom: theme.spacing(4),
  },
}));

const SectionGeneral = ({ updateForm, isDemoProduct }) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const categories = useSelector((state) => state.category.categories);
  const subcategories = useSelector((state) => state.subcategory.subcategories);
  const current = useSelector((state) => state.product.current);
  const [form, setForm] = useState({
    name: '',
    description: '',
    type: PRODUCT_TYPE.GOODS,
    active: true,
    forSale: true,
    categoryId: '',
    subCategoryId: '',
    unitMeasure: '',
    unitValue: '',
    sku: '',
    barcode: '',
  });

  useEffect(() => {
    if (current && isDemoProduct != null) {
      form.name = current.name;
      form.description = current.description;
      form.type = current.type;
      form.active = current.active;
      form.forSale = current.forSale;
      form.categoryId = current.categoryId;
      form.subCategoryId = current.subCategoryId;
      form.unitMeasure = current.unitMeasure;
      form.unitValue = current.unitValue;
      form.sku = current.sku;
      form.barcode = current.barcode;
      setForm(form);
      dispatch(getSubCategories({ categoryId: current.categoryId }));
    }
    // eslint-disable-next-line
  }, [current]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    updateForm({ ...form });
    // eslint-disable-next-line
  }, [form]);

  useEffect(() => {
    dispatch(isDemoProduct ? getDemoCategories() : getCategories());
    // eslint-disable-next-line
  }, []);

  if (!categories) {
    return (
      <Collapse in>
        <Alert
          severity="info"
          action={
            <IconButton aria-label="close" color="inherit" size="small">
              <RefreshIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Loading
        </Alert>
      </Collapse>
    );
  } else {
    return (
      <Paper>
        <Stack direction="column">
          <Stack
            sx={{ p: 2, backgroundColor: grey[200], overflow: 'hidden' }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Stack direction="column">
              <Typography variant="h6">
                <strong>General</strong>
              </Typography>
              <Typography color="textSecondary" variant="body1">
                Describe your product
              </Typography>
            </Stack>
          </Stack>

          <Stack sx={{ p: 2 }} direction="column" spacing={2}>
            <TextField
              name="name"
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              id="name"
              label="Name"
              autoFocus
              value={form.name}
            />
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id={`outlined-category-simple`}>
                  Select Category
                </InputLabel>
                <Select
                  fullWidth
                  value={form.categoryId}
                  onChange={(e) => {
                    onChange(e);
                    dispatch(getSubCategories({ categoryId: e.target.value }));
                  }}
                  name="categoryId"
                  labelId="outlined-category-simple"
                  // labelWidth={labelWidth}
                >
                  {categories.data.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      <em>{category.name}</em>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {subcategories && subcategories.count > 0 && (
                <FormControl
                  fullWidth
                  variant="outlined"
                  className={classes.formControl}
                >
                  <InputLabel id={`outlined-subCategoryId-simple`}>
                    Select Sub Category
                  </InputLabel>
                  <Select
                    fullWidth
                    value={form.subCategoryId}
                    onChange={onChange}
                    name="subCategoryId"
                    labelId="outlined-subCategoryId-simple"
                    // labelWidth={labelWidth}
                  >
                    {subcategories.data.map((subcategory) => (
                      <MenuItem key={subcategory.id} value={subcategory.id}>
                        <em>{subcategory.name}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Stack>

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel id={`outlined-product-type-simple`}>
                Select Product Type
              </InputLabel>
              <Select
                fullWidth
                value={form.type}
                onChange={onChange}
                name="type"
                labelId="outlined-product-type-simple"
                // labelWidth={labelWidth}
              >
                {Object.entries(PRODUCT_TYPE).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    <em>{toTitleCase(value)}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              variant="outlined"
              required
              fullWidth
              multiline
              onChange={onChange}
              id="description"
              label="Description"
              value={form.description}
              name="description"
            />
            <Stack
              direction={{ sm: 'column', md: 'row' }}
              spacing={2}
              divider={<Divider orientation="vertical" flexItem />}
            >
              <TextField
                variant="outlined"
                required
                type="text"
                fullWidth
                onChange={onChange}
                id="sku"
                label="SKU"
                value={form.sku}
                name="sku"
              />

              <TextField
                variant="outlined"
                required
                type="text"
                fullWidth
                onChange={onChange}
                id="barcode"
                label="Barcode"
                name="barcode"
                value={form.barcode}
              />
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    );
  }
};

export default SectionGeneral;
