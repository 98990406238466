import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import 'rc-color-picker/assets/index.css';
import {
  deleteStoreAccount,
  deleteStoreAccountData,
  deleteStoreDemoData,
} from '../../redux/auth/authActions';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formControl: {
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SettingsAccountSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.notification.loading);

  const [showDialogDeleteDemoData, setShowDialogDeleteDemoData] =
    useState(false);
  const [showDialogDeleteData, setShowDialogDeleteData] = useState(false);
  const [showDialogDeleteAccount, setShowDialogDeleteAccount] = useState(false);

  const handleDemoDataDelete = () => {
    dispatch(deleteStoreDemoData());
    setShowDialogDeleteDemoData(false);
  };

  const handleAccountDataDelete = () => {
    dispatch(deleteStoreAccountData());
    setShowDialogDeleteData(false);
  };

  const handleAccountDelete = () => {
    dispatch(deleteStoreAccount());
    setShowDialogDeleteAccount(false);
  };
  return (
    <Paper className={classes.root}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="textSecondary" gutterBottom>
              Account Settings
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              onClick={() => setShowDialogDeleteDemoData(true)}
            >
              Clear Test Data
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              variant="outlined"
              color="primary"
              onClick={() => setShowDialogDeleteData(true)}
            >
              Clear All My Data
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={loading}
              variant="outlined"
              color="secondary"
              onClick={() => setShowDialogDeleteAccount(true)}
            >
              Clear All My Data and Delete My Account
            </Button>
          </Grid>
        </Grid>
      </form>
      {showDialogDeleteData && (
        <Dialog
          open={showDialogDeleteData}
          onClose={() => setShowDialogDeleteData(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Store Data Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the data for this store. Doing
              this is irreversible
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleAccountDataDelete()}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={() => setShowDialogDeleteData(false)}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showDialogDeleteAccount && (
        <Dialog
          open={showDialogDeleteAccount}
          onClose={() => setShowDialogDeleteAccount(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Account and Store Data Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the data for this store including
              your account. Doing this is irreversible
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleAccountDelete()}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={() => setShowDialogDeleteAccount(false)}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {showDialogDeleteDemoData && (
        <Dialog
          open={showDialogDeleteDemoData}
          onClose={() => setShowDialogDeleteDemoData(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Confirm Store Demo Data Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete the demo data for this store.
              Doing this is irreversible
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => handleDemoDataDelete()}
              color="primary"
              autoFocus
            >
              Yes
            </Button>
            <Button
              onClick={() => setShowDialogDeleteDemoData(false)}
              color="primary"
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Paper>
  );
};

export default SettingsAccountSettings;
