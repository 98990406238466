import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3, 2),
    // display: 'flex',
    height: '100%',
    // maxHeight:'100vh'
  },
  formControl: {
    marginTop: '2rem',
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem',
    paddingBottom: '0.5rem',
  },
}));

const options = [
  {
    name: 'Categories',
    link: '/superadmin/demodata/categories',
  },
  {
    name: 'Sub Categories',
    link: '/superadmin/demodata/sub-categories',
  },
  {
    name: 'Products',
    link: '/superadmin/demodata/products',
  },
];

const DemoDataTab = ({ location }) => {
  const classes = useStyles();

  return (
    <Paper elevation={1} className={classes.root}>
      <List component="nav">
        {options.map((option, index) => (
          <ListItem
            key={index}
            button
            component={Link}
            to={option.link}
            selected={location && location.pathname.includes(option.link)}
            // onClick={(event) => handleListItemClick(event, index)}
          >
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Paper>
  );
};

export default DemoDataTab;
