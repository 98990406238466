import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setupDemoStore } from 'redux/store/storeActions';
import CashierIcon from 'shared/icons/cashier.svg';
import ItemsIcon from 'shared/icons/items.svg';
import StoreCheck from 'shared/icons/store-check.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    width: '100%',
    display: 'flex',
    // padding: theme.spacing(4),
  },
  image: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
    borderRadius: '0.3rem',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  contentBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const SetupStoreData = ({ handleNext }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = useSelector((state) => state.notification.notification);
  const loading = useSelector((state) => state.notification.loading);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (option && option.includes('demodata')) {
      dispatch(setupDemoStore());
    } else {
      navigate(`/admin`);
    }
  };

  const [option, setOption] = React.useState('skip');

  const handleOption = (event, newOption) => {
    setOption(newOption);
  };

  useEffect(() => {
    if (notification && notification.message.includes('Demo Store Setup')) {
      navigate(`/admin`);
      // handleNext()
    }
    // eslint-disable-next-line
  }, [notification]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={4}>
        <img src={CashierIcon} alt="Store Data" width={256} />
      </Grid>
      <Grid item xs={12} md={8}>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={4}>
            <Grid item xs={12} md={12}>
              <Typography variant="h4">
                <strong>How do you want to get started?</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography variant="body2">
                We&apos;re here to help you get the most out of Sturr during
                your trial
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <ToggleButtonGroup
                exclusive
                value={option}
                onChange={handleOption}
                aria-label="text alignment"
              >
                <ToggleButton value="skip" aria-label="demodata">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img src={StoreCheck} alt="Store Data" width={256} />
                    <Typography variant="body1" style={{ marginTop: '2rem' }}>
                      <strong> I&apos;m want to setup my own Store</strong>
                    </Typography>
                  </div>
                </ToggleButton>
                <ToggleButton value="demodata" aria-label="centered">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img src={ItemsIcon} alt="Store Data" width={256} />
                    <Typography variant="body1" style={{ marginTop: '2rem' }}>
                      <strong> I want start with some Sample Data</strong>
                    </Typography>
                  </div>
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

SetupStoreData.label = 'Setup your Store';

export default SetupStoreData;
