import {
  ADD_INVENTORY_COUNT,
  DELETE_INVENTORY_COUNT,
  GET_INVENTORY_COUNT,
  GET_INVENTORY_COUNTS,
  INVENTORY_COUNT_ERROR,
  UPDATE_INVENTORY_COUNT,
  ADD_INVENTORY_COUNT_ITEM,
  UPDATE_INVENTORY_COUNT_ITEM,
  SET_INVENTORY_COUNT,
  DELETE_INVENTORY_COUNT_ITEM,
} from './inventoryCountTypes';

const initialState = {
  inventoryCounts: null,
  current: null,
  loading: false,
  error: null,
  new: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_INVENTORY_COUNT:
      return {
        ...state,
        inventoryCounts: {
          ...state.inventoryCounts,
          data:
            state.inventoryCounts !== null
              ? state.inventoryCounts.data.concat(action.payload)
              : [action.payload],
        },
        current: null,
      };

    case UPDATE_INVENTORY_COUNT:
      return {
        ...state,
        inventoryCounts: {
          ...state.inventoryCounts,
          data:
            state.inventoryCounts !== null
              ? state.inventoryCounts.data.map((inventoryCount) =>
                  inventoryCount.id === action.payload.id
                    ? action.payload
                    : inventoryCount
                )
              : [action.payload],
        },
        current: action.payload,
      };
    case GET_INVENTORY_COUNTS:
      return {
        ...state,
        inventoryCounts: action.payload,
        current: null,
      };
    case GET_INVENTORY_COUNT:
      return {
        ...state,
        current: action.payload,
      };
    case DELETE_INVENTORY_COUNT:
      return {
        ...state,
        inventoryCounts: {
          ...state.inventoryCounts,
          data:
            state.inventoryCounts &&
            state.inventoryCounts.data &&
            state.inventoryCounts.data.filter(
              (inventoryCount) => inventoryCount.id !== action.payload
            ),
        },
      };
    case INVENTORY_COUNT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_INVENTORY_COUNT:
      return {
        ...state,
        current: Object.assign({}, state.current, action.payload),
      };
    case DELETE_INVENTORY_COUNT_ITEM:
      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.filter(
                  (item) => item.productId !== action.payload
                )
              : state.new.items,
        },
      };
    case ADD_INVENTORY_COUNT_ITEM:
      const itemExists =
        state.new &&
        state.new.items &&
        state.new.items.find(
          (item) => item.productId === action.payload.productId
        );
      if (itemExists) {
        return { ...state };
      }

      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.concat(action.payload)
              : [action.payload],
        },
      };
    case UPDATE_INVENTORY_COUNT_ITEM:
      return {
        ...state,
        new: {
          ...state.new,
          items:
            state.new && state.new.items
              ? state.new.items.map((item) =>
                  item.id === action.payload.id ? action.payload : item
                )
              : state.new.items,
        },
      };

    default:
      return state;
  }
};
