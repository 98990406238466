import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import { makeStyles } from '@mui/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { openCashRegister } from '../../../redux/cash-register/cashRegisterActions';
import { NumberFormatCustom } from '../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  avatar: {
    color: '#FFF',
    fontSize: '2em',
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const SellOpenCashRegister = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector((state) => state.notification.loading);
  const notification = useSelector((state) => state.notification.notification);
  const page = useSelector((state) => state.page.page);
  const store = useSelector((state) => state.auth.store);
  const currentCashRegister = useSelector(
    (state) => state.cashRegister.current
  );
  const currentDevice = useSelector((state) => state.device.current);

  const [form, setForm] = useState({
    cashMovementAmount: 0,
    cashMovementNotes: '',
  });

  useEffect(() => {
    if (currentDevice) {
      setForm({ ...form, deviceId: currentDevice.id });
    }
    // eslint-disable-next-line
  }, [currentDevice]);

  useEffect(() => {
    if (currentCashRegister) {
      navigate('/admin/sell/cash-management');
    }
    // eslint-disable-next-line
  }, [currentCashRegister]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (
      page &&
      page.includes('Open Register') &&
      notification &&
      notification.type === 'success'
    ) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [notification]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(openCashRegister(form));
  };

  return (
    <Paper className={classes.root}>
      <form onSubmit={handleSubmit} className={classes.form} noValidate>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon style={{ fontSize: '6rem' }} fontSize="large" />
            </Avatar>
            <Typography variant="h4">
              <strong>Register Closed</strong>
            </Typography>
            <Typography variant="h5">
              Set an opening float to open the register and make a sale.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              onChange={onChange}
              label="Opening Float"
              name="cashMovementAmount"
              autoFocus
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {store.currency}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              multiline
              onChange={onChange}
              id="notes"
              label="Notes"
              name="cashMovementNotes"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              size={'large'}
              color="primary"
              className={classes.submit}
              disabled={loading || form.cashMovementAmount.length === 0}
            >
              Open Register
            </Button>
          </Grid>

          <Grid item xs={12}>
            {loading && <LinearProgress variant="query" color="secondary" />}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SellOpenCashRegister;
