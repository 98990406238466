import {
  SET_LOADING,
  CLEAR_LOADING,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
} from '../notification/notificationTypes';
import axios from 'axios';
import { GET_STORE, GET_STORES, REMOVE_STORE, STORE_ERROR } from './storeTypes';
import { GET_USER_STORE, UPDATE_CURRENT_STORE } from '../auth/authTypes';

const url = process.env.REACT_APP_API_URL + 'store';

let notification;

// Create Store
export const addStore = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}`, data);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    const store = res.data;
    notification = {
      message: `Store : ${store.name} created successfully`,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Create Store',
    });
  }
};

// Update Store

export const updateStore = (data, id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.put(`${url}/${id}`, data);

    dispatch({ type: CLEAR_LOADING });
    // dispatch({ type: UPDATE_UNIT, payload: res.data });
    // Set Register Success Notification

    const store = res.data;
    const notification = {
      message: `Updated Store ${store.name} successfully`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Update Store',
    });
  }
};

// Update Current Store

export const updateCurrentStore = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const res = await axios.put(`${url}/owner`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_CURRENT_STORE, payload: res.data });
    // Set Register Success Notification

    // const store = res.data;
    notification = {
      message: `Updated Store`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Update Store',
    });
  }
};

// Update Current Store

export const updateCurrentStoreImage = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const res = await axios.put(`${url}/image`, data);

    dispatch({ type: CLEAR_LOADING });
    dispatch({ type: UPDATE_CURRENT_STORE, payload: res.data });
    // Set Register Success Notification

    // const store = res.data;
    notification = {
      message: `Updated Store Image`,
      type: 'success',
    };
    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Update Store',
    });
  }
};

// Get Stores
export const getStores = (filter) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  const res = await axios.get(`${url}`, {
    params: filter,
  });
  const stores = res.data;
  dispatch({ type: GET_STORES, payload: stores });
  dispatch({ type: CLEAR_LOADING });
};

// Get Store By ID
export const getStoreByID = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/${id}`);
    const store = res.data;
    dispatch({ type: GET_STORE, payload: store });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Get Store ',
    });
  }
};

// Get Store By ID
export const getStoreByPrivateUrl = (name) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    const res = await axios.get(`${url}/url`, { params: { name } });
    const store = res.data;

    localStorage.setItem('store_token', store.id);

    dispatch({ type: GET_USER_STORE, payload: store });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Get Store ',
    });
  }
};

export const checkIfStoreExists = (name) => async () => {
  try {
    const result = await axios.get(`${url}/exists`, { params: { name } });
    return result.data;
  } catch (error) {
    return false;
  }
};

// Delete Store
export const deleteStore = (id) => async (dispatch) => {
  dispatch({ type: SET_LOADING });

  try {
    await axios.delete(`${url}/${id}`);
    const notification = {
      message: `Deleted Store`,
      type: 'info',
    };

    dispatch({ type: REMOVE_STORE, payload: id });

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Delete Store',
    });
  }
};

//Get
export const getCurrentStore = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const resStore = await axios.get(`${url}store/current`);
    const store = resStore.data;
    dispatch({ type: GET_USER_STORE, payload: store });

    dispatch({ type: CLEAR_LOADING });
  } catch (error) {
    dispatch({
      type: SET_NOTIFICATION,
      payload: { message: 'Failed to Fetch Store', type: 'error' },
    });
    dispatch({ type: CLEAR_LOADING });

    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);

    console.log(error);
    dispatch({ type: STORE_ERROR, payload: 'Failed to get current store' });
  }
};

// Setup Demo Store
export const setupDemoStore = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const res = await axios.post(`${url}/importdemo`);

    dispatch({ type: CLEAR_LOADING });
    // Set Register Success Notification

    notification = {
      message: res.data.message,
      type: 'success',
    };

    dispatch({ type: SET_NOTIFICATION, payload: notification });
    dispatch({ type: CLEAR_LOADING });
    setTimeout(() => {
      dispatch({ type: CLEAR_NOTIFICATION });
    }, 1000);
  } catch (error) {
    dispatch({ type: CLEAR_LOADING });

    console.log(error);
    dispatch({
      type: STORE_ERROR,
      payload: 'Failed to Setup Store',
    });
  }
};
