import React from 'react';
import { makeStyles } from '@mui/styles';
import { Routes, Route } from 'react-router-dom';
import DemoDataTab from './DemoDataTab';
import DemoCategoryList from './categories/DemoCategoryList';
import DemoAddCategory from './categories/DemoAddCategory';
import DemoEditCategory from './categories/DemoEditCategory';
import ProductList from '../products/ProductList';
import AddEditProduct from '../products/AddEditProduct';
import EditDemoSubCategory from './subcategories/EditDemoSubCategory';
import AddDemoSubCategory from './subcategories/AddDemoSubCategory';
import DemoSubCategoryList from './subcategories/DemoSubCategoryList';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: theme.spacing(3, 2),
    display: 'flex',
    height: '100%',
    width: '100%',
  },
  sidebar: {
    flex: 0.1,
    alignItems: 'stretch',
    // background:'#F0F0F0'
  },
  container: {
    flex: 0.9,
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));
const DemoDataHome = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.sidebar}>
        <DemoDataTab {...props} />
      </div>
      <div className={classes.container}>
        <Routes>
          <Route exact path="categories" element={<DemoCategoryList />} />
          <Route exact path="categories/add" element={<DemoAddCategory />} />
          <Route
            exact
            path="categories/edit/:id"
            element={<DemoEditCategory />}
          />
          <Route
            exact
            path="sub-categories"
            element={<DemoSubCategoryList />}
          />
          <Route
            exact
            path="sub-categories/add"
            element={<AddDemoSubCategory />}
          />
          <Route
            exact
            path="sub-categories/edit/:id"
            element={<EditDemoSubCategory />}
          />
          <Route exact path="products/edit/:id" element={<AddEditProduct />} />
          <Route exact path="products/add" element={<AddEditProduct />} />
          <Route exact path="products" element={<ProductList />} />
        </Routes>
      </div>
    </div>
  );
};

export default DemoDataHome;
